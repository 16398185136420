<template>

  <div>
    <div class="card cr-card" style="margin:0px 30px 30px 30px;">
      <div class="card-body">
        <div class="no-print" style="float: right;position: relative;top:1px;"><button class="btn btn-outline-primary btn-sm" v-on:click="activateAddOperationalExperienceModal"><fai icon="plus"></fai> Add Ops Experience</button></div>
        <h5 class="card-title">{{sectionTitle}}</h5>
        <h6 class="card-subtitle mb-2 text-muted" v-if="sectionSubtitle">{{sectionSubtitle}}</h6>
        <div class="table-responsive-xxl">
          <table class="cr-resume-table table table-striped table-hover">
            <thead>
              <tr>
                <th class="cr-resume-background-none cr-resume-border-bottom-none cr-resume-border-right">&nbsp;</th>
                <th colspan="3" class="cr-resume-border-top cr-resume-border-right fs-5 text-center">As Captain</th>
                <th colspan="3" class="cr-resume-border-top cr-resume-border-right fs-5 text-center">As Crew</th>
                <th colspan="3" class="cr-resume-border-top cr-resume-border-right fs-5 text-center">Total</th>
              </tr>
              <tr>
                <th class="cr-resume-background-none cr-resume-border-top-none cr-resume-border-right">&nbsp;</th>
                <th class="text-center text-nowrap">Less than<br>8.5m (28 ft)</th>
                <th class="text-center text-nowrap">8.5m (28 ft)<br>- 12m (39 ft)</th>
                <th class="text-center text-nowrap cr-resume-border-right">Greater than<br>12m (39 ft)</th>
                <th class="text-center text-nowrap">Less than<br>8.5m (28 ft)</th>
                <th class="text-center text-nowrap">8.5m (28 ft)<br>- 12m (39 ft)</th>
                <th class="text-center text-nowrap cr-resume-border-right">Greater than<br>12m (39 ft)</th>
                <th class="text-center text-nowrap">Less than<br>8.5m (28 ft)</th>
                <th class="text-center text-nowrap">8.5m (28 ft)<br>- 12m (39 ft)</th>
                <th class="text-center text-nowrap cr-resume-border-right">Greater than<br>12m (39 ft)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="cr-resume-border-right" v-if="forPrint">Docking at Marina</td>
                <td class="text-nowrap cr-resume-border-right" v-else>Docking at Marina</td>
                <td class="text-center" v-html="formatResumeDaysCell(sectionData.captain.dockingMarina.small)"></td>
                <td class="text-center" v-html="formatResumeDaysCell(sectionData.captain.dockingMarina.medium)"></td>
                <td class="text-center cr-resume-border-right" v-html="formatResumeDaysCell(sectionData.captain.dockingMarina.large)"></td>
                <td class="text-center" v-html="formatResumeDaysCell(sectionData.crew.dockingMarina.small)"></td>
                <td class="text-center" v-html="formatResumeDaysCell(sectionData.crew.dockingMarina.medium)"></td>
                <td class="text-center cr-resume-border-right" v-html="formatResumeDaysCell(sectionData.crew.dockingMarina.large)"></td>
                <td class="text-center" v-html="formatResumeDaysCell(sectionData.total.dockingMarina.small)"></td>
                <td class="text-center" v-html="formatResumeDaysCell(sectionData.total.dockingMarina.medium)"></td>
                <td class="text-center cr-resume-border-right" v-html="formatResumeDaysCell(sectionData.total.dockingMarina.large)"></td>
              </tr>
              <tr>
                <td class="cr-resume-border-right" v-if="forPrint">Day Anchoring / Mooring Ball</td>
                <td class="text-nowrap cr-resume-border-right" v-else>Day Anchoring/Mooring Ball</td>
                <td class="text-center" v-html="formatResumeDaysCell(sectionData.captain.dayAnchoring.small)"></td>
                <td class="text-center" v-html="formatResumeDaysCell(sectionData.captain.dayAnchoring.medium)"></td>
                <td class="text-center cr-resume-border-right" v-html="formatResumeDaysCell(sectionData.captain.dayAnchoring.large)"></td>
                <td class="text-center" v-html="formatResumeDaysCell(sectionData.crew.dayAnchoring.small)"></td>
                <td class="text-center" v-html="formatResumeDaysCell(sectionData.crew.dayAnchoring.medium)"></td>
                <td class="text-center cr-resume-border-right" v-html="formatResumeDaysCell(sectionData.crew.dayAnchoring.large)"></td>
                <td class="text-center" v-html="formatResumeDaysCell(sectionData.total.dayAnchoring.small)"></td>
                <td class="text-center" v-html="formatResumeDaysCell(sectionData.total.dayAnchoring.medium)"></td>
                <td class="text-center cr-resume-border-right" v-html="formatResumeDaysCell(sectionData.total.dayAnchoring.large)"></td>
              </tr>
              <tr>
                <td class="cr-resume-border-right" v-if="forPrint">Overnight Anchoring / Mooring Ball</td>
                <td class="text-nowrap cr-resume-border-right" v-else>Overnight Anchoring/Mooring Ball</td>
                <td class="text-center" v-html="formatResumeDaysCell(sectionData.captain.overnightAnchoring.small)"></td>
                <td class="text-center" v-html="formatResumeDaysCell(sectionData.captain.overnightAnchoring.medium)"></td>
                <td class="text-center cr-resume-border-right" v-html="formatResumeDaysCell(sectionData.captain.overnightAnchoring.large)"></td>
                <td class="text-center" v-html="formatResumeDaysCell(sectionData.crew.overnightAnchoring.small)"></td>
                <td class="text-center" v-html="formatResumeDaysCell(sectionData.crew.overnightAnchoring.medium)"></td>
                <td class="text-center cr-resume-border-right" v-html="formatResumeDaysCell(sectionData.crew.overnightAnchoring.large)"></td>
                <td class="text-center" v-html="formatResumeDaysCell(sectionData.total.overnightAnchoring.small)"></td>
                <td class="text-center" v-html="formatResumeDaysCell(sectionData.total.overnightAnchoring.medium)"></td>
                <td class="text-center cr-resume-border-right" v-html="formatResumeDaysCell(sectionData.total.overnightAnchoring.large)"></td>
              </tr>
              <tr>
                <td class="cr-resume-border-right" v-if="forPrint">Mediterranean Mooring</td>
                <td class="text-nowrap cr-resume-border-right" v-else>Mediterranean Mooring</td>
                <td class="text-center" v-html="formatResumeDaysCell(sectionData.captain.mediterraneanMooring.small)"></td>
                <td class="text-center" v-html="formatResumeDaysCell(sectionData.captain.mediterraneanMooring.medium)"></td>
                <td class="text-center cr-resume-border-right" v-html="formatResumeDaysCell(sectionData.captain.mediterraneanMooring.large)"></td>
                <td class="text-center" v-html="formatResumeDaysCell(sectionData.crew.mediterraneanMooring.small)"></td>
                <td class="text-center" v-html="formatResumeDaysCell(sectionData.crew.mediterraneanMooring.medium)"></td>
                <td class="text-center cr-resume-border-right" v-html="formatResumeDaysCell(sectionData.crew.mediterraneanMooring.large)"></td>
                <td class="text-center" v-html="formatResumeDaysCell(sectionData.total.mediterraneanMooring.small)"></td>
                <td class="text-center" v-html="formatResumeDaysCell(sectionData.total.mediterraneanMooring.medium)"></td>
                <td class="text-center cr-resume-border-right" v-html="formatResumeDaysCell(sectionData.total.mediterraneanMooring.large)"></td>
              </tr>
              <tr>
                <td class="cr-resume-border-right" v-if="forPrint">24 Hour Charter Periods</td>
                <td class="text-nowrap cr-resume-border-right" v-else>24 Hour Charter Periods</td>
                <td class="text-center" v-html="formatResumeDaysCell(sectionData.captain.twentyFourHourCharterPeriods.small)"></td>
                <td class="text-center" v-html="formatResumeDaysCell(sectionData.captain.twentyFourHourCharterPeriods.medium)"></td>
                <td class="text-center cr-resume-border-right" v-html="formatResumeDaysCell(sectionData.captain.twentyFourHourCharterPeriods.large)"></td>
                <td class="text-center" v-html="formatResumeDaysCell(sectionData.crew.twentyFourHourCharterPeriods.small)"></td>
                <td class="text-center" v-html="formatResumeDaysCell(sectionData.crew.twentyFourHourCharterPeriods.medium)"></td>
                <td class="text-center cr-resume-border-right" v-html="formatResumeDaysCell(sectionData.crew.twentyFourHourCharterPeriods.large)"></td>
                <td class="text-center" v-html="formatResumeDaysCell(sectionData.total.twentyFourHourCharterPeriods.small)"></td>
                <td class="text-center" v-html="formatResumeDaysCell(sectionData.total.twentyFourHourCharterPeriods.medium)"></td>
                <td class="text-center cr-resume-border-right" v-html="formatResumeDaysCell(sectionData.total.twentyFourHourCharterPeriods.large)"></td>
              </tr>
              <tr>
                <td class="cr-resume-border-right" v-if="forPrint">Overnight Sailing</td>
                <td class="text-nowrap cr-resume-border-right" v-else>Overnight Sailing</td>
                <td class="text-center" v-html="formatResumeDaysCell(sectionData.captain.overnightSailing.small)"></td>
                <td class="text-center" v-html="formatResumeDaysCell(sectionData.captain.overnightSailing.medium)"></td>
                <td class="text-center cr-resume-border-right" v-html="formatResumeDaysCell(sectionData.captain.overnightSailing.large)"></td>
                <td class="text-center" v-html="formatResumeDaysCell(sectionData.crew.overnightSailing.small)"></td>
                <td class="text-center" v-html="formatResumeDaysCell(sectionData.crew.overnightSailing.medium)"></td>
                <td class="text-center cr-resume-border-right" v-html="formatResumeDaysCell(sectionData.crew.overnightSailing.large)"></td>
                <td class="text-center" v-html="formatResumeDaysCell(sectionData.total.overnightSailing.small)"></td>
                <td class="text-center" v-html="formatResumeDaysCell(sectionData.total.overnightSailing.medium)"></td>
                <td class="text-center cr-resume-border-right" v-html="formatResumeDaysCell(sectionData.total.overnightSailing.large)"></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="cr-resume-footer-note">All operational experience is self stated by sailor and not independently verified.</div>
      </div>
    </div>
  </div>

  <addOperationalExperienceModal
      ref="addOperationalExperienceModal"
      v-on:addedNewOperationalExperience="addedNewOperationalExperience"></addOperationalExperienceModal>

</template>
<script>

export default {
  name: 'ResumeSectionOperationalExperienceView',
  props: {
    'sectionTitle': String,
    'sectionSubtitle':  String,
    'sectionData': Object,
    'forPrint': Boolean,
    'updateResume': Function
  },
  methods: {
    formatResumeDaysCell(aCell) {
      if ( aCell.experience > 0 ) {
        return '<div>'+aCell.experience+'</div>'
      } else {
        return '--'
      }
    },
    activateAddOperationalExperienceModal() {
      this.$refs.addOperationalExperienceModal.activateModal()
    },
    addedNewOperationalExperience() {
      this.updateResume()
    }
  }
}
</script>

<style>
.cr-resume-table {
  font-size:12px;
  width: 100%;
}

.cr-resume-table th {
  background: #f6f6f6;
}

.cr-resume-background-none {
  background: transparent!important;
}

.cr-resume-border-top {
  border-top: 1px solid #ccc;
}
.cr-resume-border-right {
  border-right: 1px solid #ccc;
}

.cr-resume-border-top-none {
  border-top:none!important;
}
.cr-resume-border-bottom-none {
  border-bottom:none!important;
}

.cr-resume-footer-note {
  font-size: 11px;
  color:#999;
}
</style>