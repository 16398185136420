<template>

  <teleport to="body">
    <!-- Modal -->
    <div class="modal fade" id="viewVesselModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-fullscreen">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="viewVesselLabel">View Vessel Specifications</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="pb-3 charterRodeHelpText">Below are the specifications for this vessel. Remember, once a vessel is added it is never editable to ensure data integrity on Charter Rode. If need to make a change, you must add a new vessel with the correct specifications.</div>

            <form autocomplete="off" novalidate v-on:submit.prevent="noop">
              <div class="container">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="form-floating mb-4">
                      <select class="form-control form-control-sm" id="newVessel.owner" v-model="newVessel.owner" name="owner" disabled>
                        <option value="please_select" disabled selected>Please Select</option>
                        <option value="self">I Own This Vessel</option>
                        <option value="friend">My Friend Owns This Vessel</option>
                        <option value="charter_base">I Charter This Vessel</option>
                        <option value="commercial">Commercial</option>
                      </select>
                      <label for="newVessel.owner">Who Owns This Vessel</label>
                    </div>
                  </div>
                </div>

                <div class="row" v-show="newVessel.owner === 'commercial'">
                  <div class="col-lg-6">
                    <div class="form-floating mb-4">
                      <input type="text" class="form-control" id="newVessel.commercialOwnerName" name="commercialOwnerName" v-model="newVessel.commercialOwnerName" disabled>
                      <label for="newVessel.commercialOwnerName">Owner Company Name</label>
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div class="form-floating mb-4">
                      <input type="text" class="form-control" id="newVessel.commercialVesselFlag" name="commercialVesselFlag" v-model="newVessel.commercialVesselFlag" disabled>
                      <label class="d-none d-md-block" for="newVessel.commercialVesselFlag">Vessel Flag (Country)</label>
                      <label class="d-block d-md-none" for="newVessel.commercialVesselFlag">Vessel Flag</label>
                    </div>
                  </div>
                </div>

                <div class="row" v-show="newVessel.owner === 'charter_base'">
                  <div class="col-lg-6">
                    <div class="form-floating mb-4">
                      <input type="text" class="form-control" id="newVessel.charterCompanyName" name="charterCompanyName" v-model="newVessel.charterCompanyName" disabled>
                      <label for="newVessel.charterCompanyName">Charter Company Name</label>
                    </div>
                  </div>


                  <div class="col-lg-6">
                    <div class="form-floating mb-4">
                      <input type="text" class="form-control" id="charterBase" name="charterBase" v-model="newVessel.charterBase" disabled>
                      <label class="d-none d-xl-block" for="charterBase">Charter Base Location (City, Province, Country)</label>
                      <label class="d-block d-xl-none" for="charterBase">Charter Base Location</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-6">
                    <div class="form-floating mb-4">
                      <input type="text" class="form-control" id="newVessel.vesselName" name="vesselName" v-model="newVessel.vesselName" disabled>
                      <label for="newVessel.vesselName">Vessel Name</label>
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div class="form-floating mb-4">
                      <input type="number" class="form-control" id="newVessel.year" name="year" min="1950" max="2023" step="1" v-model="newVessel.year" disabled>
                      <label for="newVessel.year">Year</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-6">
                    <div class="form-floating mb-4">
                      <input type="text" class="form-control" id="newVessel.manufacturer" name="manufacturer" v-model="newVessel.manufacturer" disabled>
                      <label for="newVessel.manufacturer">Manufacturer</label>
                    </div>
                  </div>


                  <div class="col-lg-6">
                    <div class="form-floating mb-4">
                      <input type="text" class="form-control" id="newVessel.model" name="model" v-model="newVessel.model" disabled>
                      <label for="newVessel.model">Model</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-12">
                    <div class="form-floating mb-4">
                      <input type="text" class="form-control" id="newVessel.registrationInfo" name="registrationInfo" v-model="newVessel.registrationInfo" disabled>
                      <label for="newVessel.vesselName">Registration Info</label>
                      <div id="newVesselRegistrationInfoHelpBlock" class="form-text"><span v-show="newVessel.owner != 'commercial'"><strong>Optional</strong> - official number(s) listed on the registration, certificate, or document (for use with <a href="https://www.dco.uscg.mil/Portals/9/NMC/pdfs/forms/CG_719S.pdf" target="_blank">USCG Form CG-719S</a> to document <a href="https://www.dco.uscg.mil/Portals/9/NMC/pdfs/professional_qualifications/crediting_sea_service.pdf" target="_blank">small vessel sea time</a> for USCG Credentials).</span><span v-show="newVessel.owner === 'commercial'">Official number(s) listed on the registration, certificate, or document.</span></div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-6">
                    <div class="form-floating mb-4">
                      <input type="number" class="form-control" id="newVessel.grossTons" name="grossTons" min=".1" max="250" step=".1" v-model="newVessel.grossTons" disabled>
                      <label for="newVessel.grossTons">Gross Tonnage (GT)</label>
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div class="form-floating mb-4">
                      <input type="number" class="form-control" id="newVessel.displacementTons" name="displacementTons" min=".1" max="250" step=".1" v-model="newVessel.displacementTons" disabled>
                      <label for="newVessel.displacementTons">Displacement (tons)</label>
                    </div>
                  </div>
                </div>

                <div class="row">

                  <div class="col-lg-6">
                    <div class="form-floating mb-4">
                      <input type="number" class="form-control" id="newVessel.overallLengthMeter" name="overallLengthMeter" min=".5" max="250" step=".1" v-model="newVessel.overallLengthMeter" disabled>
                      <label for="newVessel.overallLengthMeter" v-if="user.preferredUnits == 'metric'">Overall Length (meters)</label>
                      <label for="newVessel.overallLengthMeter" v-else>Overall Length (feet)</label>
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div class="form-floating mb-4">
                      <input type="text" class="form-control" id="newVessel.waterLineLengthMeter" name="waterLineLengthMeter" min=".5" max="250" step=".1" v-model="newVessel.waterLineLengthMeter" disabled>
                      <label for="newVessel.waterLineLengthMeter" v-if="user.preferredUnits == 'metric'">Water Line Length (meters)</label>
                      <label for="newVessel.waterLineLengthMeter" v-else>Water Line Length (feet)</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-6">
                    <div class="form-floating mb-4">
                      <input type="number" class="form-control" id="newVessel.beamMeter" name="beamMeter" min=".5" max="250" step=".1" v-model="newVessel.beamMeter" disabled>
                      <label for="newVessel.beamMeter" v-if="user.preferredUnits == 'metric'">Beam (meters)</label>
                      <label for="newVessel.beamMeter" v-else>Beam (feet)</label>
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div class="form-floating mb-4">
                      <input type="number" class="form-control" id="newVessel.draftMeter" name="draftMeter" min="0" max="30" step=".1" v-model="newVessel.draftMeter" disabled>
                      <label for="newVessel.draftMeter" v-if="user.preferredUnits == 'metric'">Draft (meters)</label>
                      <label for="newVessel.draftMeter" v-else>Draft (feet)</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-6">
                    <div class="form-floating mb-4">
                      <select class="form-control form-control-sm" id="newVessel.hulls" name="hulls" v-model="newVessel.hulls" disabled>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </select>
                      <label for="newVessel.hulls"># Hulls</label>
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div class="form-floating mb-4">
                      <select class="form-control form-control-sm" id="vessel.propulsion" name="vessel.propulsion" v-model="newVessel.propulsion" disabled>
                        <option value="sail">Sail</option>
                        <option value="power">Power</option>
                      </select>
                      <label for="vessel.propulsion">Propulsion</label>
                    </div>
                  </div>
                </div>

              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
import {Modal} from "bootstrap"

export default {
  name: 'ViewVesselModal',
  emits: ['modalClose'],
  props: {
    'user': Object
  },
  data() {
    return {
      newVessel: {
        owner: "please_select",
        charterCompanyName: undefined,
        charterBase: undefined,
        commercialOwnerName:undefined,
        commercialVesselFlag: undefined,
        vesselName: undefined,
        year: undefined,
        manufacturer: undefined,
        model: undefined,
        registrationInfo: undefined,
        grossTons: undefined,
        displacementTons: undefined,
        overallLengthMeter: undefined,
        waterLineLengthMeter: undefined,
        beamMeter: undefined,
        draftMeter: undefined,
        hulls: "1",
        propulsion: "sail"
      }
    }
  },
  methods: {
    activateModal(theVessel) {
      this.newVessel = {
        owner: 'please_select',
        charterCompanyName: theVessel.charterCompanyName,
        charterBase: theVessel.charterBase,
        commercialOwnerName: theVessel.commercialOwnerName,
        commercialVesselFlag: theVessel.commercialVesselFlag,
        vesselName: theVessel.vesselName,
        year: theVessel.year,
        manufacturer: theVessel.manufacturer,
        model: theVessel.model,
        registrationInfo: theVessel.registrationInfo,
        grossTons: theVessel.grossTons,
        displacementTons: theVessel.displacementTons,
        overallLengthMeter: theVessel.overallLengthMeter,
        waterLineLengthMeter: theVessel.waterLineLengthMeter,
        beamMeter: theVessel.beamMeter,
        draftMeter: theVessel.draftMeter,
        hulls: theVessel.hulls,
        propulsion: theVessel.propulsion
      }
      if ( theVessel.ownerType === 'private' && theVessel.ownerId === this.user.id ) {
        this.newVessel.owner = 'self';
      } else if ( theVessel.ownerType === 'private' && theVessel.ownerId != this.user.id ) {
        this.newVessel.owner = 'friend';
      } else if ( theVessel.ownerType === 'charter_base' ) {
        this.newVessel.owner = 'charter_base';
      } else if ( theVessel.ownerType === 'commercial' ) {
        this.newVessel.owner = 'commercial';
      }

      this.viewVesModal = new Modal(document.getElementById('viewVesselModal'))
      let addVesEl = document.getElementById('viewVesselModal')
      let self = this
      addVesEl.addEventListener('hidden.bs.modal', function () {
        self.$emit('modalClose')
      })

      // check to see if we need to convert anything from meters to feet
      if ( this.user.preferredUnits == 'imperial' ) {
        this.newVessel.overallLengthMeter = this.convertMetersToFeet(this.newVessel.overallLengthMeter)
        this.newVessel.waterLineLengthMeter = this.convertMetersToFeet(this.newVessel.waterLineLengthMeter)
        this.newVessel.beamMeter = this.convertMetersToFeet(this.newVessel.beamMeter)
        this.newVessel.draftMeter = this.convertMetersToFeet(this.newVessel.draftMeter)
      }

      this.viewVesModal.toggle()
    },
    convertMetersToFeet(aLengthMeters) {
      return (aLengthMeters*3.28084).toFixed(1)
    }
  },
  components: {

  }
}
</script>

<style>
.search-spinner {
  text-align: center;
  margin-top:5px;
  font-size:18px;
  color:#666;
  font-weight: normal;
  z-index: 9999;
}
.selected-row {
  background-color: #0d6efd25;
}
</style>