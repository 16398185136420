<template>
  <div class="container">
    <div class="row">
      <h4>Sign Out</h4>
      <div>If you are not automatically signed out in five seconds, <a href="#" v-on:click="setLoggedOut">click here</a>.</div>
    </div>
  </div>
</template>

<script>
import auth from "../../api/auth"

export default {
  name: 'SignOut',
  components: {

  },
  mounted() {
    // as soon as this view is loaded, logout!
    this.setLoggedOut();
  },
  methods: {
    setLoggedOut() {
      auth.signOut(this.username, this.password).then(success => {
        if (!success) {
          console.log('error during logout!')
        }
        this.$store.commit('setLoggedOut')
        this.$router.push('/')
      })
    }
  }
}
</script>

<style>

</style>
