<template>
  <div class="container mt-3">
    <div class="row justify-content-center">
      <div class="col-auto">
        <div v-if="!doingLogIn" class="card mt-3 bg-light">
          <div class="card-header" style="text-align: center;">
            <h5 class="card-title" style="margin-bottom: 0px;">Welcome to Charter Rode</h5>
          </div>
          <div class="card-body">
            <div class="mb-3" style="text-align: center;">
              <fai icon="user-circle" style="font-size: 72px; color: #ccc"></fai>
            </div>
            <h6 class="card-subtitle mb-2 text-muted" style="padding-right: 40px;">Sign in with your email and password</h6>

            <div v-if="invalidCredentials" class="alert alert-danger">Incorrect email or password.</div>
            <div v-if="signInErrorMessage" class="alert alert-danger">{{signInErrorMessage}}</div>

            <div class="card-text pt-3" style="color: #666;">
              <form autocomplete="off" novalidate v-on:submit.prevent="noop">
                <div class="mb-3">
                  <input type="email" autocomplete="username" class="form-control" id="emailID" placeholder="francis.drake@gmail.com" v-model="username" @keyup.enter="doSignIn">
                </div>
                <div class="mb-3">
                  <input type="password" autocomplete="current-password" class="form-control" id="userPassword" placeholder="Password" v-model="password" @keyup.enter="doSignIn">
                  <router-link to="/forgot-pass" style="font-size:14px;text-decoration: none;">Forgot your password?</router-link>
                </div>
              </form>
            </div>
            <div class="d-grid gap-2">
              <a class="btn btn-primary" v-on:click="doSignIn">Sign In</a>
            </div>
            <div style="margin-top: 15px;font-size:12px;color:#888;">By signing in, you agree to our most recent <a style="text-decoration: none;" href="https://www.charterrode.com/terms-of-use/" target="_blank">Terms of Use</a> and <a style="text-decoration: none;" href="https://www.charterrode.com/privacy-policy/" target="_blank">Privacy Policy</a>.</div>

            <div class="card-text mt-2" style="text-align: center;font-size:14px;">Need an account?&nbsp;<router-link to="/sign-up" style="text-decoration: none;">Sign up</router-link></div>
          </div>
        </div>

        <div v-if="doingLogIn" id="process-sign-in" class="sign-in-spinner"><fai icon="spinner" spin/> Please wait while we get you signed in!</div>

      </div>
    </div>
  </div>
</template>

<script>
import auth from "../../api/auth"
import api from "../../api/api";

export default {
  name: 'SignIn',
  data() {
    return {
      username: undefined,
      password: undefined,
      doingLogIn: false,
      invalidCredentials: false,
      signInErrorMessage: undefined
    }
  },
  mounted() {
    document.getElementById('emailID').focus()
  },
  methods: {
    doSignIn() {
      this.invalidCredentials = false
      // do form validation!
      if ( this.username == undefined || this.username.trim().length <= 0 ) {
        this.invalidCredentials = true
        return
      }
      if ( this.password == undefined || this.password.trim().length <= 0 ) {
        this.invalidCredentials = true
        return
      }

      let el = document.createElement('div')
      let domString = '<div id="sign-in-backdrop" class="modal-backdrop fade in" style=""></div>'
      el.innerHTML =  domString
      document.body.appendChild(el.firstChild);
      this.doingLogIn = true
      auth.signIn(this.username, this.password).then(response => {
        if ( response && response.jwtToken && response.jwtToken.length > 0 ) {
          this.$store.commit('setJWTToken', response.jwtToken)

          // alert(theJwtToken)
          api.getMe().then(response => {
            if (response.user && response.user.firstName && response.user.lastName) {
              // all good let them go to home!
              this.$store.commit('setUser', response.user)

              // now get the drop downs
              api.getUIDropdowns().then(uiDropdowns => {
                if (uiDropdowns) {
                  this.$store.commit('setUiDropdowns', uiDropdowns)
                }
                // get rid of the static back drop
                let signInBackDrop = document.getElementById('sign-in-backdrop')
                signInBackDrop.parentNode.removeChild(signInBackDrop)

                this.$router.push('/')
              }).catch(error => {
                console.log(error)
                this.$router.push('/sign-out')
              })
            } else if ( response.createNewProfile ) {
              // need to create user
              // get rid of the static back drop
              // now get the drop downs
              api.getUIDropdowns().then(uiDropdowns => {
                if ( uiDropdowns ) {
                  this.$store.commit('setUiDropdowns', uiDropdowns)
                }
                let signInBackDrop = document.getElementById('sign-in-backdrop')
                signInBackDrop.parentNode.removeChild(signInBackDrop)
                this.$store.commit('startCreateUserFlow')
                this.$router.push('/createUser')
              }).catch(error => {
                console.log(error)
                this.$router.push('/sign-out')
              })
            } else {
              // not sure what happened, likely server-side is down....
              let signInBackDrop = document.getElementById('sign-in-backdrop')
              signInBackDrop.parentNode.removeChild(signInBackDrop)
              this.$router.push('/maintenance')
            }
          }).catch(error => {
            console.log(error)
            this.$router.push('/sign-out')
          })
        } else {
          this.doingLogIn = false
          // get rid of the static back drop
          let signInBackDrop = document.getElementById('sign-in-backdrop')
          signInBackDrop.parentNode.removeChild(signInBackDrop)

          if ( response && response.error && response.error === 'verify_email' ) {
            this.$store.commit('verifyUser', {username: this.username, password:this.password})
            this.$router.push('/sign-up')
          } else {
            // bad credentials...
            if ( response && response.message ) {
              this.signInErrorMessage = response.message
            } else {
              this.invalidCredentials = true
            }
          }
        }
      })
    }
  },
  components: {

  }
}
</script>

<style>
.sign-in-spinner {
  text-align: center;
  margin-top:50px;
  font-size:24px;
  color:#666;
  font-weight: normal;
  z-index: 9998;
}

#sign-in-backdrop {
  z-index: 9999;
}

</style>
