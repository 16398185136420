<template>
  <div id="process-sign-in"><fai icon="spinner" spin/> Please wait while we get you signed in!</div>
</template>

<script>
import api from '../../api/api'

export default {
  name: 'ProcessSignIn',
  components: {

  },
  mounted() {
    let el = document.createElement('div')
    let domString = '<div id="sign-in-backdrop" class="modal-backdrop fade in"></div>'
    el.innerHTML =  domString
    document.body.appendChild(el.firstChild);

    const urlParams = new URLSearchParams(window.location.hash.substr(window.location.hash.indexOf('id_token=')))
    const idToken = urlParams.get('id_token')
    this.$store.commit('setJWTToken',idToken)

    // alert(idToken)
    api.getMe().then(response => {
      if ( response.user && response.user.firstName && response.user.lastName ) {
        // all good let them go to home!
        this.$store.commit('setUser', response.user)

        // now get the drop downs
        api.getUIDropdowns().then(uiDropdowns => {
          if (uiDropdowns) {
            this.$store.commit('setUiDropdowns', uiDropdowns)
          }
          // get rid of the static back drop
          let signInBackDrop = document.getElementById('sign-in-backdrop')
          signInBackDrop.parentNode.removeChild(signInBackDrop)

          window.location.href = '/'
        }).catch(error => {
          console.log(error)
          this.$router.push('/signout')
        })
      } else if ( response.createNewProfile ) {
        // need to create user
        // get rid of the static back drop
        // now get the drop downs
        api.getUIDropdowns().then(uiDropdowns => {
          if ( uiDropdowns ) {
            this.$store.commit('setUiDropdowns', uiDropdowns)
          }
          let signInBackDrop = document.getElementById('sign-in-backdrop')
          signInBackDrop.parentNode.removeChild(signInBackDrop)
          this.$store.commit('startCreateUserFlow')
          this.$router.push('/createUser')
        }).catch(error => {
          console.log(error)
          this.$router.push('/signout')
        })
      } else {
        // not sure what happened, likely server-side is down....
        let signInBackDrop = document.getElementById('sign-in-backdrop')
        signInBackDrop.parentNode.removeChild(signInBackDrop)
        this.$router.push('/maintenance')
      }
    }).catch(error => {
      console.log(error)
      this.$router.push('/sign-out')
    })
  },
  methods: {

  }
}
</script>

<style>
#process-sign-in {
  text-align: center;
  margin-top:50px;
  font-size:24px;
  color:#666;
  font-weight: normal;
  z-index: 9999;
}
</style>
