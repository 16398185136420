<template>
  <form autocomplete="off" novalidate @submit.prevent="sendCreateUser">
    <div class="container">
      <div class="row mt-3 mb-3">
        <div class="col-sm-12">
          <h4>Step 1 of 3: Create Profile</h4>
          <div class="charterRodeHelpText">In order to complete your profile, we need some additional information from you.</div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="form-floating mb-3">
            <input type="text" class="form-control" v-bind:class="{'is-valid' : validation.firstName, 'is-invalid': validation.firstName == false}" v-model="user.firstName" id="firstName" name="firstName" placeholder="Art" required>
            <label for="firstName">First Name</label>
            <div class="invalid-feedback">Please let us know your name.</div>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-floating mb-3">
            <input type="text" class="form-control" v-bind:class="{'is-valid' : validation.lastName, 'is-invalid': validation.lastName == false}"  @blur="validation.lastNameBlurred = true" v-model="user.lastName" id="lastName" name="lastName" placeholder="Vandelay" required>
            <label for="lastName">Last Name</label>
            <div class="invalid-feedback">Please let us know your name.</div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="form-floating mb-3">
            <input type="email" class="form-control is-valid" v-model="user.email" id="email" name="email" placeholder="art@vandelay.com" required disabled>
            <label for="email">Email Address</label>
            <div class="invalid-feedback">Please confirm your email address.</div>
          </div>

        </div>
        <div class="col-sm-6">
          <div class="form-floating mb-3">
            <input type="text" class="form-control" v-bind:class="{'is-valid' : validation.phone, 'is-invalid': validation.phone == false}" v-model="user.phone" id="phone" name="phone" placeholder="967-5309" required>
            <label for="phone">Mobile Phone #</label>
            <div class="invalid-feedback">Please provide your mobile number so that we may reach you.</div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-6">
          <div class="form-floating mb-3">
            <input type="text" class="form-control" v-bind:class="{'is-valid' : validation.addressLine1, 'is-invalid': validation.addressLine1 == false}" v-model="user.addressLine1" id="addressLine1" name="addressLine1" placeholder="123 Acme Dr" required>
            <label for="addressLine1">Address Line 1</label>
            <div class="invalid-feedback">Please confirm your mailing address line 1.</div>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-floating mb-3">
            <input type="text" class="form-control" id="addressLine2" name="addressLine2" placeholder="Apt 5A" v-model="user.addressLine2">
            <label for="addressLine2">Address Line 2</label>
            <div class="invalid-feedback">Please confirm your mailing address line 2.</div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-6">
          <div class="form-floating mb-3">
            <input type="text" class="form-control" v-bind:class="{'is-valid' : validation.city, 'is-invalid': validation.city == false}" v-model="user.city" id="city" name="city" placeholder="Athens" required>
            <label for="city">City</label>
            <div class="invalid-feedback">Please confirm your mailing address city.</div>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-floating mb-3">
            <input type="text" class="form-control" v-bind:class="{'is-valid' : validation.province, 'is-invalid': validation.province == false}" v-model="user.province" id="province" name="province" placeholder="NA">
            <label for="province">State</label>
            <div class="invalid-feedback">Please confirm your mailing address state.</div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-6">
          <div class="form-floating mb-3">
            <input type="text" class="form-control" v-bind:class="{'is-valid' : validation.postalCode, 'is-invalid': validation.postalCode == false}" v-model="user.postalCode" id="postalCode" name="postalCode" placeholder="75309" required>
            <label for="postalCode">Postal Code</label>
            <div class="invalid-feedback">Please confirm your mailing address postal code.</div>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-floating mb-3">
            <input type="text" class="form-control" v-bind:class="{'is-valid' : validation.country, 'is-invalid': validation.country == false}" v-model="user.country" id="country" name="country" placeholder="Greece">
            <label for="country">Country</label>
            <div class="invalid-feedback">Please confirm your mailing address country.</div>
          </div>
        </div>
      </div>

      <div class="row pb-5 mb-5" v-show="!submittingCreateProfile">
        <div class="col-sm-12 mb-5">
          <div class="d-grid gap-2 col-6 mx-auto">
            <button class="btn btn-lg btn-outline-primary text-uppercase">Create Profile</button>
          </div>
        </div>
      </div>

      <div class="row pb-5 mb-5" v-show="submittingCreateProfile">
        <div class="col-sm-12">
          <div class="tx-submit-spinner mt-lg-5"><fai icon="spinner" spin/> Please wait while your profile is created</div>
        </div>
      </div>

    </div>
  </form>

</template>

<script>
import api from "../../api/api";

export default {
  name: 'CreateUser',
  data() {
    return {
      submittingCreateProfile: false,
      user: {
        firstName: undefined,
        lastName: undefined,
        email: undefined,
        phone: undefined,
        addressLine1: undefined,
        addressLine2: undefined,
        city: undefined,
        province: undefined,
        postalCode: undefined,
        country: undefined
      },
      validation : {
        firstName: undefined,
        lastName: undefined,
        email: undefined,
        phone: undefined,
        addressLine1: undefined,
        addressLine2: undefined,
        city: undefined,
        province: undefined,
        postalCode: undefined,
        country: undefined
      }
    }
  },
  mounted() {
    if ( this.$store.state.email ) {
      this.user.email = this.$store.state.email
    }
  },
  methods: {
    validateTextInput(anInput) {
      if ( !anInput ) {
        return false;
      } else if ( anInput === '' ) {
        return false;
      }
      return true;
    },
    sendCreateUser() {
      let isFormValid = true
      if ( this.user.firstName && this.user.firstName.trim().length > 0 ) {
        this.validation.firstName = true
      } else {
        this.validation.firstName = false
        isFormValid = false
      }

      if ( this.user.lastName && this.user.lastName.trim().length > 0 ) {
        this.validation.lastName = true
      } else {
        this.validation.lastName = false
        isFormValid = false
      }

      if ( this.user.phone && this.user.phone.trim().length > 0 ) {
        this.validation.phone = true
      } else {
        this.validation.phone = false
        isFormValid = false
      }

      if ( this.user.addressLine1 && this.user.addressLine1.trim().length > 0 ) {
        this.validation.addressLine1 = true
      } else {
        this.validation.addressLine1 = false
        isFormValid = false
      }

      if ( this.user.city && this.user.city.trim().length > 0 ) {
        this.validation.city = true
      } else {
        this.validation.city = false
        isFormValid = false
      }

      if ( this.user.province && this.user.province.trim().length > 0 ) {
        this.validation.province = true
      } else {
        this.validation.province = false
        isFormValid = false
      }

      if ( this.user.postalCode && this.user.postalCode.trim().length > 0 ) {
        this.validation.postalCode = true
      } else {
        this.validation.postalCode = false
        isFormValid = false
      }

      if ( this.user.country && this.user.country.trim().length > 0 ) {
        this.validation.country = true
      } else {
        this.validation.country = false
        isFormValid = false
      }

      // are we okay to continue?
      if (!isFormValid ) {
        return
      } else {
        this.submittingCreateProfile = true
        let self = this
        api.createUser(this.user).then(currentUser => {
          if (currentUser && currentUser.firstName && currentUser.lastName) {
            // all good let them go to home!
            this.$store.commit('setUser', currentUser)
            this.$store.commit('endCreateUserFlow')
            this.$router.push('/')
          } else {
            // some sort of error!
            console.log(JSON.stringify(currentUser))
          }
          self.submittingCreateProfile = false
        }).catch(error => {
          console.log(error)
          self.submittingCreateProfile = false
        })
      }
    }
  },
  components: {

  },
  computed: {
    validEmail: function() {
        return this.email !== '' && this.email.indexOf('@') > 0
    },
    validFirstName: function() {
      return this.validateTextInput(this.user.firstName)
    },
    validLastName: function() {
      return this.validateTextInput(this.user.lastName)
    },
    validPhone: function() {
      return this.validateTextInput(this.user.phone)
    },
    validAddressLine1: function() {
      return this.validateTextInput(this.user.addressLine1)
    },
    validCity: function() {
      return this.validateTextInput(this.user.city)
    },
    validProvince: function() {
      return this.validateTextInput(this.user.province)
    },
    validPostalCode: function() {
      return this.validateTextInput(this.user.postalCode)
    },
    validateCountry: function() {
      return this.validateTextInput(this.user.country)
    }
  }
}
</script>

<style>

</style>
