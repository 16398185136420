<template>
  <teleport to="body">
    <!-- Modal -->
    <div class="modal fade" id="addNewCrewmate" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true" v-on:keyup.enter="addVessel">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-fullscreen">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="addNewCrewmateLabel">Add New Crewmate</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="pb-3 charterRodeHelpText">Look for a crewmate via email. If they are not on Charter Rode yet, send them an invitation!</div>

            <form autocomplete="off" novalidate v-on:submit.prevent="noop">
              <input autocomplete="false" name="hidden" type="text" style="display:none;">
              <div class="row">
                <div class="col-lg-12">
                  <div class="form-floating mb-4">
                    <input autocomplete="new-password" type="text" class="form-control" id="searchCrewMateQuery" v-bind:name="createRandomInputName()" v-model="newCrewmate.searchQuery" v-bind:class="{'is-valid' : validation.newCrewmate.searchQuery, 'is-invalid': validation.newCrewmate.searchQuery == false}" v-on:keyup.enter="searchForCrewmates">
                    <label class="d-none d-md-block d-lg-block d-xl-block d-xxl-block" for="searchCrewMateQuery">Crewmate email address</label>
                    <label class="d-block d-md-none" for="searchCrewMateQuery">Email Address</label>
                    <div class="invalid-feedback" v-if="validation.newCrewmate.searchingSelf">Hmm a bit of an identity crisis? Let's try someone else.</div>
                    <div class="invalid-feedback" v-else>C'mon! Please give us something to work with here!</div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <button class="btn btn-outline-primary text-uppercase" @click.prevent v-on:click="searchForCrewmates" :disabled="newCrewmate.activelySearching">Search For Crewmates</button>
                </div>
                <div style="margin-top:25px;" class="search-spinner" v-if="newCrewmate.activelySearching"><fai icon="spinner" spin/> Please wait while we find your crewmate!</div>
              </div>

              <div class="row">
                <div style="margin-top:15px;" class="table-responsive-xxl" v-if="newCrewmate.searchResults">
                  <VTable
                      :data="newCrewmate.searchResults"
                      ref="searchCrematesTable"
                      class="table"
                      selectionMode="single"
                      selectedClass="table-primary"
                      @stateChanged="selectSearchCrewmateRow($event.selectedRows)">
                    <template #head>
                      <tr>
                        <th style="width:5px;">&nbsp;</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Location</th>
                      </tr>
                    </template>
                    <template #body="{ rows }">
                      <VTr v-for="row in rows" :key="row.id" :row="row">
                        <td style="vertical-align: middle"><input type="radio" class="form-check-input" name="crewmateId" v-bind:value="row.id" v-model="newCrewmate.selectedCrewmateId" v-on:click="toggleSearchCrewmateRow"> </td>
                        <td>{{row.firstName}} {{row.lastName}}</td>
                        <td>{{row.email}}</td>
                        <td>{{row.city}}, {{row.province}}</td>
                      </VTr>
                    </template>
                  </VTable>
                </div>
              </div>

              <div class="row" v-show="newCrewmate.noResultsFound">
                <div style="margin-top:15px;" class="pb-3 charterRodeHelpText">As your crewmate is not registered yet, you will not be able to request them to verify your trip until they accept the invitation. Once your crewmate completes identity verification processing, 5 CRT will be added to your Charter Rode Wallet as a Referral Reward.</div>
              </div>

              <div class="row" v-show="newCrewmate.noResultsFound">
                <div class="col-lg-4">
                  <div class="form-floating mb-4">
                    <input autocomplete="new-password" type="text" class="form-control"  id="newCrewmateFirstName" name="newCrewmateFirstName" v-model="newCrewmate.firstName" v-bind:class="{'is-valid' : validation.newCrewmate.firstName, 'is-invalid': validation.newCrewmate.firstName == false}">
                    <label for="newCrewmateFirstName">First Name</label>
                    <div class="invalid-feedback">We need to know their first name.</div>
                  </div>
                </div>

                <div class="col-lg-4">
                  <div class="form-floating mb-4">
                    <input autocomplete="new-password" type="text" class="form-control"  id="newCrewmateLastName" name="newCrewmateLastName" v-model="newCrewmate.lastName" v-bind:class="{'is-valid' : validation.newCrewmate.lastName, 'is-invalid': validation.newCrewmate.lastName == false}">
                    <label for="newCrewmateLastName">Last Name</label>
                    <div class="invalid-feedback">We need to know their last name.</div>
                  </div>
                </div>

                <div class="col-lg-4">
                  <div class="form-floating mb-4">
                    <input autocomplete="new-password" type="text" class="form-control"  id="newCrewmateEmail" name="newCrewmateEmail" v-model="newCrewmate.email" v-bind:class="{'is-valid' : validation.newCrewmate.email, 'is-invalid': validation.newCrewmate.email == false}">
                    <label for="newCrewmateLastName">Email Address</label>
                    <div class="invalid-feedback">We need to know their email address.</div>
                  </div>
                </div>
              </div>

              <div class="row mb-lg-5" v-show="newCrewmate.noResultsFound">
                <div class="col-lg-12">
                  <button class="btn btn-outline-success text-uppercase" @click.prevent v-on:click="sendCrewmateInvitation" :disabled="newCrewmate.sendingCrewmateInvitation"><span v-if="!newCrewmate.firstName">Send Invitation</span><span v-else>Send Invitation to {{newCrewmate.firstName}}</span></button>
                </div>
              </div>

            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">Cancel</button>
            <button type="button" class="btn btn-primary" @click.prevent v-on:click="setCrewmate" :disabled="newCrewmate.selectedCrewmateId == undefined">{{confirmButtonLabel}}</button>
          </div>
        </div>
      </div>
    </div>
  </teleport>

  <teleport to="body">
    <div class="position-fixed bottom-0 end-0 p-3 cr-z-index-5">
      <div id="inviteToast" class="toast hide" role="alert" aria-live="assertive" aria-atomic="true">
        <div class="toast-header">
          <strong class="me-auto">Invitation Sent Successfully!</strong>
          <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
        </div>
        <div class="toast-body">Your crewmate invitation has been sent</div>
      </div>
    </div>
  </teleport>
</template>

<script>
import api from "../../api/api"
import {Modal, Toast} from "bootstrap"

export default {
  name: 'AddNewCrewmateModal',
  emits: ['setCrewmate','modalClose'],
  props: {
    'confirmButtonLabel': String
  },
  data() {
    return {
      setCrewmateCalled: false,
      newCrewmate: {
        searchQuery: undefined,
        activelySearching: false,
        sendingCrewmateInvitation: false,
        firstName: undefined,
        lastName: undefined,
        email: undefined,
        selectedCrewmateId: undefined,
        crewmateSelected: undefined,
        selectedRows: [],
        noResultsFound: false
      },
      validation: {
        newCrewmate: {
          searchQuery: undefined,
          searchingSelf: undefined,
          firstName: undefined,
          lastName: undefined,
          email: undefined
        }
      }
    }
  },
  methods: {
    activateModal() {
      this.newCrewmate = {
        searchQuery: undefined,
        firstName: undefined,
        lastName: undefined,
        email: undefined,
        id: undefined
      }
      this.validation.newCrewmate = {
        firstName: undefined,
        lastName: undefined,
        email: undefined
      }
      this.addCrewmateModal = new Modal(document.getElementById('addNewCrewmate'))
      let addVesEl = document.getElementById('addNewCrewmate')
      let self = this
      addVesEl.addEventListener('shown.bs.modal', function() {
        document.getElementById('searchCrewMateQuery').focus()
      })
      addVesEl.addEventListener('hidden.bs.modal', function () {
        // call back to the parent component who is using us
        if ( !self.setCrewmateCalled ) {
          self.$emit('modalClose')
        }

      })
      this.addCrewmateModal.toggle()
    },
    searchForCrewmates() {
      this.validation.newCrewmate.searchQuery = undefined
      this.validation.newCrewmate.searchingSelf = undefined
      this.newCrewmate.noResultsFound = false
      if ( this.newCrewmate.searchQuery && this.newCrewmate.searchQuery.trim().length > 4 ) {
        if ( this.newCrewmate.searchQuery.trim().toLowerCase() === this.$store.state.user.email.toLowerCase() ) {
          this.validation.newCrewmate.searchQuery = false
          this.validation.newCrewmate.searchingSelf = true
          return
        }
        this.newCrewmate.searchResults = undefined
        this.newCrewmate.activelySearching = true
        api.searchCremates(this.newCrewmate.searchQuery).then(matchingCrewmates => {
          // console.log("what came back from search vessels:"+JSON.stringify(matchingVessels))
          if ( matchingCrewmates && matchingCrewmates.length > 0 ) {
            this.newCrewmate.searchResults = matchingCrewmates
          } else {
            this.newCrewmate.searchResults = undefined
            this.newCrewmate.noResultsFound = true
            this.newCrewmate.email = this.newCrewmate.searchQuery
            // focus the invite crewmate first name
            document.getElementById('newCrewmateFirstName').focus()
          }
          this.newCrewmate.activelySearching = false
        })
      } else {
        // error!
        this.validation.newCrewmate.searchQuery = false
      }
    },
    selectSearchCrewmateRow(selectedRows) {
      if ( selectedRows && selectedRows.length == 1 ) {
        this.newCrewmate.selectedRows = selectedRows
        // now set the radio of the selected row to selected....
        this.newCrewmate.selectedCrewmateId = selectedRows[0].id
        this.newCrewmate.crewmateSelected = selectedRows[0]
      } else {
        this.newCrewmate.selectedCrewmateId = undefined
        this.newCrewmate.crewmateSelected = undefined
        this.newCrewmate.selectedRows = []
      }
    },
    toggleSearchCrewmateRow(event) {
      if ( event.currentTarget.checked ) {
        if ( this.newCrewmate.searchResults ) {
          let selectedCrewmate = undefined
          this.newCrewmate.searchResults.forEach(function(aResult) {
            if ( aResult.id == event.currentTarget.value ) {
              selectedCrewmate = aResult
            }
          })
          if ( selectedCrewmate ) {
            let selectedRows = []
            selectedRows.push(selectedCrewmate)
            this.$refs.searchCrematesTable.selectRows(selectedRows)
          }
        }
      }
    },
    setCrewmate() {
      // call back to the parent component who is using us
      this.setCrewmateCalled = true
      this.$emit('setCrewmate',this.newCrewmate.crewmateSelected)
      this.addCrewmateModal.hide()
    },
    sendCrewmateInvitation() {
      // do validation
      let isFormValid = true

      if ( this.newCrewmate.firstName !== undefined && this.newCrewmate.firstName.trim().length > 0 ) {
        this.validation.newCrewmate.firstName = true
      } else {
        this.validation.newCrewmate.firstName = false
        isFormValid = false
      }
      if ( this.newCrewmate.lastName !== undefined && this.newCrewmate.lastName.trim().length > 0 ) {
        this.validation.newCrewmate.lastName = true
      } else {
        this.validation.newCrewmate.lastName = false
        isFormValid = false
      }
      if ( this.newCrewmate.email !== undefined && this.newCrewmate.email.trim().length > 0 ) {
        // test for valid email address - https://www.w3resource.com/javascript/form/email-validation.php
        if (/^\w+([.-\\+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.newCrewmate.email)) {
          this.validation.newCrewmate.email = true
        } else {
          this.validation.newCrewmate.email = false
          isFormValid = false
        }
      } else {
        this.validation.newCrewmate.email = false
        isFormValid = false
      }

      if ( isFormValid ) {
        // all good to send invite and get out of here!
        let newCrewmateInviteRequest = {
          firstName: this.newCrewmate.firstName,
          lastName: this.newCrewmate.lastName,
          email: this.newCrewmate.email
        }

        api.inviteNewCrewmate(newCrewmateInviteRequest).then(crewmateInvite => {
          // we don't really care about this...
          if ( crewmateInvite ) {
            let inviteToast = new Toast(document.getElementById('inviteToast'))
            inviteToast.show()
          }
        })
        this.addCrewmateModal.hide()
        // TODO: call back to the parent component who is using us
      }
    },
    createRandomInputName() {
      return 'randoCrwSrch'+Math.floor(Math.random() * 1000000000000);
    }
  },
  components: {

  }
}
</script>

<style>
.crNoSearchResultsLabel {
  color: #666
}

.search-spinner {
  text-align: center;
  margin-top:5px;
  font-size:18px;
  color:#666;
  font-weight: normal;
  z-index: 9999;
}
</style>