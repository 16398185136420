<template>

  <teleport to="body">
    <!-- Modal -->
    <div class="modal fade" id="addOperationalExperienceModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-fullscreen">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="addCredentialLabel">Add Operational Experience</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="pb-3 charterRodeHelpText">Indicate your newly acquired operational experience below and tap <strong>Add Experience</strong> to add it to your Sailing Resume. Just like adding Credentials, there are no CRT transaction fees to add operational experience to your resume. Charter companies will take this experience into consideration when looking at your Sailing Resume to determine if you qualify to charter with them. As always, it is important to be honest and accurate with your actual experience.</div>

            <div class="container" v-if="activelySubmittingTransaction">
              <div class="row">
                <div class="col-sm-12">
                  <div class="tx-submit-spinner mt-lg-5"><fai icon="spinner" spin/> Please wait while your experience is submitted ...</div>
                </div>
              </div>
            </div>

            <div class="row" v-show="!activelySubmittingTransaction && transactionSubmittedHadError">
              <div class="col-sm-12">
                <div class="alert alert-danger" role="alert">There was an issue with submitting your transaction. Please re-verify your information below and try again.</div>
              </div>
            </div>

            <form autocomplete="off" novalidate v-on:submit.prevent="noop">

            <div class="container" v-if="!activelySubmittingTransaction">

              <div class="row">

                <div class="col-lg-4">
                  <div class="mb-4">
                    <v-date-picker mode="date" v-model="opExpInfo.date" :popover="{ visibility: 'click' }" min-date="2000-01-01" :max-date="maxDateForTrip">
                      <template v-slot="{ inputValue, inputEvents }">
                        <div class="form-floating">
                          <input type="text" class="form-control charterRodeBtn" id="opExpDate" name="opExpDate" :value="inputValue" v-on="inputEvents" v-bind:class="{'is-valid' : validation.date, 'is-invalid': validation.date == false}">
                          <label for="opExpDate">Date of Experience</label>
                          <div class="invalid-feedback">You must let use know when you were out.</div>
                        </div>
                      </template>
                    </v-date-picker>
                  </div>
                </div>

                <div class="col-lg-4">
                  <div class="form-floating mb-4">
                    <select class="form-control form-control-sm" id="opExpRole" name="opExpRole" v-model="opExpInfo.role" v-bind:class="{'is-valid' : validation.role, 'is-invalid': validation.role == false}">
                      <option value="please_select" disabled selected>Please Select</option>
                      <option v-for="item in uiDropdowns.roleType" :key="item.value" :value="item.value">{{item.label}}</option>
                    </select>
                    <label for="opExpRole">Your Role</label>
                    <div class="invalid-feedback">Captain or Crew? Hmm hard to decide.</div>
                  </div>
                </div>

                <div class="col-lg-4">
                  <div class="form-floating mb-4">
                    <select class="form-control form-control-sm" id="opExpVessel" name="opExpVessel" v-model="opExpInfo.vessel" @change="onVesselChange($event)" v-bind:class="{'is-valid' : validation.vessel, 'is-invalid': validation.vessel == false}">
                      <option value="please_select" disabled>Please Select</option>
                      <optgroup label="Your Favorite Vessels" v-show="favoriteVessels">
                        <option v-for="item in favoriteVessels" :key="item.id" :value="item.id">{{item.fullVesselName}}</option>
                      </optgroup>
                      <option value="vessel_search">Charter Vessel Search</option>
                      <option value="add_vessel">Add New Vessel</option>
                    </select>
                    <label for="opExpVessel">Vessel</label>
                    <div class="invalid-feedback">Not going to get very far off of land are you?</div>
                  </div>
                </div>
              </div>


              <div class="row" v-show="validation.noExperienceLogged">
                <div class="col-sm-12">
                  <div class="alert alert-danger" role="alert">Well what are we logging today?</div>
                </div>
              </div>


              <div class="row">
                <div class="col-lg-4">
                  <div class="form-floating mb-4">
                    <input type="number" class="form-control" id="dockingMarina" name="dockingMarina" v-model="opExpInfo.dockingMarina" min="0" max="30" step="1" v-bind:class="{'is-valid' : validation.dockingMarina, 'is-invalid': validation.dockingMarina == false}">
                    <label for="dockingMarina">Docking Marina</label>
                    <div id="dockingMarinaHelpBlock" class="form-text">Most marinas present challenges when docking. Log one docking event each time you return to the marina.</div>
                  </div>
                </div>

                <div class="col-lg-4">
                  <div class="form-floating mb-4">
                    <input type="number" class="form-control" id="dayAnchoring" name="dayAnchoring" v-model="opExpInfo.dayAnchoring" min="0" max="30" step="1" v-bind:class="{'is-valid' : validation.dayAnchoring, 'is-invalid': validation.dayAnchoring == false}">
                    <label for="dayAnchoring">Day Anchoring/Mooring Ball</label>
                    <div id="dayAnchoringHelpBlock" class="form-text">Nothing like a great day at anchor or on a mooring ball to keep you relaxed.</div>
                  </div>
                </div>

                <div class="col-lg-4">
                  <div class="form-floating mb-4">
                    <input type="number" class="form-control" id="overnightAnchoring" name="overnightAnchoring" v-model="opExpInfo.overnightAnchoring" min="0" max="30" step="1" v-bind:class="{'is-valid' : validation.overnightAnchoring, 'is-invalid': validation.overnightAnchoring == false}">
                    <label for="overnightAnchoring">Overnight Anchoring/Mooring Ball</label>
                    <div id="overnightAnchoringHelpBlock" class="form-text">Don't forget to set your anchor watch alarm!</div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-4">
                  <div class="form-floating mb-4">
                    <input type="number" class="form-control" id="mediterraneanMooring" name="mediterraneanMooring" v-model="opExpInfo.mediterraneanMooring" min="0" max="30" step="1" v-bind:class="{'is-valid' : validation.mediterraneanMooring, 'is-invalid': validation.mediterraneanMooring == false}">
                    <label for="mediterraneanMooring">Mediterranean Mooring</label>
                    <div id="mediterraneanMooringHelpBlock" class="form-text">Med mooring is a great skill to practice in open water, log it here if you have completed it on a dock with other boats around.</div>
                  </div>
                </div>

                <div class="col-lg-4">
                  <div class="form-floating mb-4">
                    <input type="number" class="form-control" id="twentyFourHourCharterPeriods" name="twentyFourHourCharterPeriods" v-model="opExpInfo.twentyFourHourCharterPeriods" min="0" max="30" step="1" v-bind:class="{'is-valid' : validation.twentyFourHourCharterPeriods, 'is-invalid': validation.twentyFourHourCharterPeriods == false}">
                    <label for="twentyFourHourCharterPeriods">24 Hour Charter Periods</label>
                    <div id="twentyFourHourCharterPeriodsHelpBlock" class="form-text">These are tracked from extended/multi-day charter trips. This allows charter companies to quickly see that you have extended chartering experience.</div>
                  </div>
                </div>

                <div class="col-lg-4">
                  <div class="form-floating mb-4">
                    <input type="number" class="form-control" id="overnightSailing" name="overnightSailing" v-model="opExpInfo.overnightSailing" min="0" max="30" step="1" v-bind:class="{'is-valid' : validation.overnightSailing, 'is-invalid': validation.overnightSailing == false}">
                    <label for="overnightSailing">Overnight Sailing</label>
                    <div id="overnightSailingHelpBlock" class="form-text">Not docked or at anchor. You sailed continuously from sunset to sunrise.</div>
                  </div>
                </div>
              </div>

              <div class="row pb-3 pt-3">
                <div class="col-sm-12">
                  <div class="alert" v-bind:class="{'alert-danger': !confirmInformationAccurate, 'alert-success': confirmInformationAccurate}" role="alert">
                    <div class="disclaimer-text-left">
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="checkbox" id="confirmReadOnlyOperationalExperience" v-model="confirmInformationAccurate">
                        <label class="form-check-label charterRodeBtn" for="confirmReadOnlyOperationalExperience">
                          I certify that this information is accurate and I agree that Charter Rode may suspend my account if I provide false information.
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>

            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal" :disabled="activelySubmittingTransaction">Cancel</button>
            <button type="button" class="btn btn-primary" v-on:click="addExperience" :disabled="!confirmInformationAccurate || activelySubmittingTransaction">Add Experience</button>
          </div>
        </div>
      </div>
    </div>
  </teleport>

  <teleport to="body">
    <div class="position-fixed bottom-0 end-0 p-3 cr-z-index-5">
      <div id="addOperationalExpTxToast" class="toast hide" role="alert" aria-live="assertive" aria-atomic="true">
        <div class="toast-header">
          <strong class="me-auto">Transaction Submitted Successfully!</strong>
          <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
        </div>
        <div class="toast-body">Your operational experience transaction has been successfully submitted</div>
      </div>
    </div>
  </teleport>

  <searchVesselsModal
      ref="searchVesselsModal"
      confirmButtonLabel="Use Selected Vessel"
      v-bind:user="user"
      v-on:setVessel="setVessel"
      v-on:activateAddVesselModal="activateAddVesselModal"
      v-on:modalClose="searchVesselsModalClose"></searchVesselsModal>

  <addVesselModal
      ref="addVesselModal"
      v-bind:user="user"
      v-on:setVessel="setVessel"
      v-on:modalClose="addVesselModalClose"></addVesselModal>

</template>

<script>
import {Modal, Toast} from "bootstrap"
import api from "../../api/api";

export default {
  name: 'AddOperationalExperienceModalView',
  emits: ['modalClose','addedNewOperationalExperience'],
  data() {
    return {
      addedNewOperationalExperience: false,
      confirmInformationAccurate: false,
      transactionSubmittedHadError: false,
      activelySubmittingTransaction: false,
      user:  this.$store.state.user,
      uiDropdowns: this.$store.state.uiDropdowns,
      favoriteVessels: this.$store.state.user.favoriteVessels ? this.$store.state.user.favoriteVessels : undefined,
      validation: {
        role: undefined,
        vessel: undefined,
        dockingMarina: undefined,
        dayAnchoring: undefined,
        overnightAnchoring: undefined,
        mediterraneanMooring: undefined,
        twentyFourHourCharterPeriods: undefined,
        overnightSailing: undefined,
        noExperienceLogged: false
      },
      opExpInfo: {
        date: new Date(),
        role: "please_select",
        vessel: "please_select",
        dockingMarina: undefined,
        dayAnchoring: undefined,
        overnightAnchoring: undefined,
        mediterraneanMooring: undefined,
        twentyFourHourCharterPeriods: undefined,
        overnightSailing: undefined
      }
    }
  },
  methods: {
    activateModal() {
      // check for valid JWT token as they may have walked a way for a good long while...
      api.checkForValidAuthToken().then(hasValidJWTToken => {
        // nothing to do that we care...
        if ( !hasValidJWTToken ) {
          this.addOperationalExperienceModal.hide()
        }
      })
      this.addedNewOperationalExperience = false
      this.activelySubmittingTransaction = false
      this.confirmInformationAccurate = false

      // reset
      this.validation = {
        role: undefined,
        vessel: undefined,
        dockingMarina: undefined,
        dayAnchoring: undefined,
        overnightAnchoring: undefined,
        mediterraneanMooring: undefined,
        twentyFourHourCharterPeriods: undefined,
        overnightSailing: undefined,
        noExperienceLogged: false
      }
      this.opExpInfo.dockingMarina = undefined
      this.opExpInfo.dayAnchoring = undefined
      this.opExpInfo.overnightAnchoring = undefined
      this.opExpInfo.mediterraneanMooring = undefined
      this.opExpInfo.twentyFourHourCharterPeriods = undefined
      this.opExpInfo.overnightSailin = undefined

      this.addOperationalExperienceModal = new Modal(document.getElementById('addOperationalExperienceModal'))
      let addOpExpEl = document.getElementById('addOperationalExperienceModal')
      let self = this
      addOpExpEl.addEventListener('hidden.bs.modal', function () {
        if ( self.addedNewOperationalExperience ) {
          self.$emit('addedNewOperationalExperience')
        }
        self.$emit('modalClose')
      })
      this.addOperationalExperienceModal.toggle()
    },
    getFullVesselNameFor(aVessel) {
      let preFix = ' '
      if ( aVessel.propulsion && aVessel.propulsion === 'sail' ) {
        if ( !aVessel.vesselName.startsWith('SV') ) {
          preFix = 'SV '
        }
      } else if ( aVessel.propulsion && aVessel.propulsion === 'power' ) {
        if ( !aVessel.vesselName.startsWith('PV') || !aVessel.vesselName.startsWith('FV') ) {
          preFix = 'MV '
        }
      }
      if ( aVessel.charterCompanyName && aVessel.charterBase ) {
        return preFix + aVessel.vesselName + ' - '+ aVessel.charterCompanyName + ' - ' + aVessel.charterBase
      } else if ( aVessel.charterCompanyName ) {
        return preFix + aVessel.vesselName + ' - '+ aVessel.charterCompanyName
      } else if ( aVessel.charterBase ) {
        return preFix + aVessel.vesselName + ' - '+ aVessel.charterBase
      } else {
        return preFix + aVessel.vesselName
      }
    },
    addExperience() {
      let requiredFieldsValid = true

      // reset for re-submit
      this.transactionSubmittedHadError = false
      this.validation.noExperienceLogged = false

      if ( this.opExpInfo.date ) {
        this.validation.date = true
      } else {
        this.validation.date = false
        requiredFieldsValid = false
      }
      if ( this.opExpInfo.role !== "please_select" ) {
        this.validation.role = true
      } else {
        this.validation.role = false
        requiredFieldsValid = false
      }
      if ( this.opExpInfo.vessel !== "please_select" ) {
        this.validation.vessel = true
      } else {
        this.validation.vessel = false
        requiredFieldsValid = false
      }

      let isFormValid = false
      // we take a different approach than other areas here as only ONE of these items are
      // really required
      if ( this.opExpInfo.dockingMarina && parseInt(this.opExpInfo.dockingMarina) > 0 ) {
        isFormValid = true
      }

      if ( this.opExpInfo.dayAnchoring && parseInt(this.opExpInfo.dayAnchoring) > 0 ) {
        isFormValid = true
      }

      if ( this.opExpInfo.overnightAnchoring && parseInt(this.opExpInfo.overnightAnchoring) > 0 ) {
        isFormValid = true
      }

      if ( this.opExpInfo.mediterraneanMooring && parseInt(this.opExpInfo.mediterraneanMooring) > 0 ) {
        isFormValid = true
      }

      if ( this.opExpInfo.twentyFourHourCharterPeriods && parseInt(this.opExpInfo.twentyFourHourCharterPeriods) > 0 ) {
        isFormValid = true
      }

      if ( this.opExpInfo.overnightSailing && parseInt(this.opExpInfo.overnightSailing) > 0 ) {
        isFormValid = true
      }

      // are we okay to create the transaction?
      if ( !isFormValid || !requiredFieldsValid ) {
        if ( !isFormValid && requiredFieldsValid ) {
          this.validation.noExperienceLogged = true
        }
        return
      } else {
        // submit form!
        let opsExperienceTxRequest = {
          operationalExperience : {
            role: this.opExpInfo.role,
            vesselId: this.opExpInfo.vessel
          }
        }

        let offset = this.opExpInfo.date.getTimezoneOffset()
        let theOpExpDate = new Date(this.opExpInfo.date.getTime() - (offset*60*1000))
        opsExperienceTxRequest.operationalExperience.date = theOpExpDate.toISOString().split('T')[0]

        // now all the series of ifs!
        if ( this.opExpInfo.dockingMarina && parseInt(this.opExpInfo.dockingMarina) > 0 ) {
          opsExperienceTxRequest.operationalExperience.dockingMarina = parseInt(this.opExpInfo.dockingMarina)
        }

        if ( this.opExpInfo.dayAnchoring && parseInt(this.opExpInfo.dayAnchoring) > 0 ) {
          opsExperienceTxRequest.operationalExperience.dayAnchoring = parseInt(this.opExpInfo.dayAnchoring)
        }

        if ( this.opExpInfo.overnightAnchoring && parseInt(this.opExpInfo.overnightAnchoring) > 0 ) {
          opsExperienceTxRequest.operationalExperience.overnightAnchoring = parseInt(this.opExpInfo.overnightAnchoring)
        }

        if ( this.opExpInfo.mediterraneanMooring && parseInt(this.opExpInfo.mediterraneanMooring) > 0 ) {
          opsExperienceTxRequest.operationalExperience.mediterraneanMooring = parseInt(this.opExpInfo.mediterraneanMooring)
        }

        if ( this.opExpInfo.twentyFourHourCharterPeriods && parseInt(this.opExpInfo.twentyFourHourCharterPeriods) > 0 ) {
          opsExperienceTxRequest.operationalExperience.twentyFourHourCharterPeriods = parseInt(this.opExpInfo.twentyFourHourCharterPeriods)
        }

        if ( this.opExpInfo.overnightSailing && parseInt(this.opExpInfo.overnightSailing) > 0 ) {
          opsExperienceTxRequest.operationalExperience.overnightSailing = parseInt(this.opExpInfo.overnightSailing)
        }


        let self = this
        self.activelySubmittingTransaction = true
        api.submitTransaction(opsExperienceTxRequest).then(response => {
          if (response) {
            // clear out the draft trip info from store
            self.transactionSubmittedSuccessfully = true
            self.transactionSubmittedHadError = false

            let addOperationalExpTxToast = new Toast(document.getElementById('addOperationalExpTxToast'))
            addOperationalExpTxToast.show()
            self.activelySubmittingTransaction = false

            self.addedNewOperationalExperience = true

            self.addOperationalExperienceModal.hide()
          } else {
            // there was some sort of error!
            self.transactionSubmittedSuccessfully = false
            self.transactionSubmittedHadError = true
            self.activelySubmittingTransaction = false
          }
        })
      }
    },
    setOpExpDate(aDate) {
      this.opExpInfo.date = aDate
    },
    onVesselChange() {
      if ( this.opExpInfo.vessel === 'vessel_search' ) {
        this.$refs.searchVesselsModal.activateModal()
      } else if ( this.opExpInfo.vessel === 'add_vessel' ) {
        this.$refs.addVesselModal.activateModal()
      } else {
        // need to find the vessel object selected!
        let selectedId = this.opExpInfo.vessel
        let self = this
        this.favoriteVessels.forEach(function(aVessel) {
          if ( aVessel.id === selectedId ) {
            self.opExpInfo.selectedVesselObject = aVessel
          }
        })
      }
    },
    setVessel (theSelectedVessel) {
      theSelectedVessel.fullVesselName = this.getFullVesselNameFor(theSelectedVessel)
      let foundMatch = false
      if ( this.$store.state.user.favoriteVessels == undefined ) {
        this.$store.state.user.favoriteVessels = []
      } else {
        this.$store.state.user.favoriteVessels.forEach(function(aVessel) {
          if ( aVessel.id == theSelectedVessel.id ) {
            foundMatch = true
          }
        })
      }
      if ( !foundMatch ) {
        // not already in the list
        this.$store.state.user.favoriteVessels.push(theSelectedVessel)
        this.favoriteVessels = this.$store.state.user.favoriteVessels
        // now alphabetize them!
        this.favoriteVessels.sort(function (a, b) {
          if (a.fullVesselName > b.fullVesselName) return 1
          if (a.fullVesselName < b.fullVesselName) return -1
          return 0
        })
      }
      this.opExpInfo.vessel = theSelectedVessel.id
      this.opExpInfo.selectedVesselObject = theSelectedVessel
    },
    addVesselModalClose() {
      if ( this.opExpInfo.vessel === 'add_vessel' ) {
        this.opExpInfo.vessel = "please_select"
        this.opExpInfo.selectedVesselObject = undefined
      }
    },
    activateAddVesselModal() {
      this.opExpInfo.vessel === 'add_vessel'
      this.$refs.addVesselModal.activateModal()
    },
    searchVesselsModalClose() {
      if ( this.opExpInfo.vessel === 'vessel_search' ) {
        this.opExpInfo.vessel = "please_select"
        this.opExpInfo.selectedVesselObject = undefined
      }
    },
  },
  computed: {
    maxDateForTrip() {
      const oneDayMillis = 1 * 24 * 60 *60 * 1000
      let todayDate = new Date()
      let todayDateFormatted
      todayDate.setTime(todayDate.getTime() + oneDayMillis)
      // now adjust the trip date as we are not allowing logging stuff that has not happened yet!
      let newTripDate = new Date()
      if ( newTripDate < this.opExpInfo.date ) {
        this.setOpExpDate(newTripDate)
      }
      // and now format it!
      todayDateFormatted = todayDate.getFullYear() + '-' + ('0' + (todayDate.getMonth()+1)).slice(-2) + '-' + ('0' + todayDate.getDate()).slice(-2)
      return todayDateFormatted
    }
  },
  components: {

  }
}
</script>

<style>
.search-spinner {
  text-align: center;
  margin-top:5px;
  font-size:18px;
  color:#666;
  font-weight: normal;
  z-index: 9999;
}
.selected-row {
  background-color: #0d6efd25;
}
</style>