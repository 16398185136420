<template>
  <div class="container">
    <div class="row mt-3 mb-3">
      <div class="col-sm-12">
        <h4>Log Entries to Verify</h4>
        <div class="charterRodeHelpText">Here you can see log entries that your crewmates have asked you to verify for them to be confirmed on Charter Rode. To review and approve/deny an entry, select the entry from the list below and select either Approve or Reject.</div>
      </div>
    </div>

    <div class="row" v-if="gettingTransactions">
      <div class="col-12">
        <div class="tx-lookup-spinner mt-lg-5"><fai icon="spinner" spin/> Retrieving log entries to verify...</div>
      </div>
    </div>

    <div class="row" v-if="!gettingTransactions">
      <div class="col-12">
        <div style="margin-top:10px;position: relative;" class="d-grid gap-2 d-md-block">
          <button type="button" class="btn btn-outline-secondary btn-sm me-2" v-on:click="refreshTransactions" :disabled="gettingTransactions"><span v-show="gettingTransactions"><fai icon="spinner" spin/> Refreshing ...</span><span v-show="!gettingTransactions">Refresh Entries</span></button>

          <div class="d-none d-sm-none d-md-block float-end table-caption " v-if="transactionsToVerify.length === 0 || transactionsToVerify.length > 1">{{transactionsToVerify.length}} entries</div><div class="d-none d-sm-none d-md-block float-end table-caption" v-else>{{transactionsToVerify.length}} entry</div>
        </div>
        <div class="table-responsive-xxl">
          <VTable
              :data="transactionsToVerify"
              ref="transactionsToVerifyTable"
              class="table caption-top"
              selectionMode="single"
              selectedClass="table-primary"
              :selectOnClick="false">
            <template #head>
              <tr>
                <th style="width:5px;">&nbsp;</th>
                <th style="white-space: nowrap">Sailor</th>
                <th style="white-space: nowrap">Tx Date</th>
                <th style="white-space: nowrap">Tx Status</th>
                <th style="white-space: nowrap">Tx Type</th>
              </tr>
            </template>
            <template #body="{ rows }">
              <tr v-show="rows.length == 0">
                <td>&nbsp;</td>
                <td colspan="5" style="text-align: center">Hooray! You have no log entries that are waiting on you to verify! Nice work.</td>
              </tr>
              <VTr v-for="row in rows" :key="row.id" :row="row">
                <td v-on:click="showVerifyTransactionModal(row)" style="vertical-align: middle">&nbsp;</td>
                <td v-on:click="showVerifyTransactionModal(row)">{{row.sailorName}}</td>
                <td v-on:click="showVerifyTransactionModal(row)">{{formatDateTime(row.time)}}</td>
                <td v-on:click="showVerifyTransactionModal(row)" v-html="getTxStatusFor(row)"></td>
                <td v-on:click="showVerifyTransactionModal(row)">{{getTxTypeFor(row)}}</td>
              </VTr>
            </template>
          </VTable>
        </div>

      </div>
    </div>

  </div>


  <verifyTransactionModal
      ref="verifyTransactionModal"
      v-on:transactionVerified="transactionVerified"
      v-on:transactionRejected="transactionRejected"
      v-on:modalClose="verifyTransactionModalClose"></verifyTransactionModal>

</template>

<script>
import api from "../../api/api";

export default {
  name: 'TransactionsToVerify',
  data() {
    return {
      uiDropdowns: this.$store.state.uiDropdowns,
      gettingTransactions: false,
      transactionsToVerify: this.$store.state.user.transactionsToVerify ? this.$store.state.user.transactionsToVerify : []
    }
  },
  mounted() {
    this.refreshTransactions()
  },
  methods: {
    refreshTransactions() {
      this.gettingTransactions = true
      api.getMyTransactionsToVerify().then(theTxsToVerify => {
        if ( theTxsToVerify && theTxsToVerify.length > 0 ) {

          // sort all transactions with the most recent on top like  bank would do online portal
          theTxsToVerify.sort(function (a, b) {
            if (a.time > b.time) return 1
            if (a.time < b.time) return -1
            return 0
          })
          this.transactionsToVerify = theTxsToVerify
          this.$store.state.user.transactionsToVerify = theTxsToVerify
        } else {
          this.transactionsToVerify = []
          this.$store.state.user.transactionsToVerify = []
        }
        this.gettingTransactions = false
      })
    },
    showVerifyTransactionModal(selectedRows) {
      if ( selectedRows && selectedRows.length > 0 ) {
        // activate thew viewTransactionModal!
        this.$refs.verifyTransactionModal.activateModal(selectedRows[0])
      } else if ( selectedRows && selectedRows.id ) {
        this.$refs.verifyTransactionModal.activateModal(selectedRows)
      }
    },
    verifyTransactionModalClose() {
      // may clear selection?
      this.$refs.transactionsToVerifyTable.deselectAll()
    },
    transactionVerified(aTransactionId) {
      this.$refs.transactionsToVerifyTable.deselectAll()
      this.removeTransactionFromListById(aTransactionId)
      this.$store.commit('decrementUserPendingVerificationRequestsCount')

    },
    transactionRejected(aTransactionId) {
      this.$refs.transactionsToVerifyTable.deselectAll()
      this.removeTransactionFromListById(aTransactionId)
      this.$store.commit('decrementUserPendingVerificationRequestsCount')
    },
    removeTransactionFromListById(aTransactionId) {
      let indexFoundAt = -1
      for(let i = 0; i < this.transactionsToVerify.length; ++i) {
        if ( this.transactionsToVerify[i].id === aTransactionId) {
          indexFoundAt = i
          break
        }
      }
      if ( indexFoundAt >= 0 ) {
        this.transactionsToVerify.splice(indexFoundAt,1)
        this.$store.state.user.transactionsToVerify = this.transactionsToVerify
      }
    },
    formatDateTime(aDateInSeconds) {
      return new Date(aDateInSeconds).toLocaleString("en-US")
    },
    formatDate(aDateInSeconds) {
      return new Date(aDateInSeconds).toLocaleDateString("en-US")
    },
    getTxStatusFor(row) {
      let theFinalLabel = 'unknown'
      this.uiDropdowns.transaction.status.forEach(function(aStatus) {
        if ( row.status === aStatus.value ) {
          theFinalLabel = aStatus.label
        }
      })
      switch(row.status) {
        case 'pending_payment':
          return '<span class="badge bg-warning">'+theFinalLabel+'</span>'
        case 'pending_verification':
          return '<span class="badge bg-info">'+theFinalLabel+'</span>'
        case 'rejected':
          return '<span class="badge bg-danger">'+theFinalLabel+'</span>'
        case 'confirmed':
          return '<span class="badge bg-success">'+theFinalLabel+'</span>'
        case 'voided':
          return '<span class="badge bg-secondary">'+theFinalLabel+'</span>'
        default:
          return '<span class="badge bg-dark">'+theFinalLabel+'</span>'
      }
    },
    getTxTypeFor(row) {
      if ( row && row.input && row.input.log && row.input.log.trip ) {
        return 'Trip'
      } else if ( row && row.input && row.input.log && row.input.log.identityVerification ) {
        return 'Identity Verification'
      } else if ( row && row.input && row.input.log && row.input.log.credential ) {
        return 'Credential'
      } else {
        return 'unknown'
      }
    },
    getTxTripDatesFor(row) {
      if ( row && row.input && row.input.log && row.input.log.trip &&  row.input.log.trip.entries ) {
        let startDate = undefined
        let endDate = undefined
        // assuming the entries are in order, might want to sort on sequence...
        row.input.log.trip.entries.forEach(function(anEntry) {
          if ( anEntry && anEntry.date ) {
            if ( !startDate )
              startDate = anEntry.date
          }
          endDate = anEntry.date
        })

        if ( startDate === endDate ) {
          // hack to make the date more human readable...
          let startDateParts = startDate.split("-")
          if ( startDateParts[1].startsWith("0") ) {
            startDateParts[1] = startDateParts[1].substr(1,1)
          }
          return startDateParts[1]+'/'+startDateParts[2]+'/'+startDateParts[0]
        } else {
          let startDateParts = startDate.split("-")
          if ( startDateParts[1].startsWith("0") ) {
            startDateParts[1] = startDateParts[1].substr(1,1)
          }
          let endDateParts = endDate.split("-")
          if ( endDateParts[1].startsWith("0") ) {
            endDateParts[1] = endDateParts[1].substr(1,1)
          }
          return startDateParts[1]+'/'+startDateParts[2]+'/'+startDateParts[0] +' - '+ endDateParts[1]+'/'+endDateParts[2]+'/'+endDateParts[0]
        }
      } else {
        return '--'
      }
    },
    getTxTripVesselFor(row) {
      if ( row && row.input && row.input.log && row.input.log.trip &&  row.input.log.trip.entries ) {
        let vesselName = 'unknown'
        row.input.log.trip.entries.forEach(function(anEntry) {
          if ( anEntry && anEntry.vessel && anEntry.vessel.vesselName ) {
            vesselName = anEntry.vessel.vesselName
          }
        })
        return vesselName
      } else {
        return '--'
      }
    }
  },
  components: {

  }
}
</script>

<style>
.tx-lookup-spinner {
  text-align: center;
  margin-top:5px;
  font-size:22px;
  color:#666;
  font-weight: normal;
  z-index: 9999;
}
</style>
