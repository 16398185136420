<template>
  <div class="container" v-show="newUserWorkflowStep === 'step_two'">
    <div class="row mt-3 mb-3">
      <div class="col-sm-12">
        <h4>Step 2 of 3: Identity Verification</h4>
        <div class="charterRodeHelpText">In order to get started with Charter Rode, we must verify your are who you say you are. <a href="https://www.charterrode.com/article/why-validate-identity" target="_blank">Read why we verify all identities on Charter Rode</a>. It is not required, but <strong>the identity verification process will go much faster</strong> if the address on your profile matches the address on the photo ID you upload below.</div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-6" style="text-align: center;">
        <div class="input-group2 mb-3 mt-3" style="display: block;text-align: center;position: relative">
          <input type="file" class="form-control" id="front" style="display: none;" :disabled="frontImageTracking.imageUploadComplete" @change="uploadFrontImage" accept="image/jpg, image/jpeg,image/png">
          <label class="input-group-text charterRodeBtn" for="front" style="display:block;padding: 0px;background: #fff">
            <div style="padding: 50px;" :class="{'disabled-upload': frontImageTracking.imageUploadComplete, 'enabled-upload': !frontImageTracking.imageUploadComplete}">
              <div style="display: inline-block;width: 180px!important;">
                <fai class="fa-10x" :icon="[ 'far', 'address-card']" :class="{'disabled-upload': frontImageTracking.imageUploadComplete, 'enabled-upload-icon': !frontImageTracking.imageUploadComplete}"></fai>
                <span class="translate-middle badge rounded-pill" :class="{'bg-disabled-upload': frontImageTracking.imageUploadComplete, 'bg-enabled-upload-green': !frontImageTracking.imageUploadComplete}" style="position: relative;bottom: -35px;"><fai icon="plus" class="fa-2x"></fai></span>
              </div>
              <div class="mt-3" style="white-space: normal">Choose photo of your driver's license or government issued photo ID<br/><span class="text-uppercase" :class="{'disabled-upload': frontImageTracking.imageUploadComplete, 'enabled-upload-green': !frontImageTracking.imageUploadComplete}">(Front)</span></div>
            </div>
          </label>
          <span v-show="frontImageTracking.imageUploadComplete" class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-success"><fai icon="check"></fai></span>
        </div>
        <div>
          <div class="progress" v-show="frontImageTracking.startedUpload">
            <div class="progress-bar" role="progressbar" v-bind:style="{width: frontImageTracking.imageProgress +'%'}" v-bind:aria-valuenow="frontImageTracking.imageProgress" aria-valuemin="0" aria-valuemax="100">{{frontImageTracking.imageProgress}}%</div>
          </div>
        </div>
      </div>
      <div class="col-lg-6" style="text-align: center;">
        <div class="input-group2 mb-3 mt-3" style="display: block;text-align: center;position: relative">
          <input type="file" class="form-control" id="rear" style="display: none;" :disabled="rearImageTracking.imageUploadComplete" @change="uploadRearImage" accept="image/jpg, image/jpeg,image/png">
          <label class="input-group-text charterRodeBtn" for="rear" style="display:block;padding: 0px;background: #fff">
            <div style="padding: 50px;" :class="{'disabled-upload': rearImageTracking.imageUploadComplete, 'enabled-upload': !rearImageTracking.imageUploadComplete}">
              <div style="display: inline-block;width: 180px!important;">
                <fai class="fa-10x" :icon="[ 'far', 'credit-card']" :class="{'disabled-upload': rearImageTracking.imageUploadComplete, 'enabled-upload-icon': !rearImageTracking.imageUploadComplete}"></fai>
                <span class="translate-middle badge rounded-pill" :class="{'bg-disabled-upload': rearImageTracking.imageUploadComplete, 'bg-enabled-upload-green': !rearImageTracking.imageUploadComplete}" style="position: relative;bottom: -35px;"><fai icon="plus" class="fa-2x"></fai></span>
              </div>
              <div class="mt-3" style="white-space: normal">Choose photo of your driver's license or government issued photo ID<br/><span class="text-uppercase" :class="{'disabled-upload': rearImageTracking.imageUploadComplete, 'enabled-upload-green': !rearImageTracking.imageUploadComplete}">(Rear)</span></div>
            </div>
          </label>
          <span v-show="rearImageTracking.imageUploadComplete" class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-success"><fai icon="check"></fai></span>
        </div>
        <div>
          <div class="progress" v-show="rearImageTracking.startedUpload">
            <div class="progress-bar" role="progressbar" v-bind:style="{width: rearImageTracking.imageProgress +'%'}" v-bind:aria-valuenow="rearImageTracking.imageProgress" aria-valuemin="0" aria-valuemax="100">{{rearImageTracking.imageProgress}}%</div>
          </div>
        </div>
      </div>
    </div>

    <div class="row pb-3 pt-3">
      <div class="col-sm-12">
        <div class="alert" v-bind:class="{'alert-danger': !confirmIdentity, 'alert-success': confirmIdentity}" role="alert">
          <div class="disclaimer-text-left">
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="checkbox" id="confirmReadOnly" v-model="confirmIdentity">
              <label class="form-check-label charterRodeBtn" for="confirmReadOnly">
                I certify that this information is accurate and I agree that Charter Rode may use these identity documents to verify my identity for the Charter Rode service.
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row pb-5 mb-5" v-show="!submittingIdentityTransaction">
      <div class="col-sm-12">
        <div class="d-grid gap-2 col-8 mx-auto">
          <button class="btn btn-lg btn-outline-primary text-uppercase" v-on:click="proceedToPayment" :disabled="!frontImageTracking.imageUploadComplete || !rearImageTracking.imageUploadComplete || !confirmIdentity">Submit My Identity Verification Request</button>
        </div>
      </div>
    </div>

    <div class="row pb-5 mb-5" v-show="submittingIdentityTransaction">
      <div class="col-sm-12">
        <div class="tx-submit-spinner mt-lg-5"><fai icon="spinner" spin/> Please wait while your identity verification transaction is submitted</div>
      </div>
    </div>

  </div>

  <div class="container" v-show="newUserWorkflowStep === 'step_three'">
    <div class="row mt-3 mb-3">
      <div class="col-sm-12">
        <h4>Step 3 of 3: Add Funds to Your Wallet</h4>
        <div class="charterRodeHelpText">Verifying your identity costs 49 Charter Rode Tokens (CRTs) plus an additional 1 CRT transaction fee that applies to all transactions confirmed on the Sailing Resume Blockchain. If you have an identity verification voucher or any other voucher code, you may use it below. Please select a funding option below to add necessary CRT to your Charter Rode Wallet. <strong>The current CRT Exchange Rate is 1 CRT = $1 USD</strong>. <strong>You cannot exchange CRT back for USD.</strong></div>
      </div>

      <div class="row justify-content-md-center">
        <div class="col-lg-7 mt-4">
          <div class="input-group mt-3 mb-3">
            <span class="input-group-text d-none d-lg-block d-xl-block" id="basic-addon1">Code:</span>
            <input type="text" class="form-control" v-bind:class="{'is-valid' : idVerificationCodeValidated, 'is-invalid': invalidIDVerificationCode == true}" placeholder="copy your code here if you have one" aria-label="ID Voucher Code" aria-describedby="button-check-id-voucher" :disabled="verifyingCode" v-model="userIDVerificationCode">
            <button class="btn btn-outline-secondary" type="button" id="button-check-id-voucher" v-on:click="verifyIDVerificationCode" ><span v-if="verifyingCode"><fai icon="spinner" spin/> Verifying ...</span><span class="d-none d-sm-block d-md-block d-lg-block d-xl-block" v-if="!verifyingCode">Verify Code</span><span class="d-block d-sm-none" v-if="!verifyingCode"><fai :icon="['far','arrow-alt-circle-right']"></fai></span></button>
            <div class="valid-feedback" v-if="idVerificationCodeValidated">Great! You have a valid code redeemable for {{voucherAmountCrt}} CRT! Select from the available funding options below.</div>
            <div class="invalid-feedback" v-if="idVerificationCodeSubmittedForValidation">Sorry that is not a valid code, please try again.</div>
            <div class="invalid-feedback" v-else>Please copy and paste your code.</div>
          </div>

        </div>
      </div>


      <div class="row">
        <div class="col-lg-4 mt-4">
          <div class="card" :class="{'disabled-upload':!hasValidIDVoucher}">
            <div class="card-header" style="text-align: center;"><h5 class="card-title">Voucher Only</h5></div>
            <div class="card-body">
              <h6 class="card-subtitle mb-2" :class="{'text-muted':hasValidIDVoucher}"><span v-html="totalCRTFunding(0)"></span> CRT funded to your wallet</h6>
              <div class="card-text">
                <ul>
                  <li>49 CRT used for Identity Validation</li>
                  <li v-show="!voucherAmountCrt || voucherAmountCrt < 50">I don't need to secure my identity on the Sailing Resume Blockchain</li>
                  <li v-show="!voucherAmountCrt || voucherAmountCrt < 50">I am just looking around and not ready to purchase anything just yet</li>
                  <li  v-show="voucherAmountCrt && voucherAmountCrt >= 50">1 CRT used to establish my identity on the Sailing Resume Blockchain</li>
                  <li  v-show="voucherAmountCrt && voucherAmountCrt >= 50"><span v-html="totalCRTsAfterIDValidation(0)"></span> CRTs to log & secure my sailing experience on the Sailing Resume Blockchain</li>
                </ul>
                I am only interested in using the voucher code today, no discounts for me!<br/><br/><strong>Total cost today - $0 USD</strong>.<br/>&nbsp;</div>
              <div class="d-grid gap-2 col-12 mx-auto"><button class="btn btn-outline-secondary" :disabled="!hasValidIDVoucher || submittingPaymentForWallet" v-on:click="fundWallet0CRTs">Decline Offer - $0</button></div>
            </div>
          </div>
        </div>

        <div class="col-lg-4 mt-4">
          <div class="card" :class="{'disabled-upload':!hasValidIDVoucher}" style="position: relative;">
            <div class="card-header" style="text-align: center;"><h5 class="card-title">Voucher + $25<sup><fai icon="anchor" :class="{'fine-print-sup': hasValidIDVoucher, 'fine-print-sup-disabled': !hasValidIDVoucher}"></fai></sup></h5></div>
            <div class="card-body">
              <h6 class="card-subtitle mb-2" :class="{'text-muted':hasValidIDVoucher}"><span v-html="totalCRTFunding(30)"></span> CRT funded to your wallet</h6>
              <div class="card-text">
                <ul>
                  <li>49 CRT used for Identity Validation</li>
                  <li>1 CRT used to establish my identity on the Sailing Resume Blockchain</li>
                  <li><span v-html="totalCRTsAfterIDValidation(30)"></span> CRTs to log & secure my sailing experience on the Sailing Resume Blockchain</li>
                </ul>
                One-time offer! Fund your wallet with<span v-show="hasValidIDVoucher"> an additional</span> 30 CRT for $25 - <strong><span :class="{'strong-blue-color':hasValidIDVoucher}">20% savings!</span></strong><br/><br/><strong>Total cost today - $26 USD</strong><sup><fai icon="anchor" :class="{'fine-print-sup': hasValidIDVoucher, 'fine-print-sup-disabled': !hasValidIDVoucher}"></fai></sup>.<br/>&nbsp;</div>
              <div class="d-grid gap-2 col-12 mx-auto"><button class="btn" :class="{'btn-outline-secondary': !hasValidIDVoucher, 'btn-primary': hasValidIDVoucher}" :disabled="!hasValidIDVoucher || submittingPaymentForWallet" v-on:click="fundWallet30CRTs">Add 30 CRT for $26</button></div>
            </div>

            <span class="position-absolute top-0 start-50 translate-middle badge rounded-pill" :class="{'bg-disabled-upload': !hasValidIDVoucher, 'bg-primary': hasValidIDVoucher}" style="top:-6px!important;z-index: 100;text-transform: uppercase;"><fai icon="anchor"></fai> Most Popular</span>

          </div>
        </div>

        <div class="col-lg-4 mt-4">
          <div class="card">
            <div class="card-header" style="text-align: center;"><h5 class="card-title"><span v-show="!hasValidIDVoucher">No Voucher - $75</span><span v-show="hasValidIDVoucher">Voucher + $75</span><sup><fai icon="anchor" class="fine-print-sup"></fai></sup></h5></div>
            <div class="card-body">
              <h6 class="card-subtitle mb-2 text-muted"><span v-html="totalCRTFunding(100)"></span> CRT funded to your wallet</h6>
              <div class="card-text">
                <ul>
                  <li>49 CRT used for Identity Validation</li>
                  <li>1 CRT used to establish my identity on the Sailing Resume Blockchain</li>
                  <li><span v-html="totalCRTsAfterIDValidation(100)"></span> CRTs to log & secure my sailing experience on the Sailing Resume Blockchain</li>
                </ul>
                One-time offer! Fund your wallet with<span v-show="hasValidIDVoucher"> an additional</span> 100 CRT for $75 - <strong><span class="strong-blue-color">25% savings!</span></strong><br/><br/><strong>Total cost today  - $78 USD</strong><sup><fai icon="anchor" class="fine-print-sup"></fai></sup>.<br/>&nbsp;</div>
              <div class="d-grid gap-2 col-12 mx-auto"><button class="btn btn-outline-primary" :disabled="submittingPaymentForWallet" v-on:click="fundWallet100CRTs">Add 100 CRT for $78</button></div>
            </div>
          </div>
        </div>
      </div>

      <div class="row pb-5 mb-5" v-show="submittingPaymentForWallet">
        <div class="col-sm-12">
          <div class="tx-submit-spinner mt-lg-5"><fai icon="spinner" spin/> Please wait while your funding details are processed</div>
        </div>
      </div>


      <div class="row mt-5">
        <div class="col-sm-12" style="text-align: center;font-size: 14px;">
          <i><fai icon="anchor" style="color:#444"></fai> - All CRT Exchanges are via secure one-time credit card payments. An additional 4% fee applies to all CRT Exchanges to offset fees charged by our payment gateway provider, <a href="https://stripe.com/" target="_blank">Stripe</a>.</i>
        </div>
      </div>

      <div class="row mt-1">
        <div class="col-sm-12" style="text-align: center;font-style: italic; font-size: 12px;">
          How does one get a Voucher Code you may ask? Have a Verified Charter Rode Sailor invite you as their crewmate to receive a personalized Voucher Code.
        </div>
      </div>

    </div>
  </div>

  <div class="container" v-show="newUserWorkflowStep === 'complete'">
    <div class="row mt-3 mb-3">
      <div class="col-sm-12">
        <h4>Account Setup Complete!</h4>
        <div class="charterRodeHelpText">Thank you for completing your account and submitting your identity validation request. You will receive an email at <i>{{user.email}}</i> shortly once we have completed the identity verification process for your account.</div>
      </div>
    </div>

    <div v-if="performRedirect && redirectUrl" class="row justify-content-center mt-3 p-2">
      <div class="col-md-12">
        <div class="text-center fs-4"><fai icon="spinner" spin/> Performing redirect back to referring app. If your browser does not automatically redirect, <a :href="redirectUrl">tap here</a>.</div>
      </div>
    </div>

    <div v-else class="row mt-3 mb-3">
      <div class="col-md-12">
        <router-link to="/wallet">Check out your wallet</router-link> to find your keys and if you ever need to add CRTs to use on Charter Rode.
      </div>
    </div>
  </div>


  <paymentProcessingModal
      ref="paymentProcessingModal"
      v-on:paymentSuccessful="paymentSuccessful"></paymentProcessingModal>
</template>

<script>

import api from "../../api/api";

export default {
  name: 'ValidateIdentity',
  data() {
    return {
      user:  this.$store.state.user,
      confirmIdentity: false,
      submittingIdentityTransaction: false,
      submittingPaymentForWallet: false,
      newUserWorkflowStep: this.$store.state.user.newUserWorkflowStep,
      hasValidIDVoucher: false,
      verifyingCode: false,
      userIDVerificationCode: undefined,
      invalidIDVerificationCode: undefined,
      idVerificationCodeValidated: undefined,
      idVerificationCodeSubmittedForValidation: false,
      redirectUrl: undefined,
      performRedirect: false,
      frontImageTracking: {
        startedUpload: false,
        imageProgress: 0,
        imageUploadComplete: undefined,
        imageUrl: undefined
      },
      rearImageTracking: {
        startedUpload: false,
        imageProgress: 0,
        imageUploadComplete: undefined,
        imageUrl: undefined
      }
    }
  },
  mounted() {
    if ( this.newUserWorkflowStep == 'step_three' ) {
      if ( this.$store.state.signUpState != undefined ) {
        // should be in base64 format
        try {
          let stateParams = atob(this.$store.state.signUpState)
          if ( stateParams.indexOf('v=') >= 0 ) {
            let voucherCode = stateParams.substring(stateParams.indexOf('v=')).split("&")[0].split('v=')[1]
            this.userIDVerificationCode = voucherCode
            this.verifyIDVerificationCode()
          }
        } catch (err) {
          console.log(err)
        }
      }
    }
  },
  methods: {
    uploadRearImage() {
      let rearFile = document.getElementById("rear").files;
      if (!rearFile.length) {
        return alert("Please choose a file to upload first.")
      }

      this.rearImageTracking.startedUpload = true
      let rear = rearFile[0]
      let fileName = rear.name
      let fileType = rear.type

      this.uploadImageFor(rear, fileName, fileType, this.rearImageTracking)
    },
    uploadFrontImage() {
      let frontFile = document.getElementById("front").files;
      if (!frontFile.length) {
        return alert("Please choose a file to upload first.")
      }

      this.frontImageTracking.startedUpload = true
      let front = frontFile[0]
      let fileName = front.name
      let fileType = front.type

      this.uploadImageFor(front, fileName, fileType, this.frontImageTracking)

    },
    uploadImageFor(file, fileName, fileType, imageTrackingRef) {
      imageTrackingRef.imageProgress = 5
      api.getSignedS3UrlForUpload(fileName, fileType).then(theSignedUrl => {
        // upload the file!
        let signedRequest = theSignedUrl.signedRequest
        api.uploadFileToS3WithSignedUrl(file, signedRequest, function(percentComplete) {
          imageTrackingRef.imageProgress = percentComplete
        }).then(response => {
          if ( response ) {
            imageTrackingRef.imageUploadComplete = true
            imageTrackingRef.imageUrl = response
          }
        })
      })
    },
    proceedToPayment() {
      // Even if we verify the transaction, it won't clear payment of CRT if their wallet has 0 CRT balance
      // so okay
      let self = this
      this.submittingIdentityTransaction = true
      api.submitIdentityVerificationTx(this.frontImageTracking.imageUrl, this.rearImageTracking.imageUrl).then(() => {
        // we don't really care about the response...
        // console.log(response)
        // toggle to the payment section with a cool transition on-screen to select how much CRT they want to
        // pre-purchase.
        self.newUserWorkflowStep = 'step_three'
        self.$store.state.user.newUserWorkflowStep = 'step_three'
        self.$store.commit('setUser',self.$store.state.user)

        if ( self.$store.state.signUpState != undefined ) {
          // should be in base64 format
          try {
            let stateParams = atob(self.$store.state.signUpState)
            if ( stateParams.indexOf('v=') >= 0 ) {
              let voucherCode = stateParams.substring(stateParams.indexOf('v=')).split("&")[0].split('v=')[1]
              self.userIDVerificationCode = voucherCode
              self.verifyIDVerificationCode()
            }
          } catch (err) {
            console.log(err)
          }
        }
      })
    },
    verifyIDVerificationCode() {
      window.document.activeElement.blur()
      this.invalidIDVerificationCode = undefined
      if ( this.userIDVerificationCode == undefined ) {
        this.invalidIDVerificationCode = true
        return
      }
      this.verifyingCode = true
      let self = this
      api.checkIdVerificationVoucherCode(this.userIDVerificationCode).then(response => {
        self.idVerificationCodeSubmittedForValidation = true
        if ( response && response.hasValidVoucher === true ) {
          self.hasValidIDVoucher = true
          self.idVerificationCodeValidated = true
          self.hasValidIDVoucher = true
          self.voucherAmountCrt = response.crtAmount
        } else {
          self.hasValidIDVoucher = false
          self.invalidIDVerificationCode = true
          self.idVerificationCodeValidated = false
        }
        self.verifyingCode = false
      })
    },
    fundWallet0CRTs() {
      // this user is lamo, go ahead and let them off the hook!
      this.submittingPaymentForWallet = true
      api.markNewUserWorkflowComplete().then(theUpdatedUser => {
        this.$store.commit('setUser', theUpdatedUser)
        this.newUserWorkflowStep = theUpdatedUser.newUserWorkflowStep
        this.submittingPaymentForWallet = false
        this.checkForRedirectAfterSignup(theUpdatedUser.id)
      })
    },
    fundWallet30CRTs() {
      window.document.activeElement.blur()
      this.$refs.paymentProcessingModal.activatePaymentModal(30)
    },
    fundWallet100CRTs() {
      window.document.activeElement.blur()
      this.$refs.paymentProcessingModal.activatePaymentModal(100)
    },
    paymentSuccessful() {
      this.submittingPaymentForWallet = true
      api.markNewUserWorkflowComplete().then(theUpdatedUser => {
        this.$store.commit('setUser', theUpdatedUser)
        this.newUserWorkflowStep = theUpdatedUser.newUserWorkflowStep
        this.submittingPaymentForWallet = false
        this.checkForRedirectAfterSignup(theUpdatedUser.id)
      })
    },
    checkForRedirectAfterSignup(sailorId) {
      // should be in base64 format
      // do we have a redirect?
      try {
        let stateParams = atob(this.$store.state.signUpState)
        if ( stateParams.indexOf('r=') >= 0 ) {
          let redirectUrl = decodeURI(stateParams.substring(stateParams.indexOf('r=')).split("&")[0].split('r=')[1])
          // do redirect
          // first we need to figure out how to append the sailorId param name
          if ( redirectUrl.indexOf('?') < 0 ) {
            redirectUrl += "?sailorId="
          } else {
            redirectUrl += "&sailorId="
          }
          // now actually append the sailorId
          redirectUrl += sailorId

          // do this first as we are redirecting next...
          this.$store.commit('clearSignUpState')

          // now perform redirect...
          this.redirectUrl = redirectUrl
          this.performRedirect = true
          window.setTimeout(function() {
            window.location = redirectUrl
          }, 1500)

        } else {
          this.$store.commit('clearSignUpState')
        }
      } catch (err) {
        console.log(err)
      }
    },
    totalCRTFundingTier1() {
      if ( this.voucherAmountCrt ) {
        return parseInt(this.voucherAmountCrt)
      } else {
        return 0
      }
    },
    totalCRTFundingTier2() {
      if ( this.voucherAmountCrt ) {
        return parseInt(this.voucherAmountCrt) + 30
      } else {
        return 30
      }
    },
    totalCRTFundingTier3() {
      if ( this.voucherAmountCrt ) {
        return parseInt(this.voucherAmountCrt) + 100
      } else {
        return 100
      }
    },
    totalCRTFunding(aFundingAmount) {
      if ( this.voucherAmountCrt ) {
        return parseInt(this.voucherAmountCrt) + aFundingAmount
      } else {
        return aFundingAmount
      }
    },
    totalCRTsAfterIDValidation(aFundingAmount) {
      if ( this.voucherAmountCrt ) {
        let totalCRTsLeftover = parseInt(this.voucherAmountCrt) - 50
        if ( aFundingAmount ) {
          totalCRTsLeftover += aFundingAmount
        }
        if ( totalCRTsLeftover > 0 ) {
          return totalCRTsLeftover
        }
      } else if ( aFundingAmount ) {
        let totalCRTsLeftover = parseInt(aFundingAmount) - 50
        if ( totalCRTsLeftover > 0 ) {
          return totalCRTsLeftover
        }
      }
      return 0
    }
  },
  components: {

  }
}
</script>

<style>

.disabled-upload {
  color: #ccc!important;
}

.bg-disabled-upload {
  background: #ccc;
}

.enabled-upload {
  color: #0d6efd;
  font-weight: bold;
}
.enabled-upload-icon {
  color: #0d6efdB3;
}

.strong-blue-color {
  color: #0d6efdB3;
}

.enabled-upload-green {
  color: #198754;
  text-transform: uppercase;
}

.bg-enabled-upload-green {
  background: #198754E6;
}

.upload--label {;
  text-transform: uppercase;
}

.fine-print-sup {
  font-size:8px;
  color:#444;
  font-style: italic;
}

.fine-print-sup-disabled {
  font-size:8px;
  color:#ccc;
  font-style: italic;
}

</style>
