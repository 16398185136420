<template>
  <div v-if="sectionData && sectionData.length > 0">
    <div class="card cr-card" :class="{charterRodeBtn: !forPrint}" style="margin:0px 30px 30px 30px;" v-for="(vessel,index) of sectionData" :key="vessel.id" @click="!forPrint ? vessel.showDetails = !vessel.showDetails : vessel.showDetails">
      <div class="card-title-vessel-header" style="background-color: #0d6efd;height: 3px;border-top-left-radius: 25px;border-top-right-radius: 25px;"></div>
      <div class="card-body" style="padding:0rem 1rem 1rem 1rem;">
        <div style="float: right;position: relative;top:-1px;right:-10px;color:#888888;">{{++index}} of {{sectionData.length}}</div>
        <div>
           <span style="font-weight: bold;font-size: 1.3em;">{{vessel.vesselInfo.year}}</span> <span style="font-weight: bold;font-size: 1.3em;">{{vessel.vesselInfo.manufacturer}}</span> <span style="font-weight: bold;font-size: 1.3em;">{{vessel.vesselInfo.model}}</span> <span style="font-weight: bold;font-size: 1.3em;font-style:italic;">"{{vessel.vesselInfo.vesselName}}"</span>
        </div>
        <div class="table-responsive-xxl" style="margin-bottom: 5px;">
          <table class="cr-block-table" style="margin-left:10px;">
            <tbody>
              <tr>
                <td><div class="vessel-item-label">Name</div></td>
                <td>&nbsp;</td>
                <td colspan="5"><div class="vessel-item-value">{{vessel.vesselInfo.vesselName}}</div></td>
                <td>&nbsp;</td>
                <td><div class="col-2 vessel-item-label">Type</div></td>
                <td>&nbsp;</td>
                <td colspan="5"><div class="vessel-item-value"  v-html="formatBoatInfo(vessel.vesselInfo)"></div></td>
              </tr>
            </tbody>
          </table>
          <table class="cr-block-table" style="margin-left:10px;">
            <tbody v-if="vessel.vesselInfo.boatOwner == 'self'">
              <tr>
                <td><div class="vessel-item-label">Owner</div></td>
                <td>&nbsp;</td>
                <td colspan="5"><div class="vessel-item-value">My Boat</div></td>
                <td>&nbsp;</td>
                <td v-if="vessel.vesselInfo.registrationInfo"><div class="vessel-item-label">Reg. Info</div></td>
                <td v-if="vessel.vesselInfo.registrationInfo">&nbsp;</td>
                <td colspan="5" v-if="vessel.vesselInfo.registrationInfo"><div class="vessel-item-value">{{vessel.vesselInfo.registrationInfo}}</div></td>
              </tr>
            </tbody>
          </table>
          <table class="cr-block-table" style="margin-left:10px;">
            <tbody v-if="vessel.vesselInfo.boatOwner == 'friend'">
              <tr>
                <td><div class="vessel-item-label">Owner</div></td>
                <td>&nbsp;</td>
                <td colspan="5"><div class="vessel-item-value">Friends Boat</div></td>
                <td>&nbsp;</td>
                <td v-if="vessel.vesselInfo.registrationInfo"><div class="vessel-item-label">Reg. Info</div></td>
                <td v-if="vessel.vesselInfo.registrationInfo">&nbsp;</td>
                <td colspan="5" v-if="vessel.vesselInfo.registrationInfo"><div class="vessel-item-value">{{vessel.vesselInfo.registrationInfo}}</div></td>
              </tr>
            </tbody>
          </table>
          <table class="cr-block-table" style="margin-left:10px;">
            <tbody v-if="vessel.vesselInfo.boatOwner == 'charterCompany'">
              <tr>
                <td><div class="vessel-item-label">Owner</div></td>
                <td>&nbsp;</td>
                <td colspan="5"><div class="vessel-item-value">Charter Boat: {{vessel.vesselInfo.charterCompanyName}}</div></td>
                <td>&nbsp;</td>
                <td><div class="vessel-item-label">Location</div></td>
                <td>&nbsp;</td>
                <td colspan="5"><div class="vessel-item-value">{{vessel.vesselInfo.charterBase}}</div></td>
                <td>&nbsp;</td>
                <td v-if="vessel.vesselInfo.registrationInfo"><div class="vessel-item-label">Reg. Info</div></td>
                <td v-if="vessel.vesselInfo.registrationInfo">&nbsp;</td>
                <td colspan="5" v-if="vessel.vesselInfo.registrationInfo"><div class="vessel-item-value">{{vessel.vesselInfo.registrationInfo}}</div></td>
              </tr>
            </tbody>
          </table>
          <table class="cr-block-table" style="margin-left:10px;">
            <tbody v-if="vessel.vesselInfo.boatOwner == 'commercial'">
              <tr>
                <td><div class="vessel-item-label">Owner</div></td>
                <td>&nbsp;</td>
                <td colspan="5"><div class="vessel-item-value">Commercial: {{vessel.vesselInfo.commercialOwnerName}}</div></td>
                <td>&nbsp;</td>
                <td><div class="vessel-item-label">Vessel Flag</div></td>
                <td>&nbsp;</td>
                <td colspan="5"><div class="vessel-item-value">{{vessel.vesselInfo.commercialVesselFlag}}</div></td>
                <td>&nbsp;</td>
                <td v-if="vessel.vesselInfo.registrationInfo"><div class="vessel-item-label">Reg. Info</div></td>
                <td v-if="vessel.vesselInfo.registrationInfo">&nbsp;</td>
                <td colspan="5" v-if="vessel.vesselInfo.registrationInfo"><div class="vessel-item-value">{{vessel.vesselInfo.registrationInfo}}</div></td>
              </tr>
            </tbody>
          </table>
          <table class="cr-block-table" style="margin-left:10px;">
            <tbody>
              <tr>
                <td><div class="vessel-item-label">LOA</div></td>
                <td>&nbsp;</td>
                <td><div class="vessel-item-value" v-html="formatLengthFromMeters(vessel.vesselInfo.overallLengthMeter)"></div></td>
                <td>&nbsp;</td>
                <td><div class="vessel-item-label">LWL</div></td>
                <td>&nbsp;</td>
                <td><div class="vessel-item-value" v-html="formatLengthFromMeters(vessel.vesselInfo.waterLineLengthMeter)"></div></td>
                <td>&nbsp;</td>
                <td><div class="vessel-item-label">Beam</div></td>
                <td>&nbsp;</td>
                <td><div class="vessel-item-value" v-html="formatLengthFromMeters(vessel.vesselInfo.beamMeter)"></div></td>
                <td>&nbsp;</td>
                <td><div class="vessel-item-label">Draft</div></td>
                <td>&nbsp;</td>
                <td><div class="vessel-item-value" v-html="formatLengthFromMeters(vessel.vesselInfo.draftMeter)"></div></td>
              </tr>
            </tbody>
          </table>
          <table class="cr-block-table" style="margin-left:10px;">
            <tbody>
              <tr>
                <td><div class="vessel-item-label">Gross Tons</div></td>
                <td>&nbsp;</td>
                <td><div class="vessel-item-value"><span v-html="formatNumberTons(vessel.vesselInfo.grossTons)"></span> GT</div></td>
                <td>&nbsp;</td>
                <td><div class="vessel-item-label">Displacement</div></td>
                <td>&nbsp;</td>
                <td><div class="vessel-item-value"><span v-html="formatNumberTons(vessel.vesselInfo.displacementTons)"></span>T</div></td>
              </tr>
            </tbody>
          </table>
          <table class="cr-block-table" style="margin-left:10px;">
            <tbody>
            <tr>
              <td><div class="vessel-item-label">% of Qualifying Days</div></td>
              <td>&nbsp;</td>
              <td><div class="vessel-item-value"><span v-html="formatPercent(vessel.summary.percentQualifyingDays)" style="font-weight: bold;"></span></div></td>
            </tr>
            </tbody>
          </table>
          <table class="cr-block-table" style="margin-left:10px;">
            <tbody>
            <tr>
              <td><div class="vessel-item-label">Qualifying Days</div></td>
              <td>&nbsp;</td>
              <td><div class="vessel-item-value"><span v-html="formatNumber(vessel.summary.totalQualifyingDays)"></span></div></td>
              <td>&nbsp;</td>
              <td><div class="vessel-item-label">Nautical Miles</div></td>
              <td>&nbsp;</td>
              <td><div class="vessel-item-value"><span v-html="formatDecimal(vessel.summary.totalNauticalMiles)"></span></div></td>
              <td>&nbsp;</td>
              <td><div class="vessel-item-label">Hours</div></td>
              <td>&nbsp;</td>
              <td><div class="vessel-item-value"><span v-html="formatHours(vessel.summary.totalHours)"></span></div></td>
              <td>&nbsp;</td>
              <td><div class="vessel-item-label"># Entries</div></td>
              <td>&nbsp;</td>
              <td><div class="vessel-item-value"><span v-html="formatNumber(vessel.summary.totalEntries)"></span></div></td>
              <td>&nbsp;</td>
              <td><div class="vessel-item-label">First Entry</div></td>
              <td>&nbsp;</td>
              <td><div class="vessel-item-value"><span v-html="formatDate(vessel.summary.oldestEntryDate)"></span></div></td>
              <td>&nbsp;</td>
              <td><div class="vessel-item-label">Last Entry</div></td>
              <td>&nbsp;</td>
              <td><div class="vessel-item-value"><span v-html="formatDate(vessel.summary.newestEntryDate)"></span></div></td>
            </tr>
            </tbody>
          </table>
        </div>

        <div v-show="!forPrint" style="margin-left: 10px;margin-bottom: 5px;"><span class="charterRodeBtn" style="font-size:0.80em;font-weight:bold;color:#0d6efd"><span v-show="!vessel.showDetails"><fai icon="plus"/> Show</span><span v-show="vessel.showDetails"><fai icon="minus"/> Hide</span> recency ...</span></div>

        <div class="card cr-card" style="margin:0px 5px 30px 5px;" v-show="vessel.showDetails">
          <div class="card-body">
            <div>Sailor has a total of <span v-html="formatNumber(vessel.summary.totalQualifyingDays)"></span> qualifying days, <span v-html="formatDecimal(vessel.summary.totalNauticalMiles)"></span> nautical miles, <span v-html="formatHours(vessel.summary.totalHours)"></span> hours, and <span v-html="formatNumber(vessel.summary.totalEntries)"></span> entries aboard this vessel.<span v-if="vessel.summary.oldestEntryDate"> The oldest logbook entry was on <span v-html="formatDate(vessel.summary.oldestEntryDate)"></span> and the latest logbook entry was on <span v-html="formatDate(vessel.summary.newestEntryDate)"></span>.</span></div>
          </div>
        </div>

        <div class="card cr-card" style="margin:0px 5px 15px 5px;" v-show="vessel.showDetails || forPrint">
          <div class="card-body">
            <h5 class="card-title" style="margin-bottom: 3px;">Total Vessel Experience</h5>
            <div class="table-responsive-xxl">
              <table class="cr-resume-table table table-striped table-hover table-sm">
                <thead>
                  <tr>
                    <th class="cr-resume-background-none cr-resume-border-bottom-none cr-resume-border-right">&nbsp;</th>
                    <th class="cr-resume-border-top cr-resume-border-right fs-5 text-center">As Captain</th>
                    <th class="cr-resume-border-top cr-resume-border-right fs-5 text-center">As Crew</th>
                    <th class="cr-resume-border-top cr-resume-border-right fs-5 text-center">Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="text-nowrap cr-resume-border-right">Past year</td>
                    <td class="text-center cr-resume-border-right" v-html="formatResumeDaysCell(vessel.vesselLength.captain.pastYear)"></td>
                    <td class="text-center cr-resume-border-right" v-html="formatResumeDaysCell(vessel.vesselLength.crew.pastYear)"></td>
                    <td class="text-center cr-resume-border-right" v-html="formatResumeDaysCell(vessel.vesselLength.total.pastYear)"></td>
                  </tr>
                  <tr>
                    <td class="text-nowrap cr-resume-border-right">Past 3 years</td>
                    <td class="text-center cr-resume-border-right" v-html="formatResumeDaysCell(vessel.vesselLength.captain.pastThreeYears)"></td>
                    <td class="text-center cr-resume-border-right" v-html="formatResumeDaysCell(vessel.vesselLength.crew.pastThreeYears)"></td>
                    <td class="text-center cr-resume-border-right" v-html="formatResumeDaysCell(vessel.vesselLength.total.pastThreeYears)"></td>
                  </tr>
                  <tr>
                    <td class="text-nowrap cr-resume-border-right">Past 5 years</td>
                    <td class="text-center cr-resume-border-right" v-html="formatResumeDaysCell(vessel.vesselLength.captain.pastFiveYears)"></td>
                    <td class="text-center cr-resume-border-right" v-html="formatResumeDaysCell(vessel.vesselLength.crew.pastFiveYears)"></td>
                    <td class="text-center cr-resume-border-right" v-html="formatResumeDaysCell(vessel.vesselLength.total.pastFiveYears)"></td>
                  </tr>
                  <tr>
                    <td class="text-nowrap cr-resume-border-right">Past 10 years</td>
                    <td class="text-center cr-resume-border-right" v-html="formatResumeDaysCell(vessel.vesselLength.captain.pastTenYears)"></td>
                    <td class="text-center cr-resume-border-right" v-html="formatResumeDaysCell(vessel.vesselLength.crew.pastTenYears)"></td>
                    <td class="text-center cr-resume-border-right" v-html="formatResumeDaysCell(vessel.vesselLength.total.pastTenYears)"></td>
                  </tr>
                  <tr>
                    <td class="text-nowrap cr-resume-border-right">Lifetime</td>
                    <td class="text-center cr-resume-border-right" v-html="formatResumeDaysCell(vessel.vesselLength.captain.lifetime)"></td>
                    <td class="text-center cr-resume-border-right" v-html="formatResumeDaysCell(vessel.vesselLength.crew.lifetime)"></td>
                    <td class="text-center cr-resume-border-right" v-html="formatResumeDaysCell(vessel.vesselLength.total.lifetime)"></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="cr-resume-footer-note">A | B% - this is logged days (at least four hours) experience with the percentage of entries that have been verified by either a crewmate on the vessel at the time or directly with the charter company via Charter Rode. The n-m below each logged experience represents how many entries have been verified by crewmate (n) or charter company (m) digital verification. Charter company verification is the highest trusted verification. Crewmate digital verification is considered to be very reliable. Self verification may need to be verified directly with the sailor.</div>
          </div>
        </div>

        <div class="card cr-card" style="margin:0px 5px 15px 5px;" v-show="vessel.showDetails || forPrint">
          <div class="card-body">
            <h5 class="card-title"  style="margin-bottom: 3px;">Vessel Experience by Location Type</h5>
            <h6 class="card-subtitle mb-2 text-muted"  style="margin-bottom: 3px;">Included in the above Total Vessel Experience</h6>
            <div class="table-responsive-xxl">
              <table class="cr-resume-table table table-striped table-hover table-sm">
                <thead>
                <tr>
                  <th class="cr-resume-background-none cr-resume-border-bottom-none cr-resume-border-right">&nbsp;</th>
                  <th class="cr-resume-border-top cr-resume-border-right fs-5 text-center">As Captain</th>
                  <th class="cr-resume-border-top cr-resume-border-right fs-5 text-center">As Crew</th>
                  <th class="cr-resume-border-top cr-resume-border-right fs-5 text-center">Total</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td class="text-nowrap cr-resume-border-right">Inland/Lake</td>
                  <td class="text-center cr-resume-border-right" v-html="formatResumeDaysCell(vessel.locationType.captain.inlandLake)"></td>
                  <td class="text-center cr-resume-border-right" v-html="formatResumeDaysCell(vessel.locationType.crew.inlandLake)"></td>
                  <td class="text-center cr-resume-border-right" v-html="formatResumeDaysCell(vessel.locationType.total.inlandLake)"></td>
                </tr>
                <tr>
                  <td class="text-nowrap cr-resume-border-right">Great Lakes</td>
                  <td class="text-center cr-resume-border-right" v-html="formatResumeDaysCell(vessel.locationType.captain.greatLakes)"></td>
                  <td class="text-center cr-resume-border-right" v-html="formatResumeDaysCell(vessel.locationType.crew.greatLakes)"></td>
                  <td class="text-center cr-resume-border-right" v-html="formatResumeDaysCell(vessel.locationType.total.greatLakes)"></td>
                </tr>
                <tr>
                  <td class="text-nowrap cr-resume-border-right">Near Coastal</td>
                  <td class="text-center cr-resume-border-right" v-html="formatResumeDaysCell(vessel.locationType.captain.nearCoastal)"></td>
                  <td class="text-center cr-resume-border-right" v-html="formatResumeDaysCell(vessel.locationType.crew.nearCoastal)"></td>
                  <td class="text-center cr-resume-border-right" v-html="formatResumeDaysCell(vessel.locationType.total.nearCoastal)"></td>
                </tr>
                <tr>
                  <td class="text-nowrap cr-resume-border-right">Offshore</td>
                  <td class="text-center cr-resume-border-right" v-html="formatResumeDaysCell(vessel.locationType.captain.offshore)"></td>
                  <td class="text-center cr-resume-border-right" v-html="formatResumeDaysCell(vessel.locationType.crew.offshore)"></td>
                  <td class="text-center cr-resume-border-right" v-html="formatResumeDaysCell(vessel.locationType.total.offshore)"></td>
                </tr>
                <tr>
                  <td class="text-nowrap cr-resume-border-right">Canal</td>
                  <td class="text-center cr-resume-border-right" v-html="formatResumeDaysCell(vessel.locationType.captain.canal)"></td>
                  <td class="text-center cr-resume-border-right" v-html="formatResumeDaysCell(vessel.locationType.crew.canal)"></td>
                  <td class="text-center cr-resume-border-right" v-html="formatResumeDaysCell(vessel.locationType.total.canal)"></td>
                </tr>
                </tbody>
              </table>
            </div>
            <div class="cr-resume-footer-note">A | B% - this is logged days (at least four hours) experience with the percentage of entries that have been verified by either a crewmate on the vessel at the time or directly with the charter company via Charter Rode. The n-m below each logged experience represents how many entries have been verified by crewmate (n) or charter company (m) digital verification. Charter company verification is the highest trusted verification. Crewmate digital verification is considered to be very reliable. Self verification may need to be verified directly with the sailor.</div>
          </div>
        </div>

      </div>
    </div>
  </div>
  <div v-else>
    <div class="card cr-card" style="margin:0px 30px 30px 30px;">
      <div class="card-body">
        <div>Sailor has not logged any experience on their resume yet.</div>
      </div>
    </div>
  </div>

</template>
<script>

export default {
  name: 'ResumeSectionVesselsView',
  props: {
    'sectionData': Object,
    'forPrint': Boolean,
  },
  data() {
    return {
      uiDropdowns: this.$store.state.uiDropdowns,
    }
  },
  methods: {
    formatTripDates(trip) {
      // trip.date is in format YYY-MM-DD
      const monthsOfYear = ['','Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']
      let tripStart = trip.startDate.split('-')
      let tripEnd = trip.endDate.split('-')
      if ( tripStart[0] === tripEnd[0] ) { // years match
        if (tripStart[1] === tripEnd[1] ) { // months match
          return monthsOfYear[parseInt(tripStart[1])]+' '+tripStart[2]+'-'+tripEnd[2]+', '+tripStart[0]
        } else { // months mismatch
          return monthsOfYear[parseInt(tripStart[1])]+' '+tripStart[2]+'-'+monthsOfYear[parseInt(tripEnd[1])]+' '+tripEnd[2]+', '+tripStart[0]
        }
      } else { // years mismatch...
        return monthsOfYear[parseInt(tripStart[1])]+' '+tripStart[2]+', '+tripStart[0]+'-'+monthsOfYear[parseInt(tripEnd[1])]+' '+tripEnd[2]+', '+tripEnd[0]
      }
    },
    formatRole(trip) {
      let result = ''
      this.uiDropdowns.roleType.forEach(function(aRole) {
        if ( aRole.value === trip.role ) {
          result = aRole.label
        }
      })
      return result
    },
    formatPurpose(trip) {
      let result = ''
      this.uiDropdowns.purpose.forEach(function(aPurpose) {
        if ( aPurpose.value === trip.purpose ) {
          result = aPurpose.label
        }
      })
      return result
    },
    formatLocationType(trip) {
      let result = ''
      this.uiDropdowns.locationDescription.forEach(function(aLocType) {
        if ( aLocType.value === trip.locationType ) {
          result = aLocType.label
        }
      })
      return result
    },
    formatVerificationType(trip) {
      if ( trip.verificationType === 'self' ) {
        return 'Self'
      } else if ( trip.verificationType === 'crewmate' ) {
        return 'Crewmate'
      } else if ( trip.verificationType === 'charter_rode' || trip.verificationType === 'charter_company' ) {
        return 'Charter Company'
      }
    },
    formatBoatOwner(vessel) {
      if ( vessel.boatOwner === 'self' ) {
        return 'Mine'
      } else if ( vessel.boatOwner === 'friend' ) {
        return 'Friend'
      } else if ( vessel.boatOwner === 'charterCompany' ) {
        if ( vessel.chartCompanyName != undefined && vessel.charterBase != undefined ) {
          return vessel.chartCompanyName +' - '+vessel.charterBase
        } else if ( vessel.chartCompanyName != undefined ) {
          return vessel.chartCompanyName
        }
        return 'Charter Company'
      } else if ( vessel.boatOwner === 'commercial' ) {
        return 'Commercial'
      }
    },
    formatLengthFromMeters(aLengthInMeters) {
      if ( aLengthInMeters ) {
        let lengthMeters = parseFloat(aLengthInMeters).toFixed(2)
        let lengthFeet = (parseFloat(lengthMeters) * 3.2808).toFixed(0)
        let lengthFormatted = lengthMeters + 'm (' + lengthFeet + ' ft)'
        return lengthFormatted
      }
      return '--'
    },
    formatBoatInfo(aRow) {
      let vesselLengthMeters = parseFloat(aRow.overallLengthMeter).toFixed(2)
      let vesselLengthFeet = (parseFloat(aRow.overallLengthMeter) * 3.2808).toFixed(0)
      let vesselLengthFormatted = vesselLengthMeters +'m ('+vesselLengthFeet+' ft)'
      if ( aRow.propulsion === 'sail' ) {
        if (aRow.hulls === 1) {
          if ( aRow.boatOwner === 'commercial' ) {
            return 'Commercial Sailing Monohull ' + vesselLengthFormatted
          }
          return 'Sailing Monohull ' + vesselLengthFormatted
        } else if (aRow.hulls === 2) {
          if ( aRow.boatOwner === 'commercial' ) {
            return 'Commercial Sailing Catamaran ' + vesselLengthFormatted
          }
          return 'Sailing Catamaran ' + vesselLengthFormatted
        } else if (aRow.hulls === 3) {
          if ( aRow.boatOwner === 'commercial' ) {
            return 'Commerial Sailing Trimaran ' + vesselLengthFormatted
          }
          return 'Sailing Trimaran ' + vesselLengthFormatted
        } else {
          if ( aRow.boatOwner === 'commercial' ) {
            return 'Commercial Sailboat ' + aRow.vesselLength + 'm'
          }
          return 'Sailboat ' + aRow.vesselLength + 'm'
        }
      } else if ( aRow.propulsion === 'power' ) {
        if (aRow.hulls === 1) {
          if ( aRow.boatOwner === 'commercial' ) {
            return 'Commercial Motorboat ' + vesselLengthFormatted
          }
          return 'Powerboat ' + vesselLengthFormatted
        } else if (aRow.hulls === 2) {
          if ( aRow.boatOwner === 'commercial' ) {
            return 'Commercial Powercat ' + vesselLengthFormatted
          }
          return 'Powercat ' + vesselLengthFormatted
        } else if (aRow.hulls === 3) {
          if ( aRow.boatOwner === 'commercial' ) {
            return 'Commercial Powered Trimaran ' + vesselLengthFormatted
          }
          return 'Powered Trimaran ' + vesselLengthFormatted
        } else {
          if ( aRow.boatOwner === 'commercial' ) {
            return 'Commercial Motorboat ' + vesselLengthFormatted
          }
          return 'Powerboat ' + vesselLengthFormatted
        }
      }
    },
    getOwnerNameFor(aRow) {
      if ( aRow.ownerType === 'charter_base' ) {
        return aRow.charterCompanyName
      } else if ( aRow.ownerType === 'commercial' ) {
        return aRow.commercialOwnerName
      } else if ( aRow.ownerId === this.user.id ) {
        return 'Privately Owned by '+this.user.firstName+' '+this.user.lastName
      } else {
        return 'Private'
      }
    },
    formatResumeDaysCell(aCell) {
      if ( aCell.days > 0 ) {
        return '<div>'+aCell.days+'<span style="position:relative;top:-1px;font-size: 10px;color:#888;"> | '+aCell.verified.percent+'</span></div><div style="font-size: 10px;color:#888;">'+aCell.verified.crewmate+'-'+aCell.verified.charterCompany+'</div>'
      } else {
        return '--'
      }
    },
    formatNumberTons(theNumber) {
      if ( theNumber == undefined ) { return '--'}
      return parseFloat(theNumber).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    },
    formatNumber(theNumber) {
      if ( theNumber == undefined ) { return 0}
      return parseInt(theNumber).toLocaleString(undefined, { minimumFractionDigits: 0 })
    },
    formatDecimal(theNumber) {
      if ( theNumber == undefined ) { return 0}
      return parseFloat(theNumber).toLocaleString(undefined, { minimumFractionDigits: 2 })
    },
    formatHours(theNumber) {
      if ( theNumber == undefined ) { return 0}
      return parseFloat(theNumber).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 })
    },
    formatPercent(theNumber) {
      if ( theNumber == undefined ) { return '--'}
      return (parseFloat(theNumber)*100).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '%'
    },
    formatDate(aDateInSeconds) {
      return new Date(aDateInSeconds).toLocaleDateString("en-US")
    }
  }
}
</script>

<style>
.cr-resume-table {
  font-size:12px;
  width: 100%;
}

.cr-resume-table th {
  background: #f6f6f6;
}

.cr-resume-background-none {
  background: transparent!important;
}

.cr-resume-border-top {
  border-top: 1px solid #ccc;
}
.cr-resume-border-right {
  border-right: 1px solid #ccc;
}

.cr-resume-border-top-none {
  border-top:none!important;
}
.cr-resume-border-bottom-none {
  border-bottom:none!important;
}

.cr-resume-footer-note {
  font-size: 11px;
  color:#999;
}

.vessel-item-label, .vessel-item-value {
  font-size:14px;
  color: #000000;
  white-space: nowrap;
}
.vessel-item-label {
  color:#999999;
  /*font-family: "Courier New";*/
  /*position: relative;*/
  /*top:1.5px;*/
}

</style>