<template>
  <div class="container">
    <div class="row mt-3 mb-3">
      <div class="col-sm-12">
        <h4>Vessels</h4>
        <div class="charterRodeHelpText">Add and update your favorite vessels to make adding logbook entries a breeze.</div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12">
        <div style="margin-top:10px;position: relative;" class="d-grid gap-2 d-md-block">
          <button type="button" class="btn btn-outline-primary btn-sm me-2" v-on:click="searchVessels">Charter Vessel Search</button>
          <button type="button" class="btn btn-outline-primary btn-sm me-2" v-on:click="activateAddVesselModal">Add New Vessel</button>
          <button type="button" class="btn btn-outline-danger btn-sm me-2" :disabled="selectedVesselIds.length <= 0" v-on:click="removeSelectedVessels">Remove Selected</button>
          <button type="button" class="btn btn-outline-secondary btn-sm me-2" v-on:click="refreshVessels" :disabled="refreshingVessels"><span v-show="refreshingVessels"><fai icon="spinner" spin/> Refreshing ...</span><span v-show="!refreshingVessels">Refresh Vessels</span></button>
          <div class="d-none d-sm-none d-md-block float-end table-caption " v-if="favoriteVessels.length === 0 || favoriteVessels.length > 1">{{favoriteVessels.length}} vessels</div><div class="d-none d-sm-none d-md-block float-end table-caption" v-else>{{favoriteVessels.length}} vessel</div>
        </div>
        <div style="margin-top:15px;" class="table-responsive-xxl">
          <VTable
              :data="favoriteVessels"
              ref="favoriteVesselsTable"
              class="table caption-top"
              selectionMode="multiple"
              selectedClass="table-primary"
              @stateChanged="selectVessels($event.selectedRows)">
            <template #head>
              <tr>
                <th style="width:5px;">&nbsp;</th>
                <VTh sortKey="vesselName" style="white-space: nowrap">Name</VTh>
                <VTh :sortKey="getOwnerNameFor" style="white-space: nowrap">Charter Company</VTh>
                <VTh :sortKey="getOwnerLocationFor" style="white-space: nowrap">Charter Base Location</VTh>
                <VTh sortKey="year" style="white-space: nowrap">Class/Model | Length</VTh>
                <VTh :sortKey="getPropulsionFor" style="white-space: nowrap">Type</VTh>
                <th>&nbsp;</th>
              </tr>
            </template>
            <template #body="{ rows }">
              <tr v-show="rows.length == 0">
                <td>&nbsp;</td>
                <td colspan="6" style="text-align: center">Hmm on the hard are we? <a class="charterRodeLink" v-on:click="searchVessels">Let's search for a charter vessel</a> or <a class="charterRodeLink" v-on:click="activateAddVesselModal">add your first vessel!</a></td>
              </tr>
              <VTr v-for="row in rows" :key="row.id" :row="row">
                <td style="vertical-align: middle"><input type="checkbox" class="form-check-input" name="crewmateId" v-bind:value="row.id" v-model="selectedVesselIds" v-on:click="toggleVesselRow"></td>
                <td>{{row.vesselName}}</td>
                <td>{{getOwnerNameFor(row)}}</td>
                <td>{{getOwnerLocationFor(row)}}</td>
                <td>{{row.year}} {{row.manufacturer}} {{row.model}} | {{getLengthWithUnitsFor(row)}}</td>
                <td>{{getPropulsionFor(row)}}</td>
                <td style="text-align: right"><a class="link-primary" v-if="row.sailBoatDataUrl" v-bind:href="row.sailBoatDataUrl" target="_blank"><fai icon="external-link-alt"/></a><span v-if="row.sailBoatDataUrl" style="position:relative;top:-2px;color:#ccc;font-size:14px;"> | </span><a class="link-primary" href="#" v-on:click="activeViewVesselModal(row, $event)"><fai :icon="['far', 'eye']"/></a></td>
              </VTr>
            </template>
          </VTable>
        </div>

      </div>
    </div>
  </div>




  <searchVesselsModal
      ref="searchVesselsModal"
      confirmButtonLabel="Add Selected Vessel"
      v-bind:user="user"
      v-on:setVessel="setVessel"
      v-on:activateAddVesselModal="activateAddVesselModal"
      v-on:modalClose="searchVesselsModalClose"></searchVesselsModal>

  <addVesselModal
      ref="addVesselModal"
      v-bind:user="user"
      v-on:setVessel="refreshVessels"
      v-on:modalClose="addVesselModalClose"></addVesselModal>

  <viewVesselModal
      ref="viewVesselModal"
      v-bind:user="user"
      v-on:modalClose="viewVesselModalClose"></viewVesselModal>

</template>

<script>
import api from "../../api/api";

export default {
  name: 'Vessels',
  data() {
    return {
      user:  this.$store.state.user,
      favoriteVessels: this.$store.state.user.favoriteVessels ? this.$store.state.user.favoriteVessels : [],
      selectedVesselIds: [],
      refreshingVessels: false,
    }
  },
  methods: {
    searchVessels() {
      this.$refs.searchVesselsModal.activateModal()
    },
    setVessel(theSelectedVessel) {
      // add from the front-end
      theSelectedVessel.fullVesselName = this.getFullVesselNameFor(theSelectedVessel)
      let foundMatch = false
      if ( this.$store.state.user.favoriteVessels == undefined ) {
        this.$store.state.user.favoriteVessels = []
      } else {
        this.$store.state.user.favoriteVessels.forEach(function(aVessel) {
          if ( aVessel.id == theSelectedVessel.id ) {
            foundMatch = true
          }
        })
      }
      if ( !foundMatch ) {
        // not already in the list
        this.$store.state.user.favoriteVessels.push(theSelectedVessel)
        this.favoriteVessels = this.$store.state.user.favoriteVessels
        // now alphabetize them!
        this.favoriteVessels.sort(function (a, b) {
          if (a.fullVesselName > b.fullVesselName) return 1
          if (a.fullVesselName < b.fullVesselName) return -1
          return 0
        })
      }

      // now let the back-end know about the new favorite vessel
      api.addVesselsAsFavorite(theSelectedVessel.id, function(theUser) {
        // we just need to update the favorites now
        this.$store.commit('setUser', theUser)
        this.favoriteVessels = this.$store.state.user.favoriteVessels ? this.$store.state.user.favoriteVessels : undefined
      })

      // cleanup
      this.selectedVesselIds = []
      this.$refs.favoriteVesselsTable.deselectAll()
    },
    getFullVesselNameFor(aVessel) {
      if ( aVessel.charterCompanyName && aVessel.charterBase ) {
        return aVessel.vesselName + ' - '+ aVessel.charterCompanyName + ' - ' + aVessel.charterBase
      } else if ( aVessel.charterCompanyName ) {
        return aVessel.vesselName + ' - '+ aVessel.charterCompanyName
      } else if ( aVessel.charterBase ) {
        return aVessel.vesselName + ' - '+ aVessel.charterBase
      } else {
        return aVessel.vesselName
      }
    },
    searchVesselsModalClose() {
      // nothing to do here
    },
    activateAddVesselModal() {
      this.$refs.addVesselModal.activateModal()
    },
    addVesselModalClose() {
      // nothing to do here
    },
    activeViewVesselModal(row, evt) {
      evt.preventDefault()
      this.$refs.viewVesselModal.activateModal(row)
    },
    viewVesselModalClose() {
      // nothing to do here
    },
    getOwnerNameFor(aRow) {
      if ( aRow.ownerType === 'charter_base' ) {
        return aRow.charterCompanyName
      } else if ( aRow.ownerType === 'commercial' ) {
        return aRow.commercialOwnerName
      } else if ( aRow.ownerId === this.user.id ) {
        return 'Privately Owned by '+this.user.firstName+' '+this.user.lastName
      } else {
        return 'Private'
      }
    },
    getOwnerLocationFor(aRow) {
      if ( aRow.ownerType === 'charter_base' ) {
        return aRow.charterBase
      } else {
        return '--'
      }
    },
    getLengthWithUnitsFor(aRow) {
      if ( this.user && this.user.preferredUnits && this.user.preferredUnits === 'imperial') {
        // convert to feet
        return (aRow.overallLengthMeter*3.28084).toFixed(1)+' ft'
      } else {
        return aRow.overallLengthMeter+'m'
      }
    },
    getPropulsionFor(aRow) {
      if ( aRow.propulsion === 'sail' ) {
        if ( aRow.hulls == 1 ) {
          if ( aRow.ownerType === 'commercial' ) {
            return 'Commercial Sailing Monohull'
          }
          return "Sailing Monohull"
        } else if ( aRow.hulls == 2 ) {
          if ( aRow.ownerType === 'commercial' ) {
            return 'Commercial Sailing Catamaran'
          }
          return "Sailing Catamaran"
        } else if ( aRow.hulls == 3 ) {
          if ( aRow.ownerType === 'commercial' ) {
            return 'Commercial Sailing Trimaran'
          }
          return "Sailing Trimaran"
        }
      } else {
        if ( aRow.hulls == 1 ) {
          if ( aRow.ownerType === 'commercial' ) {
            return 'Commercial Motorboat'
          }
          return "Motorboat"
        } else if ( aRow.hulls == 2 ) {
          return "Power Cat"
        } else {
          return 'Motor Vessel'
        }
      }
    },
    selectVessels(selectedRows) {
      if ( selectedRows && selectedRows.length > 0 ) {
        this.selectedVesselIds = []
        let self = this
        selectedRows.forEach(function(aRow) {
          self.selectedVesselIds.push(aRow.id)
        })
      } else {
        this.selectedVesselIds = []
      }
    },
    toggleVesselRow(event) {
      let self = this
      if ( event.currentTarget.checked ) {
        // add
        if ( this.favoriteVessels ) {
          let selectedVessel = undefined
          this.favoriteVessels.forEach(function(aResult) {
            if ( aResult.id == event.currentTarget.value ) {
              selectedVessel = aResult
            }
          })
          if ( selectedVessel ) {
            this.selectedVesselIds.push(selectedVessel.id)
            let selectedRows = []
            this.selectedVesselIds.forEach(function(anId) {
              self.favoriteVessels.forEach(function(aVessel) {
                if ( aVessel.id === anId ) {
                  selectedRows.push(aVessel)
                }
              })
            })
            this.$refs.favoriteVesselsTable.selectRows(selectedRows)
          }
        }
      } else {
        // remove
        let index = this.selectedVesselIds.indexOf(event.currentTarget.value);
        if (index !== -1) {
          this.selectedVesselIds.splice(index, 1);
        }
        let selectedRows = []
        // there is some bug in VTable...
        this.$refs.favoriteVesselsTable.deselectAll()
        this.selectedVesselIds.forEach(function(anId) {
          self.favoriteVessels.forEach(function(aVessel) {
            if ( aVessel.id === anId ) {
              selectedRows.push(aVessel)
            }
          })
        })
        this.$refs.favoriteVesselsTable.selectRows(selectedRows)
      }
    },
    removeSelectedVessels() {
      window.document.activeElement.blur()
      if ( confirm("Are you sure?") ) {
        // remove from the front-end
        let self = this
        this.selectedVesselIds.forEach(function(anId) {
          let index = -1
          for(let i = 0; i < self.favoriteVessels.length; ++i) {
            if ( self.favoriteVessels[i].id === anId ) {
              index = i
              break
            }
          }
          if (index !== -1) {
            self.favoriteVessels.splice(index, 1);
          }
        })


        // tell the back end.....
        api.removeVesselsFromFavorites(this.selectedVesselIds, function(theUser) {
          // we just need to update the favorites now
          this.$store.commit('setUser', theUser)
          this.favoriteVessels = this.$store.state.user.favoriteVessels ? this.$store.state.user.favoriteVessels : undefined
        })

        // cleanup!
        this.selectedVesselIds = []
        this.$refs.favoriteVesselsTable.deselectAll()
      }
    },
    refreshVessels() {
      window.document.activeElement.blur()
      // get the current set of
      this.refreshingVessels = true
      api.getMe().then(response => {
        if (response.user && response.user.firstName && response.user.lastName) {
          // all good let them go to home!
          this.$store.commit('setUser', response.user)
          if ( this.$store.state.user.favoriteVessels ) {
            let self = this
            this.$store.state.user.favoriteVessels.forEach(function(aVessel) {
              aVessel.fullVesselName = self.getFullVesselNameFor(aVessel)
            })
            // now alphabetize them!
            this.$store.state.user.favoriteVessels.sort(function(a,b) {
              if (a.fullVesselName > b.fullVesselName) return 1
              if (a.fullVesselName < b.fullVesselName) return -1
              return 0
            })

          }
          this.favoriteVessels = this.$store.state.user.favoriteVessels ? this.$store.state.user.favoriteVessels : []
        }
        this.refreshingVessels = false
      })
    }
  },
  components: {

  }
}
</script>

<style>

</style>
