<template>
  <div class="container" style="margin-bottom: 150px;">
    <div id="maintenance-block">
      <h1>We&rsquo;ll be back soon!</h1>
      <div style="text-align:center">
        <p>Sorry, we are performing maintenance at the moment.</p>
        <p><a href="http://www.youtube.com/results?search_query=underwater+basket+weaving"><img border="0" src="maintenance.jpg"></a></p>
        <p><a href="http://www.youtube.com/results?search_query=underwater+basket+weaving">Why not take a break yourself and learn a new skill?</a></p>
        <p>We&rsquo;ll be back online shortly!</p>
      </div>
    </div>

  </div>

</template>

<script>
export default {
  name: 'Maintenance',
  data() {
    return {
    }
  },
  mounted() {
    this.$store.state.isMaintenance = true
  },

  methods: {
  },
  components: {
  }
}
</script>

<style>
  #maintenance-block { display: block; text-align: center; width: 650px; margin: 0 auto; }
</style>
