<template>

  <teleport to="body">
    <!-- Modal -->
    <div class="modal fade" id="addCredentialModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-fullscreen">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="addCredentialLabel">Add New Credential</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="pb-3 charterRodeHelpText">In order to add your credential on Charter Rode, we must verify your credential details. Please select your credential from the below list and upload the necessary photos of your legally issued credential. Charter Rode does not solely use your uploaded photos of your credential to verify authenticity. We will either contact the issuing authority and verify the details of your credential, or the issuing authority will verify your credential directly.</div>

            <div class="container" v-if="activelySubmittingTransaction">
              <div class="row">
                <div class="col-sm-12">
                  <div class="tx-submit-spinner mt-lg-5"><fai icon="spinner" spin/> Please wait while your credential is submitted ...</div>
                </div>
              </div>
            </div>

            <div class="container" v-if="loadingCredentials">
              <div class="row">
                <div class="col-sm-12">
                  <div class="tx-submit-spinner mt-lg-5"><fai icon="spinner" spin/> Please wait loading available credentials ...</div>
                </div>
              </div>
            </div>

            <form autocomplete="off" novalidate v-on:submit.prevent="noop">

            <div class="container" v-if="!activelySubmittingTransaction && !loadingCredentials">

              <div class="row">
                <div class="col-lg-12">
                  <div class="form-floating mb-4">
                    <select class="form-control form-control-sm" id="credentialAuthorityId" name="credentialAuthorityId" v-model="credential.credentialAuthorityId" v-bind:class="{'is-valid' : validation.credentialAuthorityName, 'is-invalid': validation.credentialAuthorityName == false}" @change="onSelectCredentialAuthority()">
                      <option value="please_select" disabled selected>Please Select</option>
                      <option v-for="item in uiDropdowns.credentialAuthorities" :key="item.id" :value="item.id">{{item.name}}</option>
                    </select>
                    <label for="credentialAuthorityId">Credential Authority</label>
                    <div class="invalid-feedback">We need to know the issuing authority for your newly acquired credential.</div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <div class="form-floating mb-4">
                    <select class="form-control form-control-sm" id="credentialId" name="credentialId" v-model="credential.credentialId" v-bind:class="{'is-valid' : validation.credentialTitle, 'is-invalid': validation.credentialTitle == false}" :disabled="credential.credentialAuthorityId === 'please_select'" @change="onSelectCredential()">
                      <option value="please_select" disabled selected>Please Select</option>
                      <option v-for="item in availableCredentials" :key="item.id" :value="item.id">{{item.credentialTitle}}</option>
                    </select>
                    <label for="credentialId">Your Credential</label>
                    <div id="credentialIdHelpBlock" class="form-text">Not finding your credential? <a href="mailto:support@charterrode.com">Drop us a line</a> with your credential information, we are always adding new credentials!</div>
                    <div class="invalid-feedback">We need to know specifically which credential you earned.</div>
                  </div>
                </div>
              </div>
              <div class="row" v-if="selectedCredential">
                <div class="col-lg-6" :class="{'col-lg-6' : selectedCredential.expires, 'col-lg-12': selectedCredential.expires == false}">
                  <v-date-picker mode="date" v-model="credential.issuedDate" :popover="{ visibility: 'click' }" min-date="1950-01-01" :max-date="maxDateForCredential">
                    <template v-slot="{ inputValue, inputEvents }">
                      <div class="form-floating">
                        <input type="text" class="form-control charterRodeBtn" id="issuedDate" name="issuedDate" :value="inputValue" v-on="inputEvents" v-bind:class="{'is-valid' : validation.issuedDate, 'is-invalid': validation.issuedDate == false}">
                        <label for="date">Issued Date</label>
                        <div class="invalid-feedback">You must let use know when your credential was issued.</div>
                      </div>
                    </template>
                  </v-date-picker>
                </div>
                <div class="col-lg-6" v-if="selectedCredential.expires">
                  <v-date-picker mode="date" v-model="credential.expireDate" :popover="{ visibility: 'click' }" :min-date="maxDateForCredential">
                    <template v-slot="{ inputValue, inputEvents }">
                      <div class="form-floating">
                        <input type="text" class="form-control charterRodeBtn" id="date" name="date" :value="inputValue" v-on="inputEvents" v-bind:class="{'is-valid' : validation.expireDate, 'is-invalid': validation.expireDate == false}">
                        <label for="date">Expire Date</label>
                        <div class="invalid-feedback">You must let use know when your credential will expire.</div>
                      </div>
                    </template>
                  </v-date-picker>
                </div>
              </div>

              <template v-if="selectedCredential && selectedCredential.verificationInfo.endorsements">
                <div class="row mt-3">
                  <div class="col-lg-12">Please select any additional endorsements you have been granted with this credential:</div>
                </div>
                <div class="row">
                  <div class="col-lg-12">
                    <div class="form-check ms-3" v-for="item in selectedCredential.verificationInfo.endorsements" :key="item.label">
                      <input class="form-check-input" type="checkbox" v-model="endorsements" :value="item.label" :id="item.label">
                      <label class="form-check-label charterRodeBtn" :for="item.label">
                        {{item.label}}
                      </label>
                    </div>
                    <div></div>
                  </div>
                </div>
              </template>

              <div class="row" v-if="selectedCredential">
                <div :class="{'col-lg-6' : selectedCredential.verificationInfo.rearImage.required, 'col-lg-12': selectedCredential.verificationInfo.rearImage.required == false}" style="text-align: center;" v-if="selectedCredential.verificationInfo.frontImage.required">
                  <div class="input-group2 mb-3 mt-3" style="display: block;text-align: center;position: relative">
                    <input type="file" class="form-control" id="front" style="display: none;" :disabled="frontImageTracking.imageUploadComplete" @change="uploadFrontImage" accept="image/jpg, image/jpeg,image/png">
                    <label class="input-group-text charterRodeBtn" for="front" style="min-height:350px;display:block;padding: 0px;background: #fff">
                      <div style="padding: 50px;" :class="{'disabled-upload': frontImageTracking.imageUploadComplete, 'enabled-upload': !frontImageTracking.imageUploadComplete}">
                        <div style="display: inline-block;width: 180px!important;">
                          <fai class="fa-10x" :icon="[ 'fas', 'passport']" :class="{'disabled-upload': frontImageTracking.imageUploadComplete, 'enabled-upload-icon': !frontImageTracking.imageUploadComplete}"></fai>
                          <span class="translate-middle badge rounded-pill" :class="{'bg-disabled-upload': frontImageTracking.imageUploadComplete, 'bg-enabled-upload-green': !frontImageTracking.imageUploadComplete}" style="position: relative;bottom: -35px;"><fai icon="plus" class="fa-2x"></fai></span>
                        </div>
                        <div class="mt-3" style="white-space: normal">{{selectedCredential.verificationInfo.frontImage.label}}</div>
                      </div>
                    </label>
                    <span v-show="frontImageTracking.imageUploadComplete" class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-success"><fai icon="check"></fai></span>
                  </div>
                  <div>
                    <div class="progress" v-show="frontImageTracking.startedUpload">
                      <div class="progress-bar" role="progressbar" v-bind:style="{width: frontImageTracking.imageProgress +'%'}" v-bind:aria-valuenow="frontImageTracking.imageProgress" aria-valuemin="0" aria-valuemax="100">{{frontImageTracking.imageProgress}}%</div>
                    </div>
                  </div>
                  <div v-bind:class="{'is-invalid': validation.frontImageMissing == true}"></div>
                  <div class="invalid-feedback">You must upload this picture.</div>
                </div>
                <div class="col-lg-6" style="text-align: center;" v-if="selectedCredential.verificationInfo.rearImage.required">
                  <div class="input-group2 mb-3 mt-3" style="display: block;text-align: center;position: relative">
                    <input type="file" class="form-control" id="rear" style="display: none;" :disabled="rearImageTracking.imageUploadComplete" @change="uploadRearImage" accept="image/jpg, image/jpeg,image/png">
                    <label class="input-group-text charterRodeBtn" for="rear" style="min-height:350px;display:block;padding: 0px;background: #fff">
                      <div style="padding: 50px;" :class="{'disabled-upload': rearImageTracking.imageUploadComplete, 'enabled-upload': !rearImageTracking.imageUploadComplete}">
                        <div style="display: inline-block;width: 180px!important;">
                          <fai class="fa-10x" :icon="[ 'far', 'address-card']" :class="{'disabled-upload': rearImageTracking.imageUploadComplete, 'enabled-upload-icon': !rearImageTracking.imageUploadComplete}"></fai>
                          <span class="translate-middle badge rounded-pill" :class="{'bg-disabled-upload': rearImageTracking.imageUploadComplete, 'bg-enabled-upload-green': !rearImageTracking.imageUploadComplete}" style="position: relative;bottom: -35px;"><fai icon="plus" class="fa-2x"></fai></span>
                        </div>
                        <div class="mt-3" style="white-space: normal">{{selectedCredential.verificationInfo.rearImage.label}}</div>
                      </div>
                    </label>
                    <span v-show="rearImageTracking.imageUploadComplete" class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-success"><fai icon="check"></fai></span>
                  </div>
                  <div>
                    <div class="progress" v-show="rearImageTracking.startedUpload">
                      <div class="progress-bar" role="progressbar" v-bind:style="{width: rearImageTracking.imageProgress +'%'}" v-bind:aria-valuenow="rearImageTracking.imageProgress" aria-valuemin="0" aria-valuemax="100">{{rearImageTracking.imageProgress}}%</div>
                    </div>
                  </div>
                  <div v-bind:class="{'is-invalid': validation.rearImageMissing == true}"></div>
                  <div class="invalid-feedback">You must upload this picture.</div>
                </div>
              </div>


              <div class="row pb-3 pt-3">
                <div class="col-sm-12">
                  <div class="alert" v-bind:class="{'alert-danger': !confirmInformationAccurate, 'alert-success': confirmInformationAccurate}" role="alert">
                    <div class="disclaimer-text-left">
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="checkbox" id="confirmReadOnlyCredential" v-model="confirmInformationAccurate">
                        <label class="form-check-label charterRodeBtn" for="confirmReadOnlyCredential">
                          I certify that this information is accurate and I agree that Charter Rode may suspend my account if I provide false information.
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">Cancel</button>
            <button type="button" class="btn btn-primary" v-on:click="addCredential">Add Credential</button>
          </div>
        </div>
      </div>
    </div>
  </teleport>

  <teleport to="body">
    <div class="position-fixed bottom-0 end-0 p-3 cr-z-index-5">
      <div id="addCredentialTxToast" class="toast hide" role="alert" aria-live="assertive" aria-atomic="true">
        <div class="toast-header">
          <strong class="me-auto">Transaction Submitted Successfully!</strong>
          <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
        </div>
        <div class="toast-body">Your credential transaction has been successfully submitted</div>
      </div>
    </div>
  </teleport>

</template>

<script>
import {Modal, Toast} from "bootstrap"
import api from "../../api/api";

export default {
  name: 'AddCredentialModalView',
  emits: ['modalClose'],
  data() {
    return {
      loadingCredentials: true,
      confirmInformationAccurate: false,
      activelySubmittingTransaction: false,
      user:  this.$store.state.user,
      uiDropdowns: this.$store.state.uiDropdowns,
      selectedCredential: undefined,
      frontImageTracking: {
        startedUpload: false,
        imageProgress: 0,
        imageUploadComplete: undefined,
        imageUrl: undefined
      },
      rearImageTracking: {
        startedUpload: false,
        imageProgress: 0,
        imageUploadComplete: undefined,
        imageUrl: undefined
      },
      validation: {
        credentialAuthorityName: undefined,
        credentialTitle: undefined,
        issuedDate: undefined,
        expireDate: undefined,
        frontImageMissing: undefined,
        rearImageMissing: undefined
      },
      credential: {
        credentialAuthorityId: 'please_select',
        credentialId: 'please_select',
        issuedDate: undefined,
        expireDate: undefined
      },
      endorsements: []
    }
  },
  mounted() {

  },
  methods: {
    activateModal() {
      // check for valid JWT token as they may have walked a way for a good long while...
      api.checkForValidAuthToken().then(hasValidJWTToken => {
        // nothing to do that we care...
        if ( !hasValidJWTToken ) {
          this.addCredentialModal.hide()
        }
      })
      this.activelySubmittingTransaction = false
      this.confirmInformationAccurate = false
      this.selectedCredential = undefined
      this.frontImageTracking = {
        startedUpload: false,
        imageProgress: 0,
        imageUploadComplete: undefined,
        imageUrl: undefined
      }
      this.rearImageTracking = {
        startedUpload: false,
        imageProgress: 0,
        imageUploadComplete: undefined,
        imageUrl: undefined
      }
      this.validation = {
        credentialAuthorityName: undefined,
        credentialTitle: undefined,
        issuedDate: undefined,
        expireDate: undefined,
        frontImageMissing: undefined,
        rearImageMissing: undefined
      }
      this.credential = {
        credentialAuthorityId: 'please_select',
        credentialId: 'please_select',
        issuedDate: undefined,
        expireDate: undefined
      }

      this.addCredentialModal = new Modal(document.getElementById('addCredentialModal'))
      let addCredentialEl = document.getElementById('addCredentialModal')
      let self = this
      addCredentialEl.addEventListener('hidden.bs.modal', function () {
        self.$emit('modalClose')
      })
      self.addCredentialModal.toggle()

      if (this.uiDropdowns.credentialAuthorities == undefined || this.uiDropdowns.credentials == undefined) {
        this.loadingCredentials = true
        let self = this
        api.loadAvailableCredentials().then(availableCredentials => {
          if (availableCredentials && availableCredentials.credentialAuthorities) {
            self.uiDropdowns.credentialAuthorities = availableCredentials.credentialAuthorities
          }
          if (availableCredentials && availableCredentials.credentials) {
            self.uiDropdowns.credentials = availableCredentials.credentials
          }
          self.loadingCredentials = false
        })
      } else {
        this.loadingCredentials = false
      }
    },
    onSelectCredentialAuthority() {
      this.selectedCredential = undefined
      this.credential.credentialId = 'please_select'
      this.confirmInformationAccurate = false
    },
    onSelectCredential() {
      this.confirmInformationAccurate = false
      let self = this
      this.uiDropdowns.credentials.forEach(function(aCredential) {
        if ( aCredential.id === self.credential.credentialId ) {
          self.selectedCredential = aCredential
        }
      })
    },
    uploadRearImage() {
      let rearFile = document.getElementById("rear").files;
      if (!rearFile.length) {
        return alert("Please choose a file to upload first.")
      }

      this.rearImageTracking.startedUpload = true
      let rear = rearFile[0]
      let fileName = rear.name
      let fileType = rear.type

      this.uploadImageFor(rear, fileName, fileType, this.rearImageTracking)
      this.validation.rearImageMissing = undefined
    },
    uploadFrontImage() {
      let frontFile = document.getElementById("front").files;
      if (!frontFile.length) {
        return alert("Please choose a file to upload first.")
      }

      this.frontImageTracking.startedUpload = true
      let front = frontFile[0]
      let fileName = front.name
      let fileType = front.type

      this.uploadImageFor(front, fileName, fileType, this.frontImageTracking)
      this.validation.frontImageMissing = undefined
    },
    uploadImageFor(file, fileName, fileType, imageTrackingRef) {
      imageTrackingRef.imageProgress = 5
      api.getSignedS3UrlForUpload(fileName, fileType).then(theSignedUrl => {
        // upload the file!
        let signedRequest = theSignedUrl.signedRequest
        api.uploadFileToS3WithSignedUrl(file, signedRequest, function(percentComplete) {
          imageTrackingRef.imageProgress = percentComplete
        }).then(response => {
          if ( response ) {
            imageTrackingRef.imageUploadComplete = true
            imageTrackingRef.imageUrl = response
          }
        })
      })
    },
    addCredential() {
      let isFormValid = true

      if ( this.credential.credentialAuthorityId !== 'please_select') {
        this.validation.credentialAuthorityName = true
      } else {
        this.validation.credentialAuthorityName = false
        isFormValid = false
      }

      if ( this.credential.credentialId !== 'please_select') {
        this.validation.credentialTitle = true
      } else {
        this.validation.credentialTitle = false
        isFormValid = false
      }

      if ( this.credential.issuedDate ) {
        this.validation.issuedDate = true
      } else {
        this.validation.issuedDate = false
        isFormValid = false
      }
      if ( this.credential.expireDate ) {
        this.validation.expireDate = true
      } else {
        if ( this.selectedCredential.expires ) {
          this.validation.expireDate = false
          isFormValid = false
        }
      }

      this.validation.frontImageMissing = undefined
      if ( this.selectedCredential.verificationInfo.frontImage.required ) {
        if (!this.frontImageTracking.imageUploadComplete) {
          this.validation.frontImageMissing = true
          isFormValid = false
        } else {
          this.validation.frontImageMissing = false
        }
      }

      this.validation.rearImageMissing = undefined
      if ( this.selectedCredential.verificationInfo.rearImage.required ) {
        if (!this.rearImageTracking.imageUploadComplete) {
          this.validation.rearImageMissing = true
          isFormValid = false
        } else {
          this.validation.rearImageMissing = false
        }
      }

      // figure out endorsements if present!
      this.credential.endorsements = undefined
      if ( this.endorsements && this.endorsements.length > 0 ) {
        let self = this
        self.credential.endorsements = []
        self.endorsements.forEach(function(aSelectedEndorsement) {
          let matchingEndorsement = null
          self.selectedCredential.verificationInfo.endorsements.forEach(function(anOfficialEndorsement) {
            if ( anOfficialEndorsement.label === aSelectedEndorsement ) {
              matchingEndorsement = anOfficialEndorsement
            }
          })
          if ( matchingEndorsement != null ) {
            self.credential.endorsements.push(matchingEndorsement)
          }
        })
      }

      if ( !this.confirmInformationAccurate ) {
        isFormValid = false
      }


      // are we okay to create the transaction?
      if ( !isFormValid ) {
        return
      } else {
        // submit form!
        let tripTxRequest = {
          credential : {
            credentialId: this.credential.credentialId
          }
        }
        let issOffset = this.credential.issuedDate.getTimezoneOffset()
        let finalIssuedDate = new Date(this.credential.issuedDate.getTime() - (issOffset*60*1000)).toISOString().split('T')[0]
        tripTxRequest.credential.issuedDate = finalIssuedDate
        if ( this.selectedCredential.expires ) {
          let expOffset = this.credential.expireDate.getTimezoneOffset()
          let finalExpireDate = new Date(this.credential.expireDate.getTime() - (expOffset*60*1000)).toISOString().split('T')[0]
          tripTxRequest.credential.expireDate = finalExpireDate
        }

        if ( this.selectedCredential.verificationInfo.frontImage.required ) {
          tripTxRequest.credential.frontImage = this.frontImageTracking.imageUrl
        }
        if ( this.selectedCredential.verificationInfo.rearImage.required ) {
          tripTxRequest.credential.rearImage = this.rearImageTracking.imageUrl
        }

        if ( this.credential.endorsements && this.credential.endorsements.length > 0 ) {
          tripTxRequest.credential.endorsements = this.credential.endorsements
        }

        this.activelySubmittingTransaction = true
        api.submitTransaction(tripTxRequest).then(response => {
          if (response) {
            // clear out the draft trip info from store
            this.transactionSubmittedSuccessfully = true
            this.transactionSubmittedHadError = false

            let addCredentialTxToast = new Toast(document.getElementById('addCredentialTxToast'))
            addCredentialTxToast.show()
            this.activelySubmittingTransaction = false

            this.addCredentialModal.hide()
            // if it all worked, we likely updated the user's funds so need to reset that here as well...
            api.getMe().then(response => {
              if ( response.user && response.user.firstName && response.user.lastName ) {
                // all good...
                this.$store.commit('setUser', response.user)
              }
            })

          } else {
            // there was some sort of error!
            this.transactionSubmittedSuccessfully = false
            this.transactionSubmittedHadError = true
            this.activelySubmittingTransaction = false
          }
        })
      }
    }
  },
  computed: {
    maxDateForCredential() {
      let todayDate = new Date()
      let todayDateFormatted
      // need to set it to tomorrow for the widget
      todayDate.setDate(todayDate.getDate() + 1)
      // and now format it!
      todayDateFormatted = todayDate.getFullYear() + '-' + ('0' + (todayDate.getMonth()+1)).slice(-2) + '-' + ('0' + todayDate.getDate()).slice(-2)
      return todayDateFormatted
    },
    availableCredentials() {
      let availableCredentials = []
      let self = this
      this.uiDropdowns.credentials.forEach(function(aCredential) {
        if ( aCredential.credentialAuthorityId === self.credential.credentialAuthorityId ) {
          availableCredentials.push(aCredential)
        }
      })

      // sort
      availableCredentials.sort(function (a, b) {
        if ( a.displayOrder && b.displayOrder ) {
          if (a.displayOrder > b.displayOrder) return 1
          if (a.displayOrder < b.displayOrder) return -1
          return 0
        } else {
          if (a.credentialTitle > b.credentialTitle) return 1
          if (a.credentialTitle < b.credentialTitle) return -1
          return 0
        }
      })

      return availableCredentials
    },
  },
  components: {

  }
}
</script>

<style>
.search-spinner {
  text-align: center;
  margin-top:5px;
  font-size:18px;
  color:#666;
  font-weight: normal;
  z-index: 9999;
}
.selected-row {
  background-color: #0d6efd25;
}
</style>