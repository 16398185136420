<template>
  <div class="container">
    <div class="row mt-3 mb-3">
      <div class="col-sm-12">
        <h4>Crewmates</h4>
        <div class="charterRodeHelpText">Add or remove crewmates to make crewmate-based trip verification a breeze.</div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12">
        <div style="margin-top:10px;position: relative;" class="d-grid gap-2 d-md-block">
          <button type="button" class="btn btn-outline-primary btn-sm me-2" v-on:click="addCrewmate">Add Crewmate</button>
          <button type="button" class="btn btn-outline-danger btn-sm me-2" :disabled="selectedCrewmateIds.length <= 0" v-on:click="removeSelectedCrewmates">Remove Selected</button>
          <button type="button" class="btn btn-outline-secondary btn-sm me-2" v-on:click="refreshCrewmates" :disabled="refreshingCrewmates"><span v-show="refreshingCrewmates"><fai icon="spinner" spin/> Refreshing ...</span><span v-show="!refreshingCrewmates">Refresh Crewmates</span></button>
          <div class="d-none d-sm-none d-md-block float-end table-caption" v-if="crewmates.length === 0 || crewmates.length > 1">{{crewmates.length}} crewmates</div><div class="d-none d-sm-none d-md-block float-end table-caption" v-else>{{crewmates.length}} crewmate</div>
        </div>
        <div style="margin-top:15px;" class="table-responsive-xxl">
          <VTable
              :data="crewmates"
              ref="crewmatesTable"
              class="table"
              selectionMode="multiple"
              selectedClass="table-primary"
              @stateChanged="selectCrewmates($event.selectedRows)">
            <template #head>
              <tr>
                <th style="width:5px;">&nbsp;</th>
                <VTh sortKey="getFullNameFor(row)">Name</VTh>
                <VTh sortKey="email">Email</VTh>
                <VTh sortKey="location">Location</VTh>
              </tr>
            </template>
            <template #body="{ rows }">
              <tr v-show="rows.length == 0">
                <td>&nbsp;</td>
                <td colspan="3" style="text-align: center">Ahoy there, what no crewmates? <a class="charterRodeLink" v-on:click="addCrewmate">Let's add your first crewmate!</a></td>
              </tr>
              <VTr v-for="row in rows" :key="row.id" :row="row">
                <td style="vertical-align: middle"><input type="checkbox" class="form-check-input" name="crewmateId" v-bind:value="row.id" v-model="selectedCrewmateIds" v-on:click="toggleCrewmateRow"></td>
                <td>{{row.firstName}} {{row.lastName}}</td>
                <td v-html="formatEmailLink(row.email)"></td>
                <td>{{row.city}}, {{row.province}}</td>
              </VTr>
            </template>
          </VTable>
        </div>
      </div>
    </div>
  </div>


  <addNewCrewmateModal
      ref="addCrewmateModal"
      confirmButtonLabel="Add Crewmate"
      v-on:setCrewmate="setCrewmate"
      v-on:modalClose="addCrewmateModalClose"></addNewCrewmateModal>

</template>

<script>
import api from "../../api/api";

export default {
  name: 'Crewmates',
  data() {
    return {
      crewmates: this.$store.state.user.crewmates ? this.$store.state.user.crewmates : [],
      selectedCrewmateIds: [],
      refreshingCrewmates: false,
    }
  },
  methods: {
    addCrewmate() {
      this.$refs.addCrewmateModal.activateModal()
    },
    setCrewmate(theCrewmateSelected) {
      // now do the front-end sync
      let foundMatch = false
      if ( this.$store.state.user.crewmates == undefined ) {
        this.$store.state.user.crewmates = []
      } else {
        this.$store.state.user.crewmates.forEach(function(aCrewmate) {
          if ( aCrewmate.id == theCrewmateSelected.id ) {
            foundMatch = true
          }
        })
      }

      if ( !foundMatch ) {
        // not already in the list
        this.$store.state.user.crewmates.push(theCrewmateSelected)
        this.crewmates = this.$store.state.user.crewmates
        // now alphabetize them!
        this.crewmates.sort(function (a, b) {
          let aFullName = a.lastName.toLowerCase()+a.firstName.toLowerCase()
          let bFullName = b.lastName.toLowerCase()+b.firstName.toLowerCase()
          if (aFullName > bFullName) return 1
          if (aFullName < bFullName) return -1
          return 0
        })
      }

      // now let the back-end know about the new crewmate
      api.addCrewmatesAsFavorite(theCrewmateSelected.id).then(theUser => {
        // we just need to update the favorites now
        this.$store.commit('setUser', theUser)
        this.crewmates = this.$store.state.user.crewmates ? this.$store.state.user.crewmates : []
      })

      // cleanup
      this.selectedCrewmateIds = []
      this.$refs.crewmatesTable.deselectAll()
    },
    addCrewmateModalClose() {
      // nothing to do here...
    },
    getFullNameFor(aRow) {
      return aRow.lastName+aRow.firstName
    },
    selectCrewmates(selectedRows) {
      if ( selectedRows && selectedRows.length > 0 ) {
        this.selectedCrewmateIds = []
        let self = this
        selectedRows.forEach(function(aRow) {
          self.selectedCrewmateIds.push(aRow.id)
        })
      } else {
        this.selectedCrewmateIds = []
      }
    },
    toggleCrewmateRow(event) {
      let self = this
      if ( event.currentTarget.checked ) {
        // add
        if ( this.crewmates ) {
          let selectedCrewmate = undefined
          this.crewmates.forEach(function(aResult) {
            if ( aResult.id == event.currentTarget.value ) {
              selectedCrewmate = aResult
            }
          })
          if ( selectedCrewmate ) {
            this.selectedCrewmateIds.push(selectedCrewmate.id)
            let selectedRows = []
            this.selectedCrewmateIds.forEach(function(anId) {
              self.crewmates.forEach(function(aCrewmate) {
                if ( aCrewmate.id == anId ) {
                  selectedRows.push(aCrewmate)
                }
              })
            })
            this.$refs.crewmatesTable.selectRows(selectedRows)
          }
        }
      } else {
        // remove
        let index = this.selectedCrewmateIds.indexOf(event.currentTarget.value);
        if (index !== -1) {
          this.selectedCrewmateIds.splice(index, 1);
        }
        let selectedRows = []
        // there is some bug in VTable...
        this.$refs.crewmatesTable.deselectAll()
        this.selectedCrewmateIds.forEach(function(anId) {
          self.crewmates.forEach(function(aCrewmate) {
            if ( aCrewmate.id == anId ) {
              selectedRows.push(aCrewmate)
            }
          })
        })
        this.$refs.crewmatesTable.selectRows(selectedRows)
      }
    },
    removeSelectedCrewmates() {
      window.document.activeElement.blur()
      if ( confirm("Are you sure?") ) {
        // remove from the front-end
        let self = this
        this.selectedCrewmateIds.forEach(function(anId) {
          let index = -1
          for(let i = 0; i < self.crewmates.length; ++i) {
            if ( self.crewmates[i].id === anId ) {
              index = i
              break
            }
          }
          if (index !== -1) {
            self.crewmates.splice(index, 1);
          }
        })

        // tell the back end.....
        api.removeCrewmatesFromFavorites(this.selectedCrewmateIds, function(theUser) {
          // we just need to update the favorites now
          this.$store.commit('setUser', theUser)
          this.favoriteVessels = this.$store.state.user.favoriteVessels ? this.$store.state.user.favoriteVessels : undefined
        })

        // cleanup
        this.selectedCrewmateIds = []
        this.$refs.crewmatesTable.deselectAll()
      }
    },
    formatEmailLink(anEmail) {
      return '<a href="mailto:'+anEmail+'">'+anEmail+'</a>'
    },
    refreshCrewmates() {
      // get the current set of
      window.document.activeElement.blur()
      this.refreshingCrewmates = true
      api.getMe().then(response => {
        if (response.user && response.user.firstName && response.user.lastName) {
          // all good let them go to home!
          this.$store.commit('setUser', response.user)
          this.crewmates = this.$store.state.user.crewmates ? this.$store.state.user.crewmates : []
          this.refreshingCrewmates = false

          if ( this.crewmates ) {
            // alphabetize them!
            this.crewmates.sort(function(a,b) {
              let aFullName = a.lastName.toLowerCase()+a.firstName.toLowerCase()
              let bFullName = b.lastName.toLowerCase()+b.firstName.toLowerCase()
              if (aFullName > bFullName) return 1
              if (aFullName < bFullName) return -1
              return 0
            })
          }
        }
      })
    }
  },
  components: {

  }
}
</script>

<style>

</style>
