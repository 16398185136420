<template>

  <teleport to="body">
    <!-- Modal -->
    <div class="modal fade" id="verifyTransactionModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-fullscreen">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="viewVesselLabel">Verify Log Entry</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="pb-3 charterRodeHelpText">Below are the details for this entry. Use the options below to either VERIFY or REJECT this entry from {{sailorName}}.</div>

            <form autocomplete="off" novalidate v-on:submit.prevent="noop">
              <div class="container" v-if="tripTransaction">
                <div class="row mb-3">
                  <div class="col-lg-4">
                    <div><code>Tx Id: {{transaction.id}}</code></div>
                  </div>
                  <div class="col-lg-4">
                    <div><code>Tx Date: {{formatDateTime(transaction.time)}}</code></div>
                  </div>
                  <div class="col-lg-4">
                    <div><code>Tx Status:</code> <span v-html="getTxStatusFor(transaction)"></span></div>
                    <div v-if="transaction.rejectReason"><code>Reason: {{getTxRejectReasonFor(transaction)}}</code></div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-4">
                    <div class="mb-4">
                      <div class="form-floating">
                        <input type="text" class="form-control" id="date" name="date" v-model="tripInfo.date" disabled>
                        <label for="date">Date</label>
                      </div>
                      <div class="form-check form-switch">
                        <input id="multiDayTripCheck" class="form-check-input charterRodeBtn" type="checkbox" v-model="tripInfo.multiDayTrip" disabled>
                        <label class="form-check-label charterRodeBtn multi-day-trip-label" for="multiDayTripCheck">
                          Multi-day trip
                        </label>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-4" v-bind:class="{'col-lg-4' : tripInfo.verificationAuthority == 'Self' || tripInfo.verificationAuthority == 'Crewmate', 'col-lg-8': tripInfo.verificationAuthority =='Charter Rode' || tripInfo.verificationAuthority =='Charter Base'}">
                    <div class="form-floating mb-4">
                      <select class="form-control form-control-sm" id="tripRole" name="tripRole" v-model="tripInfo.tripRole" disabled>
                        <option value="please_select" disabled selected>Please Select</option>
                        <option v-for="item in uiDropdowns.roleType" :key="item.value" :value="item.value">{{item.label}}</option>
                      </select>
                      <label for="tripRole">{{sailorName}} Role</label>
                    </div>
                  </div>

                  <div class="col-lg-4" v-if="tripInfo.verificationAuthority =='Crewmate'">
                    <div class="form-floating mb-4">
                      <select class="form-control form-control-sm" id="crewmateRole" name="crewmateRole" v-model="tripInfo.crewmateRole" disabled>
                        <option value="please_select" disabled selected>Please Select</option>
                        <option v-for="item in uiDropdowns.roleType" :key="item.value" :value="item.value">{{item.label}}</option>
                      </select>
                      <label for="crewmateRole">Your Role</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-4">
                    <div class="form-floating mb-4">
                      <input type="text" class="form-control"  id="vessel" name="vessel" v-model="tripInfo.vessel" disabled>
                      <label for="vessel">Vessel</label>
                    </div>
                  </div>

                  <div class="col-lg-8">
                    <div class="form-floating mb-4">
                      <textarea class="form-control charterRodeTextArea" id="notes" name="notes" v-model="tripInfo.notes" disabled></textarea>
                      <label for="notes">Notes</label>
                    </div>
                  </div>
                </div>

                <div class="row">

                  <div class="col-lg-4" v-show="tripInfo.multiDayTrip">
                    <div class="form-floating mb-4">
                      <input type="number" class="form-control"  id="multiDayTripDays" name="multiDayTripDays" v-model="tripInfo.multiDayTripDays" disabled>
                      <label for="multiDayTripDays">Number of Days</label>
                    </div>
                  </div>

                  <div class="col-lg-4" v-show="tripInfo.multiDayTrip">
                    <div class="form-floating mb-4">
                      <input type="number" class="form-control"  id="multiDayTripNights" name="multiDayTripNights" v-model="tripInfo.multiDayTripNights" disabled>
                      <label for="multiDayTripNights">Number of Nights</label>
                    </div>
                  </div>

                  <div class="col-lg-4" v-show="!tripInfo.multiDayTrip">
                    <div class="form-floating mb-4">
                      <input type="number" class="form-control" id="tripDuration" name="tripDuration" v-model="tripInfo.tripDuration" disabled>
                      <label for="tripDuration">Duration (hours)</label>
                    </div>
                  </div>

                  <div class="col-lg-4">
                    <div class="form-floating mb-4">
                      <input type="number" class="form-control form-control-xlg"  id="tripDistance" name="tripDistance" v-model="tripInfo.tripDistance"  disabled>
                      <label for="tripDistance" v-if="tripInfo.multiDayTrip">Avg. Sea Miles per day</label>
                      <label for="tripDistance" v-else>Sea Miles (nm.)</label>
                    </div>
                  </div>

                  <div class="col-lg-4" v-show="!tripInfo.multiDayTrip">
                    <div class="form-floating mb-4">
                      <select class="form-control form-control-sm" id="timeOfDay" name="timeOfDay" v-model="tripInfo.tripTimeOfDay" disabled>
                        <option value="please_select" disabled selected>Please Select</option>
                        <option v-for="item in uiDropdowns.timeOfDay" :key="item.value" :value="item.value">{{item.label}}</option>
                      </select>
                      <label for="timeOfDay">Time of Day</label>
                    </div>
                  </div>

                </div>

                <div class="row">

                  <div class="col-lg-4">
                    <div class="form-floating mb-4">
                      <select class="form-control form-control-sm" id="windSpeed" name="windSpeed" v-model="tripInfo.windSpeed" disabled>
                        <option value="please_select" disabled selected>Please Select</option>
                        <option v-for="item in uiDropdowns.windSpeedBeaufort" :key="item.value" :value="item.value">{{item.label}}</option>
                      </select>
                      <label for="windSpeed" v-if="tripInfo.multiDayTrip">Avg. Wind Speed per Day</label>
                      <label for="windSpeed" v-else>Wind Speed</label>
                      <div id="windSpeedHelpBlock" class="form-text">Beaufort Number: {{tripInfo.windSpeed}}</div>
                    </div>
                  </div>

                  <div class="col-lg-4">
                    <div class="form-floating mb-4">
                      <select class="form-control form-control-sm" id="weatherConditions" name="weatherConditions" v-model="tripInfo.weatherConditions" disabled>
                        <option value="please_select" disabled selected>Please Select</option>
                        <option v-for="item in uiDropdowns.weatherConditions" :key="item.value" :value="item.value">{{item.label}}</option>
                      </select>
                      <label for="weatherConditions" v-if="tripInfo.multiDayTrip">Avg. Weather Conditions per Day</label>
                      <label for="weatherConditions" v-else>Weather Conditions</label>
                    </div>
                  </div>

                  <div class="col-lg-4">
                    <div class="form-floating mb-4">
                      <select class="form-control form-control-sm" id="cloudConditions" name="cloudConditions" v-model="tripInfo.cloudConditions" disabled>
                        <option value="please_select" disabled selected>Please Select</option>
                        <option v-for="item in uiDropdowns.cloudConditions" :key="item.value" :value="item.value">{{item.label}}</option>
                      </select>
                      <label for="cloudConditions" v-if="tripInfo.multiDayTrip">Avg. Cloud Conditions per Day</label>
                      <label for="cloudConditions" v-else>Cloud Conditions</label>
                    </div>
                  </div>

                </div>

                <div class="row">
                  <div class="col-lg-4">
                    <div class="form-floating mb-4">
                      <select class="form-control form-control-sm" id="tripPurpose" name="tripPurpose" v-model="tripInfo.tripPurpose" disabled>
                        <option value="please_select" disabled selected>Please Select</option>
                        <option v-for="item in uiDropdowns.purpose" :key="item.value" :value="item.value">{{item.label}}</option>
                      </select>
                      <label for="tripPurpose">Purpose</label>
                    </div>
                  </div>

                  <div class="col-lg-4">
                    <div class="form-floating mb-4">
                      <input type="text" class="form-control" id="location" name="location" v-model="tripInfo.location" disabled>
                      <label for="location">Location</label>
                    </div>
                  </div>

                  <div class="col-lg-4">
                    <div class="form-floating mb-4">
                      <select class="form-control form-control-sm" id="locationDescription" name="locationDescription" v-model="tripInfo.locationDescription" disabled>
                        <option value="please_select" disabled selected>Please Select</option>
                        <option v-for="item in uiDropdowns.locationDescription" :key="item.value" :value="item.value">{{item.label}}</option>
                      </select>
                      <label for="locationDescription">Location Type</label>
                    </div>
                  </div>
                </div>

                <div class="row" v-if="tripInfo.charterCompany">
                  <div class="col-lg-4">
                    <div class="form-floating mb-4">
                      <input type="text" class="form-control" id="charterCompanyName" name="charterCompanyName" v-model="tripInfo.charterCompany.charterCompanyName" disabled>
                      <label for="charterCompanyName">Charter Company Name</label>
                    </div>
                  </div>

                  <div class="col-lg-4">
                    <div class="form-floating mb-4">
                      <input type="text" class="form-control" id="charterBaseName" name="charterBase" v-model="tripInfo.charterCompany.charterCompanyBaseName" disabled>
                      <label for="charterBaseName">Charter Base Name</label>
                    </div>
                  </div>

                  <div class="col-lg-4">
                    <div class="form-floating mb-4">
                      <input type="text" class="form-control" id="charterBase" name="charterBase" v-model="tripInfo.charterCompany.charterCompanyBaseLocation" disabled>
                      <label class="d-none d-xl-block" for="charterBase">Charter Base Location (City, Province, Country)</label>
                      <label class="d-block d-xl-none" for="charterBase">Charter Base Location</label>
                    </div>
                  </div>
                </div>

                <div class="row" v-if="tripInfo.charterCompany == undefined && tripInfo.fullVessel.charterCompanyName">
                  <div class="col-lg-6">
                    <div class="form-floating mb-4">
                      <input type="text" class="form-control" id="vesCharterCompanyName" name="charterCompanyName" v-model="tripInfo.fullVessel.charterCompanyName" disabled>
                      <label for="vesCharterCompanyName">Charter Company Name</label>
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div class="form-floating mb-4">
                      <input type="text" class="form-control" id="vesCharterBaseName" name="charterBase" v-model="tripInfo.fullVessel.charterBase" disabled>
                      <label for="vesCharterBaseName">Charter Base Name</label>
                    </div>
                  </div>
                </div>
                <div class="row" v-if="tripInfo.charterCompany == undefined && tripInfo.fullVessel.charterCompanyName">

                  <div class="col-lg-6">
                    <div class="form-floating mb-4">
                      <input type="text" class="form-control" id="vesCharterCompanyEmail" name="charterBaseEmail" v-model="tripInfo.fullVessel.charterCompanyEmail" disabled>
                      <label for="vesCharterCompanyEmail">Charter Company Email</label>
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div class="form-floating mb-4">
                      <input type="text" class="form-control" id="vesCharterCompanyPhone" name="charterBasePhone" v-model="tripInfo.fullVessel.charterCompanyPhone" disabled>
                      <label for="vesCharterCompanyPhone">Charter Base Phone</label>
                    </div>
                  </div>
                </div>



              </div>

              <div class="container" v-if="!tripTransaction">
                <div class="row">
                  <div class="col-lg-4">
                    <div><code>Tx Id: {{transaction.id}}</code></div>
                  </div>
                  <div class="col-lg-4">
                    <div><code>Tx Date: {{formatDateTime(transaction.time)}}</code></div>
                  </div>
                  <div class="col-lg-4">
                    <div><code>Tx Status:</code> <span v-html="getTxStatusFor(transaction)"></span></div>
                    <div v-if="transaction.rejectReason"><code>Reason: {{getTxRejectReasonFor(transaction)}}</code></div>
                  </div>
                </div>

                <div class="row mb-3">
                  <div class="col-lg-4 mt-1">
                    <div><code>Tx Type: {{getTxTypeFor(transaction)}}</code></div>
                  </div>
                </div>

                <div class="row" v-if="identityVerificationTransaction && showEmbeddedImages">
                  <div class="row mb-3">
                    <div class="col-lg-4 mt-1">
                      <div><code>Sailor Id: {{userToVerify.id}}</code></div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-4">
                      <div><code>First Name: {{userToVerify.firstName}}</code></div>
                    </div>
                    <div class="col-lg-4">
                      <div><code>Last Name: {{userToVerify.lastName}}</code></div>
                    </div>
                    <div class="col-lg-4">
                      <div><code>Email: {{userToVerify.email}}</code></div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-lg-4">
                      <div><code>Address 1: {{userToVerify.addressLine1}}</code></div>
                    </div>
                    <div class="col-lg-4">
                      <div><code>Address 2: {{userToVerify.addressLine2}}</code></div>
                    </div>
                    <div class="col-lg-4">
                      <div><code>City: {{userToVerify.city}}</code></div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-lg-4">
                      <div><code>State: {{userToVerify.province}}</code></div>
                    </div>
                    <div class="col-lg-4">
                      <div><code>Postal Code: {{userToVerify.postalCode}}</code></div>
                    </div>
                    <div class="col-lg-4">
                      <div><code>Country: {{userToVerify.country}}</code></div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-lg-4">
                      <div><code>Wallet Balance: {{userToVerify.currentWalletFundsCRT}} CRT</code></div>
                    </div>
                    <div class="col-lg-4">
                      <div><code>Workflow Step: {{userToVerify.newUserWorkflowStep}}</code></div>
                    </div>
                    <div class="col-lg-4">
                      <div></div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-lg-6" style="text-align: center;">
                      <div class="input-group2 mb-3 mt-3" style="display: block;text-align: center;position: relative">
                        <label class="input-group-text" style="display:block;padding: 0px;background: #fff">
                          <div style="padding: 50px;">
                            <div style="display: inline-block;text-align: center;">
                              <a :href="transaction.input.log.identityVerification.frontImage" target="_blank"><img class="img-fluid" :src="transaction.input.log.identityVerification.frontImage"></a>
                            </div>
                            <div class="mt-3" style="white-space: normal">Photo of the driver's license or government issued photo ID<br/><span class="text-uppercase">(Front)</span></div>
                          </div>
                        </label>
                      </div>
                    </div>
                    <div class="col-lg-6" style="text-align: center;">
                      <div class="input-group2 mb-3 mt-3" style="display: block;text-align: center;position: relative">
                        <label class="input-group-text" style="display:block;padding: 0px;background: #fff">
                          <div style="padding: 50px;">
                            <div style="display: inline-block;text-align: center;">
                              <a :href="transaction.input.log.identityVerification.rearImage" target="_blank"><img class="img-fluid" :src="transaction.input.log.identityVerification.rearImage"></a>
                            </div>
                            <div class="mt-3" style="white-space: normal">Photo of the driver's license or government issued photo ID<br/><span class="text-uppercase">(Rear)</span></div>
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row" v-if="credentialVerificationTransaction && showEmbeddedImages">
                  <div class="row">
                    <div class="col-lg-4 mt-1">
                      <div><code>Credential Title: {{transaction.input.log.credential.credentialTitle}}</code></div>
                    </div>
                    <div class="col-lg-4 mt-1">
                      <div><code>Issued Date: {{transaction.input.log.credential.issuedDate}}</code></div>
                    </div>
                    <div class="col-lg-4 mt-1" v-if="transaction.input.log.credential.expireDate">
                      <div><code>Expire Date: {{transaction.input.log.credential.expireDate}}</code></div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-12">
                      <div><code>Endorsements: {{renderEndorsements(transaction.input.log.credential.endorsements)}}</code></div>
                    </div>
                  </div>
                  <div class="row mt-3">
                    <div class="col-lg-4">
                      <div><code>First Name: {{userToVerify.firstName}}</code></div>
                    </div>
                    <div class="col-lg-4">
                      <div><code>Last Name: {{userToVerify.lastName}}</code></div>
                    </div>
                    <div class="col-lg-4">
                      <div><code>Email: {{userToVerify.email}}</code></div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-lg-4">
                      <div><code>Address 1: {{userToVerify.addressLine1}}</code></div>
                    </div>
                    <div class="col-lg-4">
                      <div><code>Address 2: {{userToVerify.addressLine2}}</code></div>
                    </div>
                    <div class="col-lg-4">
                      <div><code>City: {{userToVerify.city}}</code></div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-lg-4">
                      <div><code>State: {{userToVerify.province}}</code></div>
                    </div>
                    <div class="col-lg-4">
                      <div><code>Postal Code: {{userToVerify.postalCode}}</code></div>
                    </div>
                    <div class="col-lg-4">
                      <div><code>Country: {{userToVerify.country}}</code></div>
                    </div>
                  </div>

                  <div class="row">
                    <div :class="{'col-lg-6' : transaction.input.log.credential.rearImage, 'col-lg-12': transaction.input.log.credential.rearImage == undefined}" style="text-align: center;">
                      <div class="input-group2 mb-3 mt-3" style="display: block;text-align: center;position: relative">
                        <label class="input-group-text" style="min-height:275px;display:block;padding: 0px;background: #fff">
                          <div style="padding: 50px;">
                            <div style="display: inline-block;text-align: center;">
                              <a :href="transaction.input.log.credential.frontImage" target="_blank"><img class="img-fluid" :src="transaction.input.log.credential.frontImage"></a>
                            </div>
                            <div class="mt-3" style="white-space: normal">{{selectedCredential.verificationInfo.frontImage.label}}</div>
                          </div>
                        </label>
                      </div>
                    </div>
                    <div class="col-lg-6" style="text-align: center;" v-if="transaction.input.log.credential.rearImage">
                      <div class="input-group2 mb-3 mt-3" style="display: block;text-align: center;position: relative">
                        <label class="input-group-text" style="min-height:275px;display:block;padding: 0px;background: #fff">
                          <div style="padding: 50px;">
                            <div style="display: inline-block;text-align: center;">
                              <a :href="transaction.input.log.credential.rearImage" target="_blank"><img class="img-fluid" :src="transaction.input.log.credential.rearImage"></a>
                            </div>
                            <div class="mt-3" style="white-space: normal">{{selectedCredential.verificationInfo.rearImage.label}}</div>
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

              </div>

            </form>
          </div>
          <div class="modal-footer" style="justify-content: center!important;">
            <div class="container">
              <div class="row mx-auto">
                <div class="col-md-4"><div class="d-grid gap-2 mt-1"><button type="button" class="btn btn-danger" v-on:click="activateRejectModal" :disabled="(credentialVerificationTransaction || identityVerificationTransaction) && gettingTransactionInfoForVerification"><fai icon="ban"></fai> Reject Entry</button></div></div>
                <div class="col-md-4"><div class="d-grid gap-2 mt-1"><button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal" :disabled="(credentialVerificationTransaction || identityVerificationTransaction) && gettingTransactionInfoForVerification">Cancel</button></div></div>
                <div class="col-md-4"><div class="d-grid gap-2 mt-1"><button type="button" class="btn btn-success" v-on:click="activateConfirmModal" :disabled="(credentialVerificationTransaction || identityVerificationTransaction) && gettingTransactionInfoForVerification"><fai icon="check"></fai> Verify Entry</button></div></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </teleport>


  <teleport to="body">
    <!-- Modal -->
    <div class="modal fade" id="confirmVerifyTransactionModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-fullscreen">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Confirm Verify Log Entry</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="pb-3 charterRodeHelpText">Please complete the certification process below and let us know if you would like to add this entry to your logbook.</div>

            <div class="container" v-if="sendingVerifyTransactionRequest">
              <div class="row">
                <div class="col-sm-12">
                  <div class="tx-submit-spinner mt-lg-5"><fai icon="spinner" spin/> Please wait while this log entry is verified...</div>
                </div>
              </div>
            </div>

            <div class="container" v-if="!sendingVerifyTransactionRequest">
              <div class="row pb-3 pt-3">
                <div class="col-sm-12">
                  <div class="alert" v-bind:class="{'alert-danger': !confirmTx.agreeInfoIsCorrect, 'alert-success': confirmTx.agreeInfoIsCorrect}" role="alert">
                    <div>
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="checkbox" id="agreeInfoIsCorrect" v-model="confirmTx.agreeInfoIsCorrect">
                        <label class="form-check-label charterRodeBtn" for="agreeInfoIsCorrect">
                          <span v-if="tripTransaction && crewmateVerification">I certify that this information is accurate and I was <span style="font-weight: bold;font-style: italic">present as <span style="text-transform: capitalize">{{tripInfo.crewmateRole}}</span> for this trip</span>.</span>
                          <span v-else>I certify that this information is complete and accurate.</span>
                        </label>
                      </div>
                    </div>
                  </div>

                  <div v-if="tripTransaction && crewmateVerification" class="alert" v-bind:class="{'alert-danger': !confirmTx.addToMyLogbook, 'alert-success': confirmTx.addToMyLogbook}" role="alert">
                    <div>
                      <div class="form-check">
                        <input class="form-check-input" type="radio" id="addToMyLogbook" value="true" v-model="confirmTx.addToMyLogbook">
                        <label class="form-check-label charterRodeBtn" for="addToMyLogbook">
                          <span>Please add this entry to my logbook. I authorize Charter Rode to charge my account a <span class="disclaimer-text-highlight">total of {{tripInfo.totalFeesForEntry}}</span> to process, and commit this entry.</span>
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="radio" id="addToMyLogbookNo" value="false" v-model="confirmTx.addToMyLogbook">
                        <label class="form-check-label charterRodeBtn" for="addToMyLogbookNo">
                          <span v-if="tripInfo">I am not interested in adding this experience on Charter Rode.</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal" :disabled="sendingVerifyTransactionRequest">Close</button>
            <button v-if="tripInfo && crewmateVerification" type="button" class="btn btn-success" v-on:click="verifyTransaction" :disabled="sendingVerifyTransactionRequest || confirmTx.addToMyLogbook === undefined || confirmTx.agreeInfoIsCorrect == undefined || confirmTx.agreeInfoIsCorrect == false"><span v-if="confirmTx.addToMyLogbook == 'true'">Verify Entry & Add it to My Logbook</span><span v-else>Verify Entry</span></button>
            <button v-else type="button" class="btn btn-success" v-on:click="verifyTransaction" :disabled="sendingVerifyTransactionRequest || confirmTx.agreeInfoIsCorrect == undefined || confirmTx.agreeInfoIsCorrect == false">Verify Entry</button>
          </div>
        </div>
      </div>
    </div>
  </teleport>


  <teleport to="body">
    <!-- Modal -->
    <div class="modal fade" id="rejectVerifyTransactionModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-fullscreen">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Confirm Log Entry Rejection</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="pb-3 charterRodeHelpText">Arr, Matey! Making this log entry walk the plank are we? Please let {{sailorName}} know why you are rejecting this log entry so that they may try harder next time.</div>

            <div class="container" v-if="sendingRejectTransactionRequest">
              <div class="row">
                <div class="col-sm-12">
                  <div class="tx-submit-spinner mt-lg-5"><fai icon="spinner" spin/> Please wait while this log entry is rejected...</div>
                </div>
              </div>
            </div>

            <div v-if="tripInfo && !sendingRejectTransactionRequest" class="container">
              <div class="row">
                <div class="col-sm-12">
                  <div class="form-floating mb-4">
                    <select class="form-control form-control-sm" id="rejectTransactionReasonTrip" name="rejectTransactionReasonTrip" v-model="rejectTx.rejectReason">
                      <option value="please_select" disabled selected>Please Select</option>
                      <option v-for="item in uiDropdowns.rejectTx.trip.reasons" :key="item.value" :value="item.value">{{item.label}}</option>
                    </select>
                    <label for="rejectTransactionReasonTrip">Rejection Reason</label>
                  </div>
                </div>
              </div>
              <div class="row pb-3">
                <div class="col-sm-12">
                  <div class="form-floating mb-4">
                    <textarea class="form-control form-control-sm" style="height: auto!important;" id="rejectTransactionTripComments" name="rejectTransactionTripComments" v-model="rejectTx.rejectionComments" rows="3"></textarea>
                    <label for="rejectTransactionReasonGeneral">Rejection Comments (optional)</label>
                  </div>

                </div>
              </div>
            </div>

            <div v-else-if="!sendingRejectTransactionRequest" class="container">
              <div class="row pb-3 pt-3">
                <div class="col-sm-12">
                  <div class="form-floating mb-4">
                    <select class="form-control form-control-sm" id="rejectTransactionReasonGeneral" name="rejectTransactionReasonGeneral" v-model="rejectTx.rejectReason">
                      <option value="please_select" disabled selected>Please Select</option>
                      <option v-for="item in uiDropdowns.rejectTx.general.reasons" :key="item.value" :value="item.value">{{item.label}}</option>
                    </select>
                    <label for="rejectTransactionReasonGeneral">Rejection Reason</label>
                  </div>

                </div>
              </div>
              <div class="row pb-3 pt-3">
                <div class="col-sm-12">
                  <div class="form-floating mb-4">
                    <textarea class="form-control form-control-sm" style="height: auto!important;" id="rejectTransactionGeneralComments" name="rejectTransactionGeneralComments" v-model="rejectTx.rejectionComments" rows="3"></textarea>
                    <label for="rejectTransactionReasonGeneral">Rejection Comments (optional)</label>
                  </div>

                </div>
              </div>
            </div>

          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal" :disabled="sendingRejectTransactionRequest">Close</button>
            <button type="button" class="btn btn-danger" v-on:click="rejectTransaction" :disabled="sendingRejectTransactionRequest || rejectTx.rejectReason == undefined || rejectTx.rejectReason == 'please_select'">Reject Entry</button>
          </div>
        </div>
      </div>
    </div>
  </teleport>

  <teleport to="body">
    <div class="position-fixed bottom-0 end-0 p-3 cr-z-index-5">
      <div id="verifyTxToast" class="toast hide" role="alert" aria-live="assertive" aria-atomic="true">
        <div class="toast-header">
          <strong class="me-auto">Entry Successfully Verified!</strong>
          <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
        </div>
        <div class="toast-body">Your have successfully verified this entry</div>
      </div>
    </div>
  </teleport>

  <teleport to="body">
    <div class="position-fixed bottom-0 end-0 p-3 cr-z-index-5">
      <div id="rejectTxToast" class="toast hide" role="alert" aria-live="assertive" aria-atomic="true">
        <div class="toast-header">
          <strong class="me-auto">Entry Successfully Rejected</strong>
          <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
        </div>
        <div class="toast-body">Your have successfully rejected this entry</div>
      </div>
    </div>
  </teleport>

</template>

<script>
import {Modal, Toast} from "bootstrap"
import api from "../../api/api";

export default {
  name: 'VerifyTransactionModal',
  emits: ['modalClose','transactionVerified','transactionRejected'],
  data() {
    return {
      tripTransaction: false,
      crewmateVerification: false,
      identityVerificationTransaction: false,
      credentialVerificationTransaction: true,
      licenseTransaction: false,
      certificationTransaction: false,
      gettingTransactionInfoForVerification: false,
      showEmbeddedImages: false,
      sendingVerifyTransactionRequest: false,
      sendingRejectTransactionRequest: false,
      user:  this.$store.state.user,
      uiDropdowns: this.$store.state.uiDropdowns,
      userToVerify: {},
      transactionId: undefined,
      tripInfo: {},
      transaction: { },
      sailorName: undefined,
      voidTransactionAvailable: false,
      confirmTx: {
        agreeInfoIsCorrect: false,
        addToMyLogbook: undefined
      },
      rejectTx : {
        rejectReason: 'please_select',
        rejectionComments: undefined
      }
    }
  },
  methods: {
    activateRejectModal() {
      this.sendingRejectTransactionRequest = false
      this.rejectTx = {
        rejectReason: 'please_select',
        rejectionComments: undefined
      }
      this.rejectTxModal = new Modal(document.getElementById('rejectVerifyTransactionModal'))
      this.rejectTxModal.toggle()
    },
    activateConfirmModal() {
      this.sendingVerifyTransactionRequest = false
      this.confirmTx = {
        agreeInfoIsCorrect: false,
        addToMyLogbook: undefined
      }
      this.confirmTxModal = new Modal(document.getElementById('confirmVerifyTransactionModal'))
      this.confirmTxModal.toggle()
    },
    activateModal(theTransaction) {
      this.tripTransaction = false
      this.crewmateVerification = false
      this.identityVerificationTransaction = false
      this.credentialVerificationTransaction = false
      this.gettingTransactionInfoForVerification = false
      this.sendingVerifyTransactionRequest = false
      this.sendingRejectTransactionRequest = false
      this.showEmbeddedImages = false
      this.userToVerify = {}
      this.selectedCredential = {}
      this.transaction = {}
      this.transactionId = undefined
      this.tripInfo = {}
      this.voidTransactionAvailable = false

      this.transaction = theTransaction
      this.transactionId = theTransaction.id

      if ( theTransaction.sailorName ) {
        this.sailorName = theTransaction.sailorName
      } else{
        this.sailorName = undefined
      }

      if ( theTransaction.input.log.trip ) {
        this.tripTransaction = true
        if ( theTransaction.input.verificationAuthority.verificationAuthorityId === this.user.id ) {
          this.crewmateVerification = true
        }

        if ( theTransaction.input.log.trip.entries ) {
          // for now they are all the same....
          let theTripEntry = theTransaction.input.log.trip.entries[0]
          let multiDayTripDays = 0
          let multiDayTripNights = 0
          theTransaction.input.log.trip.entries.forEach(function(anEntry) {
            if ( anEntry.timeOfDay === 'day' ) {
              ++multiDayTripDays
            } else if ( anEntry.timeOfDay === 'night' ) {
              ++multiDayTripNights
            }
          })
          this.tripInfo = {
            date: theTripEntry.date,
            multiDayTrip: theTransaction.input.log.trip.entries.length > 1,
            tripRole: theTripEntry.role,
            vessel: theTripEntry.vessel.vesselName,
            fullVessel: theTripEntry.vessel,
            multiDayTripDays: ''+multiDayTripDays,
            multiDayTripNights: ''+multiDayTripNights,
            tripDuration: theTripEntry.durationHours,
            tripDistance: theTripEntry.nauticalMiles,
            tripTimeOfDay: theTripEntry.timeOfDay,
            windSpeed: theTripEntry.windSpeedBeaufort,
            weatherConditions: theTripEntry.weatherConditions,
            cloudConditions: theTripEntry.cloudConditions,
            tripPurpose: theTripEntry.purpose,
            location: theTripEntry.location,
            locationDescription: theTripEntry.locationType,
            notes: theTripEntry.notes ? theTripEntry.notes : undefined,
            verificationAuthority: 'Self',
            totalFeesForEntry: '1 CRT',
            crewmateRole: theTransaction.input.verificationAuthority.crewmateRole ? theTransaction.input.verificationAuthority.crewmateRole : 'crew'
          }
          if ( theTransaction.input.log.trip.charterCompany ) {
            this.tripInfo.charterCompany = theTransaction.input.log.trip.charterCompany
          }
          if ( theTransaction.input.verificationAuthority.type === 'self' ) {
            this.tripInfo.verificationAuthority = 'Self'
            this.tripInfo.totalFeesForEntry = theTransaction.input.log.trip.entries.length + ' CRT'
          } else if ( theTransaction.input.verificationAuthority.type === 'crewmate' ) {
            this.tripInfo.verificationAuthority = 'Crewmate'
            this.tripInfo.totalFeesForEntry = theTransaction.input.log.trip.entries.length + ' CRT'
          } else if ( theTransaction.input.verificationAuthority.type === 'charter_rode' ) {
            this.tripInfo.verificationAuthority = 'Charter Rode'
            this.tripInfo.totalFeesForEntry = theTransaction.input.log.trip.entries.length + 7 +' CRT'
          }
        }
      } else if ( this.transaction.input.log.identityVerification ) {
        // need to load up links that we can download the images uploaded to verify
        this.identityVerificationTransaction = true
        this.getTransactionInfoForVerification()
      } else if ( this.transaction.input.log.credential ) {
        // need to load up links that we can download the images uploaded to verify
        this.credentialVerificationTransaction = true
        this.getTransactionInfoForVerification()
      }

      // can we void this thing out still???
      if ( theTransaction.status === 'confirmed' || theTransaction.input.log.identityVerification != undefined) {
        this.voidTransactionAvailable = false
      } else {
        this.voidTransactionAvailable = true
      }

      this.viewTxModal = new Modal(document.getElementById('verifyTransactionModal'))
      let viewTxEl = document.getElementById('verifyTransactionModal')
      let self = this
      viewTxEl.addEventListener('hidden.bs.modal', function () {
        self.$emit('modalClose')
      })
      this.viewTxModal.toggle()
    },
    formatDateTime(aDateInSeconds) {
      return new Date(aDateInSeconds).toLocaleString("en-US")
    },
    formatDate(aDateInSeconds) {
      return new Date(aDateInSeconds).toLocaleDateString("en-US")
    },
    getTxStatusFor(row) {
      let theFinalLabel = 'unknown'
      this.uiDropdowns.transaction.status.forEach(function(aStatus) {
        if ( row.status === aStatus.value ) {
          theFinalLabel = aStatus.label
        }
      })
      switch(row.status) {
        case 'pending_payment':
          return '<span class="badge bg-warning">'+theFinalLabel+'</span>'
        case 'pending_verification':
          return '<span class="badge bg-info">'+theFinalLabel+'</span>'
        case 'rejected':
          return '<span class="badge bg-danger">'+theFinalLabel+'</span>'
        case 'confirmed':
          return '<span class="badge bg-success">'+theFinalLabel+'</span>'
        case 'voided':
          return '<span class="badge bg-secondary">'+theFinalLabel+'</span>'
        default:
          return '<span class="badge bg-dark">'+theFinalLabel+'</span>'
      }
    },
    getTxRejectReasonFor(row) {
      let theFinalLabel = undefined
      this.uiDropdowns.rejectTx.trip.reasons.forEach(function(aStatus) {
        if ( row.rejectReason === aStatus.value ) {
          theFinalLabel = aStatus.label
        }
      })
      if ( theFinalLabel == undefined ) {
        this.uiDropdowns.rejectTx.general.reasons.forEach(function(aStatus) {
          if ( row.rejectReason === aStatus.value ) {
            theFinalLabel = aStatus.label
          }
        })
      }
      return theFinalLabel === undefined ? 'unknown' : theFinalLabel
    },
    getTxTypeFor(row) {
      if ( row && row.input && row.input.log && row.input.log.trip ) {
        return 'Trip'
      } else if ( row && row.input && row.input.log && row.input.log.identityVerification ) {
        return 'Identity Verification'
      } else if ( row && row.input && row.input.log && row.input.log.credential ) {
        return 'Credential'
      } else {
        return 'unknown'
      }
    },
    getTxTripDatesFor(row) {
      if ( row && row.input && row.input.log && row.input.log.trip &&  row.input.log.trip.entries ) {
        let startDate = undefined
        let endDate = undefined
        // assuming the entries are in order, might want to sort on sequence...
        row.input.log.trip.entries.forEach(function(anEntry) {
          if ( anEntry && anEntry.date ) {
            if ( !startDate )
              startDate = anEntry.date
          }
          endDate = anEntry.date
        })

        if ( startDate === endDate ) {
          // hack to make the date more human readable...
          let startDateParts = startDate.split("-")
          if ( startDateParts[1].startsWith("0") ) {
            startDateParts[1] = startDateParts[1].substr(1,1)
          }
          return startDateParts[1]+'/'+startDateParts[2]+'/'+startDateParts[0]
        } else {
          let startDateParts = startDate.split("-")
          if ( startDateParts[1].startsWith("0") ) {
            startDateParts[1] = startDateParts[1].substr(1,1)
          }
          let endDateParts = endDate.split("-")
          if ( endDateParts[1].startsWith("0") ) {
            endDateParts[1] = endDateParts[1].substr(1,1)
          }
          return startDateParts[1]+'/'+startDateParts[2]+'/'+startDateParts[0] +' - '+ endDateParts[1]+'/'+endDateParts[2]+'/'+endDateParts[0]
        }
      } else {
        return '--'
      }
    },
    renderEndorsements(endorsements) {
      if ( endorsements == undefined || endorsements.length <= 0 ) {
        return 'None'
      } else {
        let count = 0;
        let finalString = ''
        for (let i = 0; i < endorsements.length; ++i ) {
          if ( count > 0 ) {
            finalString += ", "
          }
          finalString += endorsements[i].label
          ++count;
        }
        return finalString
      }
    },
    rejectTransaction() {
      this.sendingRejectTransactionRequest = true
      api.rejectTransaction(this.transactionId, this.rejectTx.rejectReason, this.rejectTx.rejectionComments).then(theRejectedTransaction => {
        if ( theRejectedTransaction && theRejectedTransaction.status === 'rejected') {
          // success!
          let rejectedTxToast = new Toast(document.getElementById('rejectTxToast'))
          rejectedTxToast.show()
          this.rejectTxModal.hide()
          this.viewTxModal.hide()
          this.sendingRejectTransactionRequest = false
          this.$emit('transactionRejected', this.transactionId)
        }
      })
    },
    verifyTransaction() {
      this.sendingVerifyTransactionRequest = true
      let self = this
      let addToMyLogBook = undefined
      if ( this.confirmTx.addToMyLogbook != undefined ) {
        if ( this.confirmTx.addToMyLogbook === "true" ) {
          addToMyLogBook = true
        } else {
          addToMyLogBook = false
        }
      }
      api.verifyTransaction(this.transactionId, addToMyLogBook != undefined ? addToMyLogBook : undefined).then(theVerifiedTransaction => {
        if ( theVerifiedTransaction &&
            (theVerifiedTransaction.status === 'unconfirmed' || theVerifiedTransaction.status === 'pending_payment' ) ) {
          // success!
          let verifyTxToast = new Toast(document.getElementById('verifyTxToast'))
          verifyTxToast.show()

          if ( self.confirmTx.addToMyLogbook === true || self.confirmTx.addToMyLogbook === "true") {
            // if it all worked, we likely updated the user's funds so need to reset that here as well...
            api.getMe().then(response => {
              if ( response.user && response.user.firstName && response.user.lastName ) {
                // all good...
                self.$store.commit('setUser', response.user)
              }
            })
          }

          this.confirmTxModal.hide()
          this.viewTxModal.hide()
          this.sendingVerifyTransactionRequest = false
          this.$emit('transactionVerified', this.transactionId)
        }
      })
    },
    getTransactionInfoForVerification() {
      this.gettingTransactionInfoForVerification = true
      api.getTransactionInfoForVerification(this.transactionId).then(data => {
        if ( data.transaction && data.user ) {
          this.transaction = data.transaction
          this.userToVerify = data.user
          this.showEmbeddedImages = true
        }
        if ( data.credential ) {
          this.selectedCredential = data.credential
        }
        this.gettingTransactionInfoForVerification = false
      })
    }
  },
  components: {

  }
}
</script>

<style>

code {
  display: inline-block;
  white-space: pre-wrap;
  color: #444;
  font-style: italic;
}

.tx-submit-spinner {
  text-align: center;
  margin-top:5px;
  font-size:22px;
  color:#666;
  font-weight: normal;
  z-index: 9999;
}

</style>