
import { createStore } from 'vuex'

// Create a new store instance
// critical components are backed by the localStorage so that if someone
// clicks the refresh screen then the right thing happens and we don't
// loose all of the info
const store = createStore({
    state () {
        return {
            jwtToken: localStorage.getItem('jwtToken'),
            email: localStorage.getItem('email'),
            isLoggedIn: !!localStorage.getItem('jwtToken'),
            createUserFlow: localStorage.getItem('createUserFlow') ? JSON.parse(localStorage.getItem('createUserFlow')) : false,
            validateUserIdentityFlow: localStorage.getItem('user') ? !(JSON.parse(localStorage.getItem('user')).identityValidated && JSON.parse(localStorage.getItem('user')).newUserWorkflowStep === 'complete') : false,
            user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : undefined,
            cognitoUser: localStorage.getItem('cognitoUser') ? JSON.parse(localStorage.getItem('cognitoUser')) : undefined,
            uiDropdowns: localStorage.getItem('uiDropdowns') ? JSON.parse(localStorage.getItem('uiDropdowns')) : undefined,
            uiDropdownsUpdatedAt: localStorage.getItem('uiDropdownsUpdatedAt') ? JSON.parse(localStorage.getItem('uiDropdownsUpdatedAt')) : undefined,
            draftTripInfo: undefined,
            verifyUser: undefined,
            signUpState: localStorage.getItem('signUpState'),
            isMaintenance: false
        }
    },
    mutations: {
        clearVerifyUser(state) {
          state.verifyUser = undefined
        },
        verifyUser(state, verifyUser) {
            state.verifyUser = verifyUser
        },
        clearSignUpState(state) {
            localStorage.removeItem('signUpState')
            state.signUpState = undefined
        },
        signUpState(state, theSignupState) {
            localStorage.setItem('signUpState', theSignupState)
            state.signUpState = theSignupState
        },
        setCognitoUser(state, aCognitoUser) {
            localStorage.setItem('cognitoUser', JSON.stringify(aCognitoUser))
            state.cognitoUser = aCognitoUser
        },
        startCreateUserFlow (state) {
            localStorage.setItem('createUserFlow', 'true')
            state.createUserFlow = true
        },
        endCreateUserFlow (state) {
            localStorage.removeItem('createUserFlow');
            state.createUserFlow = false
        },
        setLoggedOut (state) {
            localStorage.removeItem('jwtToken');
            localStorage.removeItem('email');
            localStorage.removeItem('user');
            localStorage.removeItem('cognitoUser');
            localStorage.removeItem('createUserFlow');
            localStorage.removeItem('uiDropdowns');
            localStorage.removeItem('uiDropdownsUpdatedAt');
            state.jwtToken = undefined
            state.email = undefined
            state.isLoggedIn = false
            state.createUserFlow = false
            state.validateUserIdentityFlow = false
            state.user = undefined
            state.cognitoUser = undefined
            state.uiDropdowns = undefined
            state.uiDropdownsUpdatedAt = undefined
            state.isMaintenance = false
        },
        setJWTToken(state, jwtToken) {
            localStorage.setItem('jwtToken', jwtToken)
            state.jwtToken = localStorage.getItem('jwtToken')
            state.isLoggedIn = true
        },
        async validateJWTToken(state) {
            if ( !state.jwtToken ) {
                if ( !localStorage.getItem('jwtToken') ) {
                    this.commit('setLoggedOut')
                    return false
                } else {
                    state.jwtToken = localStorage.getItem('jwtToken')
                }
            }
        },
        setUser(state, theUser) {
            // if we have the vessels, sort them!
            if ( theUser.favoriteVessels ) {
                theUser.favoriteVessels.forEach(function(aVessel) {
                    aVessel.fullVesselName = getFullVesselNameFor(aVessel)
                })
                theUser.favoriteVessels.sort(function (a, b) {
                    if (a.fullVesselName > b.fullVesselName) return 1
                    if (a.fullVesselName < b.fullVesselName) return -1
                    return 0
                })
            }

            if ( theUser.crewmates ) {
                theUser.crewmates.sort(function (a, b) {
                    let aFullName = a.lastName.toLowerCase()+a.firstName.toLowerCase()
                    let bFullName = b.lastName.toLowerCase()+b.firstName.toLowerCase()
                    if (aFullName > bFullName) return 1
                    if (aFullName < bFullName) return -1
                    return 0
                })
            }
            localStorage.setItem('user', JSON.stringify(theUser))
            state.user = theUser
            if ( !theUser.identityValidated || theUser.newUserWorkflowStep != 'complete') {
                state.validateUserIdentityFlow = true
            } else {
                state.validateUserIdentityFlow = false
            }
        },
        setUiDropdowns(state, uiDropdowns) {
            localStorage.setItem('uiDropdowns', JSON.stringify(uiDropdowns))
            state.uiDropdowns = uiDropdowns

            let uiDropdownsUpdatedAt = Date.now()
            localStorage.setItem('uiDropdownsUpdatedAt', JSON.stringify(uiDropdownsUpdatedAt))
            state.uiDropdownsUpdatedAt = uiDropdownsUpdatedAt
        },
        setDraftTrip(state, draftTripInfo) {
            state.draftTripInfo = draftTripInfo
        },
        clearDraftTrip(state) {
            state.draftTripInfo = undefined
        },
        decrementUserPendingVerificationRequestsCount(state) {
            if ( state.user && state.user.pendingVerificationRequestsCount ) {
                --state.user.pendingVerificationRequestsCount
                if ( state.user.pendingVerificationRequestsCount < 0 ) {
                    state.user.pendingVerificationRequestsCount = 0
                }
                localStorage.setItem('user', JSON.stringify(state.user))
            }
        },
        updateUserWalletBalance(state, newCRTBalance) {
            state.user.currentWalletFundsCRT = newCRTBalance
            localStorage.setItem('user', JSON.stringify(state.user))
        }
    },
    getters: {
        getJWTToken () {
            return localStorage.getItem('jwtToken')
        }
    }
})

function getFullVesselNameFor(aVessel) {
    if ( aVessel.charterCompanyName && aVessel.charterBase ) {
        return aVessel.vesselName + ' - '+ aVessel.charterCompanyName + ' - ' + aVessel.charterBase
    } else if ( aVessel.charterCompanyName ) {
        return aVessel.vesselName + ' - '+ aVessel.charterCompanyName
    } else if ( aVessel.charterBase ) {
        return aVessel.vesselName + ' - '+ aVessel.charterBase
    } else {
        return aVessel.vesselName
    }
}

export default store