<template>
  <form autocomplete="off">
    <div class="container">
      <div class="row mt-3 mb-3">
        <div class="col-sm-12">
          <h4>Update Profile</h4>
          <div class="charterRodeHelpText">We love to be in the know. Let us know the latest information about you! <span v-show="user.identityValidated">You may not change your name or email address after account creation. If you have a name change request, please contact support@charterrode.com for assistance.</span></div>
        </div>
      </div>
    </div>

    <div class="container" v-show="activelyUpdatingProfile">
      <div class="row">
        <div class="col-sm-12">
          <div class="profile-submit-spinner mt-lg-5"><fai icon="spinner" spin/> Please wait while your profile is updated</div>
        </div>
      </div>
    </div>

    <div class="container" v-show="!activelyUpdatingProfile">

      <div class="row">
        <div class="col-sm-6">
          <div class="form-floating mb-3">
            <input type="text" class="form-control" v-bind:disabled="user.identityValidated" v-bind:class="{'is-valid' : user.identityValidated || validation.firstNameBlurred && validFirstName, 'is-invalid': validation.firstNameBlurred && !validFirstName}" @blur="validation.firstNameBlurred = true" v-model="user.firstName" id="firstName" name="firstName" placeholder="Art" required>
            <label for="firstName">First Name</label>
            <div class="invalid-feedback">Please let us know your name.</div>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-floating mb-3">
            <input type="text" class="form-control" v-bind:disabled="user.identityValidated" v-bind:class="{'is-valid' : user.identityValidated || validation.lastNameBlurred && validLastName, 'is-invalid': validation.lastNameBlurred && !validLastName}" @blur="validation.lastNameBlurred = true" v-model="user.lastName" id="lastName" name="lastName" placeholder="Vandelay" required>
            <label for="lastName">Last Name</label>
            <div class="invalid-feedback">Please let us know your name.</div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="form-floating mb-3">
            <input type="email" class="form-control is-valid" v-model="user.email" id="email" name="email" placeholder="art@vandelay.com" required disabled>
            <label for="email">Email Address</label>
            <div class="invalid-feedback">Please confirm your email address.</div>
          </div>

        </div>
        <div class="col-sm-6">
          <div class="form-floating mb-3">
            <input type="text" class="form-control" v-bind:class="[!validation.phoneBlurred ? '' : validPhone ? 'is-valid' : 'is-invalid']" @blur="validation.phoneBlurred = true" v-model="user.phone" id="phone" name="phone" placeholder="967-5309" required>
            <label for="phone">Mobile Phone #</label>
            <div class="invalid-feedback">Please provide your mobile number so that we may reach you.</div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-6">
          <div class="form-floating mb-3">
            <input type="text" class="form-control" v-bind:class="[!validation.addressLine1Blurred ? '' : validAddressLine1 ? 'is-valid' : 'is-invalid']" @blur="validation.addressLine1Blurred = true" v-model="user.addressLine1" id="addressLine1" name="addressLine1" placeholder="123 Acme Dr" required>
            <label for="addressLine1">Address Line 1</label>
            <div class="invalid-feedback">Please confirm your mailing address line 1.</div>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-floating mb-3">
            <input type="text" class="form-control" id="addressLine2" name="addressLine2" placeholder="Apt 5A">
            <label for="addressLine2">Address Line 2</label>
            <div class="invalid-feedback">Please confirm your mailing address line 2.</div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-6">
          <div class="form-floating mb-3">
            <input type="text" class="form-control" v-bind:class="[!validation.cityBlurred ? '' : validCity ? 'is-valid' : 'is-invalid']" @blur="validation.cityBlurred = true" v-model="user.city" id="city" name="city" placeholder="Athens" required>
            <label for="city">City</label>
            <div class="invalid-feedback">Please confirm your mailing address city.</div>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-floating mb-3">
            <input type="text" class="form-control" v-bind:class="[!validation.provinceBlurred ? '' : validProvince ? 'is-valid' : 'is-invalid']" @blur="validation.provinceBlurred = true" v-model="user.province" id="province" name="province" placeholder="NA">
            <label for="province">State</label>
            <div class="invalid-feedback">Please confirm your mailing address state.</div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-6">
          <div class="form-floating mb-3">
            <input type="text" class="form-control" v-bind:class="[!validation.postalCodeBlurred ? '' : validPostalCode ? 'is-valid' : 'is-invalid']" @blur="validation.postalCodeBlurred = true" v-model="user.postalCode" id="postalCode" name="postalCode" placeholder="75309" required>
            <label for="postalCode">Postal Code</label>
            <div class="invalid-feedback">Please confirm your mailing address postal code.</div>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-floating mb-3">
            <input type="text" class="form-control" v-bind:class="[!validation.countryBlurred ? '' : validateCountry ? 'is-valid' : 'is-invalid']" @blur="validation.countryBlurred = true" v-model="user.country" id="country" name="country" placeholder="Greece">
            <label for="country">Country</label>
            <div class="invalid-feedback">Please confirm your mailing address country.</div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-6">
          <div class="form-floating mb-3">
            <select class="form-control form-control-sm" v-bind:class="[!validation.preferredUnitsBlurred ? '' : validatePreferredUnits ? 'is-valid' : 'is-invalid']" @blur="validation.preferredUnitsBlurred = true" v-model="user.preferredUnits" id="preferredUnits" name="preferredUnits">
              <option v-for="item in uiDropdowns.user.preferredUnits" :key="item.value" :value="item.value">{{item.label}}</option>
            </select>
            <label for="preferredUnits">Units - Length</label>
            <div id="measurementUnitsHelpBlock" class="form-text" style="padding-left: 3px;">Select preferred units for vessel lengths. Your Trusted Sailing Resume will always show the standardized format of meters (feet).</div>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-check form-switch">
            <input class="form-check-input charterRodeBtn" type="checkbox" id="showNameOnPublicResume" v-model="user.showNameOnPublicResume" @change="confirmShowNameOnPublicResume">
            <label class="form-check-label charterRodeBtn" for="showNameOnPublicResume">Display full name on Trusted Sailing Resume</label>
          </div>
          <div id="showNameOnPublicResumeHelpBlock" class="form-text" v-bind:class="[user.showNameOnPublicResume ? 'text-danger' : '']">This setting eliminates your privacy as instead of your Sailor ID, people will see your full name when <a href="https://sailingresume.io/" target="_blank">looking up your Trusted Sailing Resume</a>. Enable this setting only if you want everyone in the world to know who you are. YouTube here you come!</div>
        </div>
      </div>

      <div class="col-sm-12 mt-3 mb-5">
        <div class="d-grid gap-2 mx-auto">
          <button class="btn btn-lg btn-outline-primary text-uppercase" v-bind:class="{'disabled': isUpdateProfileDisabled}" v-on:click="sendUpdateUser">Update Profile</button>
        </div>
      </div>

    </div>
  </form>

  <div class="position-fixed bottom-0 end-0 p-3" style="z-index: 5">
    <div id="liveToast" class="toast hide" role="alert" aria-live="assertive" aria-atomic="true">
      <div class="toast-header">
        <strong class="me-auto">Profile Saved Successfully!</strong>
        <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
      </div>
      <div class="toast-body">Changes to your profile have been saved</div>
    </div>
  </div>
</template>

<script>
import api from "../../api/api";

import { Toast } from 'bootstrap';

export default {
  name: 'UserProfile',
  data() {
    return {
      profileChanged: false,
      activelyUpdatingProfile: false,
      uiDropdowns: this.$store.state.uiDropdowns,
      user: {
        identityValidated: undefined,
        firstName: undefined,
        lastName: undefined,
        email: undefined,
        phone: undefined,
        addressLine1: undefined,
        addressLine2: undefined,
        city: undefined,
        province: undefined,
        postalCode: undefined,
        country: undefined,
        preferredUnits: "imperial",
        showNameOnPublicResume: false
      },
      validation: {
        emailBlurred: false,
        firstNameBlurred: false,
        lastNameBlurred: false,
        phoneBlurred: false,
        addressLine1Blurred: false,
        cityBlurred: false,
        provinceBlurred: false,
        postalCodeBlurred: false,
        countryBlurred: false,
        preferredUnits: false,
        showNameOnPublicResume: false
      }
    }
  },
  mounted() {
    if ( this.$store.state.user ) {
      this.user.identityValidated = this.$store.state.user.identityValidated
      this.user.firstName = this.$store.state.user.firstName
      this.user.lastName = this.$store.state.user.lastName
      this.user.email = this.$store.state.user.email
      this.user.phone = this.$store.state.user.phone
      this.user.addressLine1 = this.$store.state.user.addressLine1
      this.user.addressLine2 = this.$store.state.user.addressLine2
      this.user.city = this.$store.state.user.city
      this.user.province = this.$store.state.user.province
      this.user.postalCode = this.$store.state.user.postalCode
      this.user.country = this.$store.state.user.country
      this.user.preferredUnits = this.$store.state.user.preferredUnits
      this.user.showNameOnPublicResume = this.$store.state.user.showNameOnPublicResume
    }
  },
  methods: {
    validateTextInput(anInput) {
      this.profileChanged = true
      if ( !anInput ) {
        return false;
      } else if ( anInput === '' ) {
        return false;
      }
      return true;
    },
    confirmShowNameOnPublicResume() {
      if ( this.user.showNameOnPublicResume ) {
        let confirmShowNameOnPublicResume = confirm("Are you sure you want your full name displayed on your Trusted Sailing Resume which anyone in the world may see?")
        this.user.showNameOnPublicResume = confirmShowNameOnPublicResume
        if ( this.user.showNameOnPublicResume ) {
          this.profileChanged = true
        }
      } else {
        this.profileChanged = true
        this.sendUpdateUser()
      }

      // try to loose focus
      document.getElementById('showNameOnPublicResume').blur()
    },
    sendUpdateUser(evt) {
      if ( evt ) {
        evt.preventDefault();
      }
      let self = this;
      self.activelyUpdatingProfile = true
      api.updateUser(this.user).then(currentUser => {
        self.activelyUpdatingProfile = false
        if ( currentUser && currentUser.firstName && currentUser.lastName ) {
          // update the store and refresh this view
          self.$store.commit('setUser', currentUser)
          self.$router.push('/profile')
          self.$data.profileChanged = false;
          var toastElList = [].slice.call(document.querySelectorAll('.toast'))
          var toastList = toastElList.map(function(toastEl) {
            // Creates an array of toasts (it only initializes them)
            return new Toast(toastEl) // No need for options; use the default options
          });
          toastList.forEach(toast => toast.show()); // This show them
        } else {
          // something else is going on
          console.log(JSON.stringify(currentUser))
        }
      }).catch(error => {
        console.log(error)
      })
    }
  },
  components: {

  },
  computed: {
    isUpdateProfileDisabled: function() {
      return !this.profileChanged
    },
    validEmail: function() {
      return this.email !== '' && this.email.indexOf('@') > 0
    },
    validFirstName: function() {
      return this.validateTextInput(this.user.firstName)
    },
    validLastName: function() {
      return this.validateTextInput(this.user.lastName)
    },
    validPhone: function() {
      return this.validateTextInput(this.user.phone)
    },
    validAddressLine1: function() {
      return this.validateTextInput(this.user.addressLine1)
    },
    validCity: function() {
      return this.validateTextInput(this.user.city)
    },
    validProvince: function() {
      return this.validateTextInput(this.user.province)
    },
    validPostalCode: function() {
      return this.validateTextInput(this.user.postalCode)
    },
    validateCountry: function() {
      return this.validateTextInput(this.user.country)
    },
    validatePreferredUnits: function() {
      return this.validateTextInput(this.user.preferredUnits)
    }
  }
}
</script>

<style>

.form-control {
  color: #555;
}

.profile-submit-spinner {
  text-align: center;
  margin-top:5px;
  font-size:22px;
  color:#666;
  font-weight: normal;
  z-index: 9999;
}

</style>
