<template>

  <teleport to="body">
    <!-- Modal -->
    <div class="modal fade" id="viewTransactionStatusesModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-fullscreen">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="viewVesselLabel">What Are Those Statuses Anyways?</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="pb-3 charterRodeHelpText">We know they can be confusing. The Sailing Resume Blockchain is like Bitcoin, each logbook entry you make becomes a transaction within a block on the <a href="https://blocks.charterrode.com" target="_blank" style="text-decoration: none;">Sailing Resume Blockchain</a>. Every logbook entry goes through its own journey through the below statuses to eventually be committed on the Sailing Resume Blockchain and available for everyone in the world to see. Until your logbook entry is in the final confirmed status, it has not yet been securely and permanently added to the Sailing Resume Blockchain. As an additional resource, <a href="https://www.charterrode.com/faqs" target="_blank" style="text-decoration: none;">read the Charter Rode FAQs</a> to learn more about blockchains, distributed ledgers, and how the Sailing Resume Blockchain is built using these technologies.</div>

            <div class="container-fluid d-md-none d-lg-none d-xl-none d-xxl-none">
              <div class="col-12" style="border-top:1px solid #ccc;padding-top:15px;padding-bottom:15px;">
                <div style="text-align: center;margin-bottom: 8px;"><span class="badge bg-info" style="font-size: 16px;">Pending Verification</span></div>
                <div style="text-align: justify;">All new logbook entries start in this status. If you select self verification, the logbook entry immediately moves onto pending payment. If you select crewmate or Charter Rode verification, your logbook entry stays in this status until it is verified, and then moves into payment pending, or rejected and moves into rejected status.</div>
              </div>

              <div class="col-12" style="border-top:1px solid #ccc;padding-top:15px;padding-bottom:15px;">
                <div style="text-align: center;margin-bottom: 8px;"><span class="badge bg-warning" style="font-size: 16px;">Pending Payment</span></div>
                <div style="text-align: justify;">Once a logbook entry has been verified, it enters this status. If your wallet has sufficient CRT funds, then the CRT charges for the transaction are deducted from your wallet and the transaction is moved into the unconfirmed status.</div>
              </div>

              <div class="col-12" style="border-top:1px solid #ccc;padding-top:15px;padding-bottom:15px;">
                <div style="text-align: center;margin-bottom: 8px;"><span class="badge bg-danger" style="font-size: 16px;">Rejected</span></div>
                <div style="text-align: justify;">Logbook entries will only enter the rejected status if they are rejected by the verification authority, either a crewmate, Charter Rode, or some other valid verification authority that you have asked to verify your logbook entry. If you look at the transaction details, the reason why it was rejected is provided. In many cases you are able to re-submit your transaction after resolving the issue which will put the logbook entry back into the pending verification status.</div>
              </div>

              <div class="col-12" style="border-top:1px solid #ccc;padding-top:15px;padding-bottom:15px;">
                <div style="text-align: center;margin-bottom: 8px;"><span class="badge bg-secondary" style="font-size: 16px;">Voided</span></div>
                <div style="text-align: justify;">Any logbook entry that is not yet in the confirmed status may be voided by you. Once the logbook entry is voided, no further processing will be completed. NOTE: If you void a logbook entry that is in the unconfirmed status, all CRT used to process and verify the transaction WILL NOT be refunded to your wallet.</div>
              </div>

              <div class="col-12" style="border-top:1px solid #ccc;padding-top:15px;padding-bottom:15px;">
                <div style="text-align: center;margin-bottom: 8px;"><span class="badge bg-dark" style="font-size: 16px;">Unconfirmed</span></div>
                <div style="text-align: justify;">When a logbook entry is in this status it means it is ready to be securely and permanently added to the Sailing Resume Blockchain. Groups of up to 1,000 or more logbook entries across all users of the Sailing Resume Blockchain are grouped together to form the next block on the <a href="https://blocks.charterrode.com" target="_blank" style="text-decoration: none;">Sailing Resume Blockchain</a>. Your logbook entry will stay in this status until there is enough Unconfirmed logbook entries to form and fill a new block on the Sailing Resume Blockchain.</div>
              </div>

              <div class="col-12" style="border-top:1px solid #ccc;padding-top:15px;padding-bottom:15px;">
                <div style="text-align: center;margin-bottom: 8px;"><span class="badge bg-success" style="font-size: 16px;">Confirmed</span></div>
                <div style="text-align: justify;">This is the final status for all logbook entries. This status means that your logbook entry has been securely and permanently added to the Sailing Resume Blockchain for the entire world to see. Your logbook entry is grouped with other users' entries to form a block on the <a href="https://blocks.charterrode.com" target="_blank" style="text-decoration: none;">Sailing Resume Blockchain</a>.</div>
              </div>

              <div class="col-12" style="border-top:1px solid #ccc;padding-top:15px;padding-bottom:15px;">&nbsp;</div>
            </div>

            <div class="container d-none d-md-block">
              <table style="width:100%;color:#666;margin-top:15px;">
                <tr>
                  <td style="border-top:1px solid #ccc;padding-top:15px;padding-bottom:15px;width:10px;white-space: nowrap;"><span class="badge bg-info" style="font-size: 16px;">Pending Verification</span></td>
                  <td style="border-top:1px solid #ccc;padding-top:15px;padding-bottom:15px;width:25px;">&nbsp;</td>
                  <td style="border-top:1px solid #ccc;padding-top:15px;padding-bottom:15px;text-align: justify;">All new logbook entries start in this status. If you select self verification, the logbook entry immediately moves onto pending payment. If you select crewmate or Charter Rode verification, your logbook entry stays in this status until it is verified, and then moves into payment pending, or rejected and moves into rejected status.</td>
                </tr>
                <tr>
                  <td style="border-top:1px solid #ccc;padding-top:15px;padding-bottom:15px;white-space: nowrap;"><span class="badge bg-warning" style="font-size: 16px;">Pending Payment</span></td>
                  <td style="border-top:1px solid #ccc;padding-top:15px;padding-bottom:15px;width:25px;">&nbsp;</td>
                  <td style="border-top:1px solid #ccc;padding-top:15px;padding-bottom:15px;text-align: justify;">Once a logbook entry has been verified, it enters this status. If your wallet has sufficient CRT funds, then the CRT charges for the transaction are deducted from your wallet and the transaction is moved into the unconfirmed status.</td>
                </tr>
                <tr>
                  <td style="border-top:1px solid #ccc;padding-top:15px;padding-bottom:15px;white-space: nowrap;"><span class="badge bg-danger" style="font-size: 16px;">Rejected</span></td>
                  <td style="border-top:1px solid #ccc;padding-top:15px;padding-bottom:15px;width:25px;">&nbsp;</td>
                  <td style="border-top:1px solid #ccc;padding-top:15px;padding-bottom:15px;text-align: justify;">Logbook entries will only enter the rejected status if they are rejected by the verification authority, either a crewmate, Charter Rode, or some other valid verification authority that you have asked to verify your logbook entry. If you look at the transaction details, the reason why it was rejected is provided. In many cases you are able to re-submit your transaction after resolving the issue which will put the logbook entry back into the pending verification status.</td>
                </tr>
                <tr>
                  <td style="border-top:1px solid #ccc;padding-top:15px;padding-bottom:15px;white-space: nowrap;"><span class="badge bg-secondary" style="font-size: 16px;">Voided</span></td>
                  <td style="border-top:1px solid #ccc;padding-top:15px;padding-bottom:15px;width:25px;">&nbsp;</td>
                  <td style="border-top:1px solid #ccc;padding-top:15px;padding-bottom:15px;text-align: justify;">Any logbook entry that is not yet in the confirmed status may be voided by you. Once the logbook entry is voided, no further processing will be completed. NOTE: If you void a logbook entry that is in the unconfirmed status, all CRT used to process and verify the transaction WILL NOT be refunded to your wallet.</td>
                </tr>
                <tr>
                  <td style="border-top:1px solid #ccc;padding-top:15px;padding-bottom:15px;white-space: nowrap;"><span class="badge bg-dark" style="font-size: 16px;">Unconfirmed</span></td>
                  <td style="border-top:1px solid #ccc;padding-top:15px;padding-bottom:15px;width:25px;">&nbsp;</td>
                  <td style="border-top:1px solid #ccc;padding-top:15px;padding-bottom:15px;text-align: justify;">When a logbook entry is in this status it means it is ready to be securely and permanently added to the Sailing Resume Blockchain. Groups of up to 1,000 or more logbook entries across all users of the Sailing Resume Blockchain are grouped together to form the next block on the <a href="https://blocks.charterrode.com" target="_blank" style="text-decoration: none;">Sailing Resume Blockchain</a>. Your logbook entry will stay in this status until there is enough Unconfirmed logbook entries to form and fill a new block on the Sailing Resume Blockchain.</td>
                </tr>
                <tr>
                  <td style="border-top:1px solid #ccc;padding-top:15px;padding-bottom:15px;white-space: nowrap;"><span class="badge bg-success" style="font-size: 16px;">Confirmed</span></td>
                  <td style="border-top:1px solid #ccc;padding-top:15px;padding-bottom:15px;width:25px;">&nbsp;</td>
                  <td style="border-top:1px solid #ccc;padding-top:15px;padding-bottom:15px;text-align: justify;">This is the final status for all logbook entries. This status means that your logbook entry has been securely and permanently added to the Sailing Resume Blockchain for the entire world to see. Your logbook entry is grouped with other users' entries to form a block on the <a href="https://blocks.charterrode.com" target="_blank" style="text-decoration: none;">Sailing Resume Blockchain</a>.</td>
                </tr>
                <tr>
                  <td style="border-top:1px solid #ccc;padding-top:15px;">&nbsp;</td>
                  <td style="border-top:1px solid #ccc;padding-top:15px;">&nbsp;</td>
                  <td style="border-top:1px solid #ccc;padding-top:15px;">&nbsp;</td>
                </tr>
              </table>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>
  </teleport>


</template>

<script>
import {Modal} from "bootstrap"

export default {
  name: 'ViewTransactionStatusesModal',
  emits: ['modalClose'],
  data() {
    return { }
  },
  methods: {
    activateModal() {
      this.viewTxStatusesModal = new Modal(document.getElementById('viewTransactionStatusesModal'))
      let viewTxEl = document.getElementById('viewTransactionStatusesModal')
      let self = this
      viewTxEl.addEventListener('hidden.bs.modal', function () {
        self.$emit('modalClose')
      })
      this.viewTxStatusesModal.toggle()
    }
  }
}
</script>

<style>

code {
  display: inline-block;
  white-space: pre-wrap;
  color: #444;
  font-style: italic;
}

.tx-submit-spinner {
  text-align: center;
  margin-top:5px;
  font-size:22px;
  color:#666;
  font-weight: normal;
  z-index: 9999;
}

</style>