<template>

  <teleport to="body">
    <!-- Modal -->
    <div class="modal fade" id="changePassword" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true" v-on:keyup.enter="addVessel">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-fullscreen">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="addNewVesselLabel">Change Password</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="pb-3 charterRodeHelpText">Please fill out the form below to change your password.</div>

            <div class="container" v-show="activelyChangingPassword">
              <div class="row">
                <div class="col-sm-12">
                  <div class="tx-submit-spinner mt-lg-5"><fai icon="spinner" spin/> Please wait while your password is changed</div>
                </div>
              </div>
            </div>

            <form autocomplete="off" novalidate v-on:submit.prevent="noop">
              <div class="container" v-show="!activelyChangingPassword">
                <div class="row" v-if="validation.errorMessage">
                  <div class="col-12">
                    <div class="alert alert-danger">{{validation.errorMessage}}</div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <div class="form-floating mb-4">
                      <input type="password" autocomplete="current-password" class="form-control" id="current-password" name="currentPassword" v-model="currentPassword" v-bind:class="{'is-valid' : validation.currentPassword, 'is-invalid': validation.currentPassword == false}">
                      <label for="current-password">Current Password</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12">
                    <div class="form-floating mb-4">
                      <input type="password" autocomplete="new-password" class="form-control" id="new-password" name="newPassword" v-model="newPassword" v-bind:class="{'is-valid' : validation.newPassword, 'is-invalid': validation.newPassword == false}">
                      <label for="new-password">New Password</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12">
                    <div class="form-floating mb-4">
                      <input type="password" autocomplete="new-password" class="form-control" id="confirm-new-password" name="confirmNewPassword" v-model="confirmNewPassword" v-bind:class="{'is-valid' : validation.confirmNewPassword, 'is-invalid': validation.confirmNewPassword == false}">
                      <label for="confirm-new-password">Confirm New Password</label>
                      <div v-if="validation.newPasswordMismatch" class="invalid-feedback">Passwords don't match.</div>
                    </div>
                  </div>
                </div>

              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal" v-bind:disabled="activelyChangingPassword">Cancel</button>
            <button type="button" class="btn btn-primary" v-on:click="changePassword" v-bind:disabled="activelyChangingPassword">Change Password</button>
          </div>
        </div>
      </div>
    </div>
  </teleport>


  <teleport to="body">
    <div class="position-fixed bottom-0 end-0 p-3 cr-z-index-5">
      <div id="changePasswordToast" class="toast hide" role="alert" aria-live="assertive" aria-atomic="true">
        <div class="toast-header">
          <strong class="me-auto">Password Changed Successfully!</strong>
          <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
        </div>
        <div class="toast-body">Your password has successfully been changed</div>
      </div>
    </div>
  </teleport>

</template>

<script>
import auth from "../../api/auth";
import api from "../../api/api";
import {Modal,Toast} from "bootstrap"

export default {
  name: 'ChangePasswordModal',
  emits: ['modalClose'],
  data() {
    return {
      activelyChangingPassword: false,
      currentPassword: undefined,
      newPassword: undefined,
      confirmNewPassword: undefined,
      validation: {
        errorMessage: undefined,
        newPasswordMismatch: undefined,
        currentPassword: undefined,
        newPassword: undefined,
        confirmNewPassword: undefined
      }
    }
  },
  methods: {
    activateModal() {
      this.currentPassword = undefined
      this.currentPassword = undefined
      this.currentPassword = undefined
      this.validation = {
        newPasswordMismatch: undefined,
        currentPassword: undefined,
        newPassword: undefined,
        confirmNewPassword: undefined
      }

      this.changePasswordModal = new Modal(document.getElementById('changePassword'))
      let changePasswordEl = document.getElementById('changePassword')
      let self = this
      changePasswordEl.addEventListener('hidden.bs.modal', function () {
        self.$emit('modalClose')
      })
      this.changePasswordModal.toggle()
    },
    changePassword () {
      // do validation
      let isFormValid = true

      if ( this.currentPassword ) {
        this.validation.currentPassword = true
      } else {
        this.validation.currentPassword = false
        isFormValid = false
      }
      if ( this.newPassword ) {
        this.validation.newPassword = true
      } else {
        this.validation.newPassword = false
        isFormValid = false
      }
      if ( this.confirmNewPassword ) {
        this.validation.confirmNewPassword = true
      } else {
        this.validation.confirmNewPassword = false
        isFormValid = false
      }

      if ( this.newPassword === this.confirmNewPassword ) {
        this.validation.newPassword = true
        this.validation.confirmNewPassword = true
      } else {
        this.validation.newPassword = false
        this.validation.confirmNewPassword = false
        this.validation.newPasswordMismatch = true
        isFormValid = false
      }

      if ( isFormValid ) {
        // okay so if we have a validForm then we can instruct cognito to change password
        this.activelyChangingPassword = true
        // first check for valid token
        api.checkForValidAuthToken().then(validToken => {
          if ( validToken ) {
            auth.changePassword(this.currentPassword, this.newPassword).then(error => {
              if ( error ) {
                console.log("error during change password")
                this.activelyChangingPassword = false
                // need to pop error message on screen!
                if ( error.code === 'NotAuthorizedException' ) {
                  this.validation.errorMessage = 'Oops! Looks like your current password does not match.'
                } else if ( error.code === 'InvalidPasswordException' ) {
                  this.validation.errorMessage = 'Your new password must be at least 8 characters and have at least one uppercase, one lowercase, one number, and one special character.'
                } else if ( error.code === 'LimitExceededException') {
                  this.validation.errorMessage = 'You have tried to change your password too many times unsuccessfully. Please wait a few hours and try again.'
                }
              } else {
                let changePasswordToast = new Toast(document.getElementById('changePasswordToast'))
                changePasswordToast.show()
                this.activelyChangingPassword = false
                this.changePasswordModal.hide()
              }
            })
          }
        })
      }
    }
  },
  components: {

  }
}
</script>

<style>
.search-spinner {
  text-align: center;
  margin-top:5px;
  font-size:18px;
  color:#666;
  font-weight: normal;
  z-index: 9999;
}
.selected-row {
  background-color: #0d6efd25;
}
</style>