import { createRouter, createWebHashHistory } from 'vue-router'

// the store
import store from '../store/store'

// 'views' that are routes
import Home from '../views/Home.vue'
import Maintenance from '../views/Maintenance.vue'
import SubmitTransaction from '../views/tx/SubmitTransaction.vue'
import Transactions from '../views/tx/Transactions.vue'
import TransactionsToVerify from '../views/tx/TransactionsToVerify.vue'
import UserProfile from '../views/profile/UserProfile.vue'
import UserWallet from '../views/profile/Wallet.vue'
import UserResume from '../views/profile/Resume.vue'
import UserPrintResume from '../views/profile/PrintResume.vue'
import CreateUser from '../views/profile/CreateUser.vue'
import Crewmates from '../views/other/Crewmates.vue'
import Vessels from '../views/other/Vessels.vue'
import ValidateIdentity from '../views/other/ValidateIdentity.vue'
import InviteFriend from '../views/other/Vessels.vue'
import SignIn from '../views/auth/SignIn.vue'
import SignUp from '../views/auth/SignUp.vue'
import ForgotPassword from '../views/auth/ForgotPassword.vue'
import ProcessSignIn from '../views/auth/ProcessSignIn'
import SignOut from '../views/auth/SignOut.vue'
import ProcessSignOut from '../views/auth/ProcessSignOut'

// our routes
const routes = [
    { path: '/sign-in', component: SignIn, name: 'SignIn', meta: {requiresAuth: false} },
    { path: '/sign-up', component: SignUp, name: 'SignUp', meta: {requiresAuth: false}},
    { path: '/forgot-pass', component: ForgotPassword, name: 'ForgotPassword', meta: {requiresAuth: false}},
    { path: '/maintenance', component: Maintenance, name: 'Maintenance', meta: {requiresAuth: false}},
    { path: '/process-sign-in', component: ProcessSignIn, name: 'ProcessSignIn', meta: {requiresAuth: false} },
    { path: '/', component: Home, name: 'Home', meta: {requiresAuth: true}},
    { path: '/createUser', component: CreateUser, name: 'CreateUser', meta: {requiresAuth: true}},
    { path: '/invite', component: InviteFriend, name: 'InviteFriend', meta: {requiresAuth: true}},
    { path: '/crewmates', component: Crewmates, name: 'Crewmates', meta: {requiresAuth: true}},
    { path: '/vessels', component: Vessels, name: 'Vessels', meta: {requiresAuth: true}},
    { path: '/validate-identity', component: ValidateIdentity, name: 'ValidateIdentity', meta: {requiresAuth: true}},
    { path: '/profile', component: UserProfile, name: 'UserProfile', meta: {requiresAuth: true}},
    { path: '/wallet', component: UserWallet, name: 'UserWallet', meta: {requiresAuth: true}},
    { path: '/resume', component: UserResume, name: 'UserResume', meta: {requiresAuth: true}},
    { path: '/resume/print/:id', component: UserPrintResume, name: 'UserPrintResume', meta: {requiresAuth: true}},
    { path: '/tx/submit', component: SubmitTransaction, name: 'SubmitTransaction', meta: {requiresAuth: true}},
    { path: '/tx/list', component: Transactions, name: 'Transactions', meta: {requiresAuth: true}},
    { path: '/tx/verify', component: TransactionsToVerify, name: 'TransactionsToVerify', meta: {requiresAuth: true}},
    { path: '/sign-out', component: SignOut, name: 'SignOut' ,meta: {requiresAuth: false} },
    { path: '/process-sign-out', component: ProcessSignOut, name: 'ProcessSignOut', meta: {requiresAuth: false} }
]

const routerHistory = createWebHashHistory()
const router = createRouter({
    history: routerHistory,
    strict: true,
    routes: routes,
    scrollBehavior () {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve({left:0, top:0})
            }, 10)
        })
    }
})

// remove trailing slashes
router.beforeEach((to, from, next) => {
    if (window.location.pathname === '/sign-up' ) {
        if ( window.location.search ) {
            let state = decodeURIComponent(window.location.search)
            state = btoa(state)
            store.commit('signUpState', state)
        }
        window.location.href = '/#/sign-up'
    } else if (window.location.pathname === '/sign-in' ) {
        window.location.href = '/#'
    } else if (/.\/$/.test(to.path)) {
        to.meta.redirectCode = 301
        next(to.path.replace(/\/$/, ''))
    } else {
        if (to.matched.some(record => record.meta.requiresAuth)) {
            // this route requires auth, check if logged in
            // if not, redirect to login page.

            store.commit('validateJWTToken')
            if (!store.state.isLoggedIn) {
                next({name: 'SignIn'})
            } else {
                if ( store.state.isMaintenance ) {
                    window.location.href = '/#/maintenance'
                } else {
                    if (store.state.createUserFlow) {
                        if (to.path !== '/createUser') {
                            next({name: 'CreateUser'})
                        } else {
                            next()
                        }
                    } else {
                        // now make sure that they don't need their identity validated
                        if (to.path !== '/validate-identity' &&
                            to.path !== '/profile' &&
                            to.path !== '/sign-out') {
                            if (store.state.validateUserIdentityFlow) {
                                if (store.state.user.newUserWorkflowStep === 'complete' && to.path === '/wallet') {
                                    next() // as they hopefully funded their wallet, let them go check it all out!
                                } else {
                                    next({name: 'ValidateIdentity'})
                                }
                            } else {
                                next() // go to wherever I'm going
                            }
                        } else {
                            if (to.path == '/profile' || store.state.validateUserIdentityFlow) {
                                next() // let them go to validate their identity
                            } else {
                                // make them go home
                                next({name: 'Home'})
                            }
                        }
                    }
                }
            }
        } else {
            next() // does not require auth, make sure to always call next()!
        }
    }
})

// router.afterEach((to, from) => {
//     console.log('nav: '+from.path+' >> '+ to.path)
// })

export default router