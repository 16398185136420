<template>

  <teleport to="body">
    <!-- Modal -->
    <div class="modal fade" id="viewTransactionModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-fullscreen">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="viewVesselLabel">View Log Entry</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="pb-3 charterRodeHelpText">Below are the details for this log entry. Log entries may not be voided after they are confirmed on Charter Rode.<span v-if="voidTransactionAvailable"> To void a unconfirmed log entry, preventing it from being confirmed on Charter Rode, use the Void Entry button at the bottom of the screen. <strong>NOTE: If you void an <span style="font-family:'Courier New';font-style: italic;text-transform: uppercase;">Unconfirmed</span> log entry, you WILL NOT be credited any CRT already spent to process and verify this log entry!</strong></span></div>

            <form autocomplete="off" novalidate v-on:submit.prevent="noop">
              <div class="container" v-if="tripInfo">
                <div class="row mb-3">
                  <div class="col-lg-4">
                    <div><code>Tx Id: {{tripInfo.transaction.id}}</code></div>
                  </div>
                  <div class="col-lg-4">
                    <div><code>Tx Date: {{formatDateTime(tripInfo.transaction.time)}}</code></div>
                  </div>
                  <div class="col-lg-4">
                    <div><code>Tx Status:</code> <span v-html="getTxStatusFor(tripInfo.transaction)"></span></div>

                  </div>
                  <div class="row" v-if="tripInfo.transaction.rejectReason">
                    <div class="col-lg-12">
                      <div v-if="tripInfo.transaction.rejectReason"><code>Reason: {{getTxRejectReasonFor(tripInfo.transaction)}}</code></div>
                      <div v-if="tripInfo.transaction.rejectionComments"><code>Comments: {{tripInfo.transaction.rejectionComments}}</code></div>
                    </div>
                  </div>
                  <div class="row mt-3" v-if="tripInfo.transaction.block">
                    <div class="col-lg-12">
                      <div><strong>This transaction has been committed on the Sailing Resume Blockchain</strong></div>
                    </div>
                  </div>
                  <div class="row" v-if="tripInfo.transaction.block">
                    <div class="col-lg-12">
                      <div><code>Block: <a :href="blockUrl(tripInfo.transaction.block.blockHash, tripInfo.transaction.id)" target="_blank">{{tripInfo.transaction.block.blockHash}}</a></code></div>
                    </div>
                  </div>
                  <div class="row" v-if="tripInfo.transaction.block">
                    <div class="col-lg-12">
                      <div><code>Sequence: {{tripInfo.transaction.block.blockSequence}}</code></div>
                    </div>
                  </div>
                  <div class="row" v-if="tripInfo.transaction.block">
                    <div class="col-lg-12">
                      <div><code>Time: {{formatDateTime(tripInfo.transaction.block.blockTime)}}</code></div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-4">
                    <div class="mb-4">
                      <div class="form-floating">
                        <input type="text" class="form-control" id="date" name="date" v-model="tripInfo.date" disabled>
                        <label for="date">Date</label>
                        <div class="invalid-feedback">You must let use know when you were out.</div>
                      </div>
                      <div class="form-check form-switch">
                        <input id="multiDayTripCheck" class="form-check-input charterRodeBtn" type="checkbox" v-model="tripInfo.multiDayTrip" disabled>
                        <label class="form-check-label charterRodeBtn multi-day-trip-label" for="multiDayTripCheck">
                          Multi-day trip
                        </label>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-4">
                    <div class="form-floating mb-4">
                      <select class="form-control form-control-sm" id="tripRole" name="tripRole" v-model="tripInfo.tripRole" disabled>
                        <option value="please_select" disabled selected>Please Select</option>
                        <option v-for="item in uiDropdowns.roleType" :key="item.value" :value="item.value">{{item.label}}</option>
                      </select>
                      <label for="tripRole">Your Role</label>
                    </div>
                  </div>

                  <div class="col-lg-4">
                    <div class="form-floating mb-4">
                      <input type="text" class="form-control"  id="vessel" name="vessel" v-model="tripInfo.vessel" disabled>
                      <label for="vessel">Vessel</label>
                    </div>
                  </div>
                </div>

                <div class="row">

                  <div class="col-lg-4" v-show="tripInfo.multiDayTrip">
                    <div class="form-floating mb-4">
                      <input type="number" class="form-control"  id="multiDayTripDays" name="multiDayTripDays" v-model="tripInfo.multiDayTripDays" disabled>
                      <label for="multiDayTripDays">Number of Days</label>
                    </div>
                  </div>

                  <div class="col-lg-4" v-show="tripInfo.multiDayTrip">
                    <div class="form-floating mb-4">
                      <input type="number" class="form-control"  id="multiDayTripNights" name="multiDayTripNights" v-model="tripInfo.multiDayTripNights" disabled>
                      <label for="multiDayTripNights">Number of Nights</label>
                    </div>
                  </div>

                  <div class="col-lg-4" v-show="!tripInfo.multiDayTrip">
                    <div class="form-floating mb-4">
                      <input type="number" class="form-control" id="tripDuration" name="tripDuration" v-model="tripInfo.tripDuration" disabled>
                      <label for="tripDuration">Duration (hours)</label>
                    </div>
                  </div>

                  <div class="col-lg-4">
                    <div class="form-floating mb-4">
                      <input type="number" class="form-control form-control-xlg"  id="tripDistance" name="tripDistance" v-model="tripInfo.tripDistance"  disabled>
                      <label for="tripDistance" v-if="tripInfo.multiDayTrip">Avg. Sea Miles per day</label>
                      <label for="tripDistance" v-else>Sea Miles (nm.)</label>
                    </div>
                  </div>

                  <div class="col-lg-4" v-show="!tripInfo.multiDayTrip">
                    <div class="form-floating mb-4">
                      <select class="form-control form-control-sm" id="timeOfDay" name="timeOfDay" v-model="tripInfo.tripTimeOfDay" disabled>
                        <option value="please_select" disabled selected>Please Select</option>
                        <option v-for="item in uiDropdowns.timeOfDay" :key="item.value" :value="item.value">{{item.label}}</option>
                      </select>
                      <label for="timeOfDay">Time of Day</label>
                    </div>
                  </div>

                </div>

                <div class="row">

                  <div class="col-lg-4">
                    <div class="form-floating mb-4">
                      <select class="form-control form-control-sm" id="windSpeed" name="windSpeed" v-model="tripInfo.windSpeed" disabled>
                        <option value="please_select" disabled selected>Please Select</option>
                        <option v-for="item in uiDropdowns.windSpeedBeaufort" :key="item.value" :value="item.value">{{item.label}}</option>
                      </select>
                      <label for="windSpeed" v-if="tripInfo.multiDayTrip">Avg. Wind Speed per Day</label>
                      <label for="windSpeed" v-else>Wind Speed</label>
                      <div id="windSpeedHelpBlock" class="form-text">Beaufort Number: {{tripInfo.windSpeed}}</div>
                    </div>
                  </div>

                  <div class="col-lg-4">
                    <div class="form-floating mb-4">
                      <select class="form-control form-control-sm" id="weatherConditions" name="weatherConditions" v-model="tripInfo.weatherConditions" disabled>
                        <option value="please_select" disabled selected>Please Select</option>
                        <option v-for="item in uiDropdowns.weatherConditions" :key="item.value" :value="item.value">{{item.label}}</option>
                      </select>
                      <label for="weatherConditions" v-if="tripInfo.multiDayTrip">Avg. Weather Conditions per Day</label>
                      <label for="weatherConditions" v-else>Weather Conditions</label>
                    </div>
                  </div>

                  <div class="col-lg-4">
                    <div class="form-floating mb-4">
                      <select class="form-control form-control-sm" id="cloudConditions" name="cloudConditions" v-model="tripInfo.cloudConditions" disabled>
                        <option value="please_select" disabled selected>Please Select</option>
                        <option v-for="item in uiDropdowns.cloudConditions" :key="item.value" :value="item.value">{{item.label}}</option>
                      </select>
                      <label for="cloudConditions" v-if="tripInfo.multiDayTrip">Avg. Cloud Conditions per Day</label>
                      <label for="cloudConditions" v-else>Cloud Conditions</label>
                    </div>
                  </div>

                </div>

                <div class="row">
                  <div class="col-lg-4">
                    <div class="form-floating mb-4">
                      <select class="form-control form-control-sm" id="tripPurpose" name="tripPurpose" v-model="tripInfo.tripPurpose" disabled>
                        <option value="please_select" disabled selected>Please Select</option>
                        <option v-for="item in uiDropdowns.purpose" :key="item.value" :value="item.value">{{item.label}}</option>
                      </select>
                      <label for="tripPurpose">Purpose</label>
                    </div>
                  </div>

                  <div class="col-lg-4">
                    <div class="form-floating mb-4">
                      <input type="text" class="form-control" id="location" name="location" v-model="tripInfo.location" disabled>
                      <label for="location">Location</label>
                    </div>
                  </div>

                  <div class="col-lg-4">
                    <div class="form-floating mb-4">
                      <select class="form-control form-control-sm" id="locationDescription" name="locationDescription" v-model="tripInfo.locationDescription" disabled>
                        <option value="please_select" disabled selected>Please Select</option>
                        <option v-for="item in uiDropdowns.locationDescription" :key="item.value" :value="item.value">{{item.label}}</option>
                      </select>
                      <label for="locationDescription">Location Type</label>
                    </div>
                  </div>

                </div>

                <div class="row" v-if="tripInfo.charterCompany">
                  <div class="col-lg-4">
                    <div class="form-floating mb-4">
                      <input type="text" class="form-control" id="charterCompanyName" name="charterCompanyName" v-model="tripInfo.charterCompany.charterCompanyName" disabled>
                      <label for="charterCompanyName">Charter Company Name</label>
                    </div>
                  </div>

                  <div class="col-lg-4">
                    <div class="form-floating mb-4">
                      <input type="text" class="form-control" id="charterBaseName" name="charterBase" v-model="tripInfo.charterCompany.charterCompanyBaseName" disabled>
                      <label for="charterBaseName">Charter Base Name</label>
                    </div>
                  </div>

                  <div class="col-lg-4">
                    <div class="form-floating mb-4">
                      <input type="text" class="form-control" id="charterBase" name="charterBase" v-model="tripInfo.charterCompany.charterCompanyBaseLocation" disabled>
                      <label class="d-none d-xl-block" for="charterBase">Charter Base Location (City, Province, Country)</label>
                      <label class="d-block d-xl-none" for="charterBase">Charter Base Location</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-12">
                    <div class="form-floating mb-4">
                      <textarea class="form-control charterRodeTextArea" id="notes" name="notes" v-html="tripInfo.notes" disabled></textarea>
                      <label for="notes">Notes</label>
                    </div>
                  </div>
                </div>


                <div class="row">
                  <div class="col-md-6">
                    <div class="form-floating mb-4">
                      <input class="form-control form-control-sm" id="verificationAuthority" name="verificationAuthority" v-model="tripInfo.verificationAuthority" disabled/>
                      <label for="verificationAuthority">Verification Authority</label>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="form-floating mb-4">
                      <input class="form-control form-control-sm" id="totalFees" name="totalFees" v-model="tripInfo.totalFeesForEntry" disabled/>
                      <label for="totalFees">Total Fees for this Tx</label>
                    </div>
                  </div>
                </div>

              </div>

              <div class="container" v-if="transaction">
                <div class="row">
                  <div class="col-lg-4">
                    <div><code>Tx Id: {{transaction.id}}</code></div>
                  </div>
                  <div class="col-lg-4">
                    <div><code>Tx Date: {{formatDateTime(transaction.time)}}</code></div>
                  </div>
                  <div class="col-lg-4">
                    <div><code>Tx Status:</code> <span v-html="getTxStatusFor(transaction)"></span></div>
                  </div>
                </div>

                <div class="row mt-3" v-if="transaction.block">
                  <div class="col-lg-12">
                    <div><strong>This transaction has been committed on the Sailing Resume Blockchain</strong></div>
                  </div>
                </div>
                <div class="row" v-if="transaction.block">
                  <div class="col-lg-12">
                    <div><code>Block: <a :href="blockUrl(transaction.block.blockHash, transaction.id)" target="_blank">{{transaction.block.blockHash}}</a></code></div>
                  </div>
                </div>
                <div class="row" v-if="transaction.block">
                  <div class="col-lg-12">
                    <div><code>Sequence: {{transaction.block.blockSequence}}</code></div>
                  </div>
                </div>
                <div class="row" v-if="transaction.block">
                  <div class="col-lg-12">
                    <div><code>Time: {{formatDateTime(transaction.block.blockTime)}}</code></div>
                  </div>
                </div>

                <div class="row" v-if="transaction.rejectReason">
                  <div class="col-lg-12">
                    <div v-if="transaction.rejectReason"><code>Reason: {{getTxRejectReasonFor(transaction)}}</code></div>
                    <div v-if="transaction.rejectionComments"><code>Comments: {{transaction.rejectionComments}}</code></div>
                  </div>
                </div>

                <div class="row mb-3">
                  <div class="col-lg-4 mt-1">
                    <div><code>Tx Type: {{getTxTypeFor(transaction)}}</code></div>
                  </div>
                </div>

                <div class="row" v-if="transaction.input.log.credential">
                  <div class="col-lg-4 mt-1">
                    <div><code>Credential Title: {{transaction.input.log.credential.credentialTitle}}</code></div>
                  </div>
                  <div class="col-lg-4 mt-1">
                    <div><code>Issued Date: {{transaction.input.log.credential.issuedDate}}</code></div>
                  </div>
                  <div class="col-lg-4 mt-1" v-if="transaction.input.log.credential.credentialInfo">
                    <div><code>Info: {{transaction.input.log.credential.credentialInfo}}</code></div>
                  </div>
                </div>
              </div>


              <div class="container" v-if="operationalExperienceTransaction">

                <div class="row">

                  <div class="col-lg-4">
                    <div class="form-floating mb-4">
                      <input type="text" class="form-control" id="opExpDate" name="date" v-model="opExpInfo.date" disabled>
                      <label for="date">Date</label>
                    </div>
                  </div>

                  <div class="col-lg-4">
                    <div class="form-floating mb-4">
                      <select class="form-control form-control-sm" id="opExpRole" name="opExpRole" v-model="opExpInfo.role" disabled>
                        <option value="please_select" disabled selected>Please Select</option>
                        <option v-for="item in uiDropdowns.roleType" :key="item.value" :value="item.value">{{item.label}}</option>
                      </select>
                      <label for="opExpRole">Your Role</label>
                    </div>
                  </div>

                  <div class="col-lg-4">
                    <div class="form-floating mb-4">
                      <input type="text" class="form-control" id="opExpVessel" name="date" v-model="opExpInfo.vessel.vesselName" disabled>
                      <label for="opExpVessel">Vessel</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-4">
                    <div class="form-floating mb-4">
                      <input type="number" class="form-control" id="dockingMarina" name="dockingMarina" v-model="opExpInfo.dockingMarina" disabled>
                      <label for="dockingMarina">Docking Marina</label>
                      <div id="dockingMarinaHelpBlock" class="form-text">Most marinas present challenges when docking. Log one docking event each time you return to the marina.</div>
                    </div>
                  </div>

                  <div class="col-lg-4">
                    <div class="form-floating mb-4">
                      <input type="number" class="form-control" id="dayAnchoring" name="dayAnchoring" v-model="opExpInfo.dayAnchoring" disabled>
                      <label for="dayAnchoring">Day Anchoring/Mooring Ball</label>
                      <div id="dayAnchoringHelpBlock" class="form-text">Nothing like a great day at anchor or on a mooring ball to keep you relaxed.</div>
                    </div>
                  </div>

                  <div class="col-lg-4">
                    <div class="form-floating mb-4">
                      <input type="number" class="form-control" id="overnightAnchoring" name="overnightAnchoring" v-model="opExpInfo.overnightAnchoring" disabled>
                      <label for="overnightAnchoring">Overnight Anchoring/Mooring Ball</label>
                      <div id="overnightAnchoringHelpBlock" class="form-text">Don't forget to set your anchor watch alarm!</div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-4">
                    <div class="form-floating mb-4">
                      <input type="number" class="form-control" id="mediterraneanMooring" name="mediterraneanMooring" v-model="opExpInfo.mediterraneanMooring" disabled>
                      <label for="mediterraneanMooring">Mediterranean Mooring</label>
                      <div id="mediterraneanMooringHelpBlock" class="form-text">Med mooring is a great skill to practice in open water, log it here if you have completed it on a dock with other boats around.</div>
                    </div>
                  </div>

                  <div class="col-lg-4">
                    <div class="form-floating mb-4">
                      <input type="number" class="form-control" id="twentyFourHourCharterPeriods" name="twentyFourHourCharterPeriods" v-model="opExpInfo.twentyFourHourCharterPeriods" disabled>
                      <label for="twentyFourHourCharterPeriods">24 Hour Charter Periods</label>
                      <div id="twentyFourHourCharterPeriodsHelpBlock" class="form-text">These are tracked from extended/multi-day charter trips. This allows charter companies to quickly see that you have extended chartering experience.</div>
                    </div>
                  </div>

                  <div class="col-lg-4">
                    <div class="form-floating mb-4">
                      <input type="number" class="form-control" id="overnightSailing" name="overnightSailing" v-model="opExpInfo.overnightSailing" disabled>
                      <label for="overnightSailing">Overnight Sailing</label>
                      <div id="overnightSailingHelpBlock" class="form-text">Not docked or at anchor. You sailed continuously from sunset to sunrise.</div>
                    </div>
                  </div>
                </div>

              </div>

            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-danger me-auto" v-if="voidTransactionAvailable" v-on:click="activateVoidModal"><fai icon="ban"></fai> Void Entry</button>
            <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">Close</button>
            <button type="button" class="btn btn-success" v-if="submitForPaymentAvailable" v-on:click="activateRetryPaymentModal">Retry Payment</button>
          </div>
        </div>
      </div>
    </div>
  </teleport>


  <teleport to="body">
    <!-- Modal -->
    <div class="modal fade" id="voidTransactionModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-fullscreen">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Confirm Void Log Entry</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="pb-3 charterRodeHelpText">Arr, Matey! Making this log entry walk the plank are we? Please let use know why you are voiding this log entry.</div>

            <div class="container" v-if="sendingVoidTransactionRequest">
              <div class="row">
                <div class="col-sm-12">
                  <div class="tx-submit-spinner mt-lg-5"><fai icon="spinner" spin/> Please wait while this log entry is voided...</div>
                </div>
              </div>
            </div>

            <div v-if="!sendingVoidTransactionRequest" class="container">
              <div class="row pb-3 pt-3">
                <div class="col-sm-12">
                  <div class="form-floating mb-4">
                    <select class="form-control form-control-sm" id="voidTransactionReasonTrip" name="voidTransactionReasonTrip" v-model="voidTx.voidReason">
                      <option value="please_select" disabled selected>Please Select</option>
                      <option v-for="item in uiDropdowns.voidTx.reasons" :key="item.value" :value="item.value">{{item.label}}</option>
                    </select>
                    <label for="voidTransactionReasonTrip">Void Reason</label>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-sm-12">
                  <div class="alert" v-bind:class="{'alert-danger': !voidTx.agreeToCRTCharges, 'alert-success': voidTx.agreeToCRTCharges}" role="alert">
                    <div>
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="checkbox" id="agreeInfoIsCorrect" v-model="voidTx.agreeToCRTCharges">
                        <label class="form-check-label charterRodeBtn" for="agreeInfoIsCorrect">
                          <span>I understand that I am voluntarily voiding this entry and that I WILL NOT be credited any CRT already spent to process and verify this log entry on Charter Rode and that this entry will not be confirmed on Charter Rode.</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>

          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal" :disabled="sendingVoidTransactionRequest">Close</button>
            <button type="button" class="btn btn-danger" v-on:click="voidTransaction" :disabled="sendingVoidTransactionRequest || voidTx.agreeToCRTCharges == false || voidTx.voidReason == undefined || voidTx.voidReason == 'please_select'">Void Entry</button>
          </div>
        </div>
      </div>
    </div>
  </teleport>

  <teleport to="body">
    <!-- Modal -->
    <div class="modal fade" id="retryPaymentTransactionModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-fullscreen">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Confirm Retry Payment for Log Entry</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="pb-3 charterRodeHelpText">Please re-confirm the charges for this log entry below and tap the 'Re-Submit for Payment' button.</div>

            <div class="container" v-if="sendingPaymentTransactionRequest">
              <div class="row">
                <div class="col-sm-12">
                  <div class="tx-submit-spinner mt-lg-5"><fai icon="spinner" spin/> Please wait while this log entry is re-submitted for payment...</div>
                </div>
              </div>
            </div>

            <div v-if="!sendingPaymentTransactionRequest" class="container">

              <div class="row">
                <div class="col-sm-12">
                  <div class="alert" v-bind:class="{'alert-danger': !retryPaymentTx.agreeToCRTCharges, 'alert-success': retryPaymentTx.agreeToCRTCharges}" role="alert">
                    <div>
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="checkbox" id="agreeInfoIsCorrectRetry" v-model="retryPaymentTx.agreeToCRTCharges">
                        <label class="form-check-label charterRodeBtn" for="agreeInfoIsCorrectRetry">
                          <div v-if="tripInfo">I authorize Charter Rode to charge my account a <span class="disclaimer-text-highlight">total of {{tripInfo.totalFeesForEntry}}</span> to <span v-show="tripInfo.verificationAuthority === 'charter_rode'">verify, </span>process, and commit this entry.</div>
                          <div v-else-if="identityValidationTransactionInfo">I authorize Charter Rode to charge my account <span class="disclaimer-text-highlight">up to {{identityValidationTransactionInfo.totalFeesForEntry}}</span> to <span v-show="identityValidationTransactionInfo.verificationAuthority === 'charter_rode'">verify, </span>process, and commit this entry.</div>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>

          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal" :disabled="sendingPaymentTransactionRequest">Close</button>
            <button type="button" class="btn btn-success" v-on:click="retryPaymentTransaction" :disabled="sendingPaymentTransactionRequest || retryPaymentTx.agreeToCRTCharges == false">Re-Submit for Payment</button>
          </div>
        </div>
      </div>
    </div>
  </teleport>

  <teleport to="body">
    <div class="position-fixed bottom-0 end-0 p-3 cr-z-index-5">
      <div id="voidTxToast" class="toast hide" role="alert" aria-live="assertive" aria-atomic="true">
        <div class="toast-header">
          <strong class="me-auto">Entry Successfully Voided</strong>
          <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
        </div>
        <div class="toast-body">Your have successfully voided this entry</div>
      </div>
    </div>
  </teleport>

  <teleport to="body">
    <div class="position-fixed bottom-0 end-0 p-3 cr-z-index-5">
      <div id="retryPaymentTxToast" class="toast hide" role="alert" aria-live="assertive" aria-atomic="true">
        <div class="toast-header">
          <strong class="me-auto">Entry Successfully Submitted for Payment</strong>
          <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
        </div>
        <div class="toast-body">Your have successfully re-submitted this entry for payment processing</div>
      </div>
    </div>
  </teleport>

</template>

<script>
import {Modal, Toast} from "bootstrap"
import api from "../../api/api";

export default {
  name: 'ViewTransactionModal',
  emits: ['modalClose', 'transactionVoided', 'transactionResubmittedForPayment'],
  data() {
    return {
      tripTransaction: false,
      identityValidationTransaction: false,
      operationalExperienceTransaction: false,
      sendingVoidTransactionRequest: false,
      sendingPaymentTransactionRequest: false,
      uiDropdowns: this.$store.state.uiDropdowns,
      user:  this.$store.state.user,
      tripInfo: {
        transaction: {

        }
      },
      opExpInfo: {

      },
      identityValidationTransactionInfo: {

      },
      transaction: undefined,
      voidTransactionAvailable: false,
      submitForPaymentAvailable: false,
      voidTx: {
        voidReason: 'please_select',
        agreeToCRTCharges: false
      },
      retryPaymentTx: {
        agreeToCRTCharges: false
      }
    }
  },
  methods: {
    blockUrl(blockHash, txId) {
      // send the user to the Charter Rode Block explorer defaulting to this transaction view on the chain
      return "https://www.charterrode.com/bex/"+blockHash+"/"+txId
    },
    activateVoidModal() {
      this.sendingVoidTransactionRequest = false
      this.voidTx = {
        rejectReason: 'please_select',
        agreeToCRTCharges: false
      }
      this.voidTxModal = new Modal(document.getElementById('voidTransactionModal'))
      this.voidTxModal.toggle()
    },
    activateRetryPaymentModal() {
      this.sendingPaymentTransactionRequest = false
      this.retryPaymentTx = {
        agreeToCRTCharges: false
      }
      this.retryPaymentTxModal = new Modal(document.getElementById('retryPaymentTransactionModal'))
      this.retryPaymentTxModal.toggle()
    },
    activateModal(theTransaction) {
      this.transaction = undefined
      this.tripInfo = undefined
      this.opExpInfo = undefined
      this.identityValidationTransactionInfo = {}
      this.voidTransactionAvailable = false
      this.submitForPaymentAvailable = false
      this.tripTransaction = false
      this.identityValidationTransaction = false
      this.operationalExperienceTransaction = false

      if ( theTransaction.input.log.trip ) {
        this.tripTransaction = true
        if ( theTransaction.input.log.trip.entries ) {
          // for now they are all the same....
          let theTripEntry = theTransaction.input.log.trip.entries[0]
          let multiDayTripDays = 0
          let multiDayTripNights = 0
          theTransaction.input.log.trip.entries.forEach(function(anEntry) {
            if ( anEntry.timeOfDay === 'day' ) {
              ++multiDayTripDays
            } else if ( anEntry.timeOfDay === 'night' ) {
              ++multiDayTripNights
            }
          })
          this.tripInfo = {
            date: theTripEntry.date,
            multiDayTrip: theTransaction.input.log.trip.entries.length > 1,
            tripRole: theTripEntry.role,
            vessel: theTripEntry.vessel.vesselName,
            multiDayTripDays: ''+multiDayTripDays,
            multiDayTripNights: ''+multiDayTripNights,
            tripDuration: theTripEntry.durationHours,
            tripDistance: theTripEntry.nauticalMiles,
            tripTimeOfDay: theTripEntry.timeOfDay,
            windSpeed: theTripEntry.windSpeedBeaufort,
            weatherConditions: theTripEntry.weatherConditions,
            cloudConditions: theTripEntry.cloudConditions,
            tripPurpose: theTripEntry.purpose,
            location: theTripEntry.location,
            locationDescription: theTripEntry.locationType,
            notes: theTripEntry.notes ? theTripEntry.notes : undefined,
            verificationAuthority: 'Self',
            totalFeesForEntry: '1 CRT',
          }

          if ( multiDayTripNights > 0 && multiDayTripDays > 0 ) {
            // artificially for display purposes show average miles per day as value from one trip entry times 2
            this.tripInfo.tripDistance = this.tripInfo.tripDistance * 2
          }
          this.tripInfo.transaction = theTransaction
          if ( theTransaction.input.log.trip.charterCompany ) {
            this.tripInfo.charterCompany = theTransaction.input.log.trip.charterCompany
          }
          if ( theTransaction.input.verificationAuthority.type === 'self' ) {
            this.tripInfo.verificationAuthority = 'Self'
            this.tripInfo.totalFeesForEntry = theTransaction.input.log.trip.entries.length + ' CRT'
          } else if ( theTransaction.input.verificationAuthority.type === 'crewmate' ) {
            this.tripInfo.verificationAuthority = 'Crewmate'
            this.tripInfo.totalFeesForEntry = theTransaction.input.log.trip.entries.length + ' CRT'
          } else if ( theTransaction.input.verificationAuthority.type === 'charter_rode' ) {
            this.tripInfo.verificationAuthority = 'Charter Rode'
            this.tripInfo.totalFeesForEntry = theTransaction.input.log.trip.entries.length + 7 +' CRT'
          }
        }
      } else {
        this.transaction = theTransaction
        if ( theTransaction.input.log.identityVerification ) {
          this.identityValidationTransaction = true
          // this can't be hard-coded this way, needs to come from back-end!
          this.identityValidationTransactionInfo = {
            totalFeesForEntry: '50 CRT',
            verificationAuthority: 'charter_rode'
          }
        } else if ( theTransaction.input.log.operationalExperience ) {
          this.operationalExperienceTransaction = true
          this.opExpInfo = theTransaction.input.log.operationalExperience
        }
      }

      // can we void this thing out still???
      if ( theTransaction.status === 'confirmed' || theTransaction.status === 'voided' || theTransaction.input.log.identityVerification != undefined) {
        this.voidTransactionAvailable = false
      } else {
        this.voidTransactionAvailable = true
      }

      // do we show the retry payment option?
      if ( theTransaction.status === 'pending_payment' ) {
        this.submitForPaymentAvailable = true
      }

      this.viewTxModal = new Modal(document.getElementById('viewTransactionModal'))
      let viewTxEl = document.getElementById('viewTransactionModal')
      let self = this
      viewTxEl.addEventListener('hidden.bs.modal', function () {
        self.$emit('modalClose')
      })
      this.viewTxModal.toggle()
    },
    formatDateTime(aDateInMillis) {
      return new Date(aDateInMillis).toLocaleString("en-US")
    },
    formatDate(aDateInMillis) {
      return new Date(aDateInMillis).toLocaleDateString("en-US")
    },
    getTxStatusFor(row) {
      let theFinalLabel = 'unknown'
      this.uiDropdowns.transaction.status.forEach(function(aStatus) {
        if ( row.status === aStatus.value ) {
          theFinalLabel = aStatus.label
        }
      })
      switch(row.status) {
        case 'pending_payment':
          return '<span class="badge bg-warning">'+theFinalLabel+'</span>'
        case 'pending_verification':
          return '<span class="badge bg-info">'+theFinalLabel+'</span>'
        case 'rejected':
          return '<span class="badge bg-danger">'+theFinalLabel+'</span>'
        case 'confirmed':
          return '<span class="badge bg-success">'+theFinalLabel+'</span>'
        case 'voided':
          return '<span class="badge bg-secondary">'+theFinalLabel+'</span>'
        default:
          return '<span class="badge bg-dark">'+theFinalLabel+'</span>'
      }
    },
    getTxRejectReasonFor(row) {
      let theFinalLabel = undefined
      this.uiDropdowns.rejectTx.trip.reasons.forEach(function(aStatus) {
        if ( row.rejectReason === aStatus.value ) {
          theFinalLabel = aStatus.label
        }
      })
      if ( theFinalLabel == undefined ) {
        this.uiDropdowns.rejectTx.general.reasons.forEach(function(aStatus) {
          if ( row.rejectReason === aStatus.value ) {
            theFinalLabel = aStatus.label
          }
        })
      }
      return theFinalLabel === undefined ? 'unknown' : theFinalLabel
    },
    getTxTypeFor(row) {
      if ( row && row.input && row.input.log && row.input.log.trip ) {
        return 'Trip'
      } else if ( row && row.input && row.input.log && row.input.log.identityVerification ) {
        return 'Identity Verification'
      } else if ( row && row.input && row.input.log && row.input.log.operationalExperience ) {
        return 'Operational Experience'
      } else if ( row && row.input && row.input.log && row.input.log.credential ) {
        return 'Credential'
      } else {
        return 'unknown'
      }
    },
    getTxTripDatesFor(row) {
      if ( row && row.input && row.input.log && row.input.log.trip &&  row.input.log.trip.entries ) {
        let startDate = undefined
        let endDate = undefined
        // assuming the entries are in order, might want to sort on sequence...
        row.input.log.trip.entries.forEach(function(anEntry) {
          if ( anEntry && anEntry.date ) {
            if ( !startDate )
              startDate = anEntry.date
          }
          endDate = anEntry.date
        })

        if ( startDate === endDate ) {
          // hack to make the date more human readable...
          let startDateParts = startDate.split("-")
          if ( startDateParts[1].startsWith("0") ) {
            startDateParts[1] = startDateParts[1].substr(1,1)
          }
          return startDateParts[1]+'/'+startDateParts[2]+'/'+startDateParts[0]
        } else {
          let startDateParts = startDate.split("-")
          if ( startDateParts[1].startsWith("0") ) {
            startDateParts[1] = startDateParts[1].substr(1,1)
          }
          let endDateParts = endDate.split("-")
          if ( endDateParts[1].startsWith("0") ) {
            endDateParts[1] = endDateParts[1].substr(1,1)
          }
          return startDateParts[1]+'/'+startDateParts[2]+'/'+startDateParts[0] +' - '+ endDateParts[1]+'/'+endDateParts[2]+'/'+endDateParts[0]
        }
      } else {
        return '--'
      }
    },
    voidTransaction() {
      this.sendingVoidTransactionRequest = true
      let theTransactionId = undefined
      if ( this.tripInfo && this.tripInfo.transaction && this.tripInfo.transaction.id ) {
        theTransactionId = this.tripInfo.transaction.id
      } else if ( this.transaction && this.transaction.id ) {
        theTransactionId = this.transaction.id
      }
      api.voidTransaction(theTransactionId, this.voidTx.voidReason).then(theRejectedTransaction => {
        if ( theRejectedTransaction && theRejectedTransaction.status === 'voided') {
          // success!
          let voidTxToast = new Toast(document.getElementById('voidTxToast'))
          voidTxToast.show()
          this.voidTxModal.hide()
          this.viewTxModal.hide()
          this.sendingVoidTransactionRequest = false
          this.$emit('transactionVoided', this.tripInfo.transaction.id)
        }
      })
    },
    retryPaymentTransaction() {
      this.sendingPaymentTransactionRequest = true
      let theTransactionId = undefined
      if ( this.transaction && this.transaction.input.log.identityVerification ) {
        theTransactionId = this.transaction.id
      } else {
        theTransactionId = this.tripInfo.transaction.id
      }

      api.resubmitTransactionForPayment(theTransactionId).then(theResubmittedTransaction => {
        if ( theResubmittedTransaction && theResubmittedTransaction.status) {
          // success!
          let retryPaymentTxToast = new Toast(document.getElementById('retryPaymentTxToast'))
          retryPaymentTxToast.show()
          this.retryPaymentTxModal.hide()
          this.viewTxModal.hide()
          this.sendingPaymentTransactionRequest = false
          this.$emit('transactionResubmittedForPayment', theTransactionId)
        }
      })
    }
  },
  components: {

  }
}
</script>

<style>

code {
  display: inline-block;
  white-space: pre-wrap;
  color: #444;
  font-style: italic;
}

</style>