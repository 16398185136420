<template>
  <div class="container">
    <div class="row mt-3 mb-3">
      <div class="col-sm-12">
        <h4>All Logbook Entries</h4>
        <div class="charterRodeHelpText">Here you can see all of your logbook entries and keep tabs on their status of being confirmed on Charter Rode.</div>
      </div>
    </div>

    <div class="row" v-if="hasPendingPaymentTxs && walletFunds <= 0">
      <div class="col-12">
        <div class="alert alert-warning" role="alert"><fai icon="exclamation-triangle" style="position: relative;top:-1px;"/> You have transactions that are pending payment.&nbsp;<router-link to="/wallet" style="text-decoration: none;">Fund CRTs to your wallet</router-link>&nbsp;to process and confirm these transactions!</div>
      </div>
    </div>


    <div class="row">
      <div class="col-12">
        <div style="margin-top:10px;position: relative;" class="d-grid gap-2 d-md-block">
          <button type="button" class="btn btn-outline-secondary btn-sm me-2" v-on:click="refreshTransactions" :disabled="gettingTransactions"><span v-show="gettingTransactions"><fai icon="spinner" spin/> Refreshing ...</span><span v-show="!gettingTransactions">Refresh Entries</span></button>
          <button type="button" class="btn btn-outline-primary btn-sm me-2" v-on:click="viewTransactionStatuses" :disabled="gettingTransactions">What Doe The Tx Status Mean?</button>

          <div class="d-none d-sm-none d-md-block float-end table-caption " v-if="userTransactions.length === 0 || userTransactions.length > 1">{{userTransactions.length}} entries</div><div class="d-none d-sm-none d-md-block float-end table-caption" v-else>{{userTransactions.length}} entry</div>
        </div>
        <div class="table-responsive-xxl" v-if="!gettingTransactions">
          <VTable
              :key="userTransactionsTableKey"
              :data="userTransactions"
              ref="userTransactionsTable"
              class="table caption-top"
              selectionMode="single"
              selectedClass="table-primary"
              :selectOnClick="false">
            <template #head>
              <tr>
                <th style="width:5px;">&nbsp;</th>
                <th style="white-space: nowrap">Tx Id</th>
                <th style="white-space: nowrap">Tx Date</th>
                <th style="white-space: nowrap">Tx Status</th>
                <th style="white-space: nowrap">Tx Type</th>
                <th style="white-space: nowrap;">Date(s)</th>
                <th style="white-space: nowrap;">Vessel/Credential</th>
              </tr>
            </template>
            <template #body="{ rows }">
              <tr v-show="rows.length == 0">
                <td>&nbsp;</td>
                <td colspan="6" style="text-align: center">Hmm on the hard are we? <router-link class="charterRodeLink" to="/tx/submit">Let's start by adding your first trip!</router-link></td>
              </tr>
              <VTr v-for="row in rows" :key="row.id" :row="row">
                <td v-on:click="viewTransaction(row)" style="vertical-align: middle">&nbsp;</td>
                <td v-on:click="viewTransaction(row)">{{formatTxId(row.id)}}</td>
                <td v-on:click="viewTransaction(row)">{{formatDateTime(row.time)}}</td>
                <td v-on:click="viewTransaction(row)" v-html="getTxStatusFor(row)"></td>
                <td v-on:click="viewTransaction(row)">{{getTxTypeFor(row)}}</td>
                <td v-on:click="viewTransaction(row)">{{getTxTripDatesFor(row)}}</td>
                <td v-on:click="viewTransaction(row)">{{getTxTripVesselFor(row)}}</td>
              </VTr>
            </template>
          </VTable>
        </div>

      </div>
    </div>

    <div class="row" v-if="gettingTransactions">
      <div class="col-12">
        <div class="tx-lookup-spinner mt-lg-5"><fai icon="spinner" spin/> Retrieving log entries history...</div>
      </div>
    </div>

  </div>


  <viewTransactionModal
      ref="viewTransactionModal"
      v-on:transactionVoided="transactionVoided"
      v-on:transactionResubmittedForPayment="transactionResubmittedForPayment"
      v-on:modalClose="viewTransactionModalClose"></viewTransactionModal>

  <viewTransactionStatusesModal
      ref="viewTransactionStatusesModal"></viewTransactionStatusesModal>

</template>

<script>
import api from "../../api/api";

export default {
  name: 'Transactions',
  data() {
    return {
      userTransactionsTableKey: 0,
      uiDropdowns: this.$store.state.uiDropdowns,
      gettingTransactions: false,
      userTransactions: this.$store.state.user.userTransactions ? this.$store.state.user.userTransactions : [],
      hasPendingPaymentTxs: false
    }
  },
  mounted() {
    if ( this.$store.state.user.userTransactions == undefined ) {
      this.refreshTransactions()
    } else {
      let self = this
      this.userTransactions.forEach(function (aTx) {
        if (aTx.status === 'pending_payment') {
          self.hasPendingPaymentTxs = true
        }
      })
    }
  },
  methods: {
    refreshTransactions() {
      this.gettingTransactions = true
      api.getMyTransactions().then(theUserTransactions => {
        if ( theUserTransactions && theUserTransactions.length > 0 ) {

          // sort all transactions with the most recent on top like  bank would do online portal
          theUserTransactions.sort(function (a, b) {
            if (a.time > b.time) return -1
            if (a.time < b.time) return 1
            return 0
          })
          this.userTransactions = theUserTransactions
          this.$store.state.user.userTransactions = theUserTransactions
          let self = this
          this.userTransactions.forEach(function (aTx) {
            if ( aTx.status === 'pending_payment' ) {
              self.hasPendingPaymentTxs = true
            }
          })
        } else {
          this.userTransactions = []
          this.$store.state.user.userTransactions = []
        }
        this.gettingTransactions = false
      })
    },
    viewTransaction(selectedRows) {
      if ( selectedRows && selectedRows.length > 0 ) {
        // activate thew viewTransactionModal!
        this.$refs.viewTransactionModal.activateModal(selectedRows[0])
      } else if ( selectedRows && selectedRows.id ) {
        this.$refs.viewTransactionModal.activateModal(selectedRows)
      }
    },
    viewTransactionModalClose() {
      // may clear selection?
      this.$refs.userTransactionsTable.deselectAll()
    },
    viewTransactionStatuses() {
      this.$refs.viewTransactionStatusesModal.activateModal()
    },
    transactionVoided(aTransactionId) {
      this.$refs.userTransactionsTable.deselectAll()
      // preventing a round-trip to update the entire list when we know
      // what really happened here....
      for(let i = 0; i < this.userTransactions.length; ++i) {
        if ( this.userTransactions[i].id === aTransactionId) {
          this.userTransactions[i].status = 'voided'
          break
        }
      }
      this.$store.state.user.userTransactions = this.userTransactions
      this.userTransactionsTableKey += 1
    },
    transactionResubmittedForPayment() {
      this.$refs.userTransactionsTable.deselectAll()
      // in this case we really need to refresh the list as we don't know if payment was successful or not!
      let self = this
      setTimeout(function() {
        self.refreshTransactions()
        // maybe the CRT updated?
        api.getMe().then(response => {
          if (response.user && response.user.firstName && response.user.lastName) {
            // all good let them go to home!
            self.$store.commit('setUser', response.user)
          }
        })
      }, 2500)
    },
    formatTxId(aTxId) {
      if ( aTxId ) {
        return aTxId.substr(0,8)+'...'+aTxId.substr(aTxId.length-8, aTxId.length - 1)
      }
      return ''
    },
    formatDateTime(aDateInSeconds) {
      return new Date(aDateInSeconds).toLocaleString("en-US")
    },
    formatDate(aDateInSeconds) {
      return new Date(aDateInSeconds).toLocaleDateString("en-US")
    },
    getTxStatusFor(row) {
      let theFinalLabel = 'unknown'
      this.uiDropdowns.transaction.status.forEach(function(aStatus) {
        if ( row.status === aStatus.value ) {
          theFinalLabel = aStatus.label
        }
      })
      switch(row.status) {
        case 'pending_payment':
          return '<span class="badge bg-warning">'+theFinalLabel+'</span>'
        case 'pending_verification':
          return '<span class="badge bg-info">'+theFinalLabel+'</span>'
        case 'rejected':
          return '<span class="badge bg-danger">'+theFinalLabel+'</span>'
        case 'confirmed':
          return '<span class="badge bg-success">'+theFinalLabel+'</span>'
        case 'voided':
          return '<span class="badge bg-secondary">'+theFinalLabel+'</span>'
        default:
          return '<span class="badge bg-dark">'+theFinalLabel+'</span>'
      }
    },
    getTxTypeFor(row) {
      if ( row && row.input && row.input.log && row.input.log.trip ) {
        return 'Trip'
      } else if ( row && row.input && row.input.log && row.input.log.identityVerification ) {
        return 'Identity Verification'
      } else if ( row && row.input && row.input.log && row.input.log.operationalExperience ) {
        return 'Operational Experience'
      } else if ( row && row.input && row.input.log && row.input.log.credential ) {
        return 'Credential'
      } else {
        return 'unknown'
      }
    },
    getTxTripDatesFor(row) {
      if ( row && row.input && row.input.log && row.input.log.trip &&  row.input.log.trip.entries ) {
        let startDate = undefined
        let endDate = undefined
        // assuming the entries are in order, might want to sort on sequence...
        row.input.log.trip.entries.forEach(function (anEntry) {
          if (anEntry && anEntry.date) {
            if (!startDate)
              startDate = anEntry.date
          }
          endDate = anEntry.date
        })

        if (startDate === endDate) {
          // hack to make the date more human readable...
          let startDateParts = startDate.split("-")
          if (startDateParts[1].startsWith("0")) {
            startDateParts[1] = startDateParts[1].substr(1, 1)
          }
          return startDateParts[1] + '/' + startDateParts[2] + '/' + startDateParts[0]
        } else {
          let startDateParts = startDate.split("-")
          if (startDateParts[1].startsWith("0")) {
            startDateParts[1] = startDateParts[1].substr(1, 1)
          }
          let endDateParts = endDate.split("-")
          if (endDateParts[1].startsWith("0")) {
            endDateParts[1] = endDateParts[1].substr(1, 1)
          }
          return startDateParts[1] + '/' + startDateParts[2] + '/' + startDateParts[0] + ' - ' + endDateParts[1] + '/' + endDateParts[2] + '/' + endDateParts[0]
        }
      } else if ( row && row.input && row.input.log && row.input.log.identityVerification &&  row.input.log.identityVerification.verifiedDate ) {
        let theDate = row.input.log.identityVerification.verifiedDate
        let theDateParts = theDate.split("-")
        if (theDateParts[1].startsWith("0")) {
          theDateParts[1] = theDateParts[1].substr(1, 1)
        }
        return theDateParts[1] + '/' + theDateParts[2] + '/' + theDateParts[0]
      } else if ( row && row.input && row.input.log && row.input.log.operationalExperience &&  row.input.log.operationalExperience.date ) {
        let theDate = row.input.log.operationalExperience.date
        let theDateParts = theDate.split("-")
        if (theDateParts[1].startsWith("0")) {
          theDateParts[1] = theDateParts[1].substr(1, 1)
        }
        return theDateParts[1] + '/' + theDateParts[2] + '/' + theDateParts[0]
      } else if ( row && row.input && row.input.log && row.input.log.credential &&  row.input.log.credential.issuedDate ) {
        let theDate = row.input.log.credential.issuedDate
        let theDateParts = theDate.split("-")
        if (theDateParts[1].startsWith("0")) {
          theDateParts[1] = theDateParts[1].substr(1, 1)
        }
        return theDateParts[1] + '/' + theDateParts[2] + '/' + theDateParts[0]
      } else {
        return '--'
      }
    },
    getTxTripVesselFor(row) {
      if ( row && row.input && row.input.log && row.input.log.trip &&  row.input.log.trip.entries ) {
        let vesselName = 'unknown'
        row.input.log.trip.entries.forEach(function(anEntry) {
          if ( anEntry && anEntry.vessel && anEntry.vessel.vesselName ) {
            vesselName = anEntry.vessel.vesselName
          }
        })
        return vesselName
      } else {
        if ( row && row.input && row.input.log && row.input.log.credential &&  row.input.log.credential.credentialTitle ) {
          return row.input.log.credential.credentialTitle
        } else if ( row && row.input && row.input.log && row.input.log.operationalExperience ) {
          if ( row.input.log.operationalExperience.vessel ) {
            return row.input.log.operationalExperience.vessel.vesselName
          } else {
            return '--'
          }
        } else {
          return '--'
        }
      }
    }
  },
  computed: {
    walletFunds() {
      if ( this.$store.state.user && this.$store.state.user.currentWalletFundsCRT ) {
        return this.$store.state.user.currentWalletFundsCRT
      }
      return '0'
    }
  }
}
</script>

<style>
.tx-lookup-spinner {
  text-align: center;
  margin-top:5px;
  font-size:22px;
  color:#666;
  font-weight: normal;
  z-index: 9999;
}
</style>
