<template>
  <div class="container" style="margin-bottom: 150px;">
    <div class="row mt-3 mb-3">
      <div class="col-sm-12">
        <h4>Welcome to Charter Rode {{firstName}}!</h4>
        <div class="charterRodeHelpText">What would you like to do today?</div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-4">
        <div class="card mt-3 bg-light">
          <div class="card-body">
            <h5 class="card-title">New Logbook Entry</h5>
            <h6 class="card-subtitle mb-2 text-muted">&nbsp;</h6>
            <p class="card-text">Let's log your most recent adventures and get your experience on Charter Rode!</p>
            <div class="d-grid gap-2">
              <router-link class="btn btn-outline-primary" to="/tx/submit">Add Entry</router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="card mt-3 bg-light">
          <div class="card-body">
            <h5 class="card-title">My Resume</h5>
            <h6 class="card-subtitle mb-2 text-muted">&nbsp;</h6>
            <p class="card-text">Review your resume and create a link to share with a charter company.</p>
            <div class="d-grid gap-2">
              <router-link class="btn btn-outline-secondary" to="/resume">View Resume</router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="card mt-3 bg-light">
          <div class="card-body">
            <div style="float: right;position: relative;top:1px;"><router-link to="/tx/verify"><span class="badge bg-danger" v-if="pendingVerificationRequestsCount > 0">{{pendingVerificationRequestsCount}}</span><span class="badge bg-secondary" v-else>0</span></router-link></div>
            <h5 class="card-title">Pending Verification Requests</h5>
            <h6 class="card-subtitle mb-2 text-muted">&nbsp;</h6>
            <p class="card-text">Review and verify trips you took with your friends and family.</p>
            <div class="d-grid gap-2">
              <router-link class="btn btn-outline-secondary" to="/tx/verify">Review Verification Requests</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-4">
        <div class="card mt-3 bg-light">
          <div class="card-body">
            <h5 class="card-title">Invite a Friend</h5>
            <h6 class="card-subtitle mb-2 text-muted">&nbsp;</h6>
            <p class="card-text">Invite friends and family members to join Charter Rode and earn CRT for your Wallet!</p>
            <div class="d-grid gap-2">
              <a class="btn btn-outline-primary" v-on:click="addCrewmate">Invite a Friend</a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="card mt-3 bg-light">
          <div class="card-body">
            <h5 class="card-title">All Logbook Entries</h5>
            <h6 class="card-subtitle mb-2 text-muted">&nbsp;</h6>
            <p class="card-text">View your past and pending logbook entries and keep tabs on their status.</p>
            <div class="d-grid gap-2">
              <router-link class="btn btn-outline-secondary" to="/tx/list">View All Entries</router-link>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-4">
        <div class="card mt-3 bg-light">
          <div class="card-body">
            <div style="float: right;position: relative;top:1px;"><router-link to="/wallet"><span class="badge bg-success"><fai icon="wallet" class="fa-fw"></fai> {{walletFunds}} CRT</span></router-link></div>
            <h5 class="card-title">Fund Wallet</h5>
            <h6 class="card-subtitle mb-2 text-muted">&nbsp;</h6>
            <p class="card-text">Add funds to your wallet to verify, process, and commit log entries on Charter Rode.</p>
            <div class="d-grid gap-2">
              <router-link class="btn btn-outline-success" to="/wallet">Fund Wallet</router-link>
            </div>
          </div>
        </div>
      </div>

    </div>

    <div class="row">
      <div class="col-lg-4">
        <div class="card mt-3 bg-light">
          <div class="card-body">
            <h5 class="card-title">Vessels</h5>
            <h6 class="card-subtitle mb-2 text-muted">&nbsp;</h6>
            <p class="card-text">Add and update your favorite vessels to make adding logbook entries a breeze.</p>
            <div class="d-grid gap-2">
              <router-link class="btn btn-outline-secondary" to="/vessels">Manage Vessels</router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="card mt-3 bg-light">
          <div class="card-body">
            <h5 class="card-title">Crewmates</h5>
            <h6 class="card-subtitle mb-2 text-muted">&nbsp;</h6>
            <p class="card-text">Add or remove crewmates to make crewmate-based trip verification a breeze.</p>
            <div class="d-grid gap-2">
              <router-link class="btn btn-outline-secondary" to="/crewmates">Manage Crewmates</router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="card mt-3 bg-light">
          <div class="card-body">
            <h5 class="card-title">My Profile</h5>
            <h6 class="card-subtitle mb-2 text-muted">&nbsp;</h6>
            <p class="card-text">We love to be in the know. Let us know the latest information about you!</p>
            <div class="d-grid gap-2">
              <router-link class="btn btn-outline-secondary" to="/profile">Update Profile</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-4">
        <div class="card mt-3 bg-light">
          <div class="card-body">
            <h5 class="card-title">Articles & Support</h5>
            <h6 class="card-subtitle mb-2 text-muted">&nbsp;</h6>
            <p class="card-text">Review documentation, explore additional resources, or contact support for help.</p>
            <div class="d-grid gap-2">
              <a class="btn btn-outline-secondary" href="https://www.charterrode.com/article/" target="_blank">View Articles</a>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>


  <addNewCrewmateModal
      ref="addCrewmateModal"
      confirmButtonLabel="Add Crewmate"
      v-on:setCrewmate="setCrewmate"
      v-on:modalClose="addCrewmateModalClose"></addNewCrewmateModal>


  <teleport to="body">
    <div class="position-fixed bottom-0 end-0 p-3 cr-z-index-5">
      <div id="addCrewmateToast" class="toast hide" role="alert" aria-live="assertive" aria-atomic="true">
        <div class="toast-header">
          <strong class="me-auto">Crewmate Added Successfully!</strong>
          <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
        </div>
        <div class="toast-body">Your crewmate has been added to your favorites</div>
      </div>
    </div>
  </teleport>

</template>

<script>
import api from "../api/api";
import {Toast} from "bootstrap"

export default {
  name: 'Home',
  data() {
    return {
      firstName: this.$store.state.user ? this.$store.state.user.firstName : null,
      walletFunds: this.$store.state.user && this.$store.state.user.currentWalletFundsCRT ? this.$store.state.user.currentWalletFundsCRT : 0,
      pendingVerificationRequestsCount: this.$store.state.user && this.$store.state.user.pendingVerificationRequestsCount ? this.$store.state.user.pendingVerificationRequestsCount : 0,
      pStart: { x: 0, y: 0 },
      pStop: { x: 0, y: 0 }
    }
  },
  mounted() {
    let self = this
    document.addEventListener("touchstart", function (e) {self.swipeStart(e)}, false)
    document.addEventListener("touchend", function (e) {self.swipeEnd(e)}, false)
  },
  methods: {
    addCrewmate() {
      this.$refs.addCrewmateModal.activateModal()
    },
    setCrewmate(theCrewmateSelected) {
      // this added to their 'crewmates' list
      // now let the back-end know about the new crewmate
      api.addCrewmatesAsFavorite(theCrewmateSelected.id).then(theUser => {
        // we just need to update the favorites now
        this.$store.commit('setUser', theUser)
        // nothing else to do here but to let the user know we did it!
        let addCrewmateToast = new Toast(document.getElementById('addCrewmateToast'))
        addCrewmateToast.show()
      })
    },
    addCrewmateModalClose() {
      // nothing to do here...
    },
    swipeStart(e) {
      if (typeof e["targetTouches"] !== "undefined") {
        let touch = e.targetTouches[0]
        this.pStart.x = touch.screenX
        this.pStart.y = touch.screenY
      } else {
        this.pStart.x = e.screenX
        this.pStart.y = e.screenY
      }
    },
    swipeEnd(e) {
      if (typeof e["changedTouches"] !== "undefined") {
        let touch = e.changedTouches[0]
        this.pStop.x = touch.screenX
        this.pStop.y = touch.screenY
      } else {
        this.pStop.x = e.screenX
        this.pStop.y = e.screenY
      }

      this.swipeCheck();
    },
    swipeCheck() {
      var changeY = this.pStart.y - this.pStop.y
      var changeX = this.pStart.x - this.pStop.x
      if (this.isPullDown(changeY, changeX)) {
        location.reload()
      }
    },
    isPullDown(dY, dX) {
      // methods of checking slope, length, direction of line created by swipe action
      return (
          dY < 0 &&
          ((Math.abs(dX) <= 100 && Math.abs(dY) >= 300) ||
              (Math.abs(dX) / Math.abs(dY) <= 0.3 && dY >= 60))
      )
    }
  },
  components: {}
}
</script>

<style>

</style>
