<template>
  <div class="container">
    <div class="row mt-3 mb-3">
      <div class="col-md-12">
        <h4>Wallet</h4>
        <div class="charterRodeHelpText">Your wallet may be funded with CRT to pay for processing, verifying, and committing log entries of your experience on Charter Rode. <strong>The current CRT Exchange Rate is 1 CRT = $1 USD</strong>. <strong>You cannot exchange CRT back for USD</strong>. <span v-if="!showAddFundsCarousel && !showAddFundsNotAvailableCarousel && !showKeysCarousel && !showWalletTransactionsCarousel">Tap the <fai class="charterRodeBtn" icon="plus"></fai> icon below to add funds to your wallet.</span></div>
      </div>
    </div>

    <div id="cr-wallet-carousel" class="carousel slide" data-bs-ride="carousel">
      <div class="carousel-inner">
        <div class="carousel-item active">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <div class="card charterRodeBtn" :class="[{'border-wallet-green': walletFunds > 10},{'border-wallet-warning': walletFunds > 2 && walletFunds <= 10},{'border-wallet-danger':walletFunds <= 2}]" style="border-radius: 50px;padding-left:5px;padding-right:5px;" v-on:click="slideToAddFunds">
                  <div class="card-body">
                    <div class="float-start" style="font-size: 18px;"><fai icon="dollar-sign" class="fa-fw" style="position: relative;top:1px;"></fai> Wallet Balance - <span class="" style="font-weight: bold;">{{walletFunds}} CRT</span></div>
                    <div class="float-end" style="font-size: 18px;"><fai :icon="['fas','plus']" class="fa-fw" style="position: relative;top:1px;"></fai></div>
                  </div>
                </div>

                <div class="card border-wallet mt-3 charterRodeBtn" style="border-radius: 50px;padding-left:5px;padding-right:5px;" v-on:click="slideToShowKeys">
                  <div class="card-body">
                    <div class="float-start" style="font-size: 18px;"><fai icon="key" class="fa-fw" style="position: relative;top:1px;"></fai> Charter Rode Keys</div>
                    <div class="float-end" style="font-size: 18px;"><fai :icon="['far','arrow-alt-circle-right']" class="fa-fw wallet-green" style="position: relative;top:1px;"></fai></div>
                  </div>
                </div>

                <div class="card border-wallet mt-3 charterRodeBtn" style="border-radius: 50px;padding-left:5px;padding-right:5px;" v-on:click="changePassword">
                  <div class="card-body">
                    <div class="float-start" style="font-size: 18px;"><fai icon="lock" class="fa-fw wallet-green" style="position: relative;top:1px;"></fai> Change Password</div>
                    <div class="float-end" style="font-size: 18px;"><fai :icon="['far','arrow-alt-circle-right']" class="fa-fw wallet-green" style="position: relative;top:1px;"></fai></div>
                  </div>
                </div>

                <div class="card border-wallet mt-3 charterRodeBtn" style="border-radius: 50px;padding-left:5px;padding-right:5px;" v-on:click="slideToWalletTransactions">
                  <div class="card-body">
                    <div class="float-start" style="font-size: 18px;"><fai icon="history" class="fa-fw wallet-green" style="position: relative;top:1px;"></fai> Wallet Transaction History</div>
                    <div class="float-end" style="font-size: 18px;"><fai :icon="['far','arrow-alt-circle-right']" class="fa-fw wallet-green" style="position: relative;top:1px;"></fai></div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
        <div class="carousel-item">
          <div>
            <a class="btn btn-outline-secondary btn-sm" style="border-radius: 25px;" v-on:click="slideToWalletHome"><fai :icon="['far','arrow-alt-circle-left']" class="fa-fw"></fai> Back</a>
          </div>
          <div class="container" v-show="showAddFundsNotAvailableCarousel">

            <div class="row mt-3">
              <div class="col-12">
                Well done {{user.firstName}}! Your wallet already is funded. To add additional funds, your wallet must have less than 50 CRT. The minimum exchange is 25 CRT<sup><fai icon="anchor" class="fine-print-sup"></fai></sup>.
              </div>
            </div>

            <div class="row mt-4">
              <div class="col-12" style="text-align: center;font-size: 14px;">
                <i><fai icon="anchor" style="color:#444"></fai> - All CRT Exchanges are via secure one-time credit card payments. An additional 4% fee applies to all CRT Exchanges to offset fees charged by our payment gateway provider, <a href="https://stripe.com/" target="_blank">Stripe</a>.</i>
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-sm-12" style="text-align: center;font-style: italic; font-size: 12px;">
                Each Charter Rode Wallet may hold a maximum of 75 CRT at any time
              </div>
            </div>
          </div>

          <div class="container" v-show="showAddFundsCarousel">
            <div class="row mt-3">
              <div class="col-12">
                <strong>The current CRT Exchange Rate is 1 CRT = $1 USD</strong><sup><fai icon="anchor" class="fine-print-sup"></fai></sup>. How much CRT would you like to fund to your wallet? You may add between 25 CRT and {{funding.maxCRTAllowed}} CRT.
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-12">
                <table style="width: 100%">
                  <tr>
                    <td style="vertical-align: middle;text-align: right;width:50%;">
                      <div class="input-group">
                        <input type="number" class="form-control" v-bind:min="funding.minCRTAllowed" v-bind:max="funding.maxCRTAllowed" step="1" v-model="funding.totalFundingCRT" aria-label="CRT" aria-describedby="crt-add-on" v-on:change="changeFundingCRT">
                        <span class="input-group-text" id="crt-add-on">CRT</span>
                      </div>
                    </td>
                    <td style="width: 1px;white-space: nowrap;vertical-align: middle;padding: 0px 5px;"><div style="text-align: center;font-weight: bold;"><fai icon="long-arrow-alt-left"></fai></div></td>
                    <td style="vertical-align: middle;text-align: left;width:50%;">
                      <div class="input-group">
                        <span class="input-group-text" id="usd-add-on">$</span>
                        <input type="number" class="form-control" v-model="funding.totalFundingUSD" aria-label="UD" aria-describedby="usd-add-on" disabled>
                        <span class="input-group-text" id="usd-add-on-after">USD<sup><fai icon="anchor" class="fine-print-sup"></fai></sup></span>
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
            </div>


            <div class="row mt-3">
              <div class="col-12">
                <div class="d-grid gap-2 col-12 mx-auto"><button class="btn btn-outline-primary" v-on:click="fundWallet">Add {{funding.totalFundingCRT}} CRT to my Wallet for ${{funding.totalFundingUSD}}</button></div>
              </div>
            </div>

            <div class="row mt-4">
              <div class="col-12" style="text-align: center;font-size: 14px;">
                <i><fai icon="anchor" style="color:#444"></fai> - All CRT Exchanges are via secure one-time credit card payments. An additional 4% fee applies to all CRT Exchanges to offset fees charged by our payment gateway provider, <a href="https://stripe.com/" target="_blank">Stripe</a>.</i>
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-sm-12" style="text-align: center;font-style: italic; font-size: 12px;">
                Each Charter Rode Wallet may hold a maximum of 75 CRT at any time
              </div>
            </div>

          </div>


          <div class="container" v-show="showKeysCarousel">
            <div class="row">
              <div class="col-12">
                <div class="card border-wallet-green mt-3" style="border-radius: 50px;padding-left:35px;padding-right:35px;" v-on:click="slideToShowKeys">
                  <div class="card-body">
                    <h5 class="card-title" style="font-weight: bold;"><fai icon="user-circle" class="fa-fw" style="position: relative;top:1px;"></fai> Charter Rode Sailor ID</h5>
                    <div style="font-size: 18px;">
                      <div><a v-if="resumeUrl" :href="resumeUrl" target="_blank" class="wallet-nav-link" style="padding-left: 1rem;"><code>{{user.id}}</code></a>&nbsp;<fai :icon="['far', 'copy']" class="fa-fw wallet-nav-link charterRodeBtn" @click.prevent="copySailorIdClip"></fai></div>
                    </div>
                    <div class="card-text">
                      <span style="font-weight: bold;"><fai icon="check"></fai> Safe to share your Sailor ID.</span> Your Charter Rode Sailor ID is your globally unique identifier on Charter Rode. It is a shorter key than your public encryption key that may be used to identify yourself and all transactions that you are a part of on Charter Rode. Feel free to share this ID with anyone that you would like to lookup your resume on Charter Rode, such as charter companies.
                    </div>

                  </div>
                </div>

                <div class="card border-wallet-green mt-3" style="border-radius: 50px;padding-left:35px;padding-right:35px;" v-on:click="slideToShowKeys">
                  <div class="card-body">
                    <h5 class="card-title charterRodeBtn" v-on:click="toggleShowFullKey" style="font-weight: bold;"><fai icon="key" class="fa-fw" style="position: relative;top:1px;"></fai> Charter Rode Public Encryption Key</h5>
                    <span v-if="gettingPublicKey"><fai icon="spinner" spin/> Retrieving public key...</span>
                    <div style="font-size: 18px;">
                      <code class="charterRodeBtn" v-if="!showFullPublicKey" v-html="userPublicKeyShortForDisplay" v-on:click="toggleShowFullKey"></code>
                      <code class="publicKeyFullContainer charterRodeBtn" v-else v-html="userPublicKey" v-on:click="toggleShowFullKey"></code>
                    </div>
                    <div class="card-text charterRodeBtn" v-if="!showFullPublicKey" v-on:click="toggleShowFullKey">
                      <span style="font-weight: bold;"><fai icon="check"></fai> Safe to share your public key.</span> Your public encryption key and private encryption key are used to create a digital signature for every transaction that you initiate or verify on Charter Rode. This public key is safe to share with anyone that is requesting your public key for identity verification on Charter Rode. Note that your public key is converted to a more compact format (base64 encoding) on Charter Rode. Tap here to show your full public key.
                    </div>

                  </div>
                </div>

                <div class="card border-wallet-danger mt-3" style="border-radius: 50px;padding-left:35px;padding-right:35px;" v-on:click="slideToShowKeys">
                  <div class="card-body">
                    <h5 class="card-title" style="font-weight: bold;"><fai icon="key" class="fa-fw" style="position: relative;top:1px;"></fai> Charter Rode Private Encryption Key</h5>
                    <div style="font-size: 18px;"> <code>****************** ... *****************</code></div>
                    <div class="card-text">
                      <span style="font-weight: bold;"><fai icon="exclamation-triangle"></fai> NEVER SHARE YOUR PRIVATE KEY! Charter Rode will NEVER ask for your private key.</span> Your private key is securely stored using military grade encryption both in transit and at rest by Charter Rode with redundant backups for disaster recovery. Your private key is used during the signing process of each transaction so that anyone in the world that has your public key can verify that you signed a transaction as either the creator of the transaction or as the verification authority in the case of crewmate verifications. To request a one-time copy of your private key please <a style="text-decoration: none;color:#888;" href="https://www.charterrode.com/request-my-private-key" target="_blank">follow the process here</a>.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="container" v-show="showWalletTransactionsCarousel">
            <div class="row" v-if="gettingWalletTransactions">
              <div class="col-12">
                <div class="wallet-tx-lookup-spinner mt-lg-5"><fai icon="spinner" spin/> Retrieving transaction history...</div>
              </div>
            </div>

            <div class="row" v-if="!gettingWalletTransactions">
              <div class="col-12">
                <div style="margin-top:15px;" class="table-responsive-xxl">
                  <VTable
                      :data="walletTransactions"
                      ref="walletTransactionsTable"
                      class="table caption-top"
                      selectionMode="single"
                      selectedClass=""
                      @stateChanged="viewTransaction($event.selectedRows)">
                    <template #head>
                      <tr>
                        <th style="width:5px;">&nbsp;</th>
                        <th style="white-space: nowrap">Tx ID</th>
                        <th style="white-space: nowrap">Date</th>
                        <th style="white-space: nowrap">Amount</th>
                        <th style="white-space: nowrap;text-align: right;">Wallet Balance</th>
                      </tr>
                    </template>
                    <template #body="{ rows }">
                      <tr v-show="rows.length == 0">
                        <td>&nbsp;</td>
                        <td colspan="5" style="text-align: center">Hmm on the hard are we? <a class="charterRodeLink" v-on:click="slideToAddFunds">Let's start by funding your wallet with some CRTs</a></td>
                      </tr>
                      <VTr v-for="row in rows" :key="row.id" :row="row">
                        <td style="vertical-align: middle">&nbsp;</td>
                        <td>{{getTxDisplayIdFor(row)}}</td>
                        <td>{{formatDateTime(row.createdAt)}}</td>
                        <td>
                          <span class="text-danger" v-if="row.amountCRT < 0">({{row.amountCRT}} CRT)</span>
                          <span class="text-success" v-else>+{{row.amountCRT}} CRT</span>
                        </td>
                        <td style="text-align: right;">{{row.crtBalanceAfterTransaction}} CRT</td>
                      </VTr>
                    </template>
                  </VTable>
                </div>

              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

  </div>



  <changePasswordModal
      ref="changePasswordModal"
      v-on:modalClose="changePasswordModalClose"></changePasswordModal>

  <paymentProcessingModal
      ref="paymentProcessingModal"
      v-on:paymentSuccessful="paymentSuccessful"></paymentProcessingModal>

  <teleport to="body">
    <div class="position-fixed bottom-0 end-0 p-3 cr-z-index-5">
      <div id="copySailorIdToast" class="toast hide" role="alert" aria-live="assertive" aria-atomic="true">
        <div class="toast-header">
          <strong class="me-auto">Sailor Id Copied!</strong>
          <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
        </div>
        <div class="toast-body">Your Sailor Id has been copied to your clipboard</div>
      </div>
    </div>
  </teleport>

</template>

<script>
import api from "../../api/api";
import {Carousel,Toast} from "bootstrap"

export default {
  name: 'Wallet',
  data() {
    return {
      user:  this.$store.state.user,
      userPublicKey: this.$store.state.user.publicKey ? this.$store.state.user.publicKey : undefined,
      publicKey: undefined,
      resumeUrl: undefined,
      showAddFundsCarousel: false,
      showAddFundsNotAvailableCarousel: false,
      showKeysCarousel: false,
      showWalletTransactionsCarousel: false,
      gettingPublicKey: false,
      gettingWalletTransactions: false,
      showFullPublicKey: false,
      funding: {
        maxCRTAllowed: 75,
        minCRTAllowed: 25,
        totalFundingCRT: '25',
        totalFundingUSD: '26.00' // for the processing fee of credit cards...
      },
      walletTransactions: this.$store.state.user.walletTransactions ? this.$store.state.user.walletTransactions : [],
    }
  },
  mounted() {
    let myCarousel = document.querySelector('#cr-wallet-carousel')
    this.carousel = new Carousel(myCarousel, {
      interval: false,
      wrap: false
    })
    // figure out the max funding amount, we allow a max of 75 CRT in each wallet at any given time....
    this.funding.maxCRTAllowed = 75 - this.walletFunds
    this.resumeUrl = 'https://sailingresume.io/'+this.user.id
  },
  methods: {
    clearActiveSlides() {
      this.showAddFundsCarousel = false
      this.showAddFundsNotAvailableCarousel = false
      this.showKeysCarousel = false
      this.showWalletTransactionsCarousel = false
    },
    slideToWalletHome() {
      this.carousel.to(0)
      this.clearActiveSlides()
      this.showAddFundsCarousel = false
      this.showAddFundsNotAvailableCarousel = false
      this.showKeysCarousel = false
      this.showWalletTransactionsCarousel = false
    },
    slideToAddFunds() {
      this.clearActiveSlides()
      let currentWalletFunds = parseInt(this.walletFunds)
      if ( currentWalletFunds + 25 >= 75 ) {
        this.showAddFundsNotAvailableCarousel = true
      } else {
        this.showAddFundsCarousel = true
      }
      this.carousel.next()
    },
    slideToShowKeys() {
      this.clearActiveSlides()
      this.showKeysCarousel = true
      this.carousel.next()

      if ( this.userPublicKey == undefined ) {
        this.gettingPublicKey = true
        api.getMyPublicKey().then(thePublicKey => {
          this.userPublicKey = thePublicKey
          this.$store.state.user.publicKey = thePublicKey
          this.gettingPublicKey = false
        })
      }
    },
    slideToWalletTransactions() {
      this.clearActiveSlides()
      this.showWalletTransactionsCarousel = true
      this.carousel.next()

      if ( this.$store.state.user.walletTransactions == undefined ) {
        this.gettingWalletTransactions = true
        api.getMyWalletTransactions().then(response => {
          if ( response && response.walletTxs ) {
            let theWalletTransactions = response.walletTxs
            if ( theWalletTransactions && theWalletTransactions.length > 0 ) {

              // sort all transactions with the most recent on top like  bank would do online portal
              theWalletTransactions.sort(function (a, b) {
                if (a.createdAt > b.createdAt) return -1
                if (a.createdAt < b.createdAt) return 1
                return 0
              })

              this.walletTransactions = theWalletTransactions
              this.$store.state.user.walletTransactions = theWalletTransactions
            } else {
              this.walletTransactions = []
              this.$store.state.user.walletTransactions = []
            }
          } else {
            this.walletTransactions = []
            this.$store.state.user.walletTransactions = []
          }
          if ( response && response.currentWalletFundsCRT ) {
            // update user wallet balance in localStorage...
            this.$store.commit('updateUserWalletBalance', response.currentWalletFundsCRT)
          }
          this.gettingWalletTransactions = false
        })
      }
    },
    toggleShowFullKey() {
      this.showFullPublicKey = !this.showFullPublicKey
    },
    changeFundingCRT() {
      window.document.activeElement.blur()
      if ( this.funding.totalFundingCRT ) {
        // parse and drop any decimals as we want whole positive integers only!
        try {
          this.funding.totalFundingCRT = parseInt(Math.round(this.funding.totalFundingCRT))
        } catch (err) {
          this.funding.totalFundingCRT = ''+this.funding.minCRTAllowed // min
        }
        try {
          if ( parseInt(this.funding.totalFundingCRT) > this.funding.maxCRTAllowed ) {
            this.funding.totalFundingCRT = ''+this.funding.maxCRTAllowed // max
          } else if ( parseInt(this.funding.totalFundingCRT) < this.funding.minCRTAllowed ) {
            this.funding.totalFundingCRT = ''+this.funding.minCRTAllowed // min
          }
        } catch (err) {
          this.funding.totalFundingCRT = ''+this.funding.minCRTAllowed // min
        }
      } else {
        this.funding.totalFundingCRT = ''+this.funding.minCRTAllowed // min
      }
      this.funding.totalFundingUSD = parseFloat(Math.round((this.funding.totalFundingCRT * 1.04 + Number.EPSILON) * 100) / 100).toFixed(2) // for the stripe and other fees.
    },
    fundWallet() {
      window.document.activeElement.blur()
      this.$refs.paymentProcessingModal.activatePaymentModal(this.funding.totalFundingCRT, this.funding.totalFundingUSD)
    },
    paymentSuccessful() {
      this.slideToWalletHome()
      let self = this
      setTimeout(function () {
          api.getMe().then(response => {
            if (response.user && response.user.firstName && response.user.lastName) {
              // all good let them go to home!
              self.$store.commit('setUser', response.user)
            }
          })
      }, 3500)
    },
    viewTransaction(selectedRows) {
      if ( selectedRows && selectedRows.length > 0 ) {
        console.log(selectedRows[0].id)
        // alert(selectedRows.id)
      }
    },
    formatDateTime(aDateInSeconds) {
      return new Date(aDateInSeconds).toLocaleString("en-US")
    },
    getTxDisplayIdFor(row) {
      if ( row.txId ) {
        if ( row.identityVerificationTx ) {
          return row.txId +' (Identity Verification)'
        } else if ( row.tripVerificationTx ) {
          return row.txId +' (Trip Verification)'
        }
        return row.txId
      } else if ( row.award ) {
        if ( row.awardDesc && row.awardDesc.trim().length > 0 ) {
          return row.awardDesc
        }
      }
      return '--'
    },
    changePassword() {
      this.$refs.changePasswordModal.activateModal()
    },
    changePasswordModalClose() {
      // nothing to do here!
    },
    copySailorIdClip() {
      var elementToCopy = document.createElement("input");
      elementToCopy.value = this.user.id;
      document.body.appendChild(elementToCopy);
      elementToCopy.select();
      document.execCommand("copy");
      document.body.removeChild(elementToCopy);
      let copyToClipToast = new Toast(document.getElementById('copySailorIdToast'))
      copyToClipToast.show()
    }
  },
  computed: {
    userPublicKeyShortForDisplay() {
      if ( this.userPublicKey ) {
        let keyForDisplay = this.userPublicKey
        keyForDisplay = keyForDisplay.replaceAll('\n', '')
        keyForDisplay = keyForDisplay.replace('-----BEGIN RSA PUBLIC KEY-----', '')
        keyForDisplay = keyForDisplay.replace('-----END RSA PUBLIC KEY-----', '')
        return keyForDisplay.substr(0, 18) + ' ... '+keyForDisplay.substr(keyForDisplay.length - 17, keyForDisplay.length)
      }
      return ''
    },
    walletFunds() {
      if ( this.$store.state.user && this.$store.state.user.currentWalletFundsCRT ) {
        return this.$store.state.user.currentWalletFundsCRT
      }
      return '0'
    }
  }
}
</script>

<style>

.wallet-green {
  color: #198754;
}


.border-wallet {
  border-color: #badbcc;
  /*background-color: #d1e7dd;*/
  color: #0f5132!important;
}

.border-wallet-green {
  border-color: #badbcc;
  background-color: #d1e7dd;
  color: #0f5132!important;
}

.border-wallet-warning {
  border-color: #ffc10780;
  background-color: #ffc10740;
  color: #523e02!important;
}

.border-wallet-danger {
  border-color: #dc354580;
  background-color: #dc354540;
  color: #6a1a21!important;
}

code {
  display: block;
  white-space: pre-wrap;
  color: #444;
  font-style: italic;
}

.wallet-tx-lookup-spinner {
  text-align: center;
  margin-top:5px;
  font-size:22px;
  color:#666;
  font-weight: normal;
  z-index: 9999;
}

.publicKeyFullContainer {
  font-size: 6px;
}

.wallet-nav-link {
  color: #0d6efd;
  text-decoration: none;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;
}

@media (min-width: 544px) {
  .publicKeyFullContainer {
    font-size: 10px;
  }
}
@media (min-width: 768px) {
  .publicKeyFullContainer {
    font-size: 14px;
  }
}
@media (min-width: 992px) {
  .publicKeyFullContainer {
    font-size: 16px;
  }
}
</style>
