<template>
  <div id="process-sign-in"><fai icon="spinner" spin/> Please wait while we clean up!</div>
</template>

<script>

export default {
  name: 'ProcessSignOut',
  components: {

  },
  mounted() {
    this.$store.commit('setLoggedOut')
    this.$router.push('/')
  },
  methods: {

  }
}
</script>

<style>
#process-sign-in {
  text-align: center;
  margin-top:50px;
  font-size:24px;
  color:#666;
  font-weight: normal;
}
</style>
