import Amplify, { Auth } from 'aws-amplify'
import store from "../store/store"
import axios from "axios"
import * as CryptoJS from 'crypto-js'

const config = {
    region: process.env.VUE_APP_COGNITO_USER_POOL_REGION,
    userPoolId: process.env.VUE_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.VUE_APP_COGNITO_USER_POOL_CLIENT_ID,
    endpoint: process.env.VUE_APP_COGNITO_USER_POOL_PROXY_ENDPOINT,
    clientMetadata: {'yek-ipa-x':process.env.VUE_APP_COGNITO_USER_POOL_PROXY_API_KEY}
}

Amplify.configure({Auth:config})

let auth = {

    signUp: async (email, password) => {
        try {
            const { user } = await Auth.signUp({ username: email, password: password, attributes: {email}})
            return {error:false, username: user.getUsername()}
        } catch (error) {
            if ( error && error.message ) {
                return {error:true, message:error.message}
            }
            console.log('error signing up:', error)
        }
    },

    resendConfirmationCode: async (email) => {
        try {
            await Auth.resendSignUp(email)
        } catch (err) {
            console.log('error resending code: ', err)
        }
    },

    confirmSignUp: async (email, code) => {
        try {
            let response = await Auth.confirmSignUp(email, code, {forceAliasCreation: false})
            if ( response === 'SUCCESS' ) {
                return true
            }
        } catch (error) {
            console.log('error confirming sign up', error)
        }
        return false
    },

    signIn: async (username, password) => {
        try {
            const user = await Auth.signIn(username, password)
            auth.encodeTokens(user.username, user.signInUserSession.idToken.jwtToken, user.signInUserSession.accessToken.jwtToken, user.signInUserSession.refreshToken.token)
            localStorage.setItem('jwtToken', user.signInUserSession.idToken.jwtToken)
            auth.clearCognitoUser()
            return {jwtToken: user.signInUserSession.idToken.jwtToken}
        } catch (error) {
            console.log('error signing in', error)
            if ( error && error.code && error.code === 'NetworkError' ) {
                return {error: 'network-error', message:'Check your network connection, it appears you may be offline.'}
            }
            if ( error && error.code && error.code === 'NotAuthorizedException' &&
                 error && error.message && error.message === 'User is disabled.') {
                return {error: 'user-disabled', message:'Your account has been suspended. Please contact support@charterrode.com for more information.'}
            }
            if ( error && error.code && error.code === 'UserNotConfirmedException' ) {
                return {error:'verify_email'}
            }
            return null
        }
    },

    signOut: async () => {
        try {
            auth.createCognitoUser()
            await Auth.signOut()
            store.commit('setLoggedOut')
            localStorage.removeItem('iar')
            return true
        } catch (error) {
            console.log('error signing out: ', error)
            return false
        }
    },

    changePassword: async (currentPassword, newPassword) => {
        try {
            auth.createCognitoUser()
            let user = await Auth.currentAuthenticatedUser()
            await Auth.changePassword(user, currentPassword, newPassword)
            auth.clearCognitoUser()
            return null
        } catch (error) {
            auth.clearCognitoUser()
            console.log('error changing password: ', error)
            return error
        }
    },

    forgotPassword: async (username) => {
        try {
            await Auth.forgotPassword(username)
            return {error:false}
        } catch (error) {
            console.log('error sending forgot password: ', error)
            return {error:true, message:error.message ? error.message : undefined}
        }
    },

    forgotPasswordSubmit: async (username, code, newPassword) => {
        try {
            await Auth.forgotPasswordSubmit(username, code, newPassword)
            return {error:false}
        } catch (error) {
            console.log('error forgot password submit: ', error)
            return {error:true, message:error.message ? error.message : undefined}
        }
    },

    refreshToken: async () => {
        try {
            // console.log('-------------STARTING REFRESHING TOKEN-------------')
            const response = await axios({
                method: 'POST',
                headers: {
                    "X-Amz-Target": "AWSCognitoIdentityProviderService.InitiateAuth",
                    "Content-Type": "application/x-amz-json-1.1",
                },
                url: config.endpoint,
                data: {
                    ClientId: config.userPoolWebClientId,
                    AuthFlow: 'REFRESH_TOKEN_AUTH',
                    AuthParameters: {
                        REFRESH_TOKEN: auth.decodeTokens().refreshToken
                    },
                    ClientMetadata: config.clientMetadata
                }
            })
            let jwtToken = undefined
            if ( response.data.AuthenticationResult.IdToken ) {
                const idTokenData = decodePayload(localStorage.getItem('jwtToken'))
                auth.encodeTokens(idTokenData['cognito:username'], response.data.AuthenticationResult.IdToken, response.data.AuthenticationResult.AccessToken, auth.decodeTokens().refreshToken)
                jwtToken = response.data.AuthenticationResult.IdToken
            }
            // console.log('-------------REFRESHING TOKEN COMPLETE-------------')
            return jwtToken
        } catch (error) {
            console.log('error refreshing token: ', error)
            console.log(JSON.stringify(error))
        }
        return null
    },

    createCognitoUser: () => {
        try {
            let tokens = auth.decodeTokens()
            const idTokenData = decodePayload(tokens.idToken);
            const accessTokenData = decodePayload(tokens.accessToken);
            // create an object with the UserPoolId and ClientId
            localStorage.setItem('CognitoIdentityServiceProvider.'+config.userPoolWebClientId+'.LastAuthUser', idTokenData['cognito:username'])
            localStorage.setItem('CognitoIdentityServiceProvider.'+config.userPoolWebClientId+'.'+idTokenData['cognito:username']+'.idToken', tokens.idToken)
            localStorage.setItem('CognitoIdentityServiceProvider.'+config.userPoolWebClientId+'.'+idTokenData['cognito:username']+'.accessToken', tokens.accessToken)
            localStorage.setItem('CognitoIdentityServiceProvider.'+config.userPoolWebClientId+'.'+idTokenData['cognito:username']+'.refreshToken', tokens.refreshToken)
            localStorage.setItem('CognitoIdentityServiceProvider.'+config.userPoolWebClientId+'.'+idTokenData['cognito:username']+'.clockDrift', ''+calculateClockDrift(accessTokenData['iat'], idTokenData['iat'])+'')
        } catch (error) {
            console.log('error refreshing token: ', error)
            console.log(JSON.stringify(error))
        }
    },

    clearCognitoUser: () => {
        const idTokenData = decodePayload(localStorage.getItem('jwtToken'))
        localStorage.removeItem('CognitoIdentityServiceProvider.'+config.userPoolWebClientId+'.LastAuthUser')
        localStorage.removeItem('CognitoIdentityServiceProvider.'+config.userPoolWebClientId+'.'+idTokenData['cognito:username']+'.idToken')
        localStorage.removeItem('CognitoIdentityServiceProvider.'+config.userPoolWebClientId+'.'+idTokenData['cognito:username']+'.accessToken')
        localStorage.removeItem('CognitoIdentityServiceProvider.'+config.userPoolWebClientId+'.'+idTokenData['cognito:username']+'.refreshToken')
        localStorage.removeItem('CognitoIdentityServiceProvider.'+config.userPoolWebClientId+'.'+idTokenData['cognito:username']+'.clockDrift')
        localStorage.removeItem('CognitoIdentityServiceProvider.'+config.userPoolWebClientId+'.'+idTokenData['cognito:username']+'.userData')
        localStorage.removeItem('amplify-signin-with-hostedUI')
    },

    encodeTokens: (anId, idToken, accessToken, refreshToken) => {
        if ( !anId || !idToken || !accessToken || !refreshToken ) {
            throw  "Missing one or more tokens"
        }
        let tokens = idToken+':'+accessToken+':'+refreshToken
        let encrypted = CryptoJS.AES.encrypt(tokens,anId).toString()
        localStorage.setItem('iar', encrypted)
    },

    decodeTokens: () => {
        const idTokenData = decodePayload(localStorage.getItem('jwtToken'))
        let encrypted = localStorage.getItem('iar')
        let tokenStr = CryptoJS.AES.decrypt(encrypted, idTokenData['cognito:username']).toString(CryptoJS.enc.Utf8);
        let tokens = tokenStr.split(":")
        return {
            idToken: tokens[0],
            accessToken: tokens[1],
            refreshToken: tokens[2]
        }
    }
}

export const decodePayload = (jwtToken) => {
    const payload = jwtToken.split('.')[1];
    try {
        return JSON.parse(Buffer.from(payload, 'base64').toString('utf8'));
    } catch (err) {
        return {};
    }
}
export const calculateClockDrift = (iatAccessToken, iatIdToken) => {
    const now = Math.floor(new Date() / 1000);
    const iat = Math.min(iatAccessToken, iatIdToken);
    return now - iat;
}
export default auth