<template>
  <div class="container mt-3">
<!--    <div class="row mt-3 mb-3">-->
<!--      <div class="col-sm-12">-->
<!--        <h4>Step 1 of 4: Create Account</h4>-->
<!--        <div class="charterRodeHelpText">In order to complete create an account, we need a email address and a password. Passwords must have both lower and upper case letters, a number, at least 8 characters, and a special character. A verification code will be sent to the email address provided before you may continue.</div>-->
<!--      </div>-->
<!--    </div>-->

    <div v-if="doingLogIn" id="process-sign-in" class="sign-in-spinner"><fai icon="spinner" spin/> Please wait while we get you signed in!</div>
    <div v-if="doingLogOut" id="process-sign-in" class="sign-in-spinner"><fai icon="spinner" spin/> Please wait while we get you signed out!</div>

    <div v-if="!doingLogIn && !doingLogOut" class="row justify-content-center">
      <div class="col-auto">
        <div class="card mt-3 bg-light">
          <div class="card-header" style="text-align: center;">
            <h5 class="card-title" style="margin-bottom: 0px;">Charter Rode Sign Up</h5>
          </div>
          <div class="card-body">
            <div class="mb-3" style="text-align: center;">
              <fai icon="user-circle" style="font-size: 72px; color: #ccc"></fai>
            </div>

            <h6 v-if="!showVerificationCode" class="card-subtitle mb-2 text-muted" style="padding-right: 40px;">Create a new account with your email</h6>
            <h6 v-else class="card-subtitle mb-2 text-muted" style="padding-right: 40px;">Enter verification code sent to {{username}}</h6>

            <div v-if="errorDuringSignup" class="alert alert-danger">{{signUpErrorMessage}}</div>
            <div v-if="invalidVerificationCode" class="alert alert-danger">Verification code is required.</div>
            <div v-if="verificationCodeMismatch" class="alert alert-danger">Verification code does not match, please try again.</div>

            <div v-if="!showVerificationCode">
              <div class="card-text pt-3" style="color: #666;">
                <form autocomplete="off" novalidate v-on:submit.prevent="noop">
                  <div class="mb-3">
                    <input type="email" autocomplete="off" class="form-control" id="emailID" placeholder="francis.drake@gmail.com" v-model="username" @keyup.enter="doSignUp">
                  </div>
                  <div class="mb-3">
                    <input type="password" autocomplete="new-password" class="form-control" id="userPassword" placeholder="Password" v-model="password" @keyup.enter="doSignUp">
                    <a style="font-size:14px;text-decoration: none;" href="#">&nbsp;</a>
                  </div>
                </form>
              </div>
              <div class="d-grid gap-2">
                <a class="btn btn-primary" v-on:click="doSignUp" :disabled="doingSignUp"><fai v-show="doingSignUp" icon="spinner" spin/>  Create Account</a>
              </div>
            </div>
            <div v-else>
              <div class="card-text pt-3" style="color: #666;">
                <form autocomplete="off" novalidate v-on:submit.prevent="noop">
                  <div class="mb-3">
                    <input type="text" autocomplete="off" class="form-control" id="verificationCode" placeholder="code" v-model="verificationCode" @keyup.enter="verifyCode">
                  </div>
                </form>
              </div>
              <div class="d-grid gap-2">
                <a class="btn btn-primary" v-on:click="verifyCode" :disabled="verifyingCode"><fai v-show="verifyingCode" icon="spinner" spin/> Verify Code</a>
              </div>
            </div>


            <div v-if="!showVerificationCode" style="margin-top: 15px;font-size:12px;color:#888;">By proceeding, you agree to our most recent <a style="text-decoration: none;" href="https://www.charterrode.com/terms-of-use/" target="_blank">Terms of Use</a> and <a style="text-decoration: none;" href="https://www.charterrode.com/privacy-policy/" target="_blank">Privacy Policy</a>.</div>

            <div class="card-text mt-2" style="text-align: center;font-size:14px;">Already have an account?&nbsp;<router-link to="/sign-in" style="text-decoration: none;">Sign in</router-link></div>
            <div class="card-text mt-2" style="text-align: center;font-size:14px;">Need Help? Read the <a href="https://www.charterrode.com/article/create-charter-rode-account" style="text-decoration: none;" target="_blank">Create an Account</a> article.</div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import auth from "../../api/auth"
import api from "../../api/api";

export default {
  name: 'SignUp',
  data() {
    return {
      username: undefined,
      password: undefined,
      doingLogIn: false,
      doingLogOut: false,
      doingSignUp: false,
      showVerificationCode: false,
      errorDuringSignup: false,
      signUpErrorMessage: undefined,
      invalidVerificationCode: false,
      verificationCodeMismatch: false,
      verificationCode: undefined,
      verifyingCode: false
    }
  },
  mounted() {
    // force a logout
    if ( this.$store.state.jwtToken != undefined ) {
      this.doingLogOut = true
      auth.signOut().then(signedOut => {
        if (signedOut) {
          this.$router.push('/sign-up')
          this.doingLogOut = false
        }
      })
      return
    }
    // check to see if we need to be verifying email from login screen...
    if ( this.$store.state.verifyUser && this.$store.state.verifyUser.username ) {
      this.username = this.$store.state.verifyUser.username
      this.password = this.$store.state.verifyUser.password
      this.showVerificationCode = true
      this.$store.commit('clearVerifyUser')
      auth.resendConfirmationCode(this.username)
      setTimeout(function () {
        document.getElementById('verificationCode').focus()
      }, 250)
    } else {
      document.getElementById('emailID').focus()
    }
  },
  methods: {
    doSignUp() {
      this.errorDuringSignup = false
      this.doingSignUp = false
      this.doingLogIn = false
      this.doingLogOut = false
      this.invalidVerificationCode = false
      this.verificationCodeMismatch = false
      this.verifyingCode = false
      this.signUpErrorMessage = undefined
      this.verificationCode = undefined
      // do form validation!
      if ( this.username == undefined || this.username.trim().length <= 0 ) {
        this.errorDuringSignup = true
        this.signUpErrorMessage = "Missing email address"
        return
      }
      if ( this.password == undefined || this.password.trim().length <= 0 ) {
        this.errorDuringSignup = true
        this.signUpErrorMessage = "Missing password"
        return
      }

      let el = document.createElement('div')
      let domString = '<div id="sign-in-backdrop" class="modal-backdrop fade in" style=""></div>'
      el.innerHTML =  domString
      document.body.appendChild(el.firstChild);

      this.doingSignUp = true
      auth.signUp(this.username, this.password).then(response => {
        let signInBackDrop = document.getElementById('sign-in-backdrop')
        signInBackDrop.parentNode.removeChild(signInBackDrop)

        if ( response && response.error ) {
          // show error message on screen.
          this.doingSignUp = false
          this.signUpErrorMessage = response.message
          this.errorDuringSignup = true
        } else if (response && response.username ) {
          // switch to verification code...
          this.doingSignUp = false
          this.showVerificationCode = true
          window.setTimeout(function() {
            document.getElementById('verificationCode').focus()
          }, 750)
        } else {
          // error?
          this.doingSignUp = false
          this.errorDuringSignup = true
          this.signUpErrorMessage = "Unknown error, please try again"
        }
      })
    },
    verifyCode() {
      this.invalidVerificationCode = false
      this.verificationCodeMismatch = false
      if ( this.verificationCode == undefined || this.verificationCode.trim().length < 6 ) {
        this.invalidVerificationCode = true
        return
      }

      let el = document.createElement('div')
      let domString = '<div id="sign-in-backdrop" class="modal-backdrop fade in" style=""></div>'
      el.innerHTML =  domString
      document.body.appendChild(el.firstChild);

      this.verifyingCode = true
      auth.confirmSignUp(this.username, this.verificationCode).then(response => {
        let signInBackDrop = document.getElementById('sign-in-backdrop')
        signInBackDrop.parentNode.removeChild(signInBackDrop)

        if ( response ) {
          this.doingLogIn = true
          auth.signIn(this.username, this.password).then(response => {
            if ( response && response.jwtToken && response.jwtToken.length > 0 ) {
              this.$store.commit('setJWTToken', response.jwtToken)

              // alert(theJwtToken)
              api.getMe().then(response => {
                if (response.user && response.user.firstName && response.user.lastName) {
                  // all good let them go to home!
                  this.$store.commit('setUser', response.user)

                  // now get the drop downs
                  api.getUIDropdowns().then(uiDropdowns => {
                    if (uiDropdowns) {
                      this.$store.commit('setUiDropdowns', uiDropdowns)
                    }
                    this.$router.push('/')
                  }).catch(error => {
                    console.log(error)
                    this.$router.push('/sign-out')
                  })
                } else if ( response.createNewProfile ) {
                  // need to create user
                  // get rid of the static back drop
                  // now get the drop downs
                  api.getUIDropdowns().then(uiDropdowns => {
                    if ( uiDropdowns ) {
                      this.$store.commit('setUiDropdowns', uiDropdowns)
                    }
                    this.$store.commit('startCreateUserFlow')
                    this.$router.push('/createUser')
                  }).catch(error => {
                    console.log(error)
                    this.$router.push('/sign-out')
                  })
                } else {
                  // not sure what happened, likely server-side is down....
                  this.$router.push('/maintenance')
                }
              }).catch(error => {
                console.log(error)
                this.$router.push('/sign-out')
              })
            } else {
              this.doingLogIn = false
              this.$router.push('/sign-in')
            }
          })
        } else {
          // error?
          this.verificationCodeMismatch = true
          this.verifyingCode = false
        }
      })
    }
  },
  components: {

  }
}
</script>

<style>
.sign-in-spinner {
  text-align: center;
  margin-top:50px;
  font-size:24px;
  color:#666;
  font-weight: normal;
  z-index: 9998;
}

#sign-in-backdrop {
  z-index: 9999;
}

</style>
