<template>
  <form autocomplete="off" novalidate v-on:submit="noop">
    <input autocomplete="false" name="hidden" type="text" style="display:none;">
    <div class="container" v-show="transactionSubmittedSuccessfully">
      <div class="row mt-3 mb-3">
        <div class="col-sm-12">
          <h4>New Logbook Entry Complete!</h4>
          <div class="charterRodeHelpText">Thank you {{user.firstName}} for logging your most recent adventure! Be on the lookout for an email notification to <i>{{user.email}}</i> when your transaction has been verified and confirmed on Charter Rode!</div>
        </div>
      </div>
      <div class="row pb-5 mb-5">
        <div class="col-sm-12">
          <div class="d-grid gap-2 col-8 mx-auto">
            <button class="btn btn-lg btn-outline-primary text-uppercase" v-on:click="addAnotherLogEntry">Add Another Logbook Entry</button>
          </div>
        </div>
      </div>
    </div>

    <div class="container" v-show="activelySubmittingTransaction">
      <div class="row mt-3 mb-3">
        <div class="col-sm-12">
          <h4>New Logbook Entry</h4>
          <div class="charterRodeHelpText">Let's log your most recent adventure!</div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="tx-submit-spinner mt-lg-5"><fai icon="spinner" spin/> Please wait while your trip is submitted ...</div>
        </div>
      </div>
    </div>

    <div class="container" v-show="!transactionSubmittedSuccessfully && !activelySubmittingTransaction && !showEditMultiDayTrip">
      <div class="row mt-3 mb-3">
        <div class="col-sm-12">
          <h4>New Logbook Entry</h4>
          <div class="charterRodeHelpText">Let's log your most recent adventure!</div>
        </div>
      </div>

      <div class="row" v-show="transactionSubmittedHadError">
        <div class="col-sm-12">
          <div class="alert alert-danger" role="alert">There was an issue with submitting your transaction. Please re-verify your information below and try again.</div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-4">
          <div class="mb-4">
              <v-date-picker mode="date" v-model="tripInfo.date" :popover="{ visibility: 'click' }" min-date="2000-01-01" :max-date="maxDateForTrip">
                <template v-slot="{ inputValue, inputEvents }">
                  <div class="form-floating">
                    <input type="text" class="form-control charterRodeBtn" id="date" name="date" :value="inputValue" v-on="inputEvents" v-bind:class="{'is-valid' : validation.date, 'is-invalid': validation.date == false}">
                    <label for="date">Date</label>
                    <div class="invalid-feedback">You must let use know when you were out.</div>
                  </div>
                </template>
              </v-date-picker>

            <div class="form-check form-switch">
              <input id="multiDayTripCheck" class="form-check-input charterRodeBtn" type="checkbox" v-model="tripInfo.multiDayTrip" v-on:change="onVerificationAuthorityChange">
              <label class="form-check-label charterRodeBtn multi-day-trip-label" for="multiDayTripCheck">
                Multi-day trip
              </label>
            </div>
          </div>
        </div>

        <div class="col-lg-4">
          <div class="form-floating mb-4">
            <select class="form-control form-control-sm" id="tripRole" name="tripRole" v-model="tripInfo.tripRole" v-bind:class="{'is-valid' : validation.tripRole, 'is-invalid': validation.tripRole == false}" @change="onRoleChange">
              <option value="please_select" disabled selected>Please Select</option>
              <option v-for="item in uiDropdowns.roleType" :key="item.value" :value="item.value">{{item.label}}</option>
            </select>
            <label for="tripRole">Your Role</label>
            <div class="invalid-feedback">Captain or Crew? Hmm hard to decide.</div>
          </div>
        </div>

        <div class="col-lg-4">
          <div class="form-floating mb-4">
            <select class="form-control form-control-sm" id="vessel" name="vessel" v-model="tripInfo.vessel" @change="onVesselChange($event)" v-bind:class="{'is-valid' : validation.vessel, 'is-invalid': validation.vessel == false}">
              <option value="please_select" disabled>Please Select</option>
              <optgroup label="Your Favorite Vessels" v-show="favoriteVessels">
                <option v-for="item in favoriteVessels" :key="item.id" :value="item.id">{{item.fullVesselName}}</option>
              </optgroup>
              <option value="vessel_search">Charter Vessel Search</option>
              <option value="add_vessel">Add New Vessel</option>
            </select>
            <label for="vessel">Vessel</label>
            <div class="invalid-feedback">Not going to get very far off of land are you?</div>
          </div>
        </div>
      </div>

      <div class="row">

        <div class="col-lg-4" v-show="tripInfo.multiDayTrip">
          <div class="form-floating mb-4">
            <input type="number" class="form-control"  id="multiDayTripDays" name="multiDayTripDays" v-model="tripInfo.multiDayTripDays" min="2" max="30" step="1" v-bind:class="{'is-valid' : validation.multiDayTripDays, 'is-invalid': validation.multiDayTripDays == false}" v-on:change="onVerificationAuthorityChange">
            <label for="multiDayTripDays">Number of Days</label>
            <div id="multiDayTripDaysHelpBlock" class="form-text">Days you sailed at least four hours. Anchoring in a beautiful bay for a week does not count!</div>
            <div class="invalid-feedback">You must let use know how many days you sailed.</div>
          </div>
        </div>

        <div class="col-lg-4" v-show="tripInfo.multiDayTrip">
          <div class="form-floating mb-4">
            <input type="number" class="form-control"  id="multiDayTripNights" name="multiDayTripNights" v-model="tripInfo.multiDayTripNights" min="0" max="29" step="1" v-bind:class="{'is-valid' : validation.multiDayTripNights, 'is-invalid': validation.multiDayTripNights == false}" v-on:change="onVerificationAuthorityChange">
            <label for="multiDayTripNights">Number of Nights</label>
            <div id="multiDayTripNightsHelpBlock" class="form-text">Not docked or at anchor. You sailed at least four continuous hours in night, not sunset, conditions.</div>
            <div class="invalid-feedback">You must let use know how many nights you sailed.</div>
          </div>
        </div>

        <div class="col-lg-4" v-show="!tripInfo.multiDayTrip">
            <div class="form-floating mb-4">
              <input type="number" class="form-control"  id="tripDuration" name="tripDuration" :disabled="tripInfo.multiDayTrip" min=".5" max="24" step=".5" v-model="tripInfo.tripDuration" v-bind:class="{'is-valid' : validation.tripDuration, 'is-invalid': validation.tripDuration == false}">
              <label for="tripDuration">Duration (hours)</label>
              <div id="tripDurationMultiDayHelpBlock" class="form-text" v-if="tripInfo.multiDayTrip">Use days and nights above</div>
              <div id="tripDurationHelpBlock" class="form-text" v-else>To log four and half hours, enter 4.5</div>

              <div class="invalid-feedback" v-if="validation.tripDurationTooHigh">Hmm you sure about that?</div>
              <div class="invalid-feedback" v-else>You must let use know how many hours you were out.</div>
            </div>
        </div>

        <div class="col-lg-4">
          <div class="form-floating mb-4">
            <input type="number" class="form-control form-control-xlg"  id="tripDistance" name="tripDistance" v-model="tripInfo.tripDistance" min=".5" step=".5" v-bind:class="{'is-valid' : validation.tripDistance, 'is-invalid': validation.tripDistance == false}">
            <label for="tripDistance" v-if="tripInfo.multiDayTrip">Avg. Sea Miles per day</label>
            <label for="tripDistance" v-else>Sea Miles (nm.)</label>
            <div id="tripDistanceHelpBlock" class="form-text">To log 12.54 nautical miles, enter 12.54</div>
            <div class="invalid-feedback">You must let use know how far you sailed.</div>
          </div>
        </div>

        <div class="col-lg-4" v-show="!tripInfo.multiDayTrip">
          <div class="form-floating mb-4">
            <select class="form-control form-control-sm" id="timeOfDay" name="timeOfDay" v-model="tripInfo.tripTimeOfDay" v-bind:class="{'is-valid' : validation.tripTimeOfDay, 'is-invalid': validation.tripTimeOfDay == false}">
              <option value="please_select" disabled selected>Please Select</option>
              <option v-for="item in uiDropdowns.timeOfDay" :key="item.value" :value="item.value">{{item.label}}</option>
            </select>
            <label for="timeOfDay">Time of Day</label>
            <div class="invalid-feedback">Day or night, that is the question.</div>
          </div>
        </div>

      </div>

      <div class="row">

        <div class="col-lg-4">
          <div class="form-floating mb-4">
            <select class="form-control form-control-sm" id="windSpeed" name="windSpeed" v-model="tripInfo.windSpeed" v-bind:class="{'is-valid' : validation.windSpeed, 'is-invalid': validation.windSpeed == false}">
              <option value="please_select" disabled selected>Please Select</option>
              <option v-for="item in uiDropdowns.windSpeedBeaufort" :key="item.value" :value="item.value">{{item.label}}</option>
            </select>
            <label for="windSpeed" v-if="tripInfo.multiDayTrip">Avg. Wind Speed per Day</label>
            <label for="windSpeed" v-else>Wind Speed</label>
            <div id="windSpeedHelpBlock" class="form-text">Beaufort Number: {{tripInfo.windSpeed}}</div>
            <div class="invalid-feedback">Hmm how fast were those winds?</div>
          </div>
        </div>

        <div class="col-lg-4">
          <div class="form-floating mb-4">
            <select class="form-control form-control-sm" id="weatherConditions" name="weatherConditions" v-model="tripInfo.weatherConditions" v-bind:class="{'is-valid' : validation.weatherConditions, 'is-invalid': validation.weatherConditions == false}">
              <option value="please_select" disabled selected>Please Select</option>
              <option v-for="item in uiDropdowns.weatherConditions" :key="item.value" :value="item.value">{{item.label}}</option>
            </select>
            <label for="weatherConditions" v-if="tripInfo.multiDayTrip">Avg. Weather Conditions per Day</label>
            <label for="weatherConditions" v-else>Weather Conditions</label>
            <div class="invalid-feedback">No rain?</div>
          </div>
        </div>

        <div class="col-lg-4">
          <div class="form-floating mb-4">
            <select class="form-control form-control-sm" id="tripPurpose" name="tripPurpose" v-model="tripInfo.tripPurpose" v-bind:class="{'is-valid' : validation.tripPurpose, 'is-invalid': validation.tripPurpose == false}">
              <option value="please_select" disabled selected>Please Select</option>
              <option v-for="item in uiDropdowns.purpose" :key="item.value" :value="item.value">{{item.label}}</option>
            </select>
            <label for="tripPurpose">Purpose</label>
            <div class="invalid-feedback">Hmm why do we sail?</div>
          </div>
<!--          <div class="form-floating mb-4">-->
<!--            <select class="form-control form-control-sm" id="cloudConditions" name="cloudConditions" v-model="tripInfo.cloudConditions" v-bind:class="{'is-valid' : validation.cloudConditions, 'is-invalid': validation.cloudConditions == false}">-->
<!--              <option value="please_select" disabled selected>Please Select</option>-->
<!--              <option v-for="item in uiDropdowns.cloudConditions" :key="item.value" :value="item.value">{{item.label}}</option>-->
<!--            </select>-->
<!--            <label for="cloudConditions" v-if="tripInfo.multiDayTrip">Avg. Cloud Conditions per Day</label>-->
<!--            <label for="cloudConditions" v-else>Cloud Conditions</label>-->
<!--            <div class="invalid-feedback">No clouds?</div>-->
<!--          </div>-->
        </div>

      </div>

      <div class="row">
        <div class="col-lg-8">
          <div class="form-floating mb-4">
            <input type="text" class="form-control" id="location" name="location" v-model="tripInfo.location" v-bind:class="{'is-valid' : validation.location, 'is-invalid': validation.location == false}">
            <label for="location">Location</label>
            <div id="locationHelpBlock" class="form-text">Where did you go? Marina location, name of lake etc.</div>
            <div class="invalid-feedback">Please be more specific in describing where you were.</div>
          </div>
        </div>

        <div class="col-lg-4">
          <div class="form-floating mb-4">
            <select class="form-control form-control-sm" id="locationDescription" name="locationDescription" v-model="tripInfo.locationDescription" v-bind:class="{'is-valid' : validation.locationDescription, 'is-invalid': validation.locationDescription == false}">
              <option value="please_select" disabled selected>Please Select</option>
              <option v-for="item in uiDropdowns.locationDescription" :key="item.value" :value="item.value">{{item.label}}</option>
            </select>
            <label for="locationDescription">Location Type</label>
            <div id="locationDescriptionHelpBlock" class="form-text" v-show="tripInfo.multiDayTrip">Please log canal experience separately from a multi-day trip</div>
            <div class="invalid-feedback">Is the open blue for you?</div>
          </div>
        </div>


      </div>


      <div class="row">
        <div class="col-md-12">
          <div class="form-floating mb-4">
            <textarea class="form-control charterRodeTextArea" id="notes" name="notes" v-model="tripInfo.notes"></textarea>
            <label for="notes">Notes</label>
            <div id="notesHelpBlock" class="form-text">Any other optional notes to accompany this log entry.</div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <div class="form-floating mb-4">
            <select class="form-control form-control-sm" :key="verificationAuthorityKey" v-model="tripInfo.verificationAuthority" @change="onVerificationAuthorityChange($event)" id="verificationAuthority" name="verificationAuthority" v-bind:class="{'is-valid' : validation.verificationAuthority, 'is-invalid': validation.verificationAuthority == false}">
              <option v-for="item in uiDropdowns.verificationAuthorities" :key="item.type" :disabled="!isVerificationAuthorityAvailable(item)" :value="item.type">{{item.name}} - {{item.verificationFeeInCrt}} CRT to verify</option>
            </select>
            <label for="verificationAuthority">Verification Authority</label>
            <div id="validationAuthorityHelpBlock" class="form-text">Charter company verification through Charter Rode is the highest and most trusted verification. If you chartered a boat, Charter Rode will contact the charter company and verify your charter. If you did not charter a boat, use Crewmate verification if you had someone else on the boat with you. If you were out by yourself on your or a friend's boat, use Self verification. <a href="https://www.charterrode.com/article/verification-types" target="_blank">Read more about verification types here</a>.</div>
<!--            <div id="validationAuthorityHelpBlock" class="form-text">Who would you like to verify this entry? Charter Rode may only validate trips with charter companies. Use self or crewmate if you went out on a vessel that you or a friend owns.</div>-->
            <div class="invalid-feedback">Hmm best to have us verify this entry if it is with a charter company, otherwise use cremate or self.</div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="form-floating mb-4">
            <input class="form-control form-control-sm" id="totalFees" name="totalFees" v-model="tripInfo.totalFeesForEntry" disabled/>
            <label for="totalFees">Total Fees for this Entry</label>
            <div id="totalFeesHelpBlock" class="form-text">The amount of CRT that your account will be charged to <span v-show="tripInfo.verificationAuthority === 'charter_rode'">verify, </span>process, and commit this transaction on Charter Rode.</div>
          </div>
        </div>
      </div>

      <div class="row" v-show="tripInfo.verificationAuthority === 'crewmate'">
        <div class="col-md-6">
          <div class="form-floating mb-4">
            <select class="form-control form-control-sm" :key="crewmateListKey" v-model="tripInfo.crewmateToVerify" id="crewmateToVerify" name="crewmateToVerify" @change="onCrewmateChange($event)" v-bind:class="{'is-valid' : validation.crewmateToVerify, 'is-invalid': validation.crewmateToVerify == false}">
              <option value="please_select" disabled selected>Please Select</option>
              <option v-for="item in crewmates" :key="item.type" :value="item.id">{{item.firstName}} {{item.lastName}} ({{item.email}})</option>
              <option value="add_crewmate">Add New Crewmate</option>
            </select>
            <label for="crewmateToVerify">Crewmate</label>
            <div class="invalid-feedback">Please select a crewmate to validate this entry.</div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-floating mb-4">
            <select class="form-control form-control-sm" id="crewmateRole" name="crewmateRole" v-model="tripInfo.crewmateRole" v-bind:class="{'is-valid' : validation.crewmateRole, 'is-invalid': validation.crewmateRole == false}">
              <option value="please_select" disabled selected>Please Select</option>
              <option v-for="item in uiDropdowns.roleType" :key="item.value" :value="item.value">{{item.label}}</option>
            </select>
            <label for="crewmateRole">Crewmate Role</label>
            <div class="invalid-feedback">Captain or Crew? Hmm hard to decide.</div>
          </div>
        </div>

      </div>

      <div class="row pb-3 pt-3">
        <div class="col-sm-12">
          <div class="alert" v-bind:class="{'alert-danger': !tripInfo.agreeToCRTCharges, 'alert-success': tripInfo.agreeToCRTCharges}" role="alert">
            <div class="disclaimer-text">
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox" id="agreeToCRTCharges" v-model="tripInfo.agreeToCRTCharges">
                <label class="form-check-label charterRodeBtn" for="agreeToCRTCharges">
                  I authorize Charter Rode to charge my account a <span class="disclaimer-text-highlight">total of {{tripInfo.totalFeesForEntry}}</span> to <span v-show="tripInfo.verificationAuthority === 'charter_rode'">verify, </span>process, and commit this entry.
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row pb-5 mb-5">
        <div class="col-sm-12">
          <div class="d-grid gap-2 col-8 mx-auto">
            <div v-if="tripInfo.multiDayTrip" class="text-center mt-0 pt-0"><a class="cr-footer-links charterRodeBtn" style="font-size: 12px;" @click="sendLogEntry(true)">Review Multi-Day Extended Trip Entries Before Submitting</a></div>
            <button class="btn btn-lg btn-outline-primary text-uppercase" v-on:click.prevent="sendLogEntry(false)" :disabled="activelySubmittingTransaction">Submit Log Entry</button>
          </div>
        </div>
      </div>
    </div>


    <div class="container" v-show="showEditMultiDayTrip && !activelySubmittingTransaction">
      <div class="row mt-3 mb-3">
        <div class="col-sm-12">
          <h4>Optional: Review & Adjust Multi-Day Entries</h4>
          <div class="charterRodeHelpText">Tap an entry to review the logbook entry details that will be added to your Sailing Resume. Some of the details may be modified while the dates, role, vessel, and time of day may not. When done, tap the Submit Log Entries button at the bottom of the screen to submit all remaining entries as a multi-day extended trip on your Sailing Resume.</div>
        </div>
      </div>


      <div class="row">
        <div class="col-12">
          <div class="mb-2">
            <a class="btn btn-outline-secondary btn-sm" style="border-radius: 25px;" v-on:click="backFromMultiDayView()"><fai :icon="['far','arrow-alt-circle-left']" class="fa-fw"></fai> Back</a>
          </div>
          <div class="accordion -flush" id="multiTripAccordion">
            <div class="accordion-item" v-for="(entry,index) in pendingTripTxRequest.trip.entries" :key="entry.date" :id="'ai-'+entry.date+'-'+entry.timeOfDay">
              <div class="accordion-header">
                <table style="width: 100%">
                  <tr>
                    <td :class="expandedRows[index] === true ? 'removeEntryButtonExpanded':''" class="ps-1 pe-1 text-nowrap" style="width: 10px;"><a class="btn btn-sm btn-danger charterRodeBtn" @click.stop="removeEntry(index)"><fai icon="trash-alt" fixed-width /> Remove</a></td>
                    <td class="ps-1 fw-bold accordion-button collapsed charterRodeBtn" data-bs-toggle="collapse" :data-bs-target="'#collapse-'+index" aria-expanded="false" :aria-controls="'collapse-'+index" @click="toggleRowExpanded(index)">#<span v-html="displayEntryNumber(index)"></span>&nbsp;-&nbsp;{{entry.date}} (<span v-html="showDayOrNightLabelFor(entry.timeOfDay)"></span>)</td>
                  </tr>
                </table>
              </div>
              <div :id="'collapse-'+index" class="accordion-collapse collapse">
                <div class="accordion-body" style="padding-left:0px;padding-right: 0px;">
                  <div class="container-fluid ps-2 pe-2">

                    <div class="row">
                      <div class="col-lg-4">
                        <div class="mb-4">
                          <div class="form-floating">
                            <input type="text" class="form-control disabled" id="date" name="date" :value="entry.date" disabled>
                            <label for="date">Date</label>
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-4">
                        <div class="form-floating mb-4">
                          <select class="form-control form-control-sm disabled" id="tripRole" name="tripRole" v-model="tripInfo.tripRole" disabled>
                            <option value="please_select" disabled selected>Please Select</option>
                            <option v-for="item in uiDropdowns.roleType" :key="item.value" :value="item.value">{{item.label}}</option>
                          </select>
                          <label for="tripRole">Your Role</label>
                        </div>
                      </div>

                      <div class="col-lg-4">
                        <div class="form-floating mb-4">
                          <select class="form-control form-control-sm disabled" id="vessel" name="vessel" v-model="tripInfo.vessel" disabled>
                            <option value="please_select" disabled>Please Select</option>
                            <optgroup label="Your Favorite Vessels" v-show="favoriteVessels">
                              <option v-for="item in favoriteVessels" :key="item.id" :value="item.id">{{item.fullVesselName}}</option>
                            </optgroup>
                          </select>
                          <label for="vessel">Vessel</label>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-lg-4">
                        <div class="form-floating mb-4">
                          <input type="number" class="form-control"  id="tripDuration" name="tripDuration" min=".5" max="24" step=".5" v-model="entry.durationHours">
                          <label for="tripDuration">Duration (hours)</label>
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="form-floating mb-4">
                          <input type="number" class="form-control form-control-xlg"  id="tripDistance" name="tripDistance" v-model="entry.nauticalMiles" min=".5" step=".5">
                          <label for="tripDistance">Sea Miles (nm.)</label>
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="form-floating mb-4">
                          <select class="form-control form-control-sm" id="timeOfDay" name="timeOfDay" v-model="entry.timeOfDay" disabled>
                            <option value="please_select" disabled selected>Please Select</option>
                            <option v-for="item in uiDropdowns.timeOfDay" :key="item.value" :value="item.value">{{item.label}}</option>
                          </select>
                          <label for="timeOfDay">Time of Day</label>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-lg-4">
                        <div class="form-floating mb-4">
                          <select class="form-control form-control-sm" id="windSpeed" name="windSpeed" v-model="entry.windSpeedBeaufort">
                            <option value="please_select" disabled selected>Please Select</option>
                            <option v-for="item in uiDropdowns.windSpeedBeaufort" :key="item.value" :value="item.value">{{item.label}}</option>
                          </select>
                          <label for="windSpeed">Wind Speed</label>
                          <div id="windSpeedHelpBlock" class="form-text">Beaufort Number: {{entry.windSpeedBeaufort}}</div>
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="form-floating mb-4">
                          <select class="form-control form-control-sm" id="weatherConditions" name="weatherConditions" v-model="entry.weatherConditions">
                            <option value="please_select" disabled selected>Please Select</option>
                            <option v-for="item in uiDropdowns.weatherConditions" :key="item.value" :value="item.value">{{item.label}}</option>
                          </select>
                          <label for="weatherConditions">Weather Conditions</label>
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="form-floating mb-4">
                          <select class="form-control form-control-sm" id="tripPurpose" name="tripPurpose" v-model="entry.purpose">
                            <option value="please_select" disabled selected>Please Select</option>
                            <option v-for="item in uiDropdowns.purpose" :key="item.value" :value="item.value">{{item.label}}</option>
                          </select>
                          <label for="tripPurpose">Purpose</label>
                        </div>
<!--                        <div class="form-floating mb-4">-->
<!--                          <select class="form-control form-control-sm" id="cloudConditions" name="cloudConditions" v-model="entry.cloudConditions">-->
<!--                            <option value="please_select" disabled selected>Please Select</option>-->
<!--                            <option v-for="item in uiDropdowns.cloudConditions" :key="item.value" :value="item.value">{{item.label}}</option>-->
<!--                          </select>-->
<!--                          <label for="cloudConditions">Cloud Conditions</label>-->
<!--                        </div>-->
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-lg-8">
                        <div class="form-floating mb-4">
                          <input type="text" class="form-control" id="location" name="location" v-model="entry.location">
                          <label for="location">Location</label>
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="form-floating mb-4">
                          <select class="form-control form-control-sm" id="locationDescription" name="locationDescription" v-model="entry.locationType">
                            <option value="please_select" disabled selected>Please Select</option>
                            <option v-for="item in uiDropdowns.locationDescription" :key="item.value" :value="item.value">{{item.label}}</option>
                          </select>
                          <label for="locationDescription">Location Type</label>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-floating mb-4">
                          <textarea class="form-control charterRodeTextArea" id="notes" name="notes" v-model="entry.notes"></textarea>
                          <label for="notes">Notes</label>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-5 pb-5 mb-5">
        <div class="col-sm-12">
          <div class="d-grid gap-2 col-8 mx-auto">
            <button class="btn btn-lg btn-outline-primary text-uppercase" v-on:click.prevent="submitMultiDayEntries()" :disabled="activelySubmittingTransaction">Submit Log Entries</button>
          </div>
        </div>
      </div>
    </div>


  </form>


  <searchVesselsModal
      ref="searchVesselsModal"
      confirmButtonLabel="Use Selected Vessel"
      v-bind:user="user"
      v-on:setVessel="setVessel"
      v-on:activateAddVesselModal="activateAddVesselModal"
      v-on:modalClose="searchVesselsModalClose"></searchVesselsModal>

  <addVesselModal
      ref="addVesselModal"
      v-bind:user="user"
      v-on:setVessel="setVessel"
      v-on:modalClose="addVesselModalClose"></addVesselModal>

  <addNewCrewmateModal
      ref="addCrewmateModal"
      confirmButtonLabel="Use Selected Crewmate"
      v-on:setCrewmate="setCrewmate"
      v-on:modalClose="addCrewmateModalClose"></addNewCrewmateModal>

  <teleport to="body">
    <div class="position-fixed bottom-0 end-0 p-3 cr-z-index-5">
      <div id="tripTxToast" class="toast hide" role="alert" aria-live="assertive" aria-atomic="true">
        <div class="toast-header">
          <strong class="me-auto">Transaction Submitted Successfully!</strong>
          <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
        </div>
        <div class="toast-body">Your trip transaction has been successfully submitted</div>
      </div>
    </div>
  </teleport>

</template>

<script>
import { Toast } from 'bootstrap'
import api from "../../api/api"

export default {
  name: 'SubmitTransaction',
  data() {
    return {
      transactionSubmittedSuccessfully: false,
      transactionSubmittedHadError: false,
      activelySubmittingTransaction: false,
      showEditMultiDayTrip: false,
      verificationAuthorityKey: 0,
      crewmateListKey: 0,
      user:  this.$store.state.user,
      uiDropdowns: this.$store.state.uiDropdowns,
      favoriteVessels: this.$store.state.user.favoriteVessels ? this.$store.state.user.favoriteVessels : undefined,
      crewmates: this.$store.state.user.crewmates ? this.$store.state.user.crewmates : undefined,
      tripInfo: {
        date: new Date(),
        multiDayTrip: false,
        tripRole: "please_select",
        selectedVesselObject: undefined,
        vessel: "please_select",
        multiDayTripDays: "7",
        multiDayTripNights: "0",
        tripDuration: undefined,
        tripDistance: undefined,
        tripTimeOfDay: "please_select",
        windSpeed: "please_select",
        weatherConditions: "please_select",
        cloudConditions: "please_select",
        tripPurpose: "please_select",
        location: undefined,
        locationDescription: "please_select",
        notes: undefined,
        verificationAuthority: 'crewmate',
        verificationAuthorityId: undefined,
        crewmateToVerify: 'please_select',
        selectedCrewmateToVerifyObject: undefined,
        crewmateRole: 'please_select',
        totalFeesForEntry: '1 CRT',
        agreeToCRTCharges: false
      },
      validation : {
        date: undefined,
        tripRole: undefined,
        vessel: undefined,
        multiDayTripDays: undefined,
        multiDayTripNights: undefined,
        tripDuration: undefined,
        tripDistance: undefined,
        tripTimeOfDay: undefined,
        windSpeed: undefined,
        weatherConditions: undefined,
        cloudConditions: undefined,
        tripPurpose: undefined,
        location: undefined,
        locationDescription: undefined,
        verificationAuthority: undefined,
        agreeToCRTCharges: undefined,
      },
      pendingTripTxRequest:  {trip: { entries: []}},
      expandedRows: []
    }
  },
  mounted() {
    // can we do some smart things for the user??
    this.vessel = this.favoriteVessels ? this.favoriteVessels.length == 1 ? this.favoriteVessels[0].id : "please_select" : "please_select"
    // now we need to iterate over each vessel and figure out the 'fullVesselName'
    if ( this.favoriteVessels ) {
      let self = this
      this.favoriteVessels.forEach(function(aVessel) {
        aVessel.fullVesselName = self.getFullVesselNameFor(aVessel)
      })
      // now alphabetize them!
      this.favoriteVessels.sort(function(a,b) {
        if (a.fullVesselName > b.fullVesselName) return 1
        if (a.fullVesselName < b.fullVesselName) return -1
        return 0
      })
    }
    if ( this.crewmates ) {
      // alphabetize them!
      this.crewmates.sort(function(a,b) {
        let aFullName = a.lastName.toLowerCase()+a.firstName.toLowerCase()
        let bFullName = b.lastName.toLowerCase()+b.firstName.toLowerCase()
        if (aFullName > bFullName) return 1
        if (aFullName < bFullName) return -1
        return 0
      })
    }
    let self = this
    this.$nextTick(function () {
      if ( this.$store.state.draftTripInfo != undefined ) {
        self.tripInfo = this.$store.state.draftTripInfo
      }
    })
  },
  beforeUnmount() {
    if ( !this.transactionSubmittedSuccessfully ) {
      this.$store.commit('setDraftTrip', this.tripInfo)
    }
  },
  methods: {
    getFullVesselNameFor(aVessel) {
      let preFix = ''
      if ( aVessel.propulsion && aVessel.propulsion === 'sail' ) {
        if ( !aVessel.vesselName.startsWith('SV') ) {
          preFix = 'SV '
        }
      } else if ( aVessel.propulsion && aVessel.propulsion === 'power' ) {
        if ( !aVessel.vesselName.startsWith('PV') || !aVessel.vesselName.startsWith('FV') ) {
          preFix = 'MV '
        }
      }
      if ( aVessel.charterCompanyName && aVessel.charterBase ) {
        return preFix + aVessel.vesselName + ' - '+ aVessel.charterCompanyName + ' - ' + aVessel.charterBase
      } else if ( aVessel.charterCompanyName ) {
        return preFix + aVessel.vesselName + ' - '+ aVessel.charterCompanyName
      } else if ( aVessel.charterBase ) {
        return preFix + aVessel.vesselName + ' - '+ aVessel.charterBase
      } else {
        return preFix + aVessel.vesselName
      }
    },
    displayEntryNumber(index) {
      return index + 1
    },
    toggleRowExpanded(index) {
      if ( this.expandedRows[index] === true ) {
        this.expandedRows[index] = false
      } else {
        this.expandedRows[index] = true
      }
    },
    removeEntry(index) {
      this.pendingTripTxRequest.trip.entries.splice(index, 1)
      this.expandedRows.splice(index,1)
      if ( this.pendingTripTxRequest.trip.entries.length == 0 ) {
        this.backFromMultiDayView()
      }
    },
    showDayOrNightLabelFor(dayOrNightLabel) {
      if ( dayOrNightLabel.toLowerCase() === 'day' ) {
        return 'Day'
      } else if ( dayOrNightLabel.toLowerCase() === 'night' ) {
        return 'Night'
      }
      return 'Unknown!'
    },
    backFromMultiDayView() {
      this.tripInfo.agreeToCRTCharges = false
      this.activelySubmittingTransaction = false
      this.showEditMultiDayTrip = false
      this.pendingTripTxRequest = {trip: { entries: []}}
      this.expandedRows = []
    },
    submitMultiDayEntries() {
      this.activelySubmittingTransaction = true
      let isFormValid = true

      // TODO: validate all of the forms entries!


      if ( !isFormValid ) {
        this.activelySubmittingTransaction = false
        return
      }

      // grab what we are trying to submit
      let tripTxRequest = this.pendingTripTxRequest

      api.submitTransaction(tripTxRequest).then(response => {
        if ( response ) {
          // clear out the draft trip info from store
          this.transactionSubmittedSuccessfully = true
          this.transactionSubmittedHadError = false
          this.$store.commit('clearDraftTrip')

          let tripTxToast = new Toast(document.getElementById('tripTxToast'))
          tripTxToast.show()
          this.activelySubmittingTransaction = false

          // if it all worked, we likely updated the user's funds so need to reset that here as well...
          api.getMe().then(response => {
            if ( response.user && response.user.firstName && response.user.lastName ) {
              // all good...
              this.$store.commit('setUser', response.user)
            }
          })

          // just rest portions of the form to make it easier to multiple entries!
          this.tripInfo.multiDayTripDays = 7
          this.tripInfo.multiDayTripNights= 0
          this.tripInfo.multiDayTrip = false
          this.tripInfo.tripDuration = undefined
          this.tripInfo.tripDistance = undefined
          this.tripInfo.agreeToCRTCharges = false
          this.tripInfo.totalFeesForEntry = '1 CRT'

          this.validation.date = undefined
          this.validation.tripRole = undefined
          this.validation.vessel = undefined
          this.validation.multiDayTripDays = undefined
          this.validation.multiDayTripNights = undefined
          this.validation.tripDuration = undefined
          this.validation.tripDistance = undefined
          this.validation.tripTimeOfDay = undefined
          this.validation.windSpeed = undefined
          this.validation.weatherConditions = undefined
          this.validation.cloudConditions = undefined
          this.validation.tripPurpose = undefined
          this.validation.location = undefined
          this.validation.locationDescription = undefined
          this.validation.verificationAuthority = undefined
          this.validation.agreeToCRTCharges = undefined

          // set it as draft in case they navigate away and come back...
          this.$store.commit('setDraftTrip', this.tripInfo)

        } else {
          // there was some sort of error!
          this.transactionSubmittedSuccessfully = false
          this.transactionSubmittedHadError = true
          this.activelySubmittingTransaction = false
        }
      })
    },
    sendLogEntry(useAdvancedMultiDayReview) {
      // first validate the form
      this.activelySubmittingTransaction = true
      let isFormValid = true
      if ( this.tripInfo.agreeToCRTCharges ) {
        this.validation.agreeToCRTCharges = true
      } else {
        this.validation.agreeToCRTCharges = false
        isFormValid = false
      }
      if ( this.tripInfo.date ) {
        this.validation.date = true
      } else {
        this.validation.date = false
        isFormValid = false
      }
      if ( this.tripInfo.tripRole !== "please_select" ) {
        this.validation.tripRole = true
      } else {
        this.validation.tripRole = false
        isFormValid = false
      }
      if ( this.tripInfo.vessel !== "please_select" ) {
        this.validation.vessel = true
      } else {
        this.validation.vessel = false
        isFormValid = false
      }
      try {
        let distance = parseFloat(this.tripInfo.tripDistance)
        if ( distance !== undefined && distance >= 0 ) {
          this.validation.tripDistance = true
        } else {
          this.validation.tripDistance = false
          isFormValid = false
        }
      } catch (err) {
        this.validation.tripDistance = false
        isFormValid = false
      }
      this.validation.tripDuration = undefined
      this.validation.tripDurationTooHigh = undefined
      if ( this.tripInfo.multiDayTrip ) {
        try {
          let days = parseInt(this.tripInfo.multiDayTripDays)
          if ( days !== undefined && days > 0 ) {
            this.validation.multiDayTripDays = true
          } else {
            this.validation.multiDayTripDays = false
            isFormValid = false
          }
        } catch (err) {
          this.validation.multiDayTripDays = false
          isFormValid = false
        }
        try {
          let nights = parseInt(this.tripInfo.multiDayTripNights)
          if ( nights !== undefined && nights >= 0 ) {
            this.validation.multiDayTripNights = true
          } else {
            this.validation.multiDayTripNights = false
            isFormValid = false
          }
        } catch (err) {
          this.validation.multiDayTripNights = false
          isFormValid = false
        }
      } else {
        try {
          let duration = parseFloat(this.tripInfo.tripDuration)
          if ( duration !== undefined && duration >= 0 ) {
            if ( duration > 20 ) {
              this.validation.tripDuration = false
              this.validation.tripDurationTooHigh = true
              isFormValid = false
            } else {
              this.validation.tripDuration = true
            }
          } else {
            this.validation.tripDuration = false
            isFormValid = false
          }
        } catch (err) {
          this.validation.tripDuration = false
          isFormValid = false
        }

        if (this.tripInfo.tripTimeOfDay !== "please_select") {
          this.validation.tripTimeOfDay = true
        } else {
          this.validation.tripTimeOfDay = false
          isFormValid = false
        }
      }
      if ( this.tripInfo.windSpeed !== "please_select" ) {
        this.validation.windSpeed = true
      } else {
        this.validation.windSpeed = false
        isFormValid = false
      }
      if ( this.tripInfo.weatherConditions !== "please_select" ) {
        this.validation.weatherConditions = true
      } else {
        this.validation.weatherConditions = false
        isFormValid = false
      }
      // if ( this.tripInfo.cloudConditions !== "please_select" ) {
      //   this.validation.cloudConditions = true
      // } else {
      //   this.validation.cloudConditions = false
      //   isFormValid = false
      // }
      // not used anymore....
      this.tripInfo.cloudConditions = undefined
      if ( this.tripInfo.tripPurpose !== "please_select" ) {
        this.validation.tripPurpose = true
      } else {
        this.validation.tripPurpose = false
        isFormValid = false
      }
      if (this.tripInfo.location && this.tripInfo.location.trim().length > 3 ) {
        this.validation.location = true
      } else {
        if ( this.tripInfo.location ) {
          this.tripInfo.location = this.tripInfo.location.trim()
        }
        this.validation.location = false
        isFormValid = false
      }
      if ( this.tripInfo.locationDescription !== "please_select" ) {
        this.validation.locationDescription = true
      } else {
        this.validation.locationDescription = false
        isFormValid = false
      }
      if ( this.tripInfo.verificationAuthority === "charter_rode" ||
          this.tripInfo.verificationAuthority === "self" ||
          this.tripInfo.verificationAuthority === "crewmate" ) {
        this.validation.verificationAuthority = true
      } else {
        this.validation.verificationAuthority = false
        isFormValid = false
      }

      if ( this.tripInfo.verificationAuthority === "crewmate" ) {
        // check on crewmate to verify
        if ( this.tripInfo.crewmateToVerify !== "please_select" ) {
          this.validation.crewmateToVerify = true
        } else {
          this.validation.crewmateToVerify = false
          isFormValid = false
        }
        if ( this.tripInfo.crewmateRole !== "please_select" ) {
          this.validation.crewmateRole = true
        } else {
          this.validation.crewmateRole = false
          isFormValid = false
        }
      } else {
        this.validation.crewmateToVerify = undefined
        this.validation.crewmateRole = undefined
      }

      if ( isFormValid ) {
        // submit form!
        let tripTxRequest = {
          trip : {
            entries: []
          },
          verificationAuthorityId: this.tripInfo.verificationAuthorityId
        }

        // is the vessel a charter?
        if ( this.tripInfo.selectedVesselObject.ownerType === "charter_base" &&
              this.tripInfo.selectedVesselObject.ownerId !== "unknown" ) {
          tripTxRequest.trip.charterCompanyBaseId = this.tripInfo.selectedVesselObject.ownerId
        }

        // is verification authority self?
        if ( this.tripInfo.verificationAuthority === 'crewmate' ) {
          tripTxRequest.crewmateRole = this.tripInfo.crewmateRole
        }

        // handle multi-day trip vs. single-day trips!
        if ( this.tripInfo.multiDayTrip ) {
          // so for this we stamp out an array of entries based upon
          // the number of days (tripInfo.multiDayTripDays) and
          // nights (tripInfo.multiDayTripNights)
          // once we stamp this out, we provide an opportunity for the user
          // to update each entry individually to account for any variations on dates,
          // distances, if they were captain or crew for a specific day, etc.
          let offset = this.tripInfo.date.getTimezoneOffset()
          let theTripDate = new Date(this.tripInfo.date.getTime() - (offset*60*1000))

          // do the days
          for (let dayCounter = 1; dayCounter <= this.tripInfo.multiDayTripDays; ++dayCounter ) {
            let iterDate = new Date(Number(theTripDate))
            iterDate.setDate(theTripDate.getDate() + (dayCounter -1)) // yes we do this for the classic off by one issue
            // okay if the user is logging days AND nights, for each day that we are logging day AND night
            // split the tripDistance equally between the day and night entries
            let entryDistance = this.tripInfo.tripDistance
            let entryDurationHours = 8 // the minimum requirement for 'one day' for USCG Captains License is 4 hours so give them the beneift of the doubt...
            if ( dayCounter <= (this.tripInfo.multiDayTripNights + 1) ) { //most of these longer multi day/week passages would have one fewer nights for the day of arrival so would be a partial day anyhow on miles....
              entryDistance = (entryDistance / 2).toString()
              // also override the durationHours to 12 as this is likely a 24 hour non-stop journey...
              entryDurationHours = 12
            }

            tripTxRequest.trip.entries.push({
              date: iterDate.toISOString().split('T')[0],
              role: this.tripInfo.tripRole,
              durationHours: entryDurationHours,
              nauticalMiles: entryDistance,
              timeOfDay: 'day',
              windSpeedBeaufort: this.tripInfo.windSpeed,
              weatherConditions: this.tripInfo.weatherConditions,
              // cloudConditions: this.tripInfo.cloudConditions,
              location: this.tripInfo.location,
              locationType: this.tripInfo.locationDescription,
              purpose: this.tripInfo.tripPurpose,
              notes: this.tripInfo.notes,
              vesselId: this.tripInfo.vessel
            })
          }

          // do the nights
          for (let nightCounter = 1; nightCounter <= this.tripInfo.multiDayTripNights; ++nightCounter ) {
            let iterDate = new Date(Number(theTripDate))
            iterDate.setDate(theTripDate.getDate() + (nightCounter -1)) // yes we do this for the classic off by one issue
            // okay if the user is logging days AND nights, for each day that we are logging day AND night
            // split the tripDistance equally between the day and night entries
            let entryDistance = this.tripInfo.tripDistance
            let entryDurationHours = 8 // the minimum requirement for 'one day' for USCG Captains License is 4 hours so give them the beneift of the doubt...
            if ( nightCounter <= this.tripInfo.multiDayTripDays ) {
              entryDistance = (entryDistance / 2).toString()
              // also override the durationHours to 12 as this is likely a 24 hour non-stop journey...
              entryDurationHours = 12
            }

            tripTxRequest.trip.entries.push({
              date: iterDate.toISOString().split('T')[0],
              role: this.tripInfo.tripRole,
              durationHours: entryDurationHours,
              nauticalMiles: entryDistance,
              timeOfDay: 'night',
              windSpeedBeaufort: this.tripInfo.windSpeed,
              weatherConditions: this.tripInfo.weatherConditions,
              // cloudConditions: this.tripInfo.cloudConditions,
              location: this.tripInfo.location,
              locationType: this.tripInfo.locationDescription,
              purpose: this.tripInfo.tripPurpose,
              notes: this.tripInfo.notes,
              vesselId: this.tripInfo.vessel
            })
          }

          if ( useAdvancedMultiDayReview ) {
            // let the user review on a per entry basis and let them override?
            this.activelySubmittingTransaction = false
            this.showEditMultiDayTrip = true
            this.pendingTripTxRequest = tripTxRequest

            this.expandedRows = []
            for (let i = 0; i < this.pendingTripTxRequest.trip.entries.length; ++i) {
              this.expandedRows.push(false)
            }

            // re-sort these based on date and then time of day with 'day' first then 'night' ....
            this.pendingTripTxRequest.trip.entries.sort((a, b) => {
              if (a.date === b.date) {
                return (a.timeOfDay > b.timeOfDay) ? 1 : ((b.timeOfDay > a.timeOfDay) ? -1 : 0)
              } else {
                return (a.date > b.date) ? 1 : ((b.date > a.date) ? -1 : 0)
              }
            })

            // finally scroll to the top
            window.scrollTo(0, 0);
            return
          }
        } else {
          let offset = this.tripInfo.date.getTimezoneOffset()
          let theTripDate = new Date(this.tripInfo.date.getTime() - (offset*60*1000))
          tripTxRequest.trip.entries.push({
            date: theTripDate.toISOString().split('T')[0],
            role: this.tripInfo.tripRole,
            durationHours: this.tripInfo.tripDuration,
            nauticalMiles: this.tripInfo.tripDistance,
            timeOfDay: this.tripInfo.tripTimeOfDay,
            windSpeedBeaufort: this.tripInfo.windSpeed,
            weatherConditions: this.tripInfo.weatherConditions,
            // cloudConditions: this.tripInfo.cloudConditions,
            location: this.tripInfo.location,
            locationType: this.tripInfo.locationDescription,
            purpose: this.tripInfo.tripPurpose,
            notes: this.tripInfo.notes,
            vesselId: this.tripInfo.vessel
          })
        }
        // if we get here we should submit!
        api.submitTransaction(tripTxRequest).then(response => {
          if ( response ) {
            // clear out the draft trip info from store
            this.transactionSubmittedSuccessfully = true
            this.transactionSubmittedHadError = false
            this.$store.commit('clearDraftTrip')

            let tripTxToast = new Toast(document.getElementById('tripTxToast'))
            tripTxToast.show()
            this.activelySubmittingTransaction = false

            // if it all worked, we likely updated the user's funds so need to reset that here as well...
            api.getMe().then(response => {
              if ( response.user && response.user.firstName && response.user.lastName ) {
                // all good...
                this.$store.commit('setUser', response.user)
              }
            })

            // just rest portions of the form to make it easier to multiple entries!
            this.tripInfo.multiDayTripDays = 7
            this.tripInfo.multiDayTripNights= 0
            this.tripInfo.multiDayTrip = false
            this.tripInfo.tripDuration = undefined
            this.tripInfo.tripDistance = undefined
            this.tripInfo.agreeToCRTCharges = false
            this.tripInfo.totalFeesForEntry = '1 CRT'

            this.validation.date = undefined
            this.validation.tripRole = undefined
            this.validation.vessel = undefined
            this.validation.multiDayTripDays = undefined
            this.validation.multiDayTripNights = undefined
            this.validation.tripDuration = undefined
            this.validation.tripDistance = undefined
            this.validation.tripTimeOfDay = undefined
            this.validation.windSpeed = undefined
            this.validation.weatherConditions = undefined
            this.validation.cloudConditions = undefined
            this.validation.tripPurpose = undefined
            this.validation.location = undefined
            this.validation.locationDescription = undefined
            this.validation.verificationAuthority = undefined
            this.validation.agreeToCRTCharges = undefined

            // set it as draft in case they navigate away and come back...
            this.$store.commit('setDraftTrip', this.tripInfo)

          } else {
            // there was some sort of error!
            this.transactionSubmittedSuccessfully = false
            this.transactionSubmittedHadError = true
            this.activelySubmittingTransaction = false
          }
        })
      } else {
        this.activelySubmittingTransaction = false
      }
    },
    onVerificationAuthorityChange () {
      if ( this.tripInfo.verificationAuthority === 'charter_rode' ) {
        let self = this
        this.uiDropdowns.verificationAuthorities.forEach(function(anAuthority) {
          if ( anAuthority.type === 'charter_rode' ) {
            self.tripInfo.verificationAuthorityId = anAuthority.id
          }
        })
        let totalFees = 1
        if ( this.tripInfo.multiDayTrip ) {
          totalFees = 1 * (this.tripInfo.multiDayTripDays != undefined ? this.tripInfo.multiDayTripDays : 1) + 1 * (this.tripInfo.multiDayTripNights != undefined ? this.tripInfo.multiDayTripNights : 1)
        }
        // now for our hard-coded fee.....
        totalFees += 7
        this.tripInfo.totalFeesForEntry = totalFees+' CRT'
      } else {
        if ( this.tripInfo.verificationAuthority === 'self' ) {
          this.tripInfo.verificationAuthorityId = this.$store.state.user.id
        }
        let totalFees = 1
        if ( this.tripInfo.multiDayTrip ) {
          totalFees = 1 * (this.tripInfo.multiDayTripDays != undefined ? this.tripInfo.multiDayTripDays : 1) + 1 * (this.tripInfo.multiDayTripNights != undefined ? this.tripInfo.multiDayTripNights : 1)
        }
        this.tripInfo.totalFeesForEntry = totalFees+' CRT'
      }
      this.tripInfo.agreeToCRTCharges = false
      // try to loose focus
      document.getElementById('multiDayTripCheck').blur()
    },
    setVessel (theSelectedVessel) {
      theSelectedVessel.fullVesselName = this.getFullVesselNameFor(theSelectedVessel)
      let foundMatch = false
      if ( this.$store.state.user.favoriteVessels == undefined ) {
        this.$store.state.user.favoriteVessels = []
      } else {
        this.$store.state.user.favoriteVessels.forEach(function(aVessel) {
          if ( aVessel.id == theSelectedVessel.id ) {
            foundMatch = true
          }
        })
      }
      if ( !foundMatch ) {
        // not already in the list
        this.$store.state.user.favoriteVessels.push(theSelectedVessel)
        this.favoriteVessels = this.$store.state.user.favoriteVessels
        // now alphabetize them!
        this.favoriteVessels.sort(function (a, b) {
          if (a.fullVesselName > b.fullVesselName) return 1
          if (a.fullVesselName < b.fullVesselName) return -1
          return 0
        })
      }
      this.tripInfo.vessel = theSelectedVessel.id
      this.tripInfo.selectedVesselObject = theSelectedVessel
      this.verificationAuthorityKey += 1
    },
    addVesselModalClose() {
      if ( this.tripInfo.vessel === 'add_vessel' ) {
        this.tripInfo.vessel = "please_select"
        this.tripInfo.selectedVesselObject = undefined
        this.verificationAuthorityKey += 1
      }
    },
    activateAddVesselModal() {
      this.tripInfo.vessel === 'add_vessel'
      this.$refs.addVesselModal.activateModal()
    },
    searchVesselsModalClose() {
      if ( this.tripInfo.vessel === 'vessel_search' ) {
        this.tripInfo.vessel = "please_select"
        this.tripInfo.selectedVesselObject = undefined
        this.verificationAuthorityKey += 1
      }
    },
    onVesselChange() {
      if ( this.tripInfo.vessel === 'vessel_search' ) {
        this.$refs.searchVesselsModal.activateModal()
      } else if ( this.tripInfo.vessel === 'add_vessel' ) {
        this.$refs.addVesselModal.activateModal()
      } else {
        // need to find the vessel object selected!
        let selectedId = this.tripInfo.vessel
        let self = this
        this.favoriteVessels.forEach(function(aVessel) {
          if ( aVessel.id === selectedId ) {
            self.tripInfo.selectedVesselObject = aVessel
            if ( aVessel.ownerType === 'charter_base') {
              if ( self.tripInfo.tripRole === 'captain' ) {
                self.tripInfo.verificationAuthority = 'charter_rode'
                self.onVerificationAuthorityChange()
              } else {
                // crew
                self.tripInfo.verificationAuthority = 'crewmate'
                self.onVerificationAuthorityChange()
              }
            } else {
              // default to crewmate
              self.tripInfo.verificationAuthority = 'crewmate'
              self.onVerificationAuthorityChange()
            }
            self.verificationAuthorityKey += 1
          }
        })
      }
      // finally as we have made a change here, clear the click2agreement for chargers
      this.tripInfo.agreeToCRTCharges = false
    },
    onRoleChange() {
      this.onVesselChange()
    },
    isVerificationAuthorityAvailable(aVerificationAuth) {
      if ( aVerificationAuth.type === 'charter_rode' ) {
        if ( this.tripInfo.selectedVesselObject && this.tripInfo.selectedVesselObject.ownerType === 'charter_base' ) {
          if ( this.tripInfo.tripRole && this.tripInfo.tripRole === 'captain' ) {
            // if we have already agreed to the charge, reset to provide visual indicator that options have changed
            return true
          }
        }
        // if current value is charterRode, then set to crewmate....
        if ( this.tripInfo.verificationAuthority === 'charter_rode' ) {
          this.tripInfo.verificationAuthority = 'crewmate'
          this.onVerificationAuthorityChange()
        }
        return false
      } else {
        // non charter rode types are always available....
        return true
      }
    },
    onCrewmateChange() {
      if ( this.tripInfo.crewmateToVerify === 'add_crewmate' ) {
        this.$refs.addCrewmateModal.activateModal()
      } else {
        this.tripInfo.verificationAuthorityId = this.tripInfo.crewmateToVerify
      }
    },
    setCrewmate(theCrewmateSelected) {
      let foundMatch = false
      if ( this.$store.state.user.crewmates == undefined ) {
        this.$store.state.user.crewmates = []
      } else {
        this.$store.state.user.crewmates.forEach(function(aCrewmate) {
          if ( aCrewmate.id == theCrewmateSelected.id ) {
            foundMatch = true
          }
        })
      }

      if ( !foundMatch ) {
        // not already in the list
        this.$store.state.user.crewmates.push(theCrewmateSelected)
        this.crewmates = this.$store.state.user.crewmates
        // now alphabetize them!
        this.crewmates.sort(function (a, b) {
          let aFullName = a.lastName.toLowerCase()+a.firstName.toLowerCase()
          let bFullName = b.lastName.toLowerCase()+b.firstName.toLowerCase()
          if (aFullName > bFullName) return 1
          if (aFullName < bFullName) return -1
          return 0
        })
      }
      this.tripInfo.crewmateToVerify = theCrewmateSelected.id
      this.tripInfo.selectedCrewmateToVerifyObject = theCrewmateSelected
      this.tripInfo.verificationAuthorityId = this.tripInfo.crewmateToVerify
      this.crewmateListKey += 1
    },
    addCrewmateModalClose() {
      if ( this.tripInfo.crewmateToVerify === 'add_crewmate' ) {
        this.tripInfo.crewmateToVerify = "please_select"
      }
    },
    addAnotherLogEntry(evt) {
      evt.preventDefault()
      this.transactionSubmittedSuccessfully = false
    },
    setTripDate(aDate) {
      this.tripInfo.date = aDate
    }
  },
  computed: {
    maxDateForTrip() {
      const oneDayMillis = 1 * 24 * 60 *60 * 1000
      let todayDate = new Date()
      let todayDateFormatted
      // need to set it to tomorrow for the widget
      if ( this.tripInfo.multiDayTrip ) {
        // need to set the offset based on the number of days/nights of the trip!
        todayDate.setTime(todayDate.getTime() - (oneDayMillis * this.tripInfo.multiDayTripDays))
        // now adjust the trip date as we are not allowing logging stuff that has not happened yet!
        let newTripDate = new Date()
        newTripDate.setTime(todayDate.getTime() - oneDayMillis)
        // check to see if current tripInfo.date is before the current date
        if ( newTripDate < this.tripInfo.date ) {
          this.setTripDate(newTripDate)
        }
      } else {
        todayDate.setTime(todayDate.getTime() + oneDayMillis)
        // now adjust the trip date as we are not allowing logging stuff that has not happened yet!
        let newTripDate = new Date()
        newTripDate.setTime(todayDate.getTime() - oneDayMillis)
        if ( newTripDate < this.tripInfo.date ) {
          this.setTripDate(newTripDate)
        }
      }
      // and now format it!
      todayDateFormatted = todayDate.getFullYear() + '-' + ('0' + (todayDate.getMonth()+1)).slice(-2) + '-' + ('0' + todayDate.getDate()).slice(-2)
      return todayDateFormatted

    }
  }
}
</script>

<style>
.disclaimer-text {
  font-size: 16px;
  text-align: center;
}

.disclaimer-text-highlight {
  font-weight: bold;
}

.tx-submit-spinner {
  text-align: center;
  margin-top:5px;
  font-size:22px;
  color:#666;
  font-weight: normal;
  z-index: 9999;
}

.multi-day-trip-label {
  color: #888;
}

.charterRodeTextArea {
  overflow: hidden;
}

.removeEntryButtonExpanded {
  color: #0c63e4;
  background-color: #e7f1ff;
  box-shadow: inset 0 -1px 0 rgba(0,0,0,.125);
}
</style>