<template>
  <teleport to="body">
    <!-- Modal -->
    <div class="modal fade" id="vesselSearchModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-fullscreen">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="vesselSearchModalLabel">Charter Vessel Search</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="pb-3 charterRodeHelpText">Find a vessel by charter company name, base location and vessel name below. If you don't find the vessel you are looking for, add a new vessel.</div>

            <form autocomplete="off" novalidate v-on:submit.prevent="noop">
              <input autocomplete="false" name="hidden" type="text" style="display:none;">
              <div class="row">
                <div class="col-lg-12">
                  <div class="form-floating mb-4">
                    <input autocomplete="new-password" type="text" class="form-control" id="searchVesselSearchQuery" v-bind:name="createRandomInputName()" v-model="searchVessel.searchQuery" v-bind:class="{'is-valid' : validation.searchVessel.searchQuery, 'is-invalid': validation.searchVessel.searchQuery == false}" v-on:keyup.enter="searchForVessels">
                    <label class="d-none d-sm-block d-lg-block d-xl-block d-xxl-block" for="searchVesselSearchQuery">Charter Company Name, Base Location, and/or Vessel Name</label>
                    <label class="d-block d-sm-none" for="searchVesselSearchQuery">Search Criteria</label>
                    <div class="invalid-feedback">C'mon! Please give us something to work with here!</div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <button class="btn btn-outline-primary text-uppercase" @click.prevent v-on:click="searchForVessels" :disabled="searchVessel.activelySearching">Search For Vessels</button>
                </div>
                <div style="margin-top:25px;"  class="search-spinner" v-if="searchVessel.activelySearching"><fai icon="spinner" spin/> Please wait while we find your vessel!</div>
              </div>

              <div class="row">
                <div style="margin-top:15px;" class="table-responsive-xxl" v-if="searchVessel.searchResults">
                  <VTable
                      :data="searchVessel.searchResults"
                      ref="searchVesselsTable"
                      class="table"
                      selectionMode="single"
                      selectedClass="table-primary"
                      @stateChanged="selectSearchVesselRow($event.selectedRows)">
                    <template #head>
                      <tr>
                        <th style="width:5px;">&nbsp;</th>
                        <th style="white-space: nowrap">Name</th>
                        <th style="white-space: nowrap">Charter Company</th>
                        <th style="white-space: nowrap">Charter Base Location</th>
                        <th style="white-space: nowrap">Class/Model | Length</th>
                        <th style="white-space: nowrap">Type</th>
                        <th>&nbsp;</th>
                      </tr>
                    </template>
                    <template #body="{ rows }">
                      <VTr v-for="row in rows" :key="row.id" :row="row">
                        <td style="vertical-align: middle"><input type="radio" class="form-check-input" name="vesselId" v-bind:value="row.id" v-model="searchVessel.selectedVesselId" v-on:click="toggleSearchVesselRow"> </td>
                        <td>{{row.vesselName}}</td>
                        <td>{{getOwnerNameFor(row)}}</td>
                        <td>{{getOwnerLocationFor(row)}}</td>
                        <td>{{row.year}} {{row.manufacturer}} {{row.model}} | {{getLengthWithUnitsFor(row)}}</td>
                        <td>{{getPropulsionFor(row)}}</td>
                        <td class="-pull-right"><a class="link-primary" v-if="row.sailBoatDataUrl" v-bind:href="row.sailBoatDataUrl" target="_blank"><fai icon="external-link-alt"/></a></td>
                      </VTr>
                    </template>
                  </VTable>
                </div>
              </div>

              <div class="row" v-show="searchVessel.noResultsFound">
                <div style="margin-top:15px;" class="pb-3 crNoSearchResultsLabel">Oops, looks like we don't know about this vessel yet. <span class="charterRodeBtn charterRodeLink" v-on:click="addVesselFromVesselSearch">Help your fellow sailors and add a new vessel!</span></div>
              </div>
              <div class="row" v-show="searchVessel.searchResults">
                <div style="margin-top:15px;" class="pb-3 crNoSearchResultsLabel">Not finding what you are looking for? <span class="charterRodeBtn charterRodeLink" v-on:click="addVesselFromVesselSearch">Help your fellow sailors and add a new vessel!</span></div>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">Cancel</button>
            <button type="button" class="btn btn-primary" v-on:click="setVessel" :disabled="!searchVessel.vesselSelected">{{confirmButtonLabel}}</button>
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
import api from "../../api/api"
import {Modal} from "bootstrap"

export default {
  name: 'SearchVesselsModal',
  emits: ['setVessel','modalClose', 'activateAddVesselModal'],
  props: {
    'user': Object,
    'confirmButtonLabel': String
  },
  data() {
    return {
      setVesselCalled: false,
      searchVessel: {
        vesselSelected: false,
        searchQuery: undefined,
        searchResults: undefined,
        selectedRows: [],
        selectedVesselId: undefined,
        activelySearching: false
      },
      validation: {
        searchVessel: {
          searchQuery: undefined
        }
      }
    }
  },
  methods: {
    activateModal() {
      this.searchVesselhModal = new Modal(document.getElementById('vesselSearchModal'))
      let vesSearchEl = document.getElementById('vesselSearchModal')
      this.setVesselCalled = false
      this.searchVessel = {
        vesselSelected: false,
        searchQuery: undefined,
        searchResults: undefined,
        selectedRows: [],
        selectedVesselId: undefined,
        activelySearching: false
      }
      let self = this
      vesSearchEl.addEventListener('shown.bs.modal', function() {
        document.getElementById('searchVesselSearchQuery').focus()
      })

      vesSearchEl.addEventListener('hidden.bs.modal', function () {
        if ( !self.setVesselCalled ) {
          self.$emit('modalClose')
        }
      })
      this.searchVesselhModal.toggle()
    },
    searchForVessels() {
      // reset the form from any prior errors
      this.validation.searchVessel.searchQuery = undefined
      if ( this.searchVessel.searchQuery && this.searchVessel.searchQuery.trim().length > 1 ) {
        this.searchVessel.searchResults = undefined
        this.searchVessel.activelySearching = true
        api.searchVessels(this.searchVessel.searchQuery).then(matchingVessels => {
          // console.log("what came back from search vessels:"+JSON.stringify(matchingVessels))
          if ( matchingVessels && matchingVessels.length > 0 ) {
            this.searchVessel.searchResults = matchingVessels
            this.searchVessel.noResultsFound = false
          } else {
            this.searchVessel.searchResults = undefined
            this.searchVessel.noResultsFound = true
          }
          this.searchVessel.activelySearching = false
        })
      } else {
        // error!
        this.validation.searchVessel.searchQuery = false
      }
    },
    setVessel() {
      // call back to the parent component who is using us
      this.setVesselCalled = true
      this.$emit('setVessel',this.searchVessel.vesselSelected)
      this.searchVesselhModal.hide()
    },
    selectSearchVesselRow(selectedRows) {
      if ( selectedRows && selectedRows.length == 1 ) {
        this.searchVessel.selectedRows = selectedRows
        // now set the radio of the selected row to selected....
        this.searchVessel.selectedVesselId = selectedRows[0].id
        this.searchVessel.vesselSelected = selectedRows[0]
      } else {
        this.searchVessel.selectedVesselId = undefined
        this.searchVessel.vesselSelected = undefined
        this.searchVessel.selectedRows = []
      }
    },
    toggleSearchVesselRow(event) {
      if ( event.currentTarget.checked ) {
        if ( this.searchVessel.searchResults ) {
          let selectedVessel = undefined
          this.searchVessel.searchResults.forEach(function(aResult) {
            if ( aResult.id == event.currentTarget.value ) {
              selectedVessel = aResult
            }
          })
          if ( selectedVessel ) {
            let selectedRows = []
            selectedRows.push(selectedVessel)
            this.$refs.searchVesselsTable.selectRows(selectedRows)
          }
        }
      }
    },
    addVesselFromVesselSearch() {
      this.setVesselCalled = false
      this.$emit('activateAddVesselModal')
      this.searchVesselhModal.hide()
    },
    getOwnerNameFor(aRow) {
      if ( aRow.ownerType === 'charter_base' ) {
        return aRow.charterCompanyName
      } else if ( aRow.ownerId === this.user.id ) {
        return 'Privately Owned by '+this.user.firstName+' '+this.user.lastName
      } else {
        return 'Private'
      }
    },
    getOwnerLocationFor(aRow) {
      if ( aRow.ownerType === 'charter_base' ) {
        return aRow.charterBase
      } else {
        return '--'
      }
    },
    getLengthWithUnitsFor(aRow) {
      if ( this.user && this.user.preferredUnits && this.user.preferredUnits === 'imperial') {
        // convert to feet
        return (aRow.overallLengthMeter*3.28084).toFixed(1)+' ft'
      } else {
        return aRow.overallLengthMeter+'m'
      }
    },
    getPropulsionFor(aRow) {
      if ( aRow.propulsion === 'sail' ) {
        if ( aRow.hulls == 1 ) {
          return "Sailing Monohull"
        } else if ( aRow.hulls == 2 ) {
          return "Sailing Catamaran"
        } else if ( aRow.hulls == 3 ) {
          return "Sailing Trimaran"
        }
      } else {
        if ( aRow.hulls == 1 ) {
          return "Motorboat"
        } else if ( aRow.hulls == 2 ) {
          return "Power Cat"
        } else {
          return 'Motor Vessel'
        }
      }
    },
    createRandomInputName() {
      return 'randoVesSrch'+Math.floor(Math.random() * 1000000000000);
    }
  },
  computed:{

  },
  components: {

  }
}
</script>

<style>
.crNoSearchResultsLabel {
  color: #666
}

.search-spinner {
  text-align: center;
  margin-top:5px;
  font-size:18px;
  color:#666;
  font-weight: normal;
  z-index: 9999;
}
</style>