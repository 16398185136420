<template>
  <div>
    <div class="card cr-card" style="margin:0px 30px 30px 30px;">
      <div class="card-body">
        <div class="no-print" style="float: right;position: relative;top:1px;"><button class="btn btn-outline-primary btn-sm" v-on:click="activateAddCredentialModal"><fai icon="plus"></fai> Add Credential</button> </div>
        <h5 class="card-title">Credentials</h5>
        <h6 class="card-subtitle mb-2 text-muted">All credentials are verified by Charter Rode with the appropriate granting agency. Credentials may include government issued licenses or certifications from education providers such as US Sailing or American Sailing Association.</h6>
        <div class="table-responsive-xxl">
          <table class="cr-resume-table table table-striped table-hover">
            <thead>
              <tr>
                <td class="fw-bold">Credential</td>
                <td class="fw-bold">Issued On</td>
                <td class="fw-bold">Valid Until</td>
                <td class="fw-bold">Verified By</td>
              </tr>
            </thead>
            <tbody v-if="sectionData && sectionData.length > 0">
              <tr v-for="credential in sectionData" :key="credential.id">
                <td v-html="formatTitle(credential)"></td>
                <td v-html="formatIssuedDate(credential.issuedDate)"></td>
                <td v-html="formatExpireDate(credential.expireDate)"></td>
                <td>{{credential.verifiedBy}}</td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="4" class="text-center">Sailor has not been granted any credentials by a Charter Rode supported agency or institution.</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <addCredentialModal
      ref="addCredentialModal"></addCredentialModal>

</template>
<script>

export default {
  name: 'ResumeSectionLicensesView',
  props: {
    'sectionTitle': String,
    'sectionSubtitle':  String,
    'sectionData': Object
  },
  methods: {
    formatTitle(aCredential) {
      let credentialTitle = ''
      if ( aCredential.credentialTitle && aCredential.endorsements && aCredential.endorsements.length > 0 ) {
        let count = 0;
        let finalString = ''
        for (let i = 0; i < aCredential.endorsements.length; ++i ) {
          if ( count > 0 ) {
            finalString += " | "
          }
          finalString += aCredential.endorsements[i].label
          ++count;
        }
        credentialTitle = aCredential.credentialTitle + ' ('+finalString+')'
      } else {
        credentialTitle = aCredential.credentialTitle
      }

      if ( aCredential.expireDate && new Date(aCredential.expireDate).getTime() < new Date().getTime() ) {
        return credentialTitle + ' - <span style="color:red;font-weight:bold;">EXPIRED</span>'
      }
      return credentialTitle
    },
    formatIssuedDate(aDateString) {
      return new Date(aDateString).toLocaleDateString("en-US")
    },
    formatExpireDate(aDateString) {
      if ( aDateString ) {
        return new Date(aDateString).toLocaleDateString("en-US")
      } else {
        return 'Does Not Expire'
      }
    },
    formatResumeDaysCell(aCell) {
      if ( aCell.days > 0 ) {
        return '<div>'+aCell.days+'<span style="position:relative;top:-1px;font-size: 10px;color:#888;"> | '+aCell.verified.percent+'</span></div><div style="font-size: 10px;color:#888;">'+aCell.verified.crewmate+'-'+aCell.verified.charterCompany+'</div>'
      } else {
        return '--'
      }
    },
    activateAddCredentialModal() {
      this.$refs.addCredentialModal.activateModal()
    }
  }
}
</script>

<style>
.cr-resume-table {
  font-size:12px;
  width: 100%;
}

.cr-resume-table th {
  background: #f6f6f6;
}

.cr-resume-background-none {
  background: transparent!important;
}

.cr-resume-border-top {
  border-top: 1px solid #ccc;
}
.cr-resume-border-right {
  border-right: 1px solid #ccc;
}

.cr-resume-border-top-none {
  border-top:none!important;
}
.cr-resume-border-bottom-none {
  border-bottom:none!important;
}

.cr-resume-footer-note {
  font-size: 11px;
  color:#999;
}
</style>