<template>
  <div class="container mt-3">

    <div v-if="doingLogIn" id="process-sign-in" class="sign-in-spinner"><fai icon="spinner" spin/> Please wait while we get you signed in!</div>

    <div v-if="!doingLogIn" class="row justify-content-center">
      <div class="col-auto">
        <div class="card mt-3 bg-light">
          <div class="card-header" style="text-align: center;">
            <h5 class="card-title" style="margin-bottom: 0px;">Charter Rode Forgot Password</h5>
          </div>
          <div class="card-body">
            <div class="mb-3" style="text-align: center;">
              <fai icon="user-circle" style="font-size: 72px; color: #ccc"></fai>
            </div>

            <h6 v-if="!showVerificationCode" class="card-subtitle mb-2 text-muted" style="padding-right: 40px;">Enter email address for your account</h6>
            <h6 v-else class="card-subtitle mb-2 text-muted" style="padding-right: 40px;">Enter verification code sent to {{username}}</h6>

            <div v-if="errorDuringSetPassword" class="alert alert-danger">{{setPasswordErrorMessage}}</div>
            <div v-if="invalidVerificationCode" class="alert alert-danger">Verification code is required.</div>
            <div v-if="verificationCodeMismatch" class="alert alert-danger">Verification code does not match, please try again.</div>

            <div v-if="!showVerificationCode">
              <div class="card-text pt-3" style="color: #666;">
                <form autocomplete="off" novalidate v-on:submit.prevent="noop">
                  <div class="mb-3">
                    <input type="email" autocomplete="off" class="form-control" id="emailID" placeholder="francis.drake@gmail.com" v-model="username" @keyup.enter="sendVerificationCode">
                  </div>
                </form>
              </div>
              <div class="d-grid gap-2">
                <a class="btn btn-primary" v-on:click="sendVerificationCode" :disabled="sendingVerificationCode"><fai v-show="sendingVerificationCode" icon="spinner" spin/>  Send Verification Code</a>
              </div>
            </div>
            <div v-else>
              <div class="card-text pt-3" style="color: #666;">
                <form autocomplete="off" novalidate v-on:submit.prevent="noop">
                  <div class="mb-3">
                    <input type="text" autocomplete="off" class="form-control" id="verificationCode" placeholder="code" v-model="verificationCode" @keyup.enter="setNewPassword">
                  </div>
                  <div class="mb-3">
                    <input type="password" autocomplete="new-password" class="form-control" id="userPassword" placeholder="New Password" v-model="password" @keyup.enter="setNewPassword">
                  </div>
                  <div class="mb-3">
                    <input type="password" autocomplete="new-password" class="form-control" id="confirmUserPassword" placeholder="Confirm Password" v-model="confirmPassword" @keyup.enter="setNewPassword">
                  </div>
                </form>
              </div>
              <div class="d-grid gap-2">
                <a class="btn btn-primary" v-on:click="setNewPassword" :disabled="settingNewPassword"><fai v-show="settingNewPassword" icon="spinner" spin/> Set New Password</a>
              </div>
            </div>

            <div class="card-text mt-2" style="text-align: center;font-size:14px;">Already have an account?&nbsp;<router-link to="/sign-in" style="text-decoration: none;">Sign in</router-link></div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import auth from "../../api/auth"
import api from "../../api/api";

export default {
  name: 'ForgotPassword',
  data() {
    return {
      username: undefined,
      password: undefined,
      confirmPassword: undefined,
      doingLogIn: false,
      sendingVerificationCode: false,
      settingNewPassword: false,
      showVerificationCode: false,
      errorDuringSetPassword: false,
      setPasswordErrorMessage: undefined,
      invalidVerificationCode: false,
      verificationCodeMismatch: false,
      verificationCode: undefined,
      verifyingCode: false
    }
  },
  mounted() {
    document.getElementById('emailID').focus()
  },
  methods: {
    sendVerificationCode() {
      this.sendingVerificationCode = false
      this.verifyingCode = false
      this.verificationCode = undefined
      this.invalidVerificationCode = false
      this.setPasswordErrorMessage = undefined
      this.errorDuringSetPassword = false
      this.showVerificationCode = false
      this.doingLogIn = false

      // do form validation!
      if ( this.username == undefined || this.username.trim().length <= 0 ) {
        this.errorDuringSetPassword = true
        this.setPasswordErrorMessage = "Missing email address"
        return
      }

      let el = document.createElement('div')
      let domString = '<div id="sign-in-backdrop" class="modal-backdrop fade in" style=""></div>'
      el.innerHTML =  domString
      document.body.appendChild(el.firstChild);

      this.sendingVerificationCode = true
      auth.forgotPassword(this.username).then(response => {
        let signInBackDrop = document.getElementById('sign-in-backdrop')
        signInBackDrop.parentNode.removeChild(signInBackDrop)

        if ( response && !response.error ) {
          this.showVerificationCode = true
          this.sendingVerificationCode = false
          setTimeout(function () {
            document.getElementById('verificationCode').focus()
          }, 250)
        } else {
          // error
          this.sendingVerificationCode = false
          this.errorDuringSetPassword = true
          if ( response.message ) {
            this.setPasswordErrorMessage = response.message
          } else {
            this.setPasswordErrorMessage = "Unknown error, please try again"
          }
        }
      })
    },
    setNewPassword() {
      this.invalidVerificationCode = false
      this.setPasswordErrorMessage = undefined
      this.errorDuringSetPassword = false
      this.settingNewPassword = false

      // do form validation!
      if ( this.verificationCode == undefined || this.verificationCode.trim().length <= 0 ) {
        this.errorDuringSetPassword = true
        this.setPasswordErrorMessage = "Verification code required"
        return
      }
      if ( this.password == undefined || this.password.trim().length <= 0 ) {
        this.errorDuringSetPassword = true
        this.setPasswordErrorMessage = "New password required"
        return
      }
      if ( this.confirmPassword == undefined || this.confirmPassword.trim().length <= 0 ) {
        this.errorDuringSetPassword = true
        this.setPasswordErrorMessage = "Confirm password required"
        return
      }
      if ( this.confirmPassword != this.password ) {
        this.errorDuringSetPassword = true
        this.setPasswordErrorMessage = "New password and confirm password does not match"
        return
      }

      let el = document.createElement('div')
      let domString = '<div id="sign-in-backdrop" class="modal-backdrop fade in" style=""></div>'
      el.innerHTML =  domString
      document.body.appendChild(el.firstChild);

      this.settingNewPassword = true
      auth.forgotPasswordSubmit(this.username, this.verificationCode, this.password).then(response => {
        let signInBackDrop = document.getElementById('sign-in-backdrop')
        signInBackDrop.parentNode.removeChild(signInBackDrop)

        this.settingNewPassword = false
        if ( response && !response.error ) {
          // all good sign them in with the new password
          this.doingLogIn = true
          auth.signIn(this.username, this.password).then(response => {
            if ( response && response.jwtToken && response.jwtToken.length > 0 ) {
              this.$store.commit('setJWTToken', response.jwtToken)

              // alert(theJwtToken)
              api.getMe().then(response => {
                if (response.user && response.user.firstName && response.user.lastName) {
                  // all good let them go to home!
                  this.$store.commit('setUser', response.user)

                  // now get the drop downs
                  api.getUIDropdowns().then(uiDropdowns => {
                    if (uiDropdowns) {
                      this.$store.commit('setUiDropdowns', uiDropdowns)
                    }
                    this.$router.push('/')
                  }).catch(error => {
                    console.log(error)
                    this.$router.push('/sign-out')
                  })
                } else if ( response.createNewProfile ) {
                  // need to create user
                  // get rid of the static back drop
                  // now get the drop downs
                  api.getUIDropdowns().then(uiDropdowns => {
                    if ( uiDropdowns ) {
                      this.$store.commit('setUiDropdowns', uiDropdowns)
                    }
                    this.$store.commit('startCreateUserFlow')
                    this.$router.push('/createUser')
                  }).catch(error => {
                    console.log(error)
                    this.$router.push('/sign-out')
                  })
                } else {
                  // not sure what happened, likely server-side is down....
                  this.$router.push('/maintenance')
                }
              }).catch(error => {
                console.log(error)
                this.$router.push('/sign-out')
              })
            } else {
              this.doingLogIn = false
              this.$router.push('/sign-in')
            }
          })
        } else {
          // issue - maybe let them send another code or try again?
          this.errorDuringSetPassword = true
          if ( response.message ) {
            this.setPasswordErrorMessage = response.message
          } else {
            this.setPasswordErrorMessage = "Unknown error, please try again"
          }
        }
      })
    }
  },
  components: {

  }
}
</script>

<style>
.sign-in-spinner {
  text-align: center;
  margin-top:50px;
  font-size:24px;
  color:#666;
  font-weight: normal;
  z-index: 9998;
}

#sign-in-backdrop {
  z-index: 9999;
}

</style>