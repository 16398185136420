<template>
  <teleport to="body">
    <!-- Modal -->
    <div class="modal fade" id="paymentProcessingModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="addNewVesselLabel">Fund Wallet</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">

            <table class="mb-4" style="width: 100%;color:#666;font-size: 14px;" v-if="cart.crtAmount">
              <tr>
                <td>Exchange {{cart.crtAmount}} CRT</td>
                <td style="text-align: right">{{cart.usDollarAmount}} USD</td>
              </tr>
              <tr>
                <td>Processing Fee (4.00%)</td>
                <td style="text-align: right">{{cart.txFeeAmount}} USD</td>
              </tr>
              <tr>
                <td class="pt-3" style="font-weight: bold;">Total Charges Today</td>
                <td class="pt-3" style="font-weight:bold;text-align: right">{{cart.totalChargesTodayUSD}} USD</td>
              </tr>
            </table>

            <div class="container mb-3" v-else>
              <div class="row">
                <div class="col-12">
                  <div class="payment-creation-spinner"><fai icon="spinner" spin/> Please wait while we create your secure payment session</div>
               </div>
              </div>
            </div>

            <div>
              <form id="payment-form">
                <div id="card-element"><!--Stripe.js injects the Card Element--></div>
                <button class="btn btn-primary" id="payment-button">
                  <div class="payment-spinner hidden" id="spinner"></div>
                  <span id="button-text" style="text-transform: uppercase"> Pay Now</span>
                </button>
                <p id="card-error" role="alert"></p>
                <div id="result-message" class="mb-3 result-message hidden" style="text-align: center;color: #666;">
                  <div class="mb-2" style="font-weight: bold;">Payment succeeded!</div>
                  <div style="font-style: italic;">It may take several minutes for your wallet to be funded. Lookout for an email confirming your wallet funding.</div>
                </div>
              </form>
            </div>

            <div style="text-align: center;font-size: 12px;">
              <i>All CRT Exchanges are via secure one-time credit card payments. An additional 4% fee applies to all CRT Exchanges to offset fees charged by our payment gateway provider, <a href="https://stripe.com/" target="_blank">Stripe</a>.</i>
            </div>


          </div>
        </div>
      </div>
    </div>
  </teleport>

</template>

<script>
import api from "../../api/api";
import {Modal} from "bootstrap";

export default {
  name: 'PaymentProcessingModal',
  emits: ['modalClose','paymentSuccessful'],
  data() {
    return {
      stripeAPIToken: process.env.VUE_APP_STRIPE_API_TOKEN,
      sentPaymentSuccessfulEvent: false,
      cart : {
        requestedCrtAmount: null,
        crtAmount: null,
        usDollarAmount: null,
        txFeeAmount: null,
        totalChargesTodayUSD: null
      }
    }
  },
  mounted() {
    this.includeStripe('https://js.stripe.com/v3/', function() {}.bind(this) )
  },
  methods: {
    /* Includes Stripe.js dynamically */
    includeStripe( URL, callback ){
      let documentTag = document, tag = 'script',
          object = documentTag.createElement(tag),
          scriptTag = documentTag.getElementsByTagName(tag)[0]
      object.src = URL
      if (callback) {
        object.addEventListener('load', function (e) {
          callback(null, e) }
            , false) }
      scriptTag.parentNode.insertBefore(object, scriptTag)
    },
    activatePaymentModal(crtAmount) {
      // clear out anything that may be lingering about
      document.getElementById("card-element").innerHTML = ""
      this.cart = {
        requestedCrtAmount: null,
        crtAmount: null,
        usDollarAmount: null,
        txFeeAmount: null,
        totalChargesTodayUSD: null
      }
      this.cart.requestedCrtAmount = crtAmount
      this.paymentProcessingModal = new Modal(document.getElementById('paymentProcessingModal'))
      let paymentProcessingEl = document.getElementById('paymentProcessingModal')
      let self = this
      paymentProcessingEl.addEventListener('hidden.bs.modal', function () {
        if ( !self.sentPaymentSuccessfulEvent ) {
          self.$emit('modalClose')
        }
      })
      this.paymentProcessingModal.toggle()

      // A reference to Stripe.js initialized with your real test publishable API key.
      // eslint-disable-next-line no-undef
      const stripe = Stripe(this.stripeAPIToken);

      // Disable the button until we have Stripe set up on the page
      document.getElementById("payment-button").disabled = true;

      api.createPaymentIntent(this.cart.requestedCrtAmount).then(data => {
        const elements = stripe.elements();

        const formatter = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 2
        })

        // our data return has all of the components broken out
        this.cart.crtAmount = data.crtAmount+''
        this.cart.usDollarAmount = formatter.format(data.usDollarAmountPennies  / 100)
        this.cart.txFeeAmount = formatter.format(data.txFeePennies / 100)
        this.cart.totalChargesTodayUSD = formatter.format(data.totalUSDPennies  / 100)

        const style = {
          base: {
            color: "#32325d",
            fontFamily: 'Arial, sans-serif',
            fontSmoothing: "antialiased",
            fontSize: "16px",
            "::placeholder": {
              color: "#32325d"
            }
          },
          invalid: {
            fontFamily: 'Arial, sans-serif',
            color: "#fa755a",
            iconColor: "#fa755a"
          }
        }

        let card = elements.create("card", { style: style })
        // Stripe injects an iframe into the DOM
        card.mount("#card-element")

        card.on("change", function (event) {
          // Disable the Pay button if there are no card details in the Element
          document.getElementById("payment-button").disabled = event.empty
          document.querySelector("#card-error").textContent = event.error ? event.error.message : ""
        })

        let form = document.getElementById("payment-form")
        let self = this
        form.addEventListener("submit", function(event) {
          event.preventDefault()
          // Complete payment when the submit button is clicked
          self.payWithCard(stripe, card, data.clientSecret);
        })
      })
    },
    payWithCard(stripe, card, clientSecret) {
      // Calls stripe.confirmCardPayment
      // If the card requires authentication Stripe shows a pop-up modal to
      // prompt the user to enter authentication details without leaving your page.
      this.loading(true)
      let self = this
      stripe.confirmCardPayment(clientSecret, {
            payment_method: {
              card: card
            }
          })
          .then(function(result) {
            if (result.error) {
              // Show error to your customer
              self.showError(result.error.message)
            } else {
              // The payment succeeded!
              self.orderComplete()
            }
          })
    },
    orderComplete() {
      // we don't let the back-end know as we only accept success via webhooks directly from Stripe!
      document.querySelector(".result-message").classList.remove("hidden")
      this.loading(false)
      document.querySelector("button").disabled = true
      this.sentPaymentSuccessfulEvent = true
      // show this for a brief while longer...
      let self = this
      setTimeout(() => {
        document.getElementById('result-message').className = 'mb-3 result-message hidden'
        self.paymentProcessingModal.hide()
        self.$emit('paymentSuccessful')
      }, 3000)
    },
    showError(errorMsgText) {
      // Show the customer the error from Stripe if their card fails to charge
      this.loading(false)
      let errorMsg = document.querySelector("#card-error")
      errorMsg.textContent = errorMsgText
      setTimeout(function() {
        errorMsg.textContent = ""
      }, 6000)
    },
    loading(isLoading) {
      // Show a spinner on payment submission
      if (isLoading) {
        // Disable the button and show a spinner
        document.getElementById("payment-button").disabled = true
        document.querySelector("#spinner").classList.remove("hidden")
        document.querySelector("#button-text").classList.add("hidden")
      } else {
        document.getElementById("payment-button").disabled = false
        document.querySelector("#spinner").classList.add("hidden")
        document.querySelector("#button-text").classList.remove("hidden")
      }
    }
  },
  components: {

  }
}
</script>

<style>

/*input {*/
/*  border-radius: 6px;*/
/*  margin-bottom: 6px;*/
/*  padding: 12px;*/
/*  border: 1px solid rgba(50, 50, 93, 0.1);*/
/*  height: 44px;*/
/*  font-size: 16px;*/
/*  width: 100%;*/
/*  color: #666;*/
/*  background: white;*/
/*}*/

.result-message {
  line-height: 22px;
  font-size: 16px;
}

.result-message a {
  color: rgb(89, 111, 214);
  font-weight: 600;
  text-decoration: none;
}

.hidden {
  display: none;
}

#card-error {
  color: rgb(105, 115, 134);
  text-align: left;
  font-size: 13px;
  line-height: 17px;
  margin-top: 12px;
}

#card-element {
  border-radius: 4px 4px 0 0 ;
  padding: 12px;
  border: 1px solid rgba(50, 50, 93, 0.1);
  height: 44px;
  width: 100%;
  background: white;
}

/* Buttons and links */
#payment-button {
  border-radius: 0 0 4px 4px!important;
  border: 0;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
}
#payment-button:hover {
  filter: contrast(115%);
}
#payment-button:disabled {
  opacity: 0.5;
  cursor: default;
}

/* spinner/processing state, errors */
.payment-spinner,
.payment-spinner:before,
.payment-spinner:after {
  border-radius: 50%;
}
.payment-spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
.payment-spinner:before,
.payment-spinner:after {
  position: absolute;
  content: "";
}
.payment-spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #5469d4;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}
.payment-spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #5469d4;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}

@-webkit-keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.payment-creation-spinner {
  text-align: center;
  margin-top:5px;
  font-size:16px;
  color:#666;
  font-weight: normal;
  z-index: 9999;
}
</style>
