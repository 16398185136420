<template>
  <nav class="navbar navbar-expand-sm navbar-dark bg-primary sticky-top no-print">
    <div class="container-fluid">
      <router-link class="navbar-brand" v-on:click="collapseNavbarToggler" to="/"><fai icon="anchor" fixed-width /> CR</router-link>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon small-nav-toggle"></span>
      </button>

      <div v-if="isLoggedIn && !createUserFlow" class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-md-0 mb-lg-0">
          <li v-show="!validateUserIdentityFlow && !isMaintenance" class="nav-item"><router-link class="nav-link" v-on:click="collapseNavbarToggler" to="/resume"><fai icon="file-alt" fixed-width /> Resume</router-link></li>
          <li v-show="!validateUserIdentityFlow && !isMaintenance" class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><fai icon="book" fixed-width/> Logbook</a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <li class="nav-item"><router-link class="dropdown-item" v-on:click="collapseNavbarToggler" to="/tx/submit"><fai icon="plus" fixed-width /> New Entry</router-link></li>
              <li class="nav-item"><router-link class="dropdown-item" v-on:click="collapseNavbarToggler" to="/tx/list"><fai icon="list-alt" fixed-width /> All Entries</router-link></li>
              <li class="nav-item"><router-link class="dropdown-item" v-on:click="collapseNavbarToggler" to="/vessels"><fai icon="ship" fixed-width /> Vessels</router-link></li>
              <li class="nav-item"><router-link class="dropdown-item" v-on:click="collapseNavbarToggler" to="/crewmates"><fai icon="users" fixed-width /> Crewmates</router-link></li>
            </ul>
          </li>
          <li v-show="!isMaintenance" class="nav-item"><router-link class="nav-link"  v-on:click="collapseNavbarToggler" to="/profile"><fai icon="user-circle" fixed-width /> Profile</router-link></li>
          <li class="nav-item"><router-link class="nav-link" to="/sign-out"><fai icon="sign-out-alt" fixed-width /> Sign Out</router-link></li>
        </ul>
        <span v-show="newUserWorkflowStepEqualComplete" class="navbar-text">
          <router-link class="cr-nav-link nav-link" v-on:click="collapseNavbarToggler" to="/wallet"><span class="badge bg-secondary bg-success"><fai icon="wallet" class="fa-fw"></fai> {{walletFunds}} CRT</span></router-link>
        </span>
      </div>
    </div>
  </nav>
  <router-view/>
  <div class="cr-footer-container mt-3">
    <div>Copyright © 2021-{{year}} Charter Rode All Rights Reserved<br><a class="cr-footer-links" href="https://www.charterrode.com/terms-of-use/">Terms Of Use</a> | <a class="cr-footer-links" href="https://www.charterrode.com/privacy-policy/">Privacy Policy</a></div>
  </div>
</template>

<script>

import api from "./api/api";

export default {
  name: 'App',
  data() {
    return {
      user: this.$store.state.user,
      year: new Date().getFullYear()
    }
  },
  mounted() {
    // check to see last time we updated the dropdowns!
    if ( this.$store.state.isLoggedIn ) {
      let currentTimeStamp = Date.now()
      let refreshTime = parseInt(this.$store.state.uiDropdownsUpdatedAt ? this.$store.state.uiDropdownsUpdatedAt : 0) + 24 * 60 * 60 * 1000 // 24 hours max
      if (currentTimeStamp > refreshTime) {
        // now get the drop-downs
        api.getUIDropdowns().then(uiDropdowns => {
          if (uiDropdowns) {
            this.$store.commit('setUiDropdowns', uiDropdowns)
          }
        })
      }
    }
  },
  methods: {
    collapseNavbarToggler() {
      if ( window.getComputedStyle(document.querySelector("button.navbar-toggler")).display !== 'none' ) {
        let className = document.getElementById("navbarSupportedContent").className;
        if (className === 'navbar-collapse collapse show') {
          document.getElementById("navbarSupportedContent").className = 'navbar-collapse collapse'
        }
      }
    }
  },
  computed: {
    isMaintenance() {
      return this.$store.state.isMaintenance
    },
    isLoggedIn() {
      return this.$store.state.isLoggedIn
    },
    createUserFlow() {
      return this.$store.state.createUserFlow
    },
    validateUserIdentityFlow() {
      return this.$store.state.validateUserIdentityFlow
    },
    newUserWorkflowStepEqualComplete() {
      if ( !this.$store.state.user ) {
        return false
      }
      if ( this.$store.state.user.identityValidated ) {
        return true
      }
      return this.$store.state.user.newUserWorkflowStep === 'complete'
    },
    walletFunds() {
      if ( this.$store.state.user && this.$store.state.user.currentWalletFundsCRT ) {
        return this.$store.state.user.currentWalletFundsCRT
      }
      return '0'
    }
  }
}
</script>

<style>
body {
  color: #444;
}

.cr-nav-link {
  padding:0px!important;
}

.table-caption {
  color: #888;
  font-size: 14px;
  position: absolute;
  bottom: 0;
  right: 0;
}
.form-control {
  color: #555;
}

.charterRodeBtn:hover, .charterRodeLink:hover {
  cursor: pointer;
}

.charterRodeLink {
  color: #0d6efd;
  text-decoration:underline;
}


.dropdown-item {
  color: #444;
}

.small-nav-toggle {
   font-size: 14px!important;
}

.charterRodeHelpText {
  color: #aaa;
}

.cr-z-index-5 {
  z-index: 5;
}

.disclaimer-text {
  font-size: 16px;
  text-align: center;
}

.disclaimer-text-left {
  font-size: 16px;
  text-align: left;
}

select {
  font-size: 16px!important;
}

.form-floating>.form-control:focus, .form-floating>.form-control:not(:placeholder-shown) {
  padding-bottom: 0.125rem!important;
}

.cr-footer-container {
  text-align: center;
  font-size:10px;
  color: #bbb;
  margin-bottom:25px;
}
.cr-footer-links {
  color: #bbb;
}

@media print
{
  .no-print, .no-print *
  {
    display: none !important;
  }
}

</style>
