<template>

  <teleport to="body">
    <!-- Modal -->
    <div class="modal fade" id="addNewVessel" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true" v-on:keyup.enter="addVessel">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-fullscreen">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="addNewVesselLabel">Add New Vessel</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="pb-3 charterRodeHelpText">Please let us know about this vessel. We need all this information to ensure that your experience is accurately reflected on Charter Rode. Charter Rode will verify ownership of all vessels before verifying any transactions submitted.</div>

            <div class="container" v-show="activelySubmittingNewVessel">
              <div class="row">
                <div class="col-sm-12">
                  <div class="tx-submit-spinner mt-lg-5"><fai icon="spinner" spin/> Please wait while your vessel is added</div>
                </div>
              </div>
            </div>

            <form autocomplete="off" novalidate v-on:submit.prevent="noop">
              <div class="container" v-show="!activelySubmittingNewVessel">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="form-floating mb-4">
                      <select class="form-control form-control-sm" id="newVessel.owner" v-model="newVessel.owner" name="owner" v-bind:class="{'is-valid' : validation.newVessel.owner, 'is-invalid': validation.newVessel.owner == false}">
                        <option value="please_select" disabled selected>Please Select</option>
                        <option value="self">I Own This Vessel</option>
                        <option value="friend">My Friend Owns This Vessel</option>
                        <option value="charter_base">I Charter This Vessel</option>
                        <option value="commercial">Commercial</option>
                      </select>
                      <label for="newVessel.owner">Who Owns This Vessel</label>
                    </div>
                  </div>
                </div>

                <div class="row" v-show="newVessel.owner === 'commercial'">
                  <div class="col-lg-6">
                    <div class="form-floating mb-4">
                      <input type="text" class="form-control" id="newVessel.commercialOwnerName" name="commercialOwnerName" v-model="newVessel.commercialOwnerName" v-bind:class="{'is-valid' : validation.newVessel.commercialOwnerName, 'is-invalid': validation.newVessel.commercialOwnerName == false}">
                      <label for="newVessel.commercialOwnerName">Owner Company Name</label>
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div class="form-floating mb-4">
                      <input type="text" class="form-control" id="newVessel.commercialVesselFlag" name="commercialVesselFlag" v-model="newVessel.commercialVesselFlag" v-bind:class="{'is-valid' : validation.newVessel.commercialVesselFlag, 'is-invalid': validation.newVessel.commercialVesselFlag == false}">
                      <label class="d-none d-md-block" for="newVessel.commercialVesselFlag">Vessel Flag (Country)</label>
                      <label class="d-block d-md-none" for="newVessel.commercialVesselFlag">Vessel Flag</label>
                    </div>
                  </div>
                </div>

                <div class="row" v-show="newVessel.owner === 'charter_base'">
                  <div class="col-lg-6">
                    <div class="form-floating mb-4">
                      <input type="text" class="form-control" id="newVessel.charterCompanyName" name="charterCompanyName" v-model="newVessel.charterCompanyName" v-bind:class="{'is-valid' : validation.newVessel.charterCompanyName, 'is-invalid': validation.newVessel.charterCompanyName == false}">
                      <label for="newVessel.charterCompanyName">Charter Company Name</label>
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div class="form-floating mb-4">
                      <input type="text" class="form-control" id="newVessel.charterBase" name="charterBase" v-model="newVessel.charterBase" v-bind:class="{'is-valid' : validation.newVessel.charterBase, 'is-invalid': validation.newVessel.charterBase == false}">
                      <label class="d-none d-md-block" for="newVessel.charterBase">Charter Base Location (City, Province, Country)</label>
                      <label class="d-block d-md-none" for="newVessel.charterBase">Charter Base Location</label>
                    </div>
                  </div>
                </div>
                <div class="row" v-show="newVessel.owner === 'charter_base'">
                  <div class="col-lg-6">
                    <div class="form-floating mb-4">
                      <input type="text" class="form-control" id="newVessel.charterCompanyPhone" name="charterCompanyPhone" v-model="newVessel.charterCompanyPhone">
                      <label for="newVessel.charterCompanyPhone">Charter Company Phone</label>
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div class="form-floating mb-4">
                      <input type="email" class="form-control" id="newVessel.charterCompanyEmail" name="charterCompanyEmail" v-model="newVessel.charterCompanyEmail">
                      <label for="newVessel.charterCompanyEmail">Charter Company Email</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-6">
                    <div class="form-floating mb-4">
                      <input type="text" class="form-control" id="newVessel.vesselName" name="vesselName" v-model="newVessel.vesselName" v-bind:class="{'is-valid' : validation.newVessel.vesselName, 'is-invalid': validation.newVessel.vesselName == false}">
                      <label for="newVessel.vesselName">Vessel Name</label>
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div class="form-floating mb-4">
                      <input type="number" class="form-control" id="newVessel.year" name="year" min="1950" max="2023" step="1" v-model="newVessel.year" v-bind:class="{'is-valid' : validation.newVessel.year, 'is-invalid': validation.newVessel.year == false}">
                      <label for="newVessel.year">Year</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-6">
                    <div class="form-floating mb-4">
                      <input type="text" class="form-control" id="newVessel.manufacturer" name="manufacturer" v-model="newVessel.manufacturer" v-bind:class="{'is-valid' : validation.newVessel.manufacturer, 'is-invalid': validation.newVessel.manufacturer == false}">
                      <label for="newVessel.manufacturer">Builder</label>
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div class="form-floating mb-4">
                      <input type="text" class="form-control" id="newVessel.model" name="model" v-model="newVessel.model" v-bind:class="{'is-valid' : validation.newVessel.model, 'is-invalid': validation.newVessel.model == false}">
                      <label for="newVessel.model">Model</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-12">
                    <div class="form-floating mb-4">
                      <input type="text" class="form-control" id="newVessel.registrationInfo" name="registrationInfo" v-model="newVessel.registrationInfo" v-bind:class="{'is-valid' : validation.newVessel.registrationInfo, 'is-invalid': validation.newVessel.registrationInfo == false}">
                      <label for="newVessel.vesselName">Registration Info</label>
                      <div id="newVesselRegistrationInfoHelpBlock" class="form-text"><span v-show="newVessel.owner != 'commercial'"><strong>Optional</strong> - official number(s) listed on the registration, certificate, or document (for use with <a href="https://www.dco.uscg.mil/Portals/9/NMC/pdfs/forms/CG_719S.pdf" target="_blank">USCG Form CG-719S</a> to document <a href="https://www.dco.uscg.mil/Portals/9/NMC/pdfs/professional_qualifications/crediting_sea_service.pdf" target="_blank">small vessel sea time</a> for USCG Credentials).</span><span v-show="newVessel.owner === 'commercial'">Official number(s) listed on the registration, certificate, or document.</span></div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-6">
                    <div class="form-floating mb-4">
                      <input type="number" class="form-control" id="newVessel.grossTons" name="grossTons" min=".1" max="250" step=".1" v-model="newVessel.grossTons" v-bind:class="{'is-valid' : validation.newVessel.grossTons, 'is-invalid': validation.newVessel.grossTons == false}">
                      <label for="newVessel.grossTons">Gross Tonnage (GT)</label>
                      <div id="newVesselGrossTonsHelpBlock" class="form-text">If you don't know or can't find GT, leave blank to be calculated for you</div>
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div class="form-floating mb-4">
                      <input type="number" class="form-control" id="newVessel.displacementTons" name="displacementTons" min=".1" max="250" step=".1" v-model="newVessel.displacementTons" v-bind:class="{'is-valid' : validation.newVessel.displacementTons, 'is-invalid': validation.newVessel.displacementTons == false}">
                      <label for="newVessel.displacementTons">Displacement (tons)</label>
                      <div id="newVesselDisplacementTonsHelpBlock" class="form-text">This is the actual weight of the boat, <a class="link-secondary" href="https://sailboatdata.com/" target="_blank">usually able to look it up here</a></div>
                    </div>
                  </div>
                </div>

                <div class="row">

                  <div class="col-lg-6">
                    <div class="form-floating mb-4">
                      <input type="number" class="form-control" id="newVessel.overallLengthMeter" name="overallLengthMeter" min=".5" max="250" step=".1" v-model="newVessel.overallLengthMeter" v-bind:class="{'is-valid' : validation.newVessel.overallLengthMeter, 'is-invalid': validation.newVessel.overallLengthMeter == false}">
                      <label for="newVessel.overallLengthMeter" v-if="user.preferredUnits == 'metric'">Overall Length (meters)</label>
                      <label for="newVessel.overallLengthMeter" v-else>Overall Length (feet)</label>
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div class="form-floating mb-4">
                      <input type="text" class="form-control" id="newVessel.waterLineLengthMeter" name="waterLineLengthMeter" min=".5" max="250" step=".1" v-model="newVessel.waterLineLengthMeter" v-bind:class="{'is-valid' : validation.newVessel.waterLineLengthMeter, 'is-invalid': validation.newVessel.waterLineLengthMeter == false}">
                      <label for="newVessel.waterLineLengthMeter" v-if="user.preferredUnits == 'metric'">Water Line Length (meters)</label>
                      <label for="newVessel.waterLineLengthMeter" v-else>Water Line Length (feet)</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-6">
                    <div class="form-floating mb-4">
                      <input type="number" class="form-control" id="newVessel.beamMeter" name="beamMeter" min=".5" max="250" step=".1" v-model="newVessel.beamMeter" v-bind:class="{'is-valid' : validation.newVessel.beamMeter, 'is-invalid': validation.newVessel.beamMeter == false}">
                      <label for="newVessel.beamMeter" v-if="user.preferredUnits == 'metric'">Beam (meters)</label>
                      <label for="newVessel.beamMeter" v-else>Beam (feet)</label>
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div class="form-floating mb-4">
                      <input type="number" class="form-control" id="newVessel.draftMeter" name="draftMeter" min="0" max="30" step=".1" v-model="newVessel.draftMeter" v-bind:class="{'is-valid' : validation.newVessel.draftMeter, 'is-invalid': validation.newVessel.draftMeter == false}">
                      <label for="newVessel.draftMeter" v-if="user.preferredUnits == 'metric'">Draft (meters)</label>
                      <label for="newVessel.draftMeter" v-else>Draft (feet)</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-6">
                    <div class="form-floating mb-4">
                      <select class="form-control form-control-sm" id="newVessel.hulls" name="hulls" v-model="newVessel.hulls" v-bind:class="{'is-valid' : validation.newVessel.hulls, 'is-invalid': validation.newVessel.hulls == false}">
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </select>
                      <label for="newVessel.hulls"># Hulls</label>
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div class="form-floating mb-4">
                      <select class="form-control form-control-sm" id="vessel.propulsion" name="vessel.propulsion" v-model="newVessel.propulsion" v-bind:class="{'is-valid' : validation.newVessel.propulsion, 'is-invalid': validation.newVessel.propulsion == false}">
                        <option value="sail">Sail</option>
                        <option value="power">Power</option>
                      </select>
                      <label for="vessel.propulsion">Propulsion</label>
                    </div>
                  </div>
                </div>

                <div class="row pb-3 pt-3">
                  <div class="col-sm-12">
                    <div class="alert" v-bind:class="{'alert-danger': !newVessel.confirmReadOnly, 'alert-success': newVessel.confirmReadOnly}" role="alert">
                      <div class="disclaimer-text">
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="checkbox" id="confirmReadOnly" v-model="newVessel.confirmReadOnly">
                          <label class="form-check-label charterRodeBtn" for="confirmReadOnly">
                            I certify that this information is accurate to the best of my knowledge and I understand that I am not able to edit this vessel's information once I click 'Add Vessel' below.
                          </label>
                        </div>
                      </div>
                    </div>
                    <div id="click2AgreeToReadOnlyHelpBlock" class="form-text">Vessels are not editable in order to ensure the integrity of all data that is confirmed on Charter Rode. If vessel specs can change at any time, then no one would be able to trust information on Charter Rode. If you make a mistake, you should add a new vessel with the correct specifications.</div>
                  </div>
                </div>

              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal" v-bind:disabled="activelySubmittingNewVessel">Cancel</button>
            <button type="button" class="btn btn-primary" v-on:click="addVessel" v-bind:disabled="activelySubmittingNewVessel">Add New Vessel</button>
          </div>
        </div>
      </div>
    </div>
  </teleport>


  <teleport to="body">
    <div class="position-fixed bottom-0 end-0 p-3 cr-z-index-5">
      <div id="addVesselToast" class="toast hide" role="alert" aria-live="assertive" aria-atomic="true">
        <div class="toast-header">
          <strong class="me-auto">Vessel Added Successfully!</strong>
          <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
        </div>
        <div class="toast-body">Your new vessel has been added successfully submitted</div>
      </div>
    </div>
  </teleport>

</template>

<script>
import api from "../../api/api"
import {Modal,Toast} from "bootstrap"

export default {
  name: 'AddNewVesselModal',
  emits: ['setVessel','modalClose'],
  props: {
    'user': Object
  },
  data() {
    return {
      setVesselCalled: false,
      activelySubmittingNewVessel: false,
      newVessel: {
        confirmReadOnly: false,
        owner: "please_select",
        charterCompanyName: undefined,
        charterBase: undefined,
        commercialOwnerName:undefined,
        commercialVesselFlag: undefined,
        vesselName: undefined,
        year: undefined,
        manufacturer: undefined,
        model: undefined,
        registrationInfo: undefined,
        grossTons: undefined,
        displacementTons: undefined,
        overallLengthMeter: undefined,
        waterLineLengthMeter: undefined,
        beamMeter: undefined,
        draftMeter: undefined,
        hulls: "1",
        propulsion: "sail"
      },
      validation: {
        newVessel : {
          confirmReadOnly: undefined,
          owner: undefined,
          charterCompanyName: undefined,
          charterBase: undefined,
          commercialOwnerName: undefined,
          commercialVesselFlag: undefined,
          vesselName: undefined,
          year: undefined,
          manufacturer: undefined,
          model: undefined,
          registrationInfo: undefined,
          grossTons: undefined,
          displacementTons: undefined,
          overallLengthMeter: undefined,
          waterLineLengthMeter: undefined,
          beamMeter: undefined,
          draftMeter: undefined,
          hulls: undefined,
          propulsion: undefined
        }
      }
    }
  },
  methods: {
    activateModal() {
      this.newVessel = {
        confirmReadOnly: false,
        owner: "please_select",
        charterCompanyName: undefined,
        charterBase: undefined,
        commercialOwnerName:undefined,
        commercialVesselFlag: undefined,
        vesselName: undefined,
        year: undefined,
        manufacturer: undefined,
        model: undefined,
        registrationInfo: undefined,
        grossTons: undefined,
        displacementTons: undefined,
        overallLengthMeter: undefined,
        waterLineLengthMeter: undefined,
        beamMeter: undefined,
        draftMeter: undefined,
        hulls: "1",
        propulsion: "sail"
      }
      this.validation.newVessel = {
        confirmReadOnly: undefined,
        owner: undefined,
        charterCompanyName: undefined,
        charterBase: undefined,
        commercialOwnerName: undefined,
        commercialVesselFlag: undefined,
        vesselName: undefined,
        year: undefined,
        manufacturer: undefined,
        model: undefined,
        registrationInfo: undefined,
        grossTons: undefined,
        displacementTons: undefined,
        overallLengthMeter: undefined,
        waterLineLengthMeter: undefined,
        beamMeter: undefined,
        draftMeter: undefined,
        hulls: undefined,
        propulsion: undefined
      }
      this.addVesModal = new Modal(document.getElementById('addNewVessel'))
      let addVesEl = document.getElementById('addNewVessel')
      let self = this
      addVesEl.addEventListener('hidden.bs.modal', function () {
        if ( !self.setVesselCalled ) {
          self.$emit('modalClose')
        }
      })

      if ( this.user.preferredUnits == 'imperial' ) {
        this.convertFromFeetToMeters = true
      } else {
        this.convertFromFeetToMeters = false
      }

      this.addVesModal.toggle()
    },
    addVessel () {
      // do validation
      let isFormValid = true

      if ( this.newVessel.confirmReadOnly ) {
        this.validation.newVessel.confirmReadOnly = true
      } else {
        this.validation.newVessel.confirmReadOnly = false
        isFormValid = false
      }
      if ( this.newVessel.owner !== "please_select" ) {
        this.validation.newVessel.owner = true
      } else {
        this.validation.newVessel.owner = false
        isFormValid = false
      }

      if ( this.newVessel.owner === "charter_base" ) {
        this.validation.newVessel.commercialOwnerName = undefined
        this.validation.newVessel.commercialVesselFlag = undefined
        this.newVessel.commercialOwnerName = undefined
        this.newVessel.commercialVesselFlag = undefined
        if (this.newVessel.charterCompanyName && this.newVessel.charterCompanyName.trim().length > 5) {
          this.validation.newVessel.charterCompanyName = true
        } else {
          if (this.newVessel.charterCompanyName) {
            this.newVessel.charterCompanyName = this.newVessel.charterCompanyName.trim()
          }
          this.validation.newVessel.charterCompanyName = false
          isFormValid = false
        }
        if (this.newVessel.charterBase && this.newVessel.charterBase.trim().length > 5) {
          this.validation.newVessel.charterBase = true
        } else {
          if (this.newVessel.charterBase) {
            this.newVessel.charterBase = this.newVessel.charterBase.trim()
          }
          this.validation.newVessel.charterBase = false
          isFormValid = false
        }
      } else if ( this.newVessel.owner === "commercial" ) {
        this.validation.newVessel.charterCompanyName = undefined
        this.validation.newVessel.charterBase = undefined
        this.newVessel.charterCompanyName = undefined
        this.newVessel.charterBase = undefined
        if (this.newVessel.commercialOwnerName && this.newVessel.commercialOwnerName.trim().length > 5) {
          this.validation.newVessel.commercialOwnerName = true
        } else {
          if (this.newVessel.commercialOwnerName) {
            this.newVessel.commercialOwnerName = this.newVessel.commercialOwnerName.trim()
          }
          this.validation.newVessel.commercialOwnerName = false
          isFormValid = false
        }
        if (this.newVessel.commercialVesselFlag && this.newVessel.commercialVesselFlag.trim().length > 2) {
          this.validation.newVessel.commercialVesselFlag = true
        } else {
          if (this.newVessel.commercialVesselFlag) {
            this.newVessel.commercialVesselFlag = this.newVessel.commercialVesselFlag.trim()
          }
          this.validation.newVessel.commercialVesselFlag = false
          isFormValid = false
        }
      } else {
        this.validation.newVessel.charterCompanyName = undefined
        this.validation.newVessel.charterBase = undefined
        this.validation.newVessel.commercialOwnerName = undefined
        this.validation.newVessel.commercialVesselFlag = undefined
        this.newVessel.charterCompanyName = undefined
        this.newVessel.charterBase = undefined
        this.newVessel.commercialOwnerName = undefined
        this.newVessel.commercialVesselFlag = undefined
      }

      if (this.newVessel.vesselName && this.newVessel.vesselName.trim().length > 0) {
        this.validation.newVessel.vesselName = true
      } else {
        if (this.newVessel.vesselName) {
          this.newVessel.vesselName = this.newVessel.vesselName.trim()
        }
        this.validation.newVessel.vesselName = false
        isFormValid = false
      }

      try {
        let maxYear = parseInt(new Date().getFullYear()) + 1
        let year = parseInt(this.newVessel.year)
        if ( year !== undefined && year >= 1950 && year <= maxYear ) {
          this.validation.newVessel.year = true
        } else {
          this.validation.newVessel.year = false
          isFormValid = false
        }
      } catch (err) {
        this.validation.newVessel.year = false
        isFormValid = false
      }

      if (this.newVessel.manufacturer && this.newVessel.manufacturer.trim().length > 0) {
        this.validation.newVessel.manufacturer = true
      } else {
        if (this.newVessel.manufacturer) {
          this.newVessel.manufacturer = this.newVessel.manufacturer.trim()
        }
        this.validation.newVessel.manufacturer = false
        isFormValid = false
      }

      if (this.newVessel.model && this.newVessel.model.trim().length > 0) {
        this.validation.newVessel.model = true
      } else {
        if (this.newVessel.model) {
          this.newVessel.model = this.newVessel.model.trim()
        }
        this.validation.newVessel.model = false
        isFormValid = false
      }

      if ( this.newVessel.registrationInfo ) {
        this.newVessel.registrationInfo = this.newVessel.registrationInfo.trim()
      }

      try {
        let grossTonnage = parseFloat(this.newVessel.grossTons)
        if ( grossTonnage !== undefined && grossTonnage >= 0 ) {
          this.validation.newVessel.grossTons = true
        } else {
          // try to calculate for them....
          this.calculateGrossTonnage()
          let grossTonnage = parseFloat(this.newVessel.grossTons)
          if ( grossTonnage !== undefined && grossTonnage >= 0 ) {
            this.validation.newVessel.grossTons = true
          } else {
            this.validation.newVessel.grossTons = false
            isFormValid = false
          }
        }
      } catch (err) {
        this.validation.newVessel.grossTons = false
        isFormValid = false
      }
      try {
        let disWght = parseFloat(this.newVessel.displacementTons)
        if ( disWght !== undefined && disWght >= 0 ) {
          this.validation.newVessel.displacementTons = true
        } else {
          this.validation.newVessel.displacementTons = false
          isFormValid = false
        }
      } catch (err) {
        this.validation.newVessel.displacementTons = false
        isFormValid = false
      }
      try {
        let length = parseFloat(this.newVessel.overallLengthMeter)
        if ( length !== undefined && length >= 0 ) {
          this.validation.newVessel.overallLengthMeter = true
        } else {
          this.validation.newVessel.overallLengthMeter = false
          isFormValid = false
        }
      } catch (err) {
        this.validation.newVessel.overallLengthMeter = false
        isFormValid = false
      }
      try {
        let waterLineLength = parseFloat(this.newVessel.waterLineLengthMeter)
        if ( waterLineLength !== undefined && waterLineLength >= 0 ) {
          this.validation.newVessel.waterLineLengthMeter = true
        } else {
          this.validation.newVessel.waterLineLengthMeter = false
          isFormValid = false
        }
      } catch (err) {
        this.validation.newVessel.waterLineLengthMeter = false
        isFormValid = false
      }
      try {
        let beam = parseFloat(this.newVessel.beamMeter)
        if ( beam !== undefined && beam >= 0 ) {
          this.validation.newVessel.beamMeter = true
        } else {
          this.validation.newVessel.beamMeter = false
          isFormValid = false
        }
      } catch (err) {
        this.validation.newVessel.beamMeter = false
        isFormValid = false
      }
      try {
        let draft = parseFloat(this.newVessel.draftMeter)
        if ( draft !== undefined && draft >= 0 ) {
          this.validation.newVessel.draftMeter = true
        } else {
          this.validation.newVessel.draftMeter = false
          isFormValid = false
        }
      } catch (err) {
        this.validation.newVessel.draftMeter = false
        isFormValid = false
      }

      if (this.newVessel.hulls && this.newVessel.hulls.trim().length > 0 ) {
        this.validation.newVessel.hulls = true
      } else {
        if (this.newVessel.hulls) {
          this.newVessel.hulls = this.newVessel.hulls.trim()
        }
        this.validation.newVessel.hulls = false
        isFormValid = false
      }
      if (this.newVessel.propulsion && this.newVessel.propulsion.trim().length > 0 ) {
        this.validation.newVessel.propulsion = true
      } else {
        if (this.newVessel.propulsion) {
          this.newVessel.propulsion = this.newVessel.propulsion.trim()
        }
        this.validation.newVessel.propulsion = false
        isFormValid = false
      }

      if ( !isFormValid ) {
        return
      }

      if ( this.convertFromFeetToMeters ) {
        // need to convert user input from feet to meters before submitting....
        this.newVessel.overallLengthMeter = this.convertFeetToMeters(this.newVessel.overallLengthMeter)
        this.newVessel.waterLineLengthMeter = this.convertFeetToMeters(this.newVessel.waterLineLengthMeter)
        this.newVessel.beamMeter = this.convertFeetToMeters(this.newVessel.beamMeter)
        this.newVessel.draftMeter = this.convertFeetToMeters(this.newVessel.draftMeter)
      }

      // if validated, then send to back-end and good to go!
      this.activelySubmittingNewVessel = true
      api.addVessel(this.newVessel).then(theNewVessel => {
        if (theNewVessel && theNewVessel.id ) {
          let addVesselToast = new Toast(document.getElementById('addVesselToast'))
          addVesselToast.show()
          this.$emit('setVessel', theNewVessel)
          this.setVesselCalled = true
          this.activelySubmittingNewVessel = false
        }
        this.addVesModal.hide()
      })
    },
    convertFeetToMeters(aLengthFeet) {
      return (aLengthFeet/3.28084).toFixed(2)
    },
    calculateGrossTonnage() {
      let overallLengthMeter = this.newVessel.overallLengthMeter
      let beamMeter = this.newVessel.beamMeter
      let draftMeter = this.newVessel.draftMeter
      if ( this.convertFromFeetToMeters ) {
        overallLengthMeter = this.convertFeetToMeters(overallLengthMeter)
        beamMeter = this.convertFeetToMeters(beamMeter)
        draftMeter = this.convertFeetToMeters(draftMeter)
      }

      if ( this.newVessel.propulsion && overallLengthMeter && beamMeter && draftMeter ) {
        if ( !this.newVessel.grossTons ) {
          let grossTons = 0
          if (this.newVessel.propulsion === 'sail') {
            if (this.newVessel.hulls === '1') {
              if ( draftMeter > 2 ) {
                // with a deeper keel
                grossTons = (0.375 * (parseFloat(overallLengthMeter * 3.28084) * parseFloat(beamMeter * 3.28084) * (parseFloat(draftMeter) + 0.9144) * 3.28084)) / 100
              } else {
                // simple sailing hull
                grossTons = (0.5 * (parseFloat(overallLengthMeter * 3.28084) * parseFloat(beamMeter * 3.28084) * (parseFloat(draftMeter) + 0.9144) * 3.28084)) / 100
              }
            } else {
              // simple sailing hull - even though it is a catamaran, USCG classifies it has a monohull!
              grossTons = (0.5 * (parseFloat(overallLengthMeter * 3.28084) * parseFloat(beamMeter * 3.28084) * (parseFloat(draftMeter)) * 3.28084)) / 100
            }
          } else if (this.newVessel.propulsion === 'power') {
            // simple powerboat
            grossTons = (0.67 * (parseFloat(overallLengthMeter * 3.28084) * parseFloat(beamMeter * 3.28084) * (parseFloat(draftMeter)) * 3.28084)) / 100
          }
          this.newVessel.grossTons = grossTons.toFixed(1)
        }
      }
    }
  },
  components: {

  }
}
</script>

<style>
.search-spinner {
  text-align: center;
  margin-top:5px;
  font-size:18px;
  color:#666;
  font-weight: normal;
  z-index: 9999;
}
.selected-row {
  background-color: #0d6efd25;
}
</style>