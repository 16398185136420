import axios from "axios";
import store from "../store/store";
import VueJwtDecode from "vue-jwt-decode";
import auth from "./auth";

const apiBase = process.env.VUE_APP_CHARTER_RODE_API_ENDPOINT

function processErrorResponse(errorDetail) {
    console.log(errorDetail)
    alert(errorDetail.message)
}

async function checkForValidToken() {
    let jwtToken = undefined
    if ( !localStorage.getItem('jwtToken') ) {
        this.commit('setLoggedOut')
        return
    } else {
        jwtToken = localStorage.getItem('jwtToken')
    }
    let decoded = VueJwtDecode.decode(jwtToken);
    if (decoded && (!decoded.exp || parseInt(decoded.exp) * 1000 <= new Date().getTime())) {
        // invalid token!
        // try to refresh...
        jwtToken = await auth.refreshToken()
        if ( jwtToken && jwtToken.trim().length > 0 ) {
            store.commit('setJWTToken', jwtToken)
        } else {
            store.commit('setLoggedOut')
            // maybe force home screen nav which will force logout?
            window.location = '/sign-in'
            return;
        }
    }
    return store.state.isLoggedIn
}

let api = {

    checkForValidAuthToken: async () => {
        if ( !await checkForValidToken() ) {
            return false
        }
        return true
    },

    getMe: async () => {
        if ( !await checkForValidToken() ) {
            return
        }
        try {
            const response = await axios({
                method: 'get',
                headers: {'Authorization': 'Bearer '+localStorage.getItem('jwtToken')},
                url: apiBase+'/me'
            });
            if ( response.data.responseType === "SUCCESS" ) {
                return {user:response.data.returnObject,error:false,createNewProfile:false}
            } else if ( response.data.responseType === "ERROR" ) {
                // hmm what to do here? - nothing as this is an okay for new user error
                // processErrorResponse(response.data.errorDetail)
                if ( response.data.errorDetail && response.data.errorDetail.message &&
                    response.data.errorDetail.message.toLowerCase() === 'there is no user' ) {
                    return {user:null,error:false,createNewProfile:true}
                }
            }
        } catch (error) {
            console.log(error);
        }
        // default is an unrecoverable error
        return {user:null,error:true,createNewProfile:false}
    },

    getResumeFor: async (sailorId) => {
        if ( !await checkForValidToken() ) {
            return
        }
        try {
            const response = await axios({
                method: 'get',
                url: apiBase+'/sailorResume/'+sailorId
            });
            if ( response.data.responseType === "SUCCESS" ) {
                return response.data.returnObject
            } else if ( response.data.responseType === "ERROR" ) {
                // hmm what to do here? - nothing as this is an okay for new user error
                // processErrorResponse(response.data.errorDetail)
            }
        } catch (error) {
            console.log(error);
        }
    },

    getMyPublicKey: async () => {
        if ( !await checkForValidToken() ) {
            return
        }
        try {
            const response = await axios({
                method: 'post',
                headers: {'Authorization': 'Bearer '+localStorage.getItem('jwtToken')},
                url: apiBase+'/me/publicKey'
            });
            if ( response.data.responseType === "SUCCESS" ) {
                return response.data.returnObject
            } else if ( response.data.responseType === "ERROR" ) {
                // hmm what to do here?
                processErrorResponse(response.data.errorDetail)
            }
        } catch (error) {
            console.log(error);
        }
    },

    getMyWalletTransactions: async () => {
        if ( !await checkForValidToken() ) {
            return
        }
        try {
            const response = await axios({
                method: 'post',
                headers: {'Authorization': 'Bearer '+localStorage.getItem('jwtToken')},
                url: apiBase+'/me/walletTransactions'
            });
            if ( response.data.responseType === "SUCCESS" ) {
                return response.data.returnObject
            } else if ( response.data.responseType === "ERROR" ) {
                // hmm what to do here?
                processErrorResponse(response.data.errorDetail)
            }
        } catch (error) {
            console.log(error);
        }
    },

    loadAvailableCredentials: async () => {
        if ( !await checkForValidToken() ) {
            return
        }
        try {
            const response = await axios({
                method: 'get',
                headers: {'Authorization': 'Bearer '+localStorage.getItem('jwtToken')},
                url: apiBase+'/ui/availableCredentials'
            });
            if ( response.data.responseType === "SUCCESS" ) {
                return response.data.returnObject
            } else if ( response.data.responseType === "ERROR" ) {
                // hmm what to do here?
                processErrorResponse(response.data.errorDetail)
            }
        } catch (error) {
            console.log(error);
        }
    },

    createUser: async (theNewUser) => {
        if ( !await checkForValidToken() ) {
            return
        }
        try {
            const response = await axios({
                method: 'post',
                headers: {'Authorization': 'Bearer '+localStorage.getItem('jwtToken')},
                url: apiBase+'/me/create',
                data: theNewUser
            });
            if ( response.data.responseType === "SUCCESS" ) {
                return response.data.returnObject
            } else if ( response.data.responseType === "ERROR" ) {
                // hmm what to do here?
                processErrorResponse(response.data.errorDetail)
            }
        } catch (error) {
            console.log(error);
        }
    },

    getSignedS3UrlForUpload: async  (fileName, fileType) => {
        if ( !await checkForValidToken() ) {
            return
        }
        try {
            const response = await axios({
                method: 'post',
                headers: {'Authorization': 'Bearer '+localStorage.getItem('jwtToken')},
                url: apiBase+'/me/getSignedS3UrlForUpload',
                data: {
                    fileName: fileName,
                    fileType: fileType
                }

            })
            if ( response.data.responseType === "SUCCESS" ) {
                return response.data.returnObject
            } else if ( response.data.responseType === "ERROR" ) {
                // hmm what to do here?
                processErrorResponse(response.data.errorDetail)
            }
        } catch (error) {
            console.log(error);
        }
    },

    uploadFileToS3WithSignedUrl: async  (theFile, signedRequest, progressCallback) => {
        try {
            const response = await axios({
                method: 'put',
                headers: {'Content-Type': theFile.type},
                url: signedRequest,
                data: theFile,
                onUploadProgress: (progressEvent) => {
                    if (progressEvent ) {
                        const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');
                        // console.log("onUploadProgress", totalLength);
                        if (totalLength !== null) {
                            progressCallback(Math.round((progressEvent.loaded * 100) / totalLength));
                        }
                    }
                }
            })
            // console.log (JSON.stringify(response))
            if ( response && response.config && response.config.url ) {
                return response.config.url.substr(0,response.config.url.indexOf("?"))
            }
            processErrorResponse({message:"something went wrong during upload"})
        } catch (error) {
            console.log(error);
        }
    },

    updateUser: async (theNewUser) => {
        if ( !await checkForValidToken() ) {
            return
        }
        try {
            const response = await axios({
                method: 'post',
                headers: {'Authorization': 'Bearer '+localStorage.getItem('jwtToken')},
                url: apiBase+'/me/update',
                data: theNewUser
            });
            if ( response.data.responseType === "SUCCESS" ) {
                return response.data.returnObject
            } else if ( response.data.responseType === "ERROR" ) {
                // hmm what to do here?
                processErrorResponse(response.data.errorDetail)
            }
        } catch (error) {
            console.log(error);
        }
    },

    checkIdVerificationVoucherCode: async (theVoucherCodeToCheck) => {
        if ( !await checkForValidToken() ) {
            return
        }
        try {
            const response = await axios({
                method: 'post',
                headers: {'Authorization': 'Bearer '+localStorage.getItem('jwtToken')},
                url: apiBase+'/me/checkIdVerificationVoucherCode',
                data: {idVoucherCode: theVoucherCodeToCheck}
            });
            if ( response.data.responseType === "SUCCESS" ) {
                return response.data.returnObject
            } else if ( response.data.responseType === "ERROR" ) {
                // hmm what to do here?
                processErrorResponse(response.data.errorDetail)
            }
        } catch (error) {
            console.log(error);
        }
    },

    markNewUserWorkflowComplete: async () => {
        if ( !await checkForValidToken() ) {
            return
        }
        try {
            const response = await axios({
                method: 'post',
                headers: {'Authorization': 'Bearer '+localStorage.getItem('jwtToken')},
                url: apiBase+'/me/markNewUserWorkflowComplete',
                data: {}
            });
            if ( response.data.responseType === "SUCCESS" ) {
                return response.data.returnObject
            } else if ( response.data.responseType === "ERROR" ) {
                // hmm what to do here?
                processErrorResponse(response.data.errorDetail)
            }
        } catch (error) {
            console.log(error);
        }
    },

    searchVessels: async (searchCriteria) => {
        if ( !await checkForValidToken() ) {
            return
        }
        try {
            const response = await axios({
                method: 'post',
                headers: {'Authorization': 'Bearer '+localStorage.getItem('jwtToken')},
                url: apiBase+'/me/searchVessels',
                data: {searchString: searchCriteria}
            });
            if ( response.data.responseType === "SUCCESS" ) {
                return response.data.returnObject
            } else if ( response.data.responseType === "ERROR" ) {
                // hmm what to do here?
                processErrorResponse(response.data.errorDetail)
            }
        } catch (error) {
            console.log(error);
        }
    },

    searchCremates: async (searchCriteria) => {
        if ( !await checkForValidToken() ) {
            return
        }
        try {
            const response = await axios({
                method: 'post',
                headers: {'Authorization': 'Bearer '+localStorage.getItem('jwtToken')},
                url: apiBase+'/me/searchCrewmates',
                data: {searchString: searchCriteria}
            });
            if ( response.data.responseType === "SUCCESS" ) {
                return response.data.returnObject
            } else if ( response.data.responseType === "ERROR" ) {
                // hmm what to do here?
                processErrorResponse(response.data.errorDetail)
            }
        } catch (error) {
            console.log(error);
        }
    },

    inviteNewCrewmate: async (newCrewmateInviteRequest) => {
        if ( !await checkForValidToken() ) {
            return
        }
        try {
            const response = await axios({
                method: 'post',
                headers: {'Authorization': 'Bearer '+localStorage.getItem('jwtToken')},
                url: apiBase+'/me/inviteCrewmate',
                data: newCrewmateInviteRequest
            });
            if ( response.data.responseType === "SUCCESS" ) {
                return response.data.returnObject
            } else if ( response.data.responseType === "ERROR" ) {
                // hmm what to do here?
                processErrorResponse(response.data.errorDetail)
            }
        } catch (error) {
            console.log(error);
        }
    },

    addVessel: async (theNewVessel) => {
        if ( !await checkForValidToken() ) {
            return
        }
        try {
            const response = await axios({
                method: 'post',
                headers: {'Authorization': 'Bearer '+localStorage.getItem('jwtToken')},
                url: apiBase+'/me/addNewVessel',
                data: theNewVessel
            });
            if ( response.data.responseType === "SUCCESS" ) {
                return response.data.returnObject
            } else if ( response.data.responseType === "ERROR" ) {
                // hmm what to do here?
                processErrorResponse(response.data.errorDetail)
            }
        } catch (error) {
            console.log(error);
        }
    },

    addVesselsAsFavorite: async (theVesselIdToAdd) => {
        if ( !await checkForValidToken() ) {
            return
        }
        try {
            const response = await axios({
                method: 'post',
                headers: {'Authorization': 'Bearer '+localStorage.getItem('jwtToken')},
                url: apiBase+'/me/addVesselAsFavorite',
                data: {vesselId: theVesselIdToAdd}
            });
            if ( response.data.responseType === "SUCCESS" ) {
                return response.data.returnObject
            } else if ( response.data.responseType === "ERROR" ) {
                // hmm what to do here?
                processErrorResponse(response.data.errorDetail)
            }
        } catch (error) {
            console.log(error);
        }
    },

    removeVesselsFromFavorites: async (theVesselIdsToRemove) => {
        if ( !await checkForValidToken() ) {
            return
        }
        try {
            const response = await axios({
                method: 'post',
                headers: {'Authorization': 'Bearer '+localStorage.getItem('jwtToken')},
                url: apiBase+'/me/removeVessels',
                data: {vesselIds: theVesselIdsToRemove}
            });
            if ( response.data.responseType === "SUCCESS" ) {
                return response.data.returnObject
            } else if ( response.data.responseType === "ERROR" ) {
                // hmm what to do here?
                processErrorResponse(response.data.errorDetail)
            }
        } catch (error) {
            console.log(error);
        }
    },

    addCrewmatesAsFavorite: async (theCremateIdToAdd) => {
        if ( !await checkForValidToken() ) {
            return
        }
        try {
            const response = await axios({
                method: 'post',
                headers: {'Authorization': 'Bearer '+localStorage.getItem('jwtToken')},
                url: apiBase+'/me/addCrewmateAsFavorite',
                data: {crewmateId: theCremateIdToAdd}
            });
            if ( response.data.responseType === "SUCCESS" ) {
                return response.data.returnObject
            } else if ( response.data.responseType === "ERROR" ) {
                // hmm what to do here?
                processErrorResponse(response.data.errorDetail)
            }
        } catch (error) {
            console.log(error);
        }
    },

    removeCrewmatesFromFavorites: async (theCremateIdsToRemove) => {
        if ( !await checkForValidToken() ) {
            return
        }
        try {
            const response = await axios({
                method: 'post',
                headers: {'Authorization': 'Bearer '+localStorage.getItem('jwtToken')},
                url: apiBase+'/me/removeCrewmate',
                data: {crewmateIds: theCremateIdsToRemove}
            });
            if ( response.data.responseType === "SUCCESS" ) {
                return response.data.returnObject
            } else if ( response.data.responseType === "ERROR" ) {
                // hmm what to do here?
                processErrorResponse(response.data.errorDetail)
            }
        } catch (error) {
            console.log(error);
        }
    },

    getUIDropdowns: async () => {
        if ( !await checkForValidToken() ) {
            return
        }
        try {
            const response = await axios({
                method: 'get',
                headers: {'Authorization': 'Bearer '+localStorage.getItem('jwtToken')},
                url: apiBase+'/ui/dropDowns'
            });
            if ( response.data.responseType === "SUCCESS" ) {
                return response.data.returnObject
            } else if ( response.data.responseType === "ERROR" ) {
                // hmm what to do here?
                processErrorResponse(response.data.errorDetail)
            }
        } catch (error) {
            console.log(error);
        }
    },

    submitIdentityVerificationTx: async (frontImageUrl, rearImageUrl) => {
        if ( !await checkForValidToken() ) {
            return
        }
        try {
            const response = await axios({
                method: 'post',
                headers: {'Authorization': 'Bearer '+localStorage.getItem('jwtToken')},
                url: apiBase+'/tx/submit',
                data: {
                    "identityVerification": {
                        "frontImage": frontImageUrl,
                        "rearImage": rearImageUrl
                    }
                }
            });
            if ( response.data.responseType === "ERROR" ) {
                // hmm what to do here?
                processErrorResponse(response.data.errorDetail)
            }
            return response.data.responseType
        } catch (error) {
            console.log(error);
        }
    },

    submitTransaction: async (newTxRequest) => {
        if ( !await checkForValidToken() ) {
            return
        }
        try {
            const response = await axios({
                method: 'post',
                headers: {'Authorization': 'Bearer '+localStorage.getItem('jwtToken')},
                url: apiBase+'/tx/submit',
                data: newTxRequest
            });
            if ( response.data.responseType === "SUCCESS" ) {
                return response.data.returnObject
            } else if ( response.data.responseType === "ERROR" ) {
                // hmm what to do here?
                processErrorResponse(response.data.errorDetail)
            }
        } catch (error) {
            console.log(error);
        }
    },

    getMyTransactions: async () => {
        if ( !await checkForValidToken() ) {
            return
        }
        try {
            const response = await axios({
                method: 'post',
                headers: {'Authorization': 'Bearer '+localStorage.getItem('jwtToken')},
                url: apiBase+'/me/transactions'
            });
            if ( response.data.responseType === "SUCCESS" ) {
                return response.data.returnObject
            } else if ( response.data.responseType === "ERROR" ) {
                // hmm what to do here?
                processErrorResponse(response.data.errorDetail)
            }
        } catch (error) {
            console.log(error);
        }
    },

    getMyTransactionsToVerify: async () => {
        if ( !await checkForValidToken() ) {
            return
        }
        try {
            const response = await axios({
                method: 'post',
                headers: {'Authorization': 'Bearer '+localStorage.getItem('jwtToken')},
                url: apiBase+'/me/transactionsToVerify'
            });
            if ( response.data.responseType === "SUCCESS" ) {
                return response.data.returnObject
            } else if ( response.data.responseType === "ERROR" ) {
                // hmm what to do here?
                processErrorResponse(response.data.errorDetail)
            }
        } catch (error) {
            console.log(error);
        }
    },

    verifyTransaction: async (aTxId, addToMyLogBook) => {
        if ( !await checkForValidToken() ) {
            return
        }
        try {
            const response = await axios({
                method: 'post',
                headers: {'Authorization': 'Bearer '+localStorage.getItem('jwtToken')},
                url: apiBase+'/tx/verify',
                data: {
                    transactionId: aTxId,
                    addToMyLogBook: addToMyLogBook ? addToMyLogBook : undefined
                }
            });
            if ( response.data.responseType === "SUCCESS" ) {
                return response.data.returnObject
            } else if ( response.data.responseType === "ERROR" ) {
                // hmm what to do here?
                processErrorResponse(response.data.errorDetail)
            }
        } catch (error) {
            console.log(error);
        }
    },

    rejectTransaction: async (aTxId, rejectReason, rejectionComments) => {
        if ( !await checkForValidToken() ) {
            return
        }
        try {
            const response = await axios({
                method: 'post',
                headers: {'Authorization': 'Bearer '+localStorage.getItem('jwtToken')},
                url: apiBase+'/tx/reject',
                data: {
                    transactionId: aTxId,
                    rejectReason: rejectReason,
                    rejectionComments: rejectionComments
                }
            });
            if ( response.data.responseType === "SUCCESS" ) {
                return response.data.returnObject
            } else if ( response.data.responseType === "ERROR" ) {
                // hmm what to do here?
                processErrorResponse(response.data.errorDetail)
            }
        } catch (error) {
            console.log(error);
        }
    },

    voidTransaction: async (aTxId, voidReason) => {
        if ( !await checkForValidToken() ) {
            return
        }
        try {
            const response = await axios({
                method: 'post',
                headers: {'Authorization': 'Bearer '+localStorage.getItem('jwtToken')},
                url: apiBase+'/tx/void',
                data: {
                    transactionId: aTxId,
                    voidReason: voidReason
                }
            });
            if ( response.data.responseType === "SUCCESS" ) {
                return response.data.returnObject
            } else if ( response.data.responseType === "ERROR" ) {
                // hmm what to do here?
                processErrorResponse(response.data.errorDetail)
            }
        } catch (error) {
            console.log(error);
        }
    },

    resubmitTransactionForPayment: async (aTxId) => {
        if ( !await checkForValidToken() ) {
            return
        }
        try {
            const response = await axios({
                method: 'post',
                headers: {'Authorization': 'Bearer '+localStorage.getItem('jwtToken')},
                url: apiBase+'/tx/resubmitForPayment',
                data: {
                    transactionId: aTxId
                }
            });
            if ( response.data.responseType === "SUCCESS" ) {
                return response.data.returnObject
            } else if ( response.data.responseType === "ERROR" ) {
                // hmm what to do here?
                processErrorResponse(response.data.errorDetail)
            }
        } catch (error) {
            console.log(error);
        }
    },

    createPaymentIntent: async (crtAmount) => {
        if ( !await checkForValidToken() ) {
            return
        }
        try {
            const response = await axios({
                method: 'post',
                headers: {'Authorization': 'Bearer '+localStorage.getItem('jwtToken')},
                url: apiBase+'/me/createPaymentIntent',
                data: {
                    crtAmount: crtAmount
                }
            });
            if ( response.data.responseType === "SUCCESS" ) {
                return response.data.returnObject
            } else if ( response.data.responseType === "ERROR" ) {
                // hmm what to do here?
                processErrorResponse(response.data.errorDetail)
            }
            return {
                clientSecret: 'jason',
                crtAmount: crtAmount,
            }
        } catch (error) {
            console.log(error);
        }
    },

    // requires admin user access.....
    getTransactionInfoForVerification: async (aTxId) => {
        if ( !await checkForValidToken() ) {
            return
        }
        try {
            const response = await axios({
                method: 'post',
                headers: {'Authorization': 'Bearer '+localStorage.getItem('jwtToken')},
                url: apiBase+'/tx/getTransactionInfoForVerification',
                data: {
                    transactionId: aTxId
                }
            });
            if ( response.data.responseType === "SUCCESS" ) {
                return response.data.returnObject
            } else if ( response.data.responseType === "ERROR" ) {
                // hmm what to do here?
                processErrorResponse(response.data.errorDetail)
            }
        } catch (error) {
            console.log(error);
        }
    }
}
export default api